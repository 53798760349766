.solutions-introducing {
  padding: 100px 0;
  background: #fff;

  @include viewport--md {
    padding: 68px 0;
  }

  @include viewport--sm {
    padding: 45px 0;
  }

  .container {
    padding: 0px 109px;
    position: relative;

    @include viewport--lg {
      padding: 0 60px;
    }

    @include viewport--md {
      padding: 0 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @include viewport--sm {
      padding: 0 16px;
    }
  }

  &__subtitle {
    margin-bottom: 75px;

    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
    text-align: center;
    color: $color-blue;

    @include viewport--sm {
      margin-bottom: 45px;
    }

    &--mob {
      display: none;

      @include viewport--sm {
        display: block;
      }
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 75px;

    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    color: $color-east-bay-2;
    text-align: center;

    span {
      // color: $color-torea-bay;
      color: $color-blue;
    }

    @include viewport--md {

      font-size: 36px;
      line-height: 44px;
    }

    @include viewport--sm {
      margin-bottom: 45px;
      font-size: 24px;
      line-height: 29px;
    }

    &--desktop {
      @include viewport--sm {
        display: none;
      }
    }

    &--mob {
      display: none;

      @include viewport--sm {
        display: block;
      }
    }
  }

  &__flex + .solutions-introducing__title {
    margin-top: 70px;
  }

  &__video-wrapper {
    position: relative;
    z-index: 0;

    align-self: center;
    width: 100%;
    max-width: 600px;
    min-width: 0;
    margin: 0 auto;

    background: linear-gradient(108.91deg, #5483EC 4.74%, #5789FF 20.36%, #5381FF 66.97%, #3D60CC 80.35%, #3B51AD 99.59%);
    border-radius: 10px;
    box-shadow: 0px 4px 18px rgba(66, 92, 176, 0.25);

    overflow: hidden;

    &--in-flex {
      width: 600px;
      min-width: 0;
      max-width: 65%;
      margin: 0;
      margin-left: 20px;

      @include viewport--md {
        order: -1;
        max-width: 557px;
        margin: 20px auto 0;
      }

      @include viewport--sm {
        width: 100%;
        max-width: 556px;
      }
    }

    &--desktop {
      @include viewport--md {
        display: none;
      }
    }

    &--tablet-mob {
      display: none;

      @include viewport--md {
        display: block;
      }
    }
  }

  &__video {
    position: relative;
    z-index: 0;
    display: block;
    width: 100%;
    padding-top: 56.3%;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 14px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 14px;
      object-fit: cover;
      cursor: pointer;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      border: 3px solid $color-blue;
      border-radius: 10px;
      pointer-events: none;
    }
  }

  // &__title {
  //   margin: 0 0 44px;
  //   font-size: 30px;
  //   line-height: 36px;
  //   color: $color-default-white;
  //   max-width: 500px;

  //   @include viewport--md {
  //     text-align: center;
  //     max-width: 560px;
  //     margin-bottom: 40px;
  //   }

  //   @include viewport--sm {
  //     font-size: 24px;
  //     line-height: 34px;
  //     margin-bottom: 30px;
  //   }
  // }

  &__flex {
    display: flex;
    justify-content: space-between;

    @include viewport--md {
      flex-direction: column-reverse;
    }

    &--vertical {
      flex-direction: column;

      @include viewport--md {
        flex-direction: column-reverse;
      }

      .solutions-introducing__description-block {
        margin-bottom: 75px;

        @include viewport--md {
          margin-bottom: 0;
        }
      }

      .solutions-introducing__description {
        max-width: 748px;
        text-align: center;
      }

      .solutions-introducing__img-desktop {
        margin-left: auto;
        margin-right: auto;

        @include viewport--lg {
          max-width: 100%;
        }
    
        @include viewport--md {
          margin: 0 auto 45px;
        }
      }

      .solutions-introducing__video-wrapper--in-flex {
        max-width: 748px;
        width: 100%;
        margin: 0;

        @include viewport--md {
          width: 94%;
          max-width: 556px;
          margin-top: 45px;
        }

        @include viewport--sm {
          order: 0;
          width: 94%;
          max-width: 556px;
          margin-top: 0;
          margin-bottom: 45px;
        }
      }
    }
  }

  &__description {
    margin: 49px auto;
    color: $color-new-grey;
    max-width: 504px;
    font-weight: 400;
    font-size: 20px;
    font-style: normal;
    line-height: 32px;
    text-align: left;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    span {
      color: $color-blue;
    }

    @include viewport--md {
      max-width: 560px;
      margin-top: 20px;
      margin-bottom: 20px;

      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.01em;

      &:last-child {
        margin: 0 auto;
      }

    }
  }

  // &__button {
  //   text-align: center;
  //   margin-top: 80px;

  //   @include viewport--sm {
  //     margin-top: 20px;
  //   }
  // }

  // .btn {
  //   min-width: 190px;

  //   @include viewport--sm {
  //     min-width: 0;
  //   }
  // }

  .demo-sm-form {
    align-self: stretch;
    margin-top: 75px;
    margin-bottom: 75px;

    @include viewport--sm {
      margin-top: 45px;
      margin-bottom: 45px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    form {
      justify-content: center;

      @include viewport--sm {
        flex-direction: column;
        align-items: stretch;
      }
    }

    input {
      @include viewport--sm {
        width: 100%;
        max-width: 100%;
        margin: 0 0 12px;
      }
    }

    .btn {
      @include viewport--sm {
        width: 100%;
      }
    }
  }

  &__img-desktop {
    margin-left: auto;

    @include viewport--lg {
      max-width: 65%;
    }

    @include viewport--md {
      max-width: 100%;
      margin: 0 auto 45px;
    }

    @include viewport--sm {
      display: none;
    }

    &--measurement-1 {
      transform: scale(1.09);
    }
  }

  &__img-mob {
    display: none;

    @include viewport--sm {
      display: block;
      margin: 0 auto 45px;
    }

    // &--measurement-1 {
    //   transform: scale(1.2) translateX(2%);
    // }

    &--optimisation {
      display: none;
    }
  }

  &--measurement {
    background: linear-gradient(180deg, #FAFBFF 0%, #FFFFFF 100%);
  }

  &--ecommerce {
    background: linear-gradient(180deg, #FAFBFF 0%, #FFFFFF 100%);

    .solutions-introducing__title {
      max-width: 629px;
      margin-left: auto;
      margin-right: auto;
    }

    .solutions-introducing__description-block {
      max-width: 683px;
      margin-left: auto;
      margin-right: auto;
    }

    .solutions-introducing__description {
      @include viewport--md {
        font-size: 20px;
        line-height: 32px;
      }
    }
  }

  &--b2b-and-saas {
    background: linear-gradient(180deg, #F8FBFF 0%, #FFFFFF 100%);

    .solutions-introducing__description {
      @include viewport--md {
        font-size: 20px;
        line-height: 32px;
      }
    }
  }

  &--optimisation {
    background: linear-gradient(180deg, #FFFFFF 0%, #FAFBFF 100%);

    @include viewport--sm {
      background: linear-gradient(180deg, #F9FAFF 0%, #FBFCFF 100%);
    }
  }
}
