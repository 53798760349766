.introductory-meeting {
  $root: &;
  background: linear-gradient(173deg, #252d72 0%, #10173a 100%);
  
  .container {
    padding: 80px 60px;

    @include viewport--md {
      padding: 55px 20px;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;

    @include viewport--sm {
      gap: 24px;
    }

  }

  &__title {
    margin: 0;
    width: 100%;
    font-family: $font-family--inter;
    color: $color-default-white;
    font-weight: 700;
    font-size: 36px;
    text-align: center;
    line-height: normal;

    @include viewport--md {
      font-size: 24px;
      line-height: 120%
    }
  }
  
  &__btn {
    border-radius: 3px;
    padding: 3px 32px;
    min-width: 280px;

    font-weight: 600;
    font-size: 20px;
    line-height: 240%;

    color: $color-default-white;

    @include viewport--md {
      min-width: 198px;
    }

    @include viewport--sm {
      width: 100%;
    }
  }

  &__card-list {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    gap: 35px;
    flex-wrap: wrap;

    @include viewport--md {
      gap: 16px;
    }
  }

  &__card {
    background-color: $color-default-white;
    box-shadow: 0 5px 50px 0 rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 35px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 370px;
    min-height: 229px;
    flex-shrink: 0;

    @include viewport--md {
      width: calc(50% - 8px);
    }

    @include viewport--sm {
      width: auto;
      flex-shrink: 1;
    }
  }

  &__card-text {
    font-family: $font-family--lato;
    padding: 0 35px 35px;
    margin: 0;
    text-align: left;
    font-weight: 700;
    font-size: 22px;
    color: $color-deep-koamaru;
    line-height: normal;
  }

  &__card-image {
    margin-left: 35px;
    margin-right: auto;
    width: 300px;
    position: relative;
    padding-bottom: 24.5%;

    @include viewport--md {
      width: 80%;
    }

    img {
      width: 100%;
      object-fit: cover;
      position: absolute;
      bottom: 35px;
    }

    &--labels {
      padding-bottom: 28.5%;
      width: 90%;

      // @include viewport--md {
      //   width: 94%;
      // }

      img {
        bottom: 25px;
      }
    }
  }

}