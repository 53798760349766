.glossary-page {
  &__content {
    margin: 18px auto 0;
  
    @include viewport--md {
      margin: 10px auto 0;
    }
  
    @include viewport--sm {
      margin: 0;
    }
  }

  &__section {
    padding: 83px 50px 75px;
    border-bottom: 1px solid #eaeeff;

    @include viewport--md {
      padding: 50px 25px;
    }

    @include viewport--sm {
      padding: 38px 0;
    }
  }

  &__section:last-child {
    border: none;
  }

  &__section-title {
    margin: 0;
    margin-bottom: 60px;
    font-family: $font-family--inter;

    color: $color-torea-bay-4;
    font-size: 38px;
    font-weight: 600;
    line-height: normal;

    @include viewport--sm {
      margin-bottom: 38px;
      font-size: 24px;
    }
  }

  &__section-list {
    margin: 0;
    padding: 0;
    list-style: none;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 156px;
    row-gap: 30px;

    font-family: $font-family--lato;
    font-size: 19px;
    font-weight: 400;
    line-height: normal;

    @include viewport--lg {
      column-gap: 60px;
    }
  
    @include viewport--md {
      column-gap: 40px;
    }
  
    @include viewport--sm {
      grid-template-columns: 1fr;
      row-gap: 30px;
      font-size: 19px;
    }
  }

  &__item-link {
    color: $color-comet-2;
    transition: 0.3s linear;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover:not(.focus-visible),
      &.focus-visible:focus {
        color: $color-blue;
      }

      &:active:not(.hack-selector) {
        color: rgba($color-blue, 0.6);
      }
    }
  }

  .container {
    padding: 0 220px;

    @include viewport--lg {
      padding: 0 140px;
    }
  
    @include viewport--md {
      padding: 0 80px;
    }
  
    @include viewport--sm {
      padding: 0 23px;
    }
  }
}
