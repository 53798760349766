.introducing-segmentstream {
  $root: &;
  padding: 75px 0;

  @include viewport--sm {
    padding: 30px 0;
  }

  &__header-wrap {
    width: 100%;
    max-width: 818px;
    margin: 0 auto 70px;

    @include viewport--sm {
      margin: 0 auto 50px;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  &__header-label {
    color: $color-blue;
    text-align: center;
    font-family: $font-family--inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0 0 55px;

    @include viewport--sm {
      color: $color-blue;
      text-align: center;
      font-family: $font-family--inter;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  &__header-title {
    color: $color-torea-bay-4;
    text-align: center;
    font-family: $font-family--inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0 0 55px;

    @include viewport--sm {
      color: $color-torea-bay-4;
      text-align: center;
      font-family: $font-family--inter;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  &__header-text {
    color: $color-port-gore;
    text-align: center;
    font-family: $font-family--lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 29.5px; /* 147.5% */
    margin: 0 0 55px;

    @include viewport--sm {
      color: $color-port-gore;
      text-align: center;
      font-family: $font-family--lato;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 29.5px; /* 163.889% */
    }
  }

  &__header-btn {
    width: fit-content;

    @include viewport--sm {
      width: 100%;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 45.5% 1fr;
    grid-template-rows: min-content 1fr;
    gap: 35px 35px;

    @include viewport--md {
      display: flex;
      flex-direction: column;
      gap: 0;
    }
  }

  &__img-wrap {
    grid-area: 1/1/3/2;

    @include viewport--md {
      width: 100%;
      margin: 0 auto 40px;
      max-width: 595px;
    }

    @include viewport--sm {
      max-width: unset;
    }

    img {
      width: 100%;
    }
  }

  &__title {
    grid-area: 1/2/2/3;
    color: $color-torea-bay-4;
    font-family: $font-family--inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 120% */
    max-width: 595px;
    margin: 0 0 0 auto;

    @include viewport--md {
      text-align: center;
      margin: 0 auto 40px;
    }

    @include viewport--sm {
      font-size: 28px;
    }
  }

  @include viewport--sm {
    color: $color-torea-bay-4;
    text-align: center;
    font-family: $font-family--inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 120% */
    max-width: unset;

  }

  &__text-wrap {
    grid-area: 2/2/3/3;
    max-width: 594px;
    margin: 0 0 0 auto;

    @include viewport--md {
      margin: 0 auto;
    }

    @include viewport--sm {
      max-width: unset;
    }
  }

  &__text {
    color: $color-port-gore;
    font-family: $font-family--lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30.2px; /* 151% */
    margin: 0;

    & + #{$root}__text {
      margin-top: 30px;
    }

    @include viewport--md {
      text-align: center;
    }

    @include viewport--sm {
      color: $color-port-gore;
      text-align: center;
      font-family: $font-family--lato;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 30.2px; /* 167.778% */
    }
  }

  &--ai-driven-attribution {
    background-color: $color-zircon-3;
    padding-top: 75px;

    @include viewport--sm {
      padding-top: 20px;
    }

    .container {
      padding: 0 100px;

      @include viewport--lg {
        padding: 0 60px;
      }

      @include viewport--sm {
        padding: 0 20px;
      }
    }

    #{$root}__header-label {
      @include viewport--sm {
        margin-bottom: 38px;
      }
    }

    #{$root}__header-title {
      @include viewport--sm {
        margin-bottom: 38px;
      }
    }

    #{$root}__header-text {
      @include viewport--sm {
        margin-bottom: 38px;
      }
    }
  }
}