.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;

  clip: rect(0 0 0 0);
}

.wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  //min-height: 100vh;
  min-width: $viewport--sm;

  @include viewport--md {
    overflow-x: hidden;
  }

  // используйте для страниц с min-height: 100vh для визуального скрытия скроллбара
  &--no-scrollbar {
    height: 100vh;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .header,
  .footer {
    flex-shrink: 0;
  }

  main {
    position: relative;
    flex-grow: 1;

    @include viewport--md {
      margin-top: 107px;
    }

    @include viewport--sm {
      margin-top: 77px;
    }
  }
}

.scroll-lock {
  overflow: hidden;
}

.btn-reset {
  padding: 0;

  background: $color-transparent;
  border: none;
  cursor: pointer;
}

.gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 600px;
  background-image: $almost-white;
  z-index: -1;

  @include viewport--md {
    height: 800px;
  }

  @include viewport--sm {
    height: 600px;
  }
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}
