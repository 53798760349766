.main-intro {
  background: $almost-white no-repeat;

  padding: 77px 0 56px;

  @include viewport--md {
    padding: 50px 0;
  }

  @include viewport--sm {
    padding: 30px 0 28px;
  }

  .container {
    position: relative;

    @include viewport--md {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__title {
    width: 380px;
    margin: 0 0 32px;

    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    color: $color-black;

    @include viewport--md {
      width: 470px;

      font-size: 36px;
      line-height: 44px;
      text-align: center;
    }

    @include viewport--sm {
      width: 100%;
      max-width: 350px;
      margin-bottom: 14px;

      font-size: 28px;
      line-height: 36px;
    }
  }

  &__description {
    width: 380px;
    margin: 0 0 60px;

    font-size: 18px;
    line-height: 26px;
    color: $color-ship-cove;

    @include viewport--md {
      width: 470px;
      margin: 0 0 50px;

      font-size: 18px;
      line-height: 26px;
      text-align: center;
    }

    @include viewport--sm {
      width: 100%;
      max-width: 350px;
      margin: 0 0 34px;

      font-size: 16px;
      line-height: 24px;
    }
  }

  img {
    position: absolute;
    top: 20px;
    right: 85px;
    max-width: 659px;

    @include viewport--lg {
      right: 2.5vw;
    }

    @media (max-width: 1279px) {
      width: 50%;
      top: 50%;
      transform: translateY(-50%);
    }

    @include viewport--md {
      position: static;
      margin-right: 20px;
      transform: none;

      width: 100%;
    }

    @include viewport--sm {
      margin-right: 12px;
    }
  }

  .img-fix {
    @include viewport--md {
      max-width: 659px;

      img {
        left: -20px;
      }
    }

    @include viewport--sm {
      img {
        left: -12px;
      }
    }
  }

  &__action {
    width: 380px;
    display: flex;
    align-items: center;

    @include viewport--md {
      width: 470px;
      justify-content: center;
      margin-top: 40px;
    }

    @include viewport--sm {
      width: 100%;
      flex-direction: column;
      margin-top: 24px;

      .btn {
        min-width: 236px;
      }
    }
  }

  &__more-link {
    margin-left: 40px;
    margin-right: 32px;

    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: $color-blue;
    transition: color $default-transition-settings;
    text-decoration: underline;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-black;
      }
    }

    @include viewport--sm {
      margin-top: 26px;
      margin-left: 0;
      margin-right: 0;
    }
  }
}
