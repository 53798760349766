.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100000;

  display: flex;
  flex-direction: column;
  overflow-y: auto;

  background-color: rgba($color-default-black, 0.6);
  visibility: hidden;
  opacity: 0;
  transform: scale(1.2);
  transition: opacity $modal-transition-settings,
    transform $modal-transition-settings,
    visibility $modal-transition-settings;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__wrapper {
    position: relative;

    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
    padding: 60px 40px;

    @include viewport--sm {
      padding: 20px 16px;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    cursor: pointer;
  }

  &__content {
    position: relative;

    width: 600px;
    padding: 40px;

    background-color: $color-default-white;

    @include viewport--sm {
      width: 100%;
      padding: 20px;
    }
  }

  &__close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    transition: transform $default-transition-settings;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        transform: scale(1.1);
      }
    }
  }

  &--responsive {
    .modal__content {
      width: 100%;
    }
  }

  &--no-scale {
    transform: none;
  }

  &--active {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }

  &--disabled {
    display: none;
  }
}

#hubspot-messages-iframe-container {
  z-index: 99999 !important;
}
