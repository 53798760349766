.link-back {
  box-sizing: content-box;
  width: calc(100% - 120px);
  max-width: 1200px;
  margin: 45px auto 0;
  padding: 0 60px;

  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: $color-blue;
  font-family: $font-family--inter;

  @include viewport--md {
    width: auto;
    margin: 50px auto 0;
    padding: 0 40px;
  }

  @include viewport--sm {
    margin: 29px auto 0;
    padding: 0 18px;
    font-size: 16px;
    line-height: 19px;
  }

  svg {
    display: block;
    margin-right: 19px;

    fill: $color-blue;
    transition: transform $default-transition-settings, fill $default-transition-settings;

    @include viewport--md {
      margin-right: 15px;
    }
  }

  a {
    display: flex;
    align-items: center;
    width: max-content;

    color: inherit;
    transition: color $default-transition-settings;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-black;

        svg {
          fill: $color-black;
          transform: translateX(-20%);
        }
      }
    }
  }

  &--ghost {
    margin: 0;
    padding: 0;
  }

  &--white {
    color: $color-default-white;

    svg {
      fill: $color-default-white;
    }

    a {
      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-melrose-2;
  
          svg {
            fill: $color-melrose-2;
          }
        }
      }
    }
  }
}
