.traditional-marketing {
  $root: &;

  padding-top: 100px;
  padding-bottom: 50px;

  @include viewport--md {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @include viewport--sm {
    padding-top: 20px;
    padding-bottom: 55px;
  }

  .container {
    padding: 0 130px;

    @include viewport--lg {
      padding: 0 60px;
    }

    @include viewport--sm {
      padding: 0 30px;
    }
  }

  &__title {
    color: $color-torea-bay-4;
    text-align: center;
    font-family: $font-family--inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    margin: 0 0 100px;

    span {
      color: $color-blue;
    }

    @include viewport--md {
      margin-bottom: 30px;
    }

    @include viewport--sm {
      color: $color-blue;
      text-align: center;
      font-family: $font-family--inter;
      font-size: 29px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0 0 45px;
    }
  }

  &__item {
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }

    @include viewport--md {
      margin-bottom: 44px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &--attribution-no-longer {
    padding-bottom: 97px;

    #{$root}__item {
      &:nth-child(1) {
        margin-bottom: 70px;
      }
      &:nth-child(2) {
        margin-bottom: 84px;
      }
      &:nth-child(3) {
        margin-bottom: 0;
      }
    }
  }

  &--digital-budget {
    padding-top: 73px;
    padding-bottom: 48px;

    @include viewport--md {
      padding-top: 36px;
      padding-bottom: 50px;
    }

    .container {
      @include viewport--lg {
        padding: 0 130px;
      }

      @include viewport--1280 {
        padding: 0 60px;
      }

      @include viewport--md {
        padding: 0 20px;
      }
    }

    #{$root}__title {
      @include viewport--md {
        font-size: 24px;
        line-height: 120%;
        margin: 0 auto 45px;
        max-width: 400px;
      }

      @include viewport--sm {
        // text-align: center;
        // padding: 0 10px;
        color: $color-deep-koamaru;
      }
    }

    #{$root}__item {
      margin-bottom: 120px;

      @include viewport--md {
        margin-bottom: 45px;
      }

      &:last-child {
        margin-bottom: 0;

        .marketing-card__text-wrap {
          @include viewport--sm {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &--marketing-mix-optimization {
    padding: 0;

    .container {
      padding: 75px 100px;

      @include viewport--1280 {
        padding: 75px 60px;
      }

      @include viewport--md {
        padding: 50px 20px 0;
      }

      @include viewport--sm {
        padding: 50px 20px 0;
      }
    }

    #{$root}__title {
      @include viewport--md {
        font-size: 24px;
        line-height: 120%;
        margin-bottom: 10px;
      }

      @include viewport--sm {
        color: $color-torea-bay-4;
      }
    }

    #{$root}__item {
      margin-bottom: 100px;

      @include viewport--md {
        margin: 0;
        padding: 25px 0;
      }

      @include viewport--sm {
        margin: 0;
      }

      &:last-child {
        margin-bottom: 0;

        .marketing-card__text-wrap {
          @include viewport--sm {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &--ai-driven-attribution {
    padding-top: 85px;
    padding-bottom: 20px;

    @include viewport--md {
      padding-bottom: 50px;
    }

    @include viewport--sm {
      padding-top: 50px;
      padding-bottom: 20px;
      margin-bottom: 16px;
    }

    .container {
      padding: 0 120px;
      padding-right: 80px;

      @include viewport--1280 {
        padding: 0 60px;
      }

      @include viewport--md {
        padding: 0 40px;
      }

      @include viewport--sm {
        padding: 0 20px;
      }
    }
  }

  &--ai-driven-attribution-2 {
    padding-top: 25px;
    padding-bottom: 85px;

    @include viewport--md {
      padding-bottom: 50px;
    }

    @include viewport--sm {
      padding-bottom: 55px;
    }

    .container {
      padding: 0 100px;

      @include viewport--lg {
        padding: 0 60px;
      }

      @include viewport--md {
        padding: 0 40px;
      }

      @include viewport--sm {
        padding: 0 20px;
      }
    }

    #{$root}__title {
      margin-bottom: 55px;
    }

    #{$root}__item {
      margin-bottom: 55px;

      @include viewport--sm {
        margin-bottom: 50px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &--ai-driven-attribution-3 {
    padding-top: 100px;
    padding-bottom: 100px;

    @include viewport--1280 {
      padding-top: 75px;
      padding-bottom: 75px;
    }

    @include viewport--sm {
      padding-top: 55px;
      padding-bottom: 55px;
    }
  }

  &--solutions-page {
    padding-top: 75px;
    padding-bottom: 75px;

    @include viewport--sm {
      padding-top: 50px;
      padding-bottom: 20px;
    }

    .container {
      @include viewport--sm {
        padding: 0 20px;
      }
    }

    #{$root}__item {
      margin-bottom: 100px;

      @include viewport--md {
        margin-bottom: 70px;
      }

      @include viewport--sm {
        margin-bottom: 50px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &--incrementality-testing-page {
    padding-top: 75px;
    padding-bottom: 75px;

    @include viewport--sm {
      padding-top: 50px;
      padding-bottom: 20px;
    }

    .container {
      @include viewport--sm {
        padding: 0 20px;
      }
    }

    #{$root}__item {
      margin-bottom: 125px;

      @include viewport--md {
        margin-bottom: 70px;
      }

      @include viewport--sm {
        margin-bottom: 50px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &--synthetic-conversions {
    padding-top: 157px;

    .container {
      padding-left: 95px;
      padding-right: 100px;
    }

    @include viewport--lg {
      .container {
        padding-left: 60px;
        padding-right: 60px;
      }
    }

    @include viewport--1280 {
      padding-top: 75px;
      padding-bottom: 75px;
    }

    @include viewport--md {
      .container {
        padding-left: 40px;
        padding-right: 40px;
      }
    }

    @include viewport--sm {
      padding-top: 55px;
      padding-bottom: 55px;

      .container {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  &--ltv-forecasting-solution {
    padding-top: 108px;
    padding-bottom: 100px;

    .container {
      padding-left: 120px;
      padding-right: 120px;
    }

    @include viewport--lg {
      .container {
        padding: 0 60px;
      }
    }

    @include viewport--1280 {
      padding-top: 75px;
      padding-bottom: 75px;
    }

    @include viewport--md {
      .container {
        padding: 0 40px;
      }
    }

    @include viewport--sm {
      padding-top: 42px;
      padding-bottom: 42px;

      .container {
        padding: 0 16px;
      }
    }
  }
}
