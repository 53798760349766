.about {
  font-family: $font-family--inter;
  text-align: center;
  color: #33376b;

  &__inner {
    padding-top: 112px;
    padding-bottom: 112px;

    @include viewport--md {
      padding: 112px 20px;
    }

    @include viewport--sm {
      padding: 88px 16px;

      display: flex;
      flex-direction: column
    }
  }

  &__title {
    @include title-h2;

    margin: 0 auto 24px;
    max-width: 550px;
    color: #03113c;

    @include viewport--md {
      max-width: 480px;
    }
  }

  &__description {
    @include text-body;

    max-width: 550px;
    margin: 0 auto 56px;

    @include viewport--md {
      max-width: 480px;
    }

    @include viewport--sm {
      margin-bottom: 45px;
    }
  }

  &__brands-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0 7px;
    margin: 0 auto 100px;
    gap: 20px;
    max-width: 900px;

    @include viewport--md {
      margin: 0 auto 110px;
      max-width: 100%;
      padding: 0;
      gap: 24px;
    }

    @include viewport--sm {
      width: auto;
      margin: 0 0 45px;

      order: 4;

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;
    }
  }

  &__brands-item {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;

    img {
      max-width: 189px;
      height: auto;
      max-height: 43px;
      object-fit: contain;
    }

    @include viewport--sm {
      border: 1px solid rgba(175, 185, 215, 0.4);
      border-radius: 8px;
      height: 72px;

      padding: 12px;
      width: auto;

      img {
        max-width: 100%;
      }
    }
  }

  &__map {
    display: flex;
    flex-direction: column;
    margin-bottom: 112px;

    @include viewport--md {
      margin-bottom: 64px;
    }

    @include viewport--sm {
      margin-bottom: 56px;
    }
  }

  &__map-title {
    display: grid;
    grid-template-columns: 44px auto;
    align-items: center;
    gap: 10px;

    background: #f2f5ff;
    color: #4d6dd3;
    border-radius: 80px;
    padding: 6px;
    padding-right: 24px;
    margin: 0 0 64px;

    align-self: center;

    @include title-h4;

    @include viewport--sm {
      margin-bottom: 56px;
    }
  }

  &__map-group {
    display: grid;
    grid-template-columns: 100%;
    gap: 32px;

    &--tablet,
    &--mobile {
      display: none;
    }

    @include viewport--md {
      &--desktop {
        display: none;
      }

      &--tablet {
        display: grid;
      }
    }

    @include viewport--sm {
      &--tablet {
        display: none;
      }

      &--mobile {
        display: grid;
      }
    }
  }

  &__map-item {
    padding-bottom: 31px;
    border-bottom: 1px solid rgba(#AFB9D7, 0.4);

    &:last-child {
      padding-bottom: 0;
      border-bottom: none
    }
  }

  &__map-item-title {
    @include title-h3;

    margin: 0 0 16px;
    padding: 0 16px;
    color: #03113c;
    text-align: left;

    @include viewport--md {
      margin-bottom: 15px;
    }
  }

  &__map-image {
    img {
      width: 100%;
      height: auto;
    }
  }

  &__reviews {
    @include viewport--sm {
      order: 5;
    }
  }
}