.ga-intro {
  padding: 60px 0 30px;
  background-image: $almost-white;

  @include viewport--md {
    background-image: none;
    background-color: $color-ghost-white;
    padding: 50px 0 75px;
  }

  @include viewport--sm {
    padding: 40px 0 55px;
  }

  .google-partner {
    @include viewport--md {
      margin: 0 auto;
      justify-content: center;
      order: 1;
    }
  }
}

.ga-intro__container {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;

  @include viewport--md {
    flex-direction: column;
    justify-content: flex-start;
  }

  .ga-intro__wrap {
    & + .img-fix {
      position: relative;
      height: 100%;
      max-width: 51%;
      margin: auto 0;

      @include viewport--md {
        max-width: 517px;
        order: -1;
        margin: 0 auto;
      }

      img {
        @include viewport--md {
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      svg {
        @include viewport--md {
          width: 100%;
          height: auto;
          display: flex;
        }
      }
    }
  }
}

.ga-intro__wrap {
  padding-top: 54px;

  @include viewport--md {
    padding-top: 0;
    text-align: center;
  }
}

.ga-intro__title {
  margin: 0 0 36px;
  font-size: 48px;
  line-height: 56px;
  max-width: 550px;

  @include viewport--md {
    margin-bottom: 25px;
    max-width: none;
    font-size: 36px;
    line-height: 44px;
  }

  @include viewport--sm {
    margin: 0 auto 20px;
    font-size: 24px;
    line-height: 34px;
    max-width: 280px;
  }
}

.ga-intro__description {
  margin-bottom: 26px;
  max-width: 430px;
  font-size: 18px;
  line-height: 26px;

  @include viewport--md {
    margin: 0 auto 25px;
  }

  @include viewport--sm {
    max-width: 350px;
    font-size: 16px;
    line-height: 24px;
  }
}

.ga-intro__link {
  display: block;
  margin-bottom: 32px;
  width: max-content;
  font-size: 18px;
  line-height: 26px;
  color: $color-blue;
  font-weight: 600;
  transition: color $default-transition-settings;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      color: $color-comet;
    }
  }

  @include viewport--md {
    margin: 0 auto 25px;
  }

  @include viewport--sm {
    font-size: 16px;
    line-height: 24px;
    width: auto;
  }
}

.ga-intro__btns {
  margin-bottom: 70px;
  max-width: 440px;
  display: flex;

  &--desktop {
    @include viewport--md {
      display: flex;
      justify-content: center;
      margin: 0 auto 25px;
    }

    @include viewport--sm {
      display: block;
      text-align: center;
    }
  }

  .btn {
    min-width: 214px;

    @include viewport--sm {
      width: 100%;
      max-width: 280px;
    }

    & + .btn {
      margin-left: 14px;

      @include viewport--sm {
        margin-top: 20px;
        margin-left: 0;
      }
    }
  }
}
