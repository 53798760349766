.slider-team {
  @include viewport--sm {
    width: 100vw;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    padding: 0;

    list-style: none;

    @include viewport--md {
      flex-direction: column;
    }

    @include viewport--sm {
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: calc(33.3333% - 5%);
    margin-right: 7.5%;

    &:last-child {
      margin-right: 0;
    }

    @include viewport--md {
      margin-right: 0;
      width: 500px;
      margin-bottom: 10px;
    }

    @include viewport--sm {
      padding: 0 16px;
      margin-bottom: 0;
    }
  }

  &__img {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 300px;
    overflow: hidden;

    @include viewport--sm {
      height: 265px;
    }
  }

  &__position {
    margin: 50px 0 8px;

    font-weight: 700;
    font-size: 26px;
    line-height: 34px;
    text-align: center;
    color: $color-black;

    @include viewport--md {
      margin: 54px 0 14px;
    }

    @include viewport--sm {
      font-size: 24px;
      line-height: 32px;
      margin: 45px 0 16px;
    }
  }

  &__benefits {
    margin: 0;

    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: $color-storm-grey;

    @include viewport--sm {
      font-size: 16px;
      line-height: 24px;
      width: 290px;
    }
  }

  .slider-arrows {
    display: none;

    @include viewport--sm {
      display: flex;
      margin-top: 40px;
    }
  }
}
