.custom-input {
  position: relative;
  z-index: 1;

  &__input {
    display: block;
    width: 100%;
    border: 1px solid #c0c7e8;
    border-radius: 8px;
    padding: 35px 15px 9px;

    font-weight: 600;
    font-size: 16px;

    line-height: 24px;
    color: #33376b;

    font-family: $font-family--inter;

    background: $color-default-white;
  }

  &__label {
    position: absolute;
    z-index: 1;
    left: 15px;
    top: 8px;

    font-family: $font-family--inter;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #afb9d7;
  }
}