.trusted-brands-main {
  .container {
    padding: 0 40px;

    @include viewport--md {
      padding: 0 20px;
    }

    @include viewport--sm {
      padding: 0 10px;
    }
  }

  &__inner {
    padding: 56px 0;

    @include viewport--md {
      padding: 65px 0;
    }

    @include viewport--sm {
      padding: 30px 0;
    }
  }

  &__title {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    clip: rect(0 0 0 0);
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px 25px;

    @include viewport--lg {
      max-width: 900px;
      margin: 0 auto;
    }

    @include viewport--sm {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 4px;
    }
  }

  &__item {
    height: fit-content;
    display: flex;
    justify-content: center;

    @include viewport--sm {
      border: 1px solid #E8ECF7;
      border-radius: 5px;
      height: 48px;

      padding: 8px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        max-height: 100%;
        object-fit: contain;
      }
    }
  }

  &--blue {
    background-color: $color-alice-blue;
  }
}
