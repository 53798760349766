body {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: $font-family--lato;
  color: $color-black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $color-default-black;
  text-decoration: none;
}

h1 {
  span {
    color: $color-blue;
  }
}

h1,h2,h3 {
  font-family: $font-family--inter;
}

p {
  font-family: $font-family--lato;
}

br {
  @include viewport--md {
    display: none;
  }
}
