.resource-card-big {
  display: flex;
  width: 1014px;
  max-width: 100%;

  background-color: $color-default-white;
  box-shadow: 0px 4px 10px rgba(163, 184, 251, 0.15);
  border-radius: 19.5px;

  overflow: hidden;
  transition: transform $default-transition-settings;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      transform: translate(0, -5px);

      .resource-card-big__link {
        &::after {
          transform: translateX(5px);
        }
      }
    }
  }

  @include viewport--md {
    flex-direction: column;
    width: 477px;
  }

  @include viewport--sm {
    width: 340px;

    border-radius: 15px;
    // box-shadow: 0px 3.81443px 28.6082px rgba(66, 92, 176, 0.25);
  }

  &__img-desktop {
    flex-shrink: 0;
    width: 477px;

    object-fit: cover;

    @include viewport--md {
      width: 100%;
    }

    @include viewport--sm {
      display: none;
    }
  }

  &__img-mob {
    display: none;
    width: 100%;

    @include viewport--sm {
      display: block;
    }
  }

  &__text-block {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 32px 70px;

    @include viewport--md {
      padding: 56px 60px;
    }

    @include viewport--sm {
      align-items: center;
      padding: 28px 29px;
    }
  }

  &__type {
    margin-bottom: 29px;

    font-weight: 700;
    font-size: 19px;
    line-height: 24px;
    text-transform: uppercase;
    color: $color-blue;

    @include viewport--sm {
      font-size: 14px;
      line-height: 17px;
      text-align: center;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
    color: $color-bunting-2;

    @include viewport--sm {
      font-size: 22px;
      line-height: 126.3%;
      text-align: center;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    margin-top: 29px;

    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: $color-blue;

    @include viewport--sm {
      font-size: 17px;
      line-height: 24px;
    }

    &::after {
      content: "";
      display: block;
      width: 7px;
      height: 12px;
      margin-left: 8px;

      background-image: url("/assets/img/svg/angle-blue-right.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

      transition: transform $default-transition-settings;
    }
  }
}