.unlock-potential {
  .container {
    padding: 35px 130px 75px;

    @include viewport--md {
      padding: 50px 20px;
    }
  }

  br {
    @include viewport--md {
      display: initial;
    }

    @include viewport--sm {
      display: initial;
    }
  }

  &__title {
    font-family: $font-family--inter;
    font-weight: 700;
    font-size: 36px;
    line-height: 133%;
    text-align: center;
    color: $color-torea-bay-4;
    margin: 0 auto 75px;
    max-width: 550px;

    @include viewport--md {
      font-size: 24px;
      line-height: 120%;
      margin: 0 auto 35px;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 35px;
  }

  &__item {
    box-shadow: 0 4px 19px 0 rgba(66, 92, 176, 0.25);
    background: linear-gradient(270deg, #9eb1f6 0%, #455db5 100%);
    border-radius: 10px;
    padding: 35px;
  }

  &__item-text {
    margin: 0;
    font-family: $font-family--inter;
    font-weight: 600;
    font-size: 28px;
    line-height: normal;
    color: $color-default-white;
  }
}