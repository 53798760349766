.form-steps {
  $root: &;

  position: relative;
  z-index: 1;

  &.is-success {
    .form-steps__inner {
      opacity: 0;
      pointer-events: none;

      @include viewport--md {
        display: none;
      }
    }

    #{$root}__message-wrap {
      opacity: 1;
      pointer-events: auto;

      @include viewport--md {
        position: relative;
      }
    }
  }

  &__inner {
    border-radius: 16px;
    padding: 32px;
    background: $color-default-white;
    color: #33376b;

    display: flex;
    flex-direction: column;
    gap: 16px;

    transition: opacity 0.1s;

    @include viewport--sm {
      padding: 24px;
    }
  }

  &__head {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  &__back-btn {
    display: block;
    width: 32px;
    height: 32px;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;

    opacity: 0;
    pointer-events: none;

    transition: opacity $default-transition-settings;

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }

    &.is-active {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &__label {
    background: #edf1ff;
    border-radius: 100px;
    padding: 8px 16px;

    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #afb9d7;

    font-family: $font-family--inter;

    margin: 0;
  }

  &__steps {
    overflow: hidden;
    position: relative;
    z-index: 1;
    transition: height $default-transition-settings;
    margin-bottom: 32px;
  }

  &__step {
    display: flex;
    flex-direction: column;
    transition: $default-transition-settings;
    transition-property: transform, opacity;

    &.is-hidden {
      opacity: 0;
      transform: translateX(-100%);
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  &__step-title {
    margin: 0 0 32px;
    font-family: $font-family--inter;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }

  &__step-fields {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__step-btn {
    font-size: 24px;
    line-height: 32px;
    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    width: 100%;

    &.is-next {
      &::after {
        content: "";
        display: block;
        width: 25px;
        height: 25px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("../img/svg/icon-arrow-right.svg");
      }
    }
  }

  &__select {
    z-index: 2;
  }

  &__disclaimer {
    font-family: $font-family--inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #afb9d7;
    text-align: center;

    max-height: 0;
    transition: max-height $default-transition-settings;
    overflow: hidden;
  

    &.is-active {
      max-height: 48px;
    }

    a {
      text-decoration: underline;
      text-decoration-skip-ink: none;
      color: $color-royal-blue-3;

      text-underline-offset: 4px;
      transition: color $default-transition-settings;

      white-space: nowrap;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-black;
        }
      }
    }
  }

  &__message-wrap {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $color-default-white;
    padding: 43px 40px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-radius: 9px;
    overflow: hidden;
    pointer-events: none;
    transition: opacity $default-transition-settings 0.3s;

    @include viewport--sm {
      padding: 59px 30px;
    }
  }

  &__message-icon {
    flex: 0 1 auto;
    width: 55px;
    display: flex;
    place-content: center;
    margin-bottom: 28px;

    svg {
      width: 100%;
      height: 100%;
      flex: 0 1 auto;
    }
  }

  &__message-title {
    color: $color-black;
    text-align: center;
    font-family: $font-family--inter;
    font-size: 37.007px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0 0 7px;
  }

  &__message-text {
    color: $color-comet-4;
    text-align: center;
    font-family: $font-family--lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;

    & + #{$root}__message-text {
      padding-top: 10px;
    }
  }
}