.question-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 182px;
  width: 100%;
  padding: 30px 53px 38px 47px;

  background-image: url("../img/bg/newsletter-subscribe-bg@1x.png");
  background-repeat: no-repeat;
  background-position: 75%;
  border: 1px solid $color-quartz;
  border-radius: 5px;
  background-size: 456px 323px;

  @include retina {
    background-image: url("../img/bg/newsletter-subscribe-bg@2x.png");
  }

  @include viewport--lg {
    padding: 38px 40px;
  }

  @include viewport--md {
    flex-direction: column;
    align-items: flex-start;
    min-height: 307px;
    padding: 48px 40px 54px;
    background-size: contain;
    background-position: right;
    border-radius: 10px;

    background-image: url("../img/bg/newsletter-subscribe-bg-md@1x.png");

    @include retina {
      background-image: url("../img/bg/newsletter-subscribe-bg-md@2x.png");
    }
  }

  @include viewport--sm {
    padding: 30px;
    background-image: none;
    background-color: $color-default-white;
    min-height: 0;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 440px;

    @include viewport--sm {
      width: 100%;
    }

    b {
      margin-bottom: 4px;

      font-weight: 700;
      font-size: 36px;
      line-height: 48px;
      color: $color-black;

      @include viewport--md {
        margin-bottom: 10px;
      }

      @include viewport--sm {
        margin-bottom: 16px;
        font-size: 24px;
        line-height: 34px;
      }
    }

    p {
      width: 330px;
      margin: 0;

      font-size: 16px;
      line-height: 24px;
      color: $color-ship-cove;

      @include viewport--md {
        margin-bottom: 21px;

        font-size: 18px;
        line-height: 26px;
      }

      @include viewport--sm {
        margin-bottom: 28px;
        font-size: 16px;
        line-height: 24px;
        width: 100%;
      }
    }
  }

  .btn {
    min-width: 208px;

    @include viewport--sm {
      width: 100%;
      padding: 25px 20px 26px;
      font-size: 20px;
      line-height: 24px;
    }
  }

  &--only-title {
    @include viewport--md {
      padding-top: 38px;
      padding-bottom: 44px;
    }

    @include viewport--sm {
      padding: 30px 30px 40px;
      min-height: 0;
    }

    @media (max-width: 413px) {
      padding: 24px;
    }

    .question-banner__wrapper {
      @include viewport--md {
        width: 400px;
      }

      @include viewport--sm {
        width: 100%;
      }

      b {
        font-size: 30px;
        line-height: 46px;

        @include viewport--md {
          margin-bottom: 25px;

          font-size: 36px;
          line-height: 48px;
        }

        @include viewport--sm {
          font-size: 24px;
          line-height: 34px;
        }
      }
    }
  }
}
