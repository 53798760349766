.slider-arrows {
  display: flex;
  justify-content: center;

  &__btn + &__btn {
    margin-left: 45px;
  }

  &__btn {
    display: flex;

    svg {
      transition: transform $default-transition-settings;
    }

    &:first-child {
      transform: rotate(-180deg);
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        svg {
          transform: translateX(20%);
        }
      }
    }
  }
}
