.progress-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include viewport--lg {
    width: 1160px;
    margin: 0 auto;
  }

  @media (max-width: 1279px) {
    width: 904px;
  }

  @include viewport--md {
    width: 688px;
  }

  &__item {
    pointer-events: none;
    position: relative;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    height: auto;
    background-color: $color-transparent;
    border-radius: 0;

    &:not(:last-child) {
      &::before {
        content: "";
        position: absolute;
        top: 16px;
        left: 126px;

        width: 0;
        height: 2px;

        background-color: $color-blue;
        z-index: 1;
      }

      &::after {
        content: "";
        position: absolute;
        top: 16px;
        left: 126px;
        width: 442px;
        height: 2px;
        background: $color-hawkes-blue;
        border-radius: 100px;

        @include viewport--lg {
          width: 422px;
        }

        @media (max-width: 1279px) {
          width: 294px;
        }

        @include viewport--md {
          width: 186px;
        }
      }
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:not(.swiper-pagination-bullet-active) {
        &:hover,
        &:focus {
          .progress-pagination__label {
            color: $color-blue;
          }

          .progress-pagination__bullet {
            background-color: rgba($color-blue, 0.6);
            box-shadow: 0 0 0 1px rgba($color-blue, 0.6);
          }
        }
      }
    }
  }

  .swiper-pagination-bullet-active {
    pointer-events: none;
    cursor: default;

    .progress-pagination__label {
      color: $color-blue;
    }

    .progress-pagination__bullet {
      box-shadow: 0 0 0 1px rgba($color-blue, 0.5);
      background-color: $color-blue;
      transform: scale(1.43);
    }

    &::before {
      width: 442px;
      transition: inherit;

      @include viewport--lg {
        width: 422px;
      }

      @media (max-width: 1279px) {
        width: 294px;
      }

      @include viewport--md {
        width: 186px;
      }
    }
  }

  &__label {
    padding-top: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: $color-black;
    text-align: center;
    width: 190px;
    transition: color $default-transition-settings;
    pointer-events: all;

    @include viewport--md {
      padding-top: 15px;
    }
  }

  &__bullet {
    border-radius: 50%;
    height: 32px;
    width: 32px;
    border: 8px solid $color-default-white;
    box-shadow: 0 0 0 1px rgba($color-hawkes-blue, 0.5);
    background-color: $color-hawkes-blue;
    transition: background $default-transition-settings, box-shadow $default-transition-settings;
    pointer-events: all;
  }

  &--autoplay-disabled {
    .progress-pagination__item {
      &::before {
        opacity: 0;
      }
    }
  }
}
