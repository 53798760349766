.demo-sm-form {
  form {
    display: flex;
  }

  input {
    flex-grow: 1;
    flex-shrink: 0;
    align-self: flex-start;
    width: 200px;
    max-width: 318px;
    padding: 16px 22px 17px;
    margin-right: 14px;

    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    border: 1px solid $color-periwinkle-gray;
    border-radius: 5px;

    &::placeholder {
      color: $color-rock-blue-2;
    }

    @include viewport--sm {
      margin-right: 10px;
    }

    @media (max-width: 395px) {
      width: 0;
    }
  }

  .btn {
    min-width: 180px;
    max-width: 400px;

    @include viewport--sm {
      min-width: 152px;
      max-width: initial;
    }

    @media (max-width: 395px) {
      min-width: max-content;
    }
  }
}