.blue-banner {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto 28px;
  padding: 60px 85px 60px 75px;

  color: $color-periwinkle;
  background-color: $color-torea-bay;

  border-radius: 10px;

  @include viewport--md {
    padding: 47px 114px 72px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  @include viewport--sm {
    padding: 47px 30px 55px;
  }

  .btn {
    width: 226px;
    padding: 24px 26px;

    font-size: 18px;
    line-height: 22px;
  }

  &--company {
    padding: 51px 96px 60px 87px;

    @include viewport--md {
      padding: 47px 114px 46px;
    }

    @include viewport--sm {
      padding: 47px 25px 43px;
    }

    h2 {
      margin-bottom: 22px;

      font-size: 36px;
      line-height: 48px;

      @include viewport--sm {
        margin-bottom: 17px;

        font-size: 24px;
        line-height: 34px;
      }

      @include viewport--sm {
        margin-bottom: 26px;

        font-size: 24px;
        line-height: 34px;
      }
    }

    &__text {
      font-size: 18px;
      line-height: 26px;

      p + p {
        margin-top: 24px;
      }
    }
  }
}

.blue-banner__wrapper {
  max-width: 590px;
  padding-right: 30px;
  margin-right: auto;

  @include viewport--md {
    margin-right: 0;
    padding-right: 0;
  }

  &--vacancy {
    max-width: 700px;
  }

  &--company {
    max-width: 650px;
  }
}

.blue-banner__title {
  margin: 0;
  margin-bottom: 32px;

  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  color: $color-default-white;

  @include viewport--md {
    margin-bottom: 19px;

    font-size: 36px;
    line-height: 48px;
  }

  @include viewport--sm {
    font-size: 24px;
    line-height: 34px;
  }

  span {
    white-space: nowrap;
  }
}

.blue-banner__text {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;

  @include viewport--md {
    margin-bottom: 37px;
  }

  @include viewport--sm {
    font-size: 16px;
    line-height: 24px;
  }

  p {
    margin: 0;
  }

  &--vacancy {
    max-width: 300px;

    @include viewport--md {
      max-width: 390px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &--company {
    p {
      margin-bottom: 25px;
      font-weight: normal;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
