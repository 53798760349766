.footer {
  $root: &;
  padding-bottom: 40px;
  padding-top: 70px;

  .container {
    padding: 0 107px;
  }


  @include viewport--sm {
    padding-top: 5px;
  }

  .container {
    @include viewport--md {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__content-wrapper {
    display: grid;
    grid-template-columns: 416px repeat(3, 1fr);
    column-gap: 50px;
    grid-template-rows: auto auto auto;

    @include viewport--lg {
      column-gap: 50px;
      grid-template-columns: 300px repeat(3, 1fr);
    }

    @include viewport--md {
      display: flex;
      flex-direction: column;
    }
  }

  &__main-logo {
    grid-area: 1/1/2/2;
    margin-left: -14px;

    @include viewport--md {
      order: 1;
      margin-left: 0;
      padding-left: 9px;
      position: relative;
      padding-top: 22px;
      padding-bottom: 16px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        background-color: $color-periwinkle-gray;
      }

    }

    svg {
      width: 292px;
      height: auto;

      @include viewport--md {
        width: 267px;
      }
    }
  }

  &__partner--sk {
    display: none;
    padding: 20px 0;
  }

  &__partners-wrapper {
    grid-area: 1/1/2/2;
    margin-top: 110px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: fit-content;
    width: 100%;
    max-width: 320px;

    @include viewport--md {
      order: 7;
      margin: 0 25px 18px;
      max-width: 360px;
      width: auto;
    }

    &--extra {
      grid-area: 2/2/3/3;
      margin-top: 11px;
      margin-bottom: 40px;

      @include viewport--md {
        display: none;
      }

      img {
        width: 179px;
      }
    }

  }

  &__nav-block {
    position: relative;
    padding-top: 23px;
    padding-bottom: 15px;

    @include viewport--md {
      padding-top: 0;
      padding-bottom: 0;
    }

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      display: block;
      height: 1px;
      left: -50px;
      background-color: $color-mischka;


      @include viewport--md {
        content: none;
      }

    }

    &--knowless {
      grid-area: 1/2/2/3;

      @include viewport--md {
        order: 3;
      }

      &::before {
        left: 0;
      }
    }

    &--company {
      grid-area: 1/4/2/5;
      padding-left: 40px;

      @include viewport--lg {
        padding-left: 0;
      }

      @include viewport--md {
        order: 4;
      }

      #{$root}__nav-item {
        @include viewport--md {
          padding: 15px 42px;
        }
      }
    }

    &--solutions {
      grid-area: 1/3/2/4;

      @include viewport--md {
        order: 2;
      }
    }

    &.is-open {
      #{$root}__nav-list {
        @include viewport--md {
          visibility: visible;
          opacity: 1;
          height: auto;
        }
      }

      #{$root}__title-icon {
        transform: translateY(-50%) rotate(180deg);

        svg {
          stroke: $color-blue;
        }
      }

      #{$root}__nav-title {
        @include viewport--md {
          color: $color-blue;
        }
      }

      #{$root}__nav-title-wrap {
        @include viewport--md {
          &:before {
            content: none;
          }
        }
      }
    }
  }

  &__address {
    grid-area: 2/3/3/5;
    padding-left: 120px;
    margin-top: 22px;
    margin-bottom: 45px;
    width: 100%;
    max-width: 440px;

    @include viewport--lg {
      padding-left: 0;
    }

    @include viewport--md {
      order: 6;
      margin-top: 0;
      margin-bottom: 12px;
      padding-left: 25px;
      padding-right: 25px;
      padding-top: 40px;
    }

    p {
      color: $color-rock-blue;
      font-family: $font-family--inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      margin: 0;

      & + p {
        margin-top: 6px;

        @include viewport--sm {
          margin-top: 15px;
        }
      }
    }
  }

  &__agreements-wrapper {
    grid-area: 3/1/4/5;

    max-width: 80%;
    margin: 0 auto 13px;

    @include viewport--md {
      order: 5;
      max-width: unset;
      margin: 0;
    }

    #{$root}__link {
      line-height: 30px;
    }

    &.is-open {
      #{$root}__agreement-list {
        @include viewport--md {
          visibility: visible;
          opacity: 1;
          height: auto;
        }
      }

      #{$root}__title-icon {
        transform: translateY(-50%) rotate(180deg);

        svg {
          stroke: $color-blue;
        }
      }

      #{$root}__nav-title {
        @include viewport--md {
          color: $color-blue;
        }
      }

      #{$root}__nav-title-wrap {
        @include viewport--md {
          &:before {
            content: none;
          }
        }
      }
    }
  }

  &__copyright {
    grid-area: 4/1/5/5;
    color: $color-comet;
    text-align: center;
    font-family: $font-family--inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    margin: 0;

    @include viewport--md {
      order: 8;
      text-align: left;
      padding-left: 25px;
    }
  }

  &__partner {
    img {
      height: auto;
      user-select: none;
    }

    &--microsoft {
      margin-left: 5px;

      @include viewport--md {
        margin-left: 8px;
      }

      img {
        width: 81px;

        @include viewport--md {
          width: 89px;
        }
      }
    }

    &--meta {
      margin-top: 5px;

      img {
        width: 80px;

        @include viewport--md {
          width: 88px;
        }
      }
    }

    &--gmp {
      img {
        width: 124px;

        @include viewport--md {
          width: 138px;
        }
      }
    }

    &--gdpr {
      img {
        width: 106px;

        @include viewport--md {
          width: 118px;
        }
      }
    }

    &--soc2 {
      img {
        width: 46px;

        @include viewport--md {
          width: 52px;
        }
      }
    }

    &--sap {
      margin-left: 12px;
      margin-top: -5px;

      @include viewport--sm {
        margin-top: -2px;
      }

      img {
        width: 134px;

        @include viewport--md {
          width: 148px;
        }
      }
    }
  }

  &__nav-title-wrap {
    margin: 0 0 16px 0;
    position: relative;

    @include viewport--md {
      margin-bottom: 0;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        background-color: $color-periwinkle-gray;
      }
    }

    &--agreements {
      display: none;

      @include viewport--md {
        display: block;
      }
    }
  }

  &__title-icon {
    display: none;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);

    svg {
      width: 24px;
      height: auto;
      fill: rgba(0, 0, 0, 0);
      stroke: $color-torea-bay-4;
      transition: stroke $default-transition-settings;
    }

    @include viewport--md {
      display: block;
    }
  }

  &__nav-title {
    color: $color-black;
    font-family: $font-family--inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    transition: color $default-transition-settings;

    @include viewport--md {
      color: $color-torea-bay-4;
      font-family: $font-family--proxima;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding: 25px 55px 25px 25px;
    }
  }


  &__nav-list {
    list-style: none;
    padding: 0;
    margin: 0;

    @include viewport--md {
      height: 0;
      overflow: hidden;
      visibility: hidden;
      opacity: 0;
      transition: opacity $default-transition-settings,
      visibility $default-transition-settings;
    }
  }

  &__nav-item {
    margin: 0 0 14px 0;

    @include viewport--md {
      padding: 18px 42px;
      margin: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__nav-link,
  #{$root}__agreement-link {
    display: flex;
    color: $color-comet;
    font-family: $font-family--inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    transition: color $default-transition-settings;

    @include viewport--md {
      color: $color-fiord;
      font-family: $font-family--proxima;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-blue;
      }
    }
  }

  &__nav-icon {
    display: none;

    @include viewport--md {
      display: flex;
      width: 27px;
      height: 27px;
      place-content: center;
      flex-shrink: 0;
      margin: auto 17px auto 0;

      img {
        flex-shrink: 0;
        max-height: 100%;
        max-width: 100%;
      }
    }
  }


  &__agreement-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    width: fit-content;
    transition: max-height $default-transition-settings;

    @include viewport--md {
      flex-direction: column;
      width: 100%;
      height: 0;
      visibility: hidden;
      opacity: 0;
      overflow: hidden;
      transition: opacity $default-transition-settings,
      visibility $default-transition-settings;
    }
  }

  &__agreement-item {
    padding: 6px 23px;
    border-left: 1px solid $color-lavender;
    border-right: 1px solid $color-lavender;

    @include viewport--md {
      padding: 14px 42px;
      margin: 0;
      border: none;
    }

    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }
  }


}
