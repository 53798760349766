.solutions-introducing-split {
  $root: &;

  padding-top: 100px;
  padding-bottom: 65px;

  background: linear-gradient(180deg, #FAFBFF 0%, #FFFFFF 100%);

  @include viewport--md {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  @include viewport--sm {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  &__columns {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include viewport--md {
      flex-direction: column;
    }
  }

  &__img {
    width: 50%;
    max-width: 630px;
    margin-right: 60px;

    @include viewport--md {
      width: 100%;
      margin-right: 0;
    }

    @include viewport--sm {
      max-width: 500px;
    }

    &--desktop {
      @include viewport--sm {
        display: none;
      }
    }

    &--mob {
      display: none;

      @include viewport--sm {
        display: block;
      }
    }
  }

  &__text-column {
    flex-grow: 1;
    max-width: 540px;

    @include viewport--md {
      order: -1;
      margin-bottom: 45px;
    }

    .demo-sm-form {
      margin-top: 75px;

      @include viewport--md {
        display: none;
      }
    }
  }

  &__title {
    margin: 0;
    color: $color-torea-bay-4;
    font-family: $font-family--inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 114.3%;

    span {
      color: $color-blue;
    }

    @include viewport--md {
      text-align: center;
    }

    @include viewport--sm {
      font-size: 24px;
      font-weight: 700;
    }
  }

  &__mob-form {
    display: none;
    margin-top: 45px;

    @include viewport--md {
      display: block;
    }

    form {
      justify-content: center;

      @include viewport--sm {
        flex-direction: column;
        align-items: stretch;
      }
    }

    input {
      @include viewport--sm {
        width: 100%;
        max-width: 100%;
        margin: 0 0 12px;
      }
    }

    .btn {
      @include viewport--sm {
        width: 100%;
      }
    }
  }

  &--solutions-page {
    background: $color-default-white;
    padding-top: 10px;
    padding-bottom: 50px;

    @include viewport--sm {
      padding-bottom: 0;
    }

    .container {
      padding: 0 100px;

      @include viewport--lg {
        padding: 0 60px;
      }

      @include viewport--md {
        padding: 0 30px;
      }

      @include viewport--sm {
        padding: 0 20px;
      }
    }

    #{$root}__text-column {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;

      @include viewport--md {
        margin-bottom: 37px;
      }
    }

    #{$root}__title {
      padding-top: 15px;
      margin: 0 0 21px;

      @include viewport--md {
        order: 1;
        margin: 0;
      }
    }

    .demo-sm-form {
      margin: 0;
      padding: 0;
      display: none;
    }

    #{$root}__columns {
      display: grid;
      grid-template-columns: 46.5% 1fr;
      grid-template-rows: 1fr 1fr;
      column-gap: 88px;

      @include viewport--md {
        display: flex;
        flex-direction: column;
        column-gap: 0;
      }
    }

    #{$root}__img {
      grid-row: span 2;
      width: 100%;
      margin-left: 20px;
      margin-right: 0;

      @include viewport--md {
        margin-left: 0;
        order: 2;
      }

      @include viewport--sm {
        margin-left: -10px;
        margin-right: -10px;
        width: calc(100% + 20px);
      }
    }

    #{$root}__btn {
      margin-bottom: auto;
      margin-top: 52px;
      width: fit-content;

      @include viewport--md {
        order: 3;
        margin-top: 30px;
      }

      @include viewport--sm {
        width: 100%;
      }
    }
  }
}