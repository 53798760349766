.powered-measurement {
  $root: &;

  .container {
    padding: 35px 130px 0;

    @include viewport--1280 {
      padding-left: 60px;
      padding-right: 60px;
    }

    @include viewport--md {
      padding: 0 20px;
    }

    @include viewport--sm {
      padding: 0 16px;
    }
  }

  &__stats {
    font-family: $font-family--inter;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;

    @include viewport--md {
      gap: 0;
    }
  }

  &__label {
    margin: 0;
    font-family: $font-family--inter;
    font-size: 22px;
    text-align: center;
    color: $color-blue;

    @include viewport--md {
      font-size: 17px;
      margin-bottom: 24px;
    }
  }

  &__title {
    margin: 3px 0 0;
    font-weight: 700;
    font-size: 36px;
    line-height: 130%;
    max-width: 800px;
    text-align: center;
    color: $color-deep-koamaru;

    @include viewport--1280 {
      font-size: 30px;
    }

    @include viewport--md {
      font-size: 24px;
      line-height: 120%;
      margin: 0 0 55px;
      max-width: 510px;
    }

    @include viewport--sm {
      max-width: 350px;
      margin: 0 0 24px;
    }
  }

  &__stats-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;

    @include viewport--md {
      gap: 16px;
    }

    @include viewport--sm {
      flex-direction: column;
      gap: 16px;
      max-width: 520px;
    }
  }

  .statistic-block {
    border-radius: 24px;
    padding: 32px;

    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    transition: transform $default-transition-settings;
    min-height: 495px;

    position: relative;
    z-index: 1;

    flex-grow: 1;
    max-width: 480px;

    overflow: hidden;

    @include hover-focus {
      transform: translateY(-5px);

      .statistic-block__link::after {
        transform: translateX(5px);
      }
    }
    
    @include viewport--1280 {
      max-width: calc(50% - 10px);
    }

    @include viewport--md {
      padding: 24px;
      min-height: auto;
    }

    @include viewport--sm {
      max-width: 100%;
    }

    &__label {
      margin: 0 0 32px;
      font-family: $font-family--inter;
      border-radius: 40px;
      padding: 10px 24px;
      background-color: #4d6dd3;
      font-size: 16px;
      line-height: 20px;

      text-align: center;
      color: $color-default-white;

      &--white {
        background: $color-default-white;
        color: #546ccc;
      }

      @include viewport--md {
        margin-bottom: 24px;
      }
    }

    &__title {
      margin: 0 0 16px;
      font-size: 32px;
      line-height: 40px;
      color: #2b3270;
      font-weight: 600;

      &--white {
        color: $color-default-white;
      }

      @include viewport--md {
        font-size: 24px;
        line-height: 32px;
      }

      @include viewport--sm {

      }
    }

    &__link {
      margin: 0;
      position: relative;
      padding-right: 16px;

      font-family: $font-family--lato;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;

      color: #4d6dd3;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 9px;
        width: 6px;
        height: 8px;
        background-image: url("data:image/svg+xml,%3Csvg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 4L0 7.4641L0 0.535898L6 4Z' fill='%234D6DD3' /%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        transition: transform $default-transition-settings;
      }

      
      &--white {
        color: $color-default-white;

        &::after {
          background-image: url("data:image/svg+xml,%3Csvg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 4L0 7.4641L0 0.535898L6 4Z' fill='white' /%3E%3C/svg%3E");
        }
      }

      @include viewport--md {
        margin-bottom: 13px;
      }
    }

    &__image-wrapper {
      width: 100%;
      position: absolute;
      z-index: -1;

      img {
        width: 100%;
        height: auto;
      }

      @include viewport--1280 {
        position: static;
      }
    }

    &__text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      min-height: 100%;
    }

    &__list,
    &__description {
      margin: 0;
      padding: 0;
      padding-left: 25px;
      font-size: 16px;
      line-height: 24px;
      font-family: $font-family--lato;
      font-weight: 400;

      display: grid;
      grid-template-columns: 100%;
      gap: 10px;
      margin-top: 8px;

      @include viewport--md {
        margin-top: 0;
        gap: 7px;
      }
    }

    &__description {
      padding-left: 0;
      max-width: 425px;

      p {
        margin: 0;
      }
    }

    &__head {
      display: flex;
      align-items: center;
      gap: 20px;
      width: 100%;
      margin-bottom: 32px;

      .statistic-block__label {
        margin-bottom: 0;
      }

      .statistic-block__link {
        margin-left: auto;
      }

      @include viewport--md {
        margin-bottom: 24px;
      }
    }

    
    &--incremental-attribution { 
      border: 1px solid #c8d6ff;
      background: linear-gradient(161deg, #fff 0%, #d8e2ff 100%);
      overflow: hidden;
      max-width: calc(100% - 500px);

      .statistic-block__image-wrapper {
        max-width: 434px;
        right: 11px;
        bottom: -2px;
      }

      .statistic-block__title {
        max-width: 305px;
      }

      @include viewport--lg {
        .statistic-block__image-wrapper {
          max-width: 380px;
        }
      }

      @include viewport--1280 {
        max-width: calc(50% - 10px);
        padding-bottom: 0;

        .statistic-block__image-wrapper {
          margin-left: auto;
        }
      }

      @include viewport--md {
        padding: 23px;
        padding-bottom: 0;

        .statistic-block__title {
          max-width: 260px;
        }

        .statistic-block__image-wrapper {
          max-width: 300px;
          margin-right: -16px;
        }
      }

      @include viewport--sm {
        max-width: 100%;

        .statistic-block__image-wrapper {
          width: 100%;
          max-width: 300px;
          margin: 0 auto;
          transform: translateX(-5px);
        }
      }
    }

    &--geo-lift-testing {
      background: linear-gradient(180deg, #29306e 0%, #566095 100%);
  
      .statistic-block__image-wrapper {
        max-width: 408px;
        right: 0;
        bottom: 0;
      }

      @include viewport--1280 {
        padding-bottom: 0;
      }

      @include viewport--1280 {
        .statistic-block__image-wrapper {
          margin-left: auto;
          margin-top: auto;
          margin-right: -32px;
        }
      }

      @include viewport--md {
        .statistic-block__image-wrapper {
          width: 333px;
          margin-right: -24px;
        }
      }

      @include viewport--sm {
        .statistic-block__image-wrapper {
          align-self: center;
          width: 436px;
          max-width: 436px;
          transform: translateX(-29px);

          margin-bottom: -8px;
        }

        .statistic-block__link {
          margin-bottom: 18px;
        }
      }
    }

    &--mix-optimization,
    &--synthetic-conversions {
      background: #2b3270;
      color: $color-default-white;
      width: 100%;
      max-width: 100%;

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;
      min-height: 440px;

      .statistic-block__image-wrapper {
        max-width: 432px;
        align-self: flex-end;
        justify-self: flex-end;
        position: static;
      }

      .statistic-block__link {
        margin-top: auto;
      }

      @include viewport--md {
        grid-template-columns: 1fr 280px;
        min-height: 368px;

        .statistic-block__text {
          max-width: 332px;
        }

        .statistic-block__link {
          position: absolute;
          top: 32px;
          right: 24px;
        }
      }

      @include viewport--sm {
        grid-template-columns: 100%;
        gap: 32px;

        .statistic-block__text {
          max-width: 100%;
        }

        .statistic-block__label {
          padding: 10px 23px;
        }

        .statistic-block__list {
          margin-bottom: 24px;
        }

        .statistic-block__link {
          position: relative;
          top: auto;
          right: auto;
          margin-bottom: 0;
        }

        .statistic-block__image-wrapper {
          justify-self: center;
        }
      }
    }

    &--customer-ltv-prediction {
      background: linear-gradient(149deg, #31468d 0%, #6c86da 100%);
      min-height: 624px;

      display: grid;
      grid-template-columns: 241px auto;
      gap: 0 20px;
      align-content: flex-start;

      .statistic-block__image-wrapper {
        max-width: 448px;
        right: 0;
        bottom: 0;
      }

      .statistic-block__label {
        grid-row: 1;
        grid-column: 1;
      }

      .statistic-block__title {
        grid-column: 1 / -1;
        grid-row: 2;
      }

      .statistic-block__link {
        justify-self: flex-end;
        align-self: center;
        grid-row: 1;
        grid-column: 2;
        margin-bottom: 32px;
      }

      @include viewport--md {
        max-width: 100%;
        min-height: auto;

        grid-template-columns: 1fr 320px;
        justify-items: flex-start;
        min-height: 267px;

        .statistic-block__title {
          grid-column: 1;
          max-width: 325px;
        }

        .statistic-block__link {
          grid-column: 1;
          grid-row: 3;
          justify-self: flex-start;
          margin-bottom: 0;
        }

        .statistic-block__image-wrapper {
          position: absolute;
          max-width: 320px;
          right: -4px;
          bottom: -7px;
        }
      }

      @include viewport--sm {
        grid-template-columns: 100%;
        padding-bottom: 0;

        .statistic-block__image-wrapper {
          position: static;
          width: 120%;
          margin: 0 auto -8px;
          transform: translateX(-13px);
        }

        .statistic-block__link {
          margin-bottom: 20px;
        }
      }
    }

    &--predictive-lead-scoring {
      background: linear-gradient(161deg, #fff 0%, #d8e2ff 100%);
      border: 1px solid #c8d6ff;
      max-width: calc(100% - 500px);

      display: grid;
      grid-template-columns: 232px auto;
      grid-template-rows: auto auto 1fr auto;
      gap: 0 20px;


      .statistic-block__label {
        grid-row: 1;
        grid-column: 1;
      }

      .statistic-block__title {
        grid-column: 1 / -1;
        grid-row: 2;
      }

      .statistic-block__link {
        justify-self: flex-end;
        align-self: center;
        grid-row: 1;
        grid-column: 2;
        margin-bottom: 32px;
      }

      .statistic-block__image-wrapper {
        margin-top: auto;
        position: static;
        grid-column: 1 / -1;
        
      }

      @include viewport--1280 {
        max-width: calc(50% - 10px);
      }

      @include viewport--md {
        max-width: 100%;

        .statistic-block__title {
          margin-bottom: 20px;
        }
      }

      @include viewport--sm {
        grid-template-columns: 100%;
        
        .statistic-block__link {
          grid-column: 1;
          grid-row: 3;
          justify-self: flex-start;
          margin-bottom: 32px;
        }

        .statistic-block__title {
          margin-bottom: 18px;
        }

        .statistic-block__image-wrapper {
          max-width: 320px;
          margin: 0 auto;
        }
      }
    }

    &--synthetic-conversions {
      background: #2b3270 linear-gradient(225deg, #29306e 0%, #566095 100%);
      min-height: 397px;

      grid-template-columns: 100%;

      .statistic-block__text {
        max-width: 600px;
      }

      .statistic-block__image-wrapper {
        max-width: 443px;
        position: absolute;
        right: 4px;
        bottom: 13px;
      }

      @include viewport--lg {
        grid-template-columns: 1fr 440px;

        .statistic-block__image-wrapper {
          position: static;
        }
      }

      @include viewport--1280 {
        grid-template-columns: 1fr 350px;
      }

      @include viewport--md {
        grid-template-columns: 1fr 310px;
        min-height: 344px;
        align-items: center;

        .statistic-block__link {
          position: relative;
          margin-bottom: 0;
          top: auto;
          right: auto;
        }

        .statistic-block__image-wrapper {
          align-self: center;
          margin-right: -20px;
        }
      }

      @include viewport--sm {
        grid-template-columns: 100%;
        gap: 16px;
        padding-bottom: 11px;

        .statistic-block__description {
          margin-bottom: 24px;
        }

        .statistic-block__image-wrapper {
          margin-left: -11px;
          margin-right: -20px;
          width: auto;
        }
      }
    }
    
  }

  &__reviews-block {
    padding: 88px 0;

    @include viewport--md {
      padding: 55px 0;
    }
  }
}


