.sticky-top {
  position: relative;

  background: linear-gradient(90.04deg, #5A8FFF 0.04%, #4D6DD3 99.97%);
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: $color-default-white;

  span {
    // color: #FC5F20;
    // text-decoration: underline;
    display: inline-flex;
    align-items: center;

    font-weight: 700;
    padding-left: 30px;

    &::after {
      content: "";

      display: block;
      width: 9px;
      height: 14px;
      margin-left: 8px;

      background-image: url("/assets/img/svg/angle-white-right.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 9px 14px;

      @include viewport--md {
        width: 5px;
        height: 8px;
        margin-left: 5px;

        background-size: 5px 8px;
      }
    }
  }

  @include viewport--md {
    font-size: 14px;
    line-height: 18px;
    display: none;
  }

  &__link {
    display: block;
    padding: 20px 90px;

    color: $color-default-white;

    @include viewport--md {
      padding: 16px 36px;
    }
  }

  &__image-wrapper {
    height: 0px;
  }

  &__icon {
    position: relative;
    top: -46px;
  }

  &__close {
    height: 20px;
    width: 20px;
    background-image: url("/assets/img/content/dark-cross.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px 12px;
    position: absolute;
    top: 13px;
    right: 55px;
    cursor: pointer;

    @include  viewport--md {
      right: 10px;
    }
  }

  &__close-btn {
    position: absolute;
    top: 17px;
    right: 21px;

    width: 21px;
    height: 21px;
    padding: 0;

    background-color: transparent;
    border: none;
    background-image: url("/assets/img/svg/white-cross.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px 12px;

    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.7;
    }

    @include  viewport--md {
      top: 8px;
      right: 11px;
    }
  }

  &__hide {
    display: none;
  }

  &__text {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    color: #353B7A;
    font-weight: 700;


    @include viewport--md {
      padding: 16px;
      justify-content: center;
      text-align: center;
    }
  }

  .btn {
    background: #FC5F20;
    border-radius: 100px;
    border: none;
    padding: 8px 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    text-align: center;
    color: #FFF;
    height: 33px;
    min-width: 185px;
    margin: 0 30px;
    //@include viewport--sm {
    //  min-width: 0;
    //}
  }
}

.sticky-top__container {
  display: flex;
  justify-content: space-evenly;
  max-width: 1440px;
  margin: 0 auto;
  align-items: center;

  @include viewport--sm {
    flex-direction: column;
  }
}
