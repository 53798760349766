.demo-form-section {
  $root: &;
  padding-top: 56px;
  padding-bottom: 65px;
  background-color: $color-midnight-express;

  @include viewport--md {
    padding-top: 0;
    padding-bottom: 59px;
  }

  .container {
    @include viewport--xl {
      padding: 0 113px;
    }

    @include viewport--lg {
      padding: 0 40px;
    }

    @include viewport--md {
      padding: 0 40px;
    }

    @include viewport--sm {
      padding: 0 16px;
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: calc(100% - 44.5% - 50px) 44.5%;
    grid-template-rows: min-content min-content 1fr;
    column-gap: 50px;

    @include viewport--lg {
      grid-template-columns: 1fr 54%;
      column-gap: 30px;
    }

    @include viewport--md {
      display: flex;
      flex-direction: column;
      padding-top: 35px;
    }

  }

  &__info-title-wrap {
    grid-row: 1;
    grid-column: 1;

    margin-top: 0;
    margin-bottom: 24px;

    @include viewport--sm {
      order: 1;
    }

  }

  &__brands {
    grid-row: 4;
    grid-column: 1 / -1;

    @include viewport--lg {
      order: 5;
    }

    @include viewport--md {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__form {
    grid-row: 1 / span 3;
    grid-column: 2;

    @include viewport--lg {
      margin-right: auto;
      margin-left: auto;
    }

    @include viewport--md {
      transform: none;
    }

    @include viewport--sm {
      order: 2;
    }
  }

  &__brands {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
    gap: 20px;

    @include viewport--md {
      margin: 0 auto;
      width: 100%;
      margin-bottom: 20px;
      justify-content: center;
    }

    @include viewport--sm {
      width: auto;
      margin: 0;
      order: 5;
    }
  }

  &__brands-item {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;


    // @include viewport--sm {
    //   min-width: 33%;
    // }

    img {
      max-width: 189px;
      height: auto;
      max-height: 43px;
      object-fit: contain;

      // @include viewport--sm {
      //   max-width: 113px;
      // }
    }

    // &--loreal {
    //   img {
    //     @include viewport--sm {
    //       max-width: 95px;
    //     }
    //   }
    // }

    // &--simplisafe {
    //   img {
    //     @include viewport--sm {
    //       max-width: 87px;
    //     }
    //   }
    // }

    // &--kitchenaid {
    //   img {
    //     @include viewport--sm {
    //       max-width: 113px;
    //     }
    //   }
    // }

    // &--carshop {
    //   img {
    //     @include viewport--sm {
    //       max-width: 97px;
    //     }
    //   }
    // }
  }

  &__info {
    max-width: 590px;
    margin-right: 15px;
    margin-left: 24px;

    @include viewport--md {
      order: 0;
      max-width: none;
      padding-top: 26px;
      margin-left: -40px;
      margin-right: -40px;
      padding-left: 40px;
      padding-right: 40px;
    }
  }


  &__info-title {
    font-family: $font-family--inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    margin: 0;
    color: $color-default-white;
    width: 100%;

    @include viewport--sm {
      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 13px;
      margin-left: auto;
      margin-right: auto;
    }

    @include viewport--sm {
      margin-bottom: 0;
    }

    h2 {
      display: inline;
      margin: 0;

      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
      color: inherit;
    }

    &--desktop {
      @include viewport--md {
        //display: none;
      }
    }

    &--brands {
      margin-bottom: 16px;

      @include viewport--md {
        display: none;
      }
    }

    &--tablet {
      display: none;
      margin-bottom: 45px;
      padding-left: 5px;

      font-weight: 700;
      font-size: 36px;
      line-height: 44px;

      @include viewport--md {
        //display: block;
      }
    }
  }

  &__info-list {
    list-style: none;
    padding: 0;
    margin: 0 0 38px;

    @include viewport--md {
      margin-bottom: 58px;
    }

    @include viewport--sm {
      margin-bottom: 35px;
    }
  }

  &__info-item {
    color: $color-lavender-3;
    font-family: $font-family--lato;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    position: relative;
    padding-left: 28px;
    margin-bottom: 27px;

    @include viewport--md {
      padding-left: 33px;
    }

    @include viewport--sm {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      padding-left: 47px;
    }


    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 13px;
      height: 9px;
      top: 50%;
      left: 0;
      background-image: url("../img/svg/icon-tick.svg");
      background-repeat: no-repeat;
      background-size: contain;
      transform: translateY(-50%);

      @include viewport--md {
        top: 12px;
        width: 19px;
        height: 12px;
      }

      @include viewport--sm {
        top: 10px;
        left: 14px;
        width: 16px;
        height: 12px;
      }
    }
  }

  &__form {
    position: relative;
    height: fit-content;
    max-width: 543px;
    width: 100%;
    padding: 47px 24px 45px;
    background-color: $color-default-white;
    border-radius: 8px;
    transition: background-color $default-transition-settings;

    &.is-success {
      background-color: $color-transparent;

      form {
        opacity: 0;
        pointer-events: none;

        @include viewport--md {
          display: none;
        }
      }

      #{$root}__message-wrap {
        opacity: 1;
        pointer-events: auto;

        @include viewport--md {
          position: relative;
        }
      }
    }

    form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 14px 12px;
      transition: opacity 0.1s;

      @include viewport--sm {
        display: flex;
        flex-direction: column;
        gap: 0;
      }

      @include viewport--md {
        max-width: 492px;
        margin: 0 auto;
      }
    }

    @include viewport--md {
      width: 570px;
      max-width: none;
      padding: 26px 21px 22px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 50px;
    }

    @include viewport--sm {
      width: 100%;
      max-width: 530px;
      padding-left: 21px;
      padding-right: 21px;
      margin-bottom: 35px;
    }
  }

  &__form-title {
    display: none;
    margin-bottom: 42px;

    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: $color-comet;

    @include viewport--md {
      align-self: center;
      display: block;
      margin-bottom: 31px;

      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__form-item {
    position: relative;

    @include viewport--sm {
      margin-bottom: 14px;
    }

    label {
      position: absolute;
      top: 8px;
      left: 13px;

      color: $color-ship-cove;
      font-family: $font-family--inter;
      font-size: 14.398px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.01em;

      @include viewport--sm {
        left: 9px;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: 0;
      }
    }

    input {
      width: 100%;
      padding: 35px 12px 13px;

      color: $color-black;
      font-family: $font-family--inter;
      font-size: 17.483px;
      font-style: normal;
      font-weight: 600;
      line-height: 19.6px;
      letter-spacing: 0.03em;

      background-color: $color-default-white;
      border: 1px solid $color-resource-input-border;
      border-radius: 5px;

      @include viewport--sm {
        padding: 29px 8px 9px;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0;
      }
    }
  }

  &__form-btn {
    margin-top: 7px;
    margin-bottom: 5px;
    padding: 22px 26px 24px;
    line-height: 24px;

    grid-column: span 2;

    color: $color-default-white;
    text-align: center;
    font-family: $font-family--inter;
    font-size: 21.238px;
    font-style: normal;
    font-weight: 600;

    @include viewport--md {
      margin-top: 0;
      margin-bottom: 15px;
      padding: 18px 26px 18px;
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__form-disclaimer {
    align-self: center;

    line-height: 16px;
    text-align: center;
    color: $color-ship-cove;
    grid-column: span 2;

    font-family: $font-family--inter;
    font-size: 14.158px;
    font-style: normal;
    font-weight: 400;

    @include viewport--sm {
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;


    }

    a {
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      color: $color-blue;
      text-decoration: underline;
      text-underline-offset: 4px;

      transition: color $default-transition-settings;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-black;
        }
      }
    }
  }

  &__message-wrap {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $color-default-white;
    padding: 43px 40px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-radius: 9px;
    overflow: hidden;
    pointer-events: none;
    transition: opacity $default-transition-settings 0.3s;

    @include viewport--sm {
      padding: 59px 30px;
    }
  }

  &__message-icon {
    flex: 0 1 auto;
    width: 55px;
    display: flex;
    place-content: center;
    margin-bottom: 28px;

    svg {
      width: 100%;
      height: 100%;
      flex: 0 1 auto;
    }
  }

  &__message-title {
    color: $color-black;
    text-align: center;
    font-family: $font-family--inter;
    font-size: 37.007px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0 0 7px;
  }

  &__message-text {
    color: $color-comet-4;
    text-align: center;
    font-family: $font-family--lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;

    & + #{$root}__message-text {
      padding-top: 10px;
    }
  }

  &__description {
    color: $color-lavender-3;

    font-weight: 400;
    font-family: $font-family--lato;
    font-size: 18px;
    line-height: 26px;

    display: grid;
    grid-template-columns: 100%;
    gap: 25px;
    margin-bottom: 35px;

    max-width: 543px;

    p {
      margin: 0;
    }

    @include viewport--md {
      max-width: 100%;
    }

    @include viewport--sm {
      order: 4;
    }
  }

  &__founder {
    grid-row: 3;
    grid-column: 1;
    margin-bottom: 52px;

    @include viewport--sm {
      order: 3;
    }
  }
}
