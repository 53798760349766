.solutions-intro {
  background-color: #fff;
  padding: 75px 0 36px;

  @include viewport--md {
    padding: 40px 0 0;
  }

  @include viewport--sm {
    padding: 64px 0 0;
  }

  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include viewport--md {
      align-items: center;
    }
  }

  &__main-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__main-left {
    width: 52.1%;

    @include viewport--md {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }

  &__main-right {
    width: 470px;
    margin-left: 30px;

    img {
      width: 100%;
      height: auto;
      max-height: 390px;

      object-fit: contain;
    }

    @include viewport--md {
      display: none;
    }
  }

  &__video-wrapper {
    position: relative;
    z-index: 0;

    width: 100%;

    &--mob {
      display: none;

      @include viewport--md {
        display: block;
        max-width: 693px;
        margin-top: 50px;
      }

      @include viewport--sm {
        margin-top: 38px;
      }
    }
  }

  &__video {
    position: relative;
    z-index: 0;

    display: block;
    width: 100%;
    padding-top: 56.3%;
    // margin-top: 9px;

    // border: 2px solid $color-blue;
    // filter: drop-shadow(38px 36px 24.0148px rgba(166, 181, 228, 0.2));
    // box-shadow: 38px 36px 24.0148px rgba(166, 181, 228, 0.2);

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      pointer-events: none;

      @include viewport--md {
        pointer-events: auto;
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      object-fit: cover;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      width: 100%;
      height: 100%;

      border: 2px solid $color-blue;

      @include viewport--md {
        pointer-events: none;
      }
    }
  }

  &__subtitle {
    margin-bottom: 30px;

    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    color: $color-blue;

    @include viewport--sm {
      margin-bottom: 38px;
    }
  }

  &__title {
    font-size: 42px;
    line-height: 56px;
    color: $color-black;
    margin: 0 0 30px;
    font-weight: 700;
    max-width: 1003px;

    @include viewport--md {
      font-size: 36px;
      line-height: 44px;
      margin: 0 0 26px;
      max-width: 620px;
      text-align: center;
    }

    @include viewport--sm {
      font-size: 28px;
      line-height: 36px;
      margin: 0 0 26px;
      max-width: 360px;
    }

    @media (max-width: 413px) {
      font-size: 26px;
      line-height: 34px;
    }

    span {
      color: $color-blue;
    }

    &--desk-tab {
      @include viewport--sm {
        display: none;
      }
    }

    &--mob {
      display: none;

      @include viewport--sm {
        display: block;
      }
    }
  }

  &__img-mob {
    display: none;

    @include viewport--md {
      display: block;
      margin-bottom: 26px;
    }
  }

  &__description {
    font-size: 22px;
    line-height: 36px;
    margin: 0 0 44px;
    color: $color-ship-cove;
    font-weight: 300;
    max-width: 1010px;

    h2 {
      display: inline;

      font-size: inherit;
      line-height: inherit;
      margin: 0;
      font-weight: inherit;
    }

    span {
      font-weight: bold;
      color: $color-comet;
    }

    @include viewport--md {
      margin: 0 0 34px;
      max-width: 500px;
      text-align: center;
    }

    @include viewport--sm {
      font-size: 17px;
      line-height: 24px;
      max-width: 360px;
      margin: 0 0 38px;
    }
  }

  .demo-sm-form {
    align-self: stretch;

    form {
      @include viewport--md {
        justify-content: center;
      }

      @include viewport--sm {
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
      }
    }

    input {
      @include viewport--sm {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 12px;
      }
    }

    .btn {
      @include viewport--sm {
        width: 100%;
      }
    }
  }

  &__partners {
    display: flex;
    align-items: center;
    margin-top: 75px;
    margin-left: -10px;

    @include viewport--md {
      margin-top: 38px;
      margin-left: 0;
    }

    a[href] {
      transition: opacity $default-transition-settings;

      &:hover {
        opacity: 0.7;
      }
    }

    &--desktop {
      @include viewport--md {
        display: none;
      }
    }

    &--desktop-tablet {
      @include viewport--sm {
        display: none;
      }
    }

    &--tablet {
      display: none;

      @include viewport--md {
        display: flex;
        margin-top: 0;
        margin-bottom: 38px;
      }
    }

    &--mob {
      display: none;

      @include viewport--sm {
        display: flex;
      }
    }

    &--facebook {
      margin-top: 42px;
      margin-left: -9px;

      @include viewport--md {
        margin-top: 38px;
        margin-left: 0;
      }
    }
  }

  // &__partner-gcp {
  //   width: 122px;
  //   height: 55px;

  //   object-fit: contain;
  // }

  // &__partner-gmp {
  //   width: 201px;
  //   height: 63px;

  //   object-fit: contain;

  //   @include viewport--md {
  //     width: 187px;
  //     height: 70px;
  //   }
  // }

  &__partner-gmp {
    margin-bottom: 4px;
  }

  &--google-ads {
    .solutions-intro__main-left {
      align-self: flex-end;
    }
  }

  &--measurement {

  }

  &--vertical {
    .solutions-intro__main-wrapper {
      flex-direction: column;
    }

    .solutions-intro__main-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 753px;
    }

    .solutions-intro__main-right {
      width: 100%;
      max-width: 693px;
      margin-left: 0;
      margin-top: 107px;
    }

    .solutions-intro__subtitle {
      text-align: center;
    }

    .solutions-intro__title {
      text-align: center;
    }

    .solutions-intro__description {
      text-align: center;
    }

    .demo-sm-form {
      form {
        justify-content: center;
      }
    }
  }

  &--ecommerce {
    padding: 107px 0 75px;

    background: linear-gradient(180deg, #FFFFFF 22.76%, #F2F5FF 75.06%, #FFFFFF 99.57%);

    @include viewport--sm {
      padding: 84px 0 45px;

      background: $color-default-white;
    }

    .solutions-intro__description {
      margin-bottom: 30px;

      font-size: 20px;
      line-height: 32px;
      font-weight: 600;

      @include viewport--sm {
        margin-bottom: 38px;
        font-size: 17px;
        line-height: 24px;
      }
    }
  }

  &--marketing-reporting {
    .solutions-intro__main-left {
      align-self: flex-start;
      max-width: 545px;

      @include viewport--md {
        align-self: center;
        max-width: 100%;
      }
    }

    .solutions-intro__title {
      margin: 0 0 42px;

      @include viewport--md {
        margin: 0 0 38px;
      }

      @include viewport--sm {
        margin: 0 0 38px;
      }
    }

    .solutions-intro__img-mob {
      @include viewport--md {
        margin-bottom: 38px;
      }
    }

    .solutions-intro__description {
      margin-bottom: 42px;

      font-weight: 600;
      font-size: 20px;
      line-height: 32px;
      color: $color-comet;

      @include viewport--md {
        margin-bottom: 38px;
      }

      @include viewport--sm {
        font-size: 17px;
        line-height: 24px;
      }
    }

    .solutions-intro__partners {
      margin-top: 42px;
      margin-left: 0;

      @include viewport--md {
        margin-top: 0;
      }
    }

    .solutions-intro__main-right {
      width: 514px;

      img {
        max-height: 486px;
      }
    }
  }

  &--b2b-and-saas {
    background: linear-gradient(180deg, #FFFFFF 22.76%, #FAFBFF 68.78%, #FFFFFF 97.85%);

    padding-bottom: 70px;

    @include viewport--md {
      padding: 40px 0 70px;
    }

    @include viewport--sm {
      padding: 84px 0 41px;

      background: $color-default-white;
    }

    .solutions-intro__title {
      max-width: 548px;

      line-height: 51px;

      @include viewport--md {
        line-height: 44px;
      }
  
      @include viewport--sm {
        line-height: 36px;
      }
  
      @media (max-width: 413px) {
        line-height: 34px;
      }
    }

    .solutions-intro__description {
      margin-bottom: 30px;

      font-size: 20px;
      line-height: 32px;
      font-weight: 600;

      @include viewport--sm {
        margin-bottom: 38px;
        font-size: 17px;
        line-height: 24px;
      }
    }

    .solutions-intro__partners {
      margin-top: 30px;
      margin-left: 0;

      @include viewport--sm {
        margin-top: 36px;
      }
    }

    .solutions-intro__main-right {
      display: none;
    }
  }

  &--optimisation {
    .solutions-intro {
      &__title {
        font-size: 40px;
        line-height: 48px;

        @include viewport--md {
          font-size: 36px;
          line-height: 44px;
        }

        @include viewport--sm {
          font-size: 28px;
          line-height: 36px;
        }

        @media (max-width: 413px) {
          font-size: 26px;
          line-height: 34px;
        }
      }

      &__description {
        max-width: 530px;
        margin: 0 0 35px;

        font-size: 20px;
        line-height: 32px;
        font-weight: 600;
    
        @include viewport--md {
          max-width: 500px;
          margin: 0 0 34px;
        }
    
        @include viewport--sm {
          max-width: 360px;

          font-size: 17px;
          line-height: 24px;
          margin: 0 0 38px;
        }
      }

      &__partners {
        margin-top: 35px;
        margin-left: 0;

        @include viewport--md {
          margin-top: 38px;
        }
      }

      &__partner-gmp {
        margin-bottom: 6.3%;
      }
    }
  }
}
