.say-card {
  position: relative;

  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 370px;
  min-height: 316px;
  // padding: 40px 40px 40px 266px;

  box-shadow: 0px 3.5px 18.5px rgba(66, 92, 176, 0.25);
  background-color: $color-default-white;
  border-radius: 10px;
  overflow: hidden;

  transition: transform $default-transition-settings;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      transform: translate(0, -5px);

      .say-card__link {
        &::after {
          transform: translateX(5px);
        }
      }
    }
  }

  @include viewport--sm {
    flex-direction: column;
    max-width: 348px;
    min-height: 0;

    border-radius: 18px;
  }

  img {
    margin: 0;
  }

  &__img {
    flex-shrink: 0;
    width: 100%;

    object-fit: cover;

    @include viewport--sm {
      display: none;
    }
  }

  &__img-mob {
    display: none;

    transform: scale(1.003);

    @include viewport--sm {
      display: block;
    }
  }

  &__text-block {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 35px 30px 33px 35px;

    text-align: left;

    @include viewport--sm {
      padding: 25px 13px 37px 25px;
    }
  }

  &__text {
    margin: 0;
    color: $color-resolution-blue;
    font-family: $font-family--inter;
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    line-height: 133%; /* 26.6px */
    letter-spacing: 0.2px;

    @include viewport--sm {
      font-size: 18px;
      line-height: 133%;
      letter-spacing: 0.18px;
    }
  }

  &__numbers {
    display: flex;
    margin-top: 31px;

    @include viewport--sm {
      margin-top: 31px;
    }
  }

  &__number-item {
    display: flex;
    align-items: center;
    margin-left: 27px;
    // max-width: 121px;

    &:first-child {
      margin-left: 0;
    }
  }

  &__number {
    flex-shrink: 0;
    // margin-bottom: 4px;
    margin-right: 23px;

    font-weight: 700;
    font-size: 45px;
    line-height: 55px;
    color: $color-torea-bay-4;

    @include viewport--sm {
      margin-right: 25px;

      font-size: 40px;
      line-height: 49px;
    }

    // @include viewport--sm {
    //   margin-bottom: 10px;

    //   font-size: 30px;
    //   line-height: 26px;
    // }
  }

  &__number-title {
    max-width: 133px;

    font-weight: 300;
    font-size: 17px;
    line-height: 21px;
    color: $color-comet;

    @include viewport--sm {
      font-size: 17px;
      line-height: 21px;
    }
  }

  &__author {
    margin-top: 20px;

    font-weight: 400;
    font-size: 14px;
    line-height: 126.3%;
    color: $color-fiord;
  }

  .say-card__bottom-logo {
    margin-top: 18px;
  }

  &__link {
    display: flex;
    align-items: center;
    margin-top: 31px;

    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: $color-blue;

    @include viewport--sm {
      margin-top: 31px;

      font-size: 20px;
      line-height: 27px;
    }

    &::after {
      content: "";
      display: block;
      width: 7px;
      height: 12px;
      margin-left: 8px;

      background-image: url("/assets/img/svg/angle-blue-right.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

      transition: transform $default-transition-settings;
    }
  }

  &--no-anim {
    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        transform: none;

        .say-card__link {
          &::after {
            transform: none;
          }
        }
      }
    }
  }

  &--no-shadow {
    box-shadow: none;
  }

  &--mini {
    flex-direction: row;
    max-width: 519px;
    min-height: 173px;

    border-radius: 9px;
    background-color: transparent;

    @include viewport--sm {
      flex-direction: column;
      max-width: 340px;

      border-radius: 15px;
    }

    .say-card__img {
      width: 151px;
      margin-left: -1px;

      transform: scale(1.01);

      @include viewport--sm {
        margin-left: 0;
      }
    }

    .say-card__img-mob {
      transform: scale(1.01);
    }

    .say-card__text-block {
      flex-grow: 0;
      justify-content: center;
      padding: 15px 29px;

      background-color: $color-default-white;

      @include viewport--sm {
        align-items: center;
        padding: 35px 29px;
      }
    }

    .say-card__text {
      flex-grow: 0;

      font-size: 19px;
      line-height: 126.3%;

      @include viewport--sm {
        font-size: 22px;
        line-height: 126.3%;
        text-align: center;
      }

      span {
        font-size: 19px;
        line-height: 126.3%;

        @include viewport--sm {
          font-size: 22px;
        }
      }
    }

    .say-card__author {
      @include viewport--sm {
        display: none;
      }
    }

    .say-card__bottom-logo {
      display: none;

      @include viewport--sm {
        display: block;
      }
    }
  }

  &--mini-bmgmoney {
    max-width: 454px;
    min-height: 161px;

    border-radius: 7px;
    background-color: $color-default-white;

    @include viewport--sm {
      max-width: 342px;

      border-radius: 15px;
      background-color: transparent;
    }

    .say-card {
      &__img-sm {
        order: 1;
        flex-shrink: 0;
        margin-right: 38px;

        @include viewport--sm {
          display: none;
        }
      }

      &__img-sm--iconfix {
        align-self: center;

        @include viewport--sm {
          display: none;
        }
      }

      &__text-block {
        padding: 15px 29px 15px 38px;

        @include viewport--sm {
          padding: 35px 29px;
        }
      }

      &__text {
        font-size: 19px;
        line-height: 25px;
        letter-spacing: 0.01em;
        color: $color-rhino;

        @include viewport--sm {
          font-size: 22px;
          line-height: 28px;
          letter-spacing: normal;
          color: $color-comet;
        }

        span {
          font-weight: 800;
          font-size: 21px;
          line-height: 28px;
          color: $color-rhino;

          @include viewport--sm {
            font-weight: 700;
            font-size: 22px;
            color: $color-crusta;
          }
        }
      }

      &__bottom-logo {
        margin-top: 24px;
      }
    }
  }

  &--financial-services {
    min-height: 316px;

    .say-card {
      &__text-block {
        @include viewport--sm {
          padding: 37px 30px;
        }
      }

      &__text {
        max-width: 272px;

        font-size: 20px;
        line-height: 133%;

        @include viewport--sm {
          max-width: 100%;

          font-size: 21px;
        }

        span {
          font-size: inherit;
        }
      }

      &__numbers {
        margin-top: 20px;

        @include viewport--sm {
          margin-top: 21px;
        }
      }

      &__link {
        margin-top: 20px;

        @include viewport--sm {
          margin-top: 21px;
        }
      }
    }

    br {
      display: inline;
    }
  }

  &--mini-szallas {
    max-width: 439px;
    min-height: 123px;

    border-radius: 5.5px;
    background-color: $color-default-white;

    @include viewport--sm {
      max-width: 342px;

      border-radius: 14px;
    }

    .say-card {
      &__img-sm {
        flex-shrink: 0;
        margin-left: 27px;

        @include viewport--sm {
          display: none;
        }
      }

      &__img-sm--iconfix {
        align-self: center;

        @include viewport--sm {
          display: none;
        }
      }

      &__img-sm-mob {
        display: none;
        margin: 49px auto 0;

        @include viewport--sm {
          display: block;
        }
      }

      &__text-block {
        padding: 21px 27px;

        @include viewport--sm {
          padding: 36px 30px 48px;
        }
      }

      &__text {
        font-weight: 700;
        font-size: 15px;
        line-height: 132.8%;
        letter-spacing: 0.01em;
        color: $color-rhino;

        @include viewport--sm {
          font-size: 20px;
          line-height: 126.3%;
          letter-spacing: normal;
        }
      }
    }
  }
}
