.more-products {
  background: $almost-white no-repeat;
  padding: 110px 0 55px;

  @include viewport--md {
    padding: 90px 0 43px;
  }

  @include viewport--sm {
    padding: 46px 0 30px;
  }

  &__title {
    font-size: 36px;
    line-height: 48px;
    color: $color-black;
    font-weight: 700;
    margin: 0 0 70px;
    text-align: center;

    @include viewport--md {
      margin-bottom: 96px;
    }

    @include viewport--sm {
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 56px;
    }
  }

  &__cards {
    display: flex;
    justify-content: space-between;

    @include viewport--md {
      flex-direction: column;
    }
  }

  .product-card {
    width: calc(50% - 25px);

    @include viewport--md {
      width: 100%;
      max-width: 630px;
      margin: 0 auto;
    }
  }

  .product-card + .product-card {
    margin-left: 50px;

    @include viewport--md {
      margin-left: auto;
      margin-top: 42px;
    }

    @include viewport--sm {
      margin-top: 30px;
    }
  }
}
