.request-demo-section {
  padding: 75px 0;

  background: linear-gradient(90.64deg, #394380 0.67%, #4F6ED4 102.07%);

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    @include viewport--sm {
      flex-direction: column;
    }
  }

  &__text-block {
    width: 398px;
    max-width: 100%;
    margin-right: 75px;

    @include viewport--sm {
      width: 340px;
      margin-right: 0;
      margin-bottom: 45px;
    }
  }

  &__title {
    margin: 0;

    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: $color-default-white;

    @include viewport--sm {
      font-size: 24px;
      line-height: 29px;
      text-align: center;
    }
  }

  &__text {
    margin-top: 30px;

    font-size: 20px;
    line-height: 30px;
    color: $color-default-white;

    @include viewport--sm {
      margin-top: 45px;

      font-size: 20px;
      line-height: 30px;
      text-align: center;
    }
  }

  &__form-block {
    width: 362px;
    max-width: 100%;
  }
}