.interface {
  padding: 40px 0 95px;
  text-align: center;

  @include viewport--md {
    padding-bottom: 75px;
  }
}

.interface__title {
  margin: 0 auto 60px;
  max-width: 580px;
  font-size: 36px;
  line-height: 48px;

  @include viewport--md {
    margin-bottom: 30px;
    max-width: 450px;
  }

  @include viewport--sm {
    margin-bottom: 48px;
    max-width: 350px;
    font-size: 24px;
    line-height: 34px;
  }
}

.interface__img {
  padding: 23px;
  border-radius: 20px;
  box-shadow: 0px 5px 50px rgba(50, 59, 117, 0.1);
  width: max-content;
  max-width: 1046px;
  width: 100%;
  margin: 0 auto 76px;

  @include viewport--md {
    margin-bottom: 57px;
  }

  @include viewport--sm {
    margin-bottom: 60px;
    padding: 7px;
    border-radius: 10px;
  }

  img {
    border-radius: 10px;

    @include viewport--sm {
      border-radius: 5px;
    }
  }
}

.interface__btn {
  padding: 28px 70px;
  font-size: 18px;
  line-height: 22px;

  @include viewport--md {
    padding: 17px 50px;
    font-size: 16px;
    line-height: 20px;
  }
}
