.reviews {
  display: grid;
  grid-template-columns: 100%;
  gap: 64px;

  @include viewport--sm {
    gap: 56px;
  }

  &__slider {
    width: 100%;

    display: grid;
    grid-template-columns: 100%;
    gap: 32px;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    width: 100%;
    height: auto;
  
    @include viewport--md {
      display: flex;
      gap: 0;
    }
  
    @include viewport--sm {
      grid-template-columns: 1fr;
    }
  }

  &__link {
    @include title-h4;

    text-decoration: underline;
    text-decoration-skip-ink: none;
    text-align: center;
    color: $color-blue;
    transition: opacity $default-transition-settings;

    display: flex;
    align-items: center;
    gap: 8px;

    justify-self: center;
  
    @include hover-focus {
      opacity: 0.5;
    }

    svg {
      display: block;
      width: 24px;
      height: 24px;
    }
  }

  
  &__pagination {
    display: none;
    align-items: center;
    justify-content: center;
    gap: 4px;

    .swiper-pagination-bullet {
      display: block;
      width: 6px;
      height: 6px;
      background: #ccd8ff;
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      background: $color-blue;
    }

    @include viewport--md {
      display: flex;
    }
  }

  .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0;
  }
}

.review-card {
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
  justify-items: center;

  padding: 32px;
  gap: 32px;

  @include viewport--md {
    padding: 16px 22px
  }

  &__quote {
    @include title-h3;

    margin: 0;
    font-family: $font-family--inter;
    font-weight: 600;
    text-align: center;
    color: #33376b;
  }

  &__rating {
    width: 116px;
    height: 28px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

