.marketing-card {
  $root: &;

  display: grid;
  grid-template-columns: 467px 44.5%;
  justify-content: space-between;

  @include viewport--md {
    width: 100%;
    max-width: 515px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    @include viewport--sm {
      max-width: unset;
    }
  }

  &__title {
    color: $color-deep-koamaru;
    font-family: $font-family--inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: auto 0 27px;

    span {
      color: $color-blue;
    }

    @include viewport--md {
      width: 100%;
      max-width: unset;
      text-align: center;
      margin-bottom: 30px;
      order: 1;
    }

    @include viewport--sm {
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 0 0 45px;
    }
  }

  &__text-wrap {
    margin: 0 0 auto;
    padding-bottom: 20px;

    @include viewport--md {
      text-align: center;
      padding-bottom: 0;
      order: 3;
    }
  }

  &__text {
    color: $color-port-gore;

    font-family: $font-family--lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30.2px;
    margin: 0;

    & + p {
      margin-top: 10px;
    }

    @include viewport--sm {
      font-size: 18px;
    }
  }

  &__link {
    display: flex;
    width: fit-content;
    position: relative;
    padding-right: 31px;
    color: $color-blue;
    font-family: $font-family--lato;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 40px;

    @include viewport--sm {
      margin-top: 20px;
    }

    @include hover-focus {
      &::after {
        transform: translateX(5px);
      }
    }

    &::before {
      content: "";
      display: block;
      height: 2px;
      background-color: $color-blue;
      position: absolute;
      left: 0;
      right: 32px;
      bottom: -2px;
    }

    &::after {
      content: "";
      position: absolute;
      width: 11px;
      height: 13px;
      right: 0;
      top: 6px;
      transition: transform $default-transition-settings;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='13' viewBox='0 0 11 13' fill='none'%3E%3Cpath d='M10.8242 6.23437L0.10248 12.4246L0.10248 0.0441751L10.8242 6.23437Z' fill='%23E1E7FC'/%3E%3C/svg%3E");
    }
  }

  &__img-wrap {
    grid-area: 1/2/3/3;
    position: relative;
    height: fit-content;

    @include viewport--md {
      margin-bottom: 30px;
      order: 2;
    }

    @include viewport--sm {
      margin-bottom: 35px;
    }
  }

  &__desc {
    margin: 0;
    margin-bottom: 56px;

    font-weight: 600;
    font-size: 24px;
    line-height: 1.25;
    color: #a3b8fb;
    font-family: $font-family--inter;

    @include viewport--md {
      margin-bottom: 25px;
    }
  }

  &--reverse {
    #{$root}__img-wrap {
      grid-area: 1/1/3/2;
      margin-right: 0;
    }

    #{$root}__text-wrap {
      max-width: 92%;

      @include viewport--md {
        max-width: unset;
      }
    }
  }

  &--digital-budget {
    grid-template-columns: minmax(0, 475px) 50%;
    column-gap: 35px;

    #{$root}__title {
      margin-bottom: 27px;

      @include viewport--md {
        font-size: 20px;
        padding: 0;
        text-align: left;
        margin-bottom: 16px;
      }
    }

    #{$root}__text {
      font-size: 20px;
      line-height: 151%;

      @include viewport--1280 {
        font-size: 18px;
        line-height: 168%;
      }

      @include viewport--md {
        text-align: left;
      }
    }

    #{$root}__img-wrap {
      align-self: center;
      justify-self: end;

      @include viewport--md {
        margin-bottom: 16px;
        order: -1;
      }

      // @include viewport--sm {
      //   margin-bottom: 39px;
      // }
    }

    #{$root}__link {
      margin-top: 35px;

      @include viewport--md {
        margin-top: 16px;
      }
    }
  }

  &--marketing-mix-optimization {
    grid-template-columns: 1fr 53%;
    grid-template-rows: repeat(2, min-content);
    gap: 27px 20px;

    @include viewport--md {
      max-width: initial;
      gap: 35px;
    }

    #{$root}__number {
      color: #a3b8fb;
      margin-bottom: 35px;
      display: block;

      @include viewport--md {
        display: none;
      }
    }

    #{$root}__title {
      max-width: 480px;
      margin: 0;
      align-self: end;

      @include viewport--md {
        align-self: auto;
        font-size: 22px;
        max-width: 560px;
        margin: 0;
      }
    }

    #{$root}__text-wrap {
      align-self: start;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      padding-bottom: 0;
      margin: 0;

      @include viewport--1280 {
        gap: 0;
      }

      @include viewport--md {
        align-self: auto;
      }
    }

    #{$root}__text {
      font-size: 20px;
      line-height: 151%;
      width: 100%;
      max-width: 470px;
      padding: 0;

      & + p {
        margin-top: 0;
      }

      @include viewport--md {
        font-size: 18px;
        line-height: 168%;
        text-align: center;
        max-width: 560px;
      }

      @include viewport--sm {
        text-align: center;
      }
    }

    #{$root}__img-wrap {
      grid-area: 1 / 2 / 3 / 3;
      border-radius: 16px;

      align-self: center;
      justify-self: center;
      padding: 12px 16px 20px;

      @include viewport--1280 {
        padding: 0;
      }

      @include viewport--md {
        max-width: 658px;
      }

      @include viewport--sm {
        margin-bottom: 0;
        border-radius: 10px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        box-shadow: 0 3px 6px 0 rgba(62, 72, 149, 0.08),
          0 10px 10px 0 rgba(62, 72, 149, 0.05);
        border-radius: 16px;

        @include viewport--sm {
          border-radius: 10px;
        }
      }
    }
  }

  &--ai-driven-attribution {
    grid-template-columns: 510px 1fr;
    align-items: center;
    gap: 0 30px;

    @include viewport--1280 {
      grid-template-columns: 450px 1fr;
    }

    #{$root}__number {
      display: block;
      color: #a3b8fb;
      margin-bottom: 35px;
    }

    #{$root}__title {
      grid-row: 2;
    }

    #{$root}__img-wrap {
      max-width: 630px;
      margin-left: auto;
      grid-area: 1 / 2 / 5 / 5;

      img {
        width: 100%;
      }

      @include viewport--sm {
        margin: 0 auto 35px;
      }
    }

    #{$root}__title {
      margin-top: 0;
    }

    #{$root}__text-wrap {
      padding-bottom: 0;
      margin-bottom: 0;
      grid-row: 3;
    }

    #{$root}__text {
      font-size: 20px;
      line-height: 1.5;

      @include viewport--sm {
        font-size: 18px;
        line-height: 30.2px;
      }
    }
  }

  &--solutions-page {
    grid-template-columns: 45% 48%;
    gap: 15px;

    @include viewport--md {
      gap: 0;
    }

    #{$root}__title {
      width: 100%;
      max-width: 480px;

      @include viewport--sm {
        text-align: center;
        padding: 0;
        margin-bottom: 20px;
      }
    }

    #{$root}__text-wrap {
      width: 100%;
      max-width: 470px;
    }

    #{$root}__text {
      @include viewport--sm {
        text-align: center;
      }
    }

    #{$root}__img-wrap {
      @include viewport--sm {
        margin-bottom: 20px;
      }

      img {
        width: 100%;
      }
    }

    &.marketing-card--reverse {
      grid-template-columns: 45% 45%;

      #{$root}__text {
        max-width: 480px;
      }
    }
  }

  &--incrementality-testing-page {
    grid-template-columns: 45% 48%;
    gap: 15px;

    @include viewport--md {
      gap: 0;
    }

    #{$root}__title {
      width: 100%;
      max-width: 480px;

      @include viewport--sm {
        text-align: center;
        padding: 0;
        margin-bottom: 20px;
      }
    }

    #{$root}__text-wrap {
      width: 100%;
      max-width: 470px;
    }

    #{$root}__text {
      font-size: 20px;
      @include viewport--sm {
        text-align: center;
      }
    }

    #{$root}__img-wrap {
      @include viewport--sm {
        margin-bottom: 20px;
      }

      img {
        width: 100%;
      }
    }
  }

  &--problem {
    grid-template-columns: 500px minmax(auto, 655px);
    gap: 15px;

    @include viewport--1280 {
      grid-template-columns: 440px minmax(auto, 655px);
    }

    #{$root}__text-wrap {
      margin: 0;
      padding-top: 75px;
      max-width: 500px;

      @include viewport--1280 {
        padding-top: 0;
        max-width: 440px;
      }
    }

    #{$root}__title {
      font-size: 32px;

      @include viewport--md {
        font-size: 27px;
      }
    }

    #{$root}__text {
      font-size: 20px;
      line-height: 1.3;

      @include viewport--md {
        font-size: 18px;
      }
    }

    #{$root}__img-wrap {
      grid-area: auto;

      img {
        width: 100%;
      }

      @include viewport--md {
        margin-bottom: 0;
      }
    }
  }

  &--problem-synthetic-conversions {
    grid-template-columns: 500px minmax(auto, 535px);

    @include viewport--1280 {
      gap: 0 50px;

      grid-template-columns: 440px minmax(auto, 535px);
    }

    #{$root}__title {
      font-size: 32px;
      line-height: 1.2;
      margin-bottom: 40px;

      @include viewport--md {
        font-size: 27px;
      }
    }

    #{$root}__desc {
      margin-bottom: 44px;

      @include viewport--md {
        margin-bottom: 27px;
      }
    }

    #{$root}__text {
      font-size: 20px;
      line-height: 1.3;

      & + p {
        margin-top: 25px;
      }

      @include viewport--md {
        font-size: 18px;
      }
    }
  }

  &--ltv-forecasting-solution {
    grid-template-columns: 575px minmax(auto, 571px);
    gap: 15px;

    @include viewport--1280 {
      grid-template-columns: 440px minmax(auto, 571px);
    }

    @include viewport--md {
      gap: 38px;
    }

    #{$root}__desc {
      margin-bottom: 48px;

      @include viewport--md {
        margin-bottom: 30px;
      }
    }

    #{$root}__text-wrap {
      margin: 0;
      padding-bottom: 0;

      @include viewport--md {
        order: 0;
        text-align: left;
      }
    }

    #{$root}__title {
      font-size: 32px;
      margin-bottom: 38px;

      @include viewport--md {
        // font-size: 27px;
        text-align: left;
        margin-bottom: 22px;
    
      }
    }

    #{$root}__text {
      font-size: 20px;
      line-height: 1.3;

      & + p {
        margin-top: 27px;
      }

      @include viewport--md {
        // font-size: 18px;
      }
    }

    #{$root}__img-wrap {
      grid-area: auto;

      img {
        width: 100%;
      }

      @include viewport--md {
        margin-bottom: 0;
      }
    }
  }
}
