@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Proxima Nova";

  font-display: block;
  src:
    url("../fonts/ProximaNova-Regular.woff2") format("woff2"),
    url("../fonts/ProximaNova-Regular.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: "Proxima Nova";

  font-display: block;
  src:
    url("../fonts/ProximaNova-Semibold.woff2") format("woff2"),
    url("../fonts/ProximaNova-Semibold.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "Proxima Nova";

  font-display: block;
  src:
    url("../fonts/ProximaNova-Bold.woff2") format("woff2"),
    url("../fonts/ProximaNova-Bold.woff") format("woff");
}

@font-face {
  font-family: 'Roboto Mono', monospace;
  src: url("../fonts/RobotoMono-Bold.ttf") format("ttf");
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "Lato";

  font-display: block;
  src:
          url("../fonts/Lato-Bold.woff2") format("woff2"),
          url("../fonts/Lato-Bold.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Inter";

  font-display: block;
  src:
          url("../fonts/Inter-Regular.woff2") format("woff2"),
          url("../fonts/Inter-Regular.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Lato";

  font-display: block;
  src:
          url("../fonts/Lato-Regular.woff2") format("woff2"),
          url("../fonts/Lato-Regular.woff") format("woff");
}

@font-face {
  font-style: italic;
  font-weight: 400;
  font-family: "Lato";

  font-display: block;
  src:
          url("../fonts/Lato-Regular-Italic.woff2") format("woff2"),
          url("../fonts/Lato-Regular-Italic.woff") format("woff");
}


@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: "Inter";

  font-display: block;
  src:
          url("../fonts/Inter-Semi-Bold.woff2") format("woff2"),
          url("../fonts/Inter-Semi-Bold.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "Inter";

  font-display: block;
  src:
          url("../fonts/Inter-Medium.woff2") format("woff2"),
          url("../fonts/Inter-Medium.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "Inter";

  font-display: block;
  src:
          url("../fonts/Inter-Bold.woff2") format("woff2"),
          url("../fonts/Inter-Bold.woff") format("woff");
}
