.starting-plan {
  $root: &;
  .container {
    padding: 80px 60px;

    @include viewport--md {
      padding: 55px 20px;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;

    @include viewport--md {
      gap: 35px;
    }
  }

  &__title {
    margin: 0;
    font-family: $font-family--inter;
    font-weight: 700;
    font-size: 36px;
    line-height: 133%;
    text-align: center;
    color: $color-torea-bay-4;

    @include viewport--md {
      font-size: 24px;
      line-height: 120%;
    }
  }

  &__card-list {
    margin: 0;
    padding: 0;
    list-style: none;

    display: flex;
    flex-wrap: wrap;
    gap: 35px;
    justify-content: center;

    @include viewport--sm {
      flex-direction: column;
      align-items: center;
      align-self: stretch;
    }
  }

  &__btn-block {
    display: flex;
    justify-content: center;
    gap: 24px;
    flex-wrap: wrap;

    @include viewport--sm {
      flex-direction: column;
      width: 100%;
      max-width: 370px;
    }

    .btn {
      border-radius: 3px;
      min-width: 220px;
      padding: 16px 32px;
    }

    .btn--bordered {
      padding: 14px 32px;
    }
  }

  &--main {
    background: linear-gradient(180deg, #f5f7ff 0%, #fff 100%);
  }

  &--marketing-mix-optimization {
    background-color: #feffff;

    .container {
      padding: 35px 60px 75px;

      @include viewport--md {
        padding: 25px 20px 50px;
      }
    }
  }
}