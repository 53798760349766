.say-card-simple {
  width: 444px;
  max-width: 100%;
  padding: 56px 40px 56px 50px;

  background-color: $color-default-white;
  border: 1px solid #EDF0FF;
  box-shadow: 0px 4px 10px rgba(163, 184, 251, 0.15);
  border-radius: 20px;

  @include viewport--sm {
    width: 350px;
    padding: 40px 50px
  }

  &__logo {
    @include viewport--sm {
      display: none;
    }
  }

  &__logo-mob {
    display: none;

    @include viewport--sm {
      display: block;
    }
  }

  &__text {
    margin-top: 40px;

    font-size: 18px;
    line-height: 30px;
    color: $color-comet;

    span {
      font-weight: 800;
      font-size: 22px;
      color: $color-torea-bay-4;
    }
  }
}
