.solutions-description-section {
  padding: 100px 0 75px;

  background-color: $color-zircon-2;

  @include viewport--sm {
    padding: 45px 0;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    margin: 0;

    max-width: 630px;

    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    color: $color-torea-bay-4;

    @include viewport--sm {
      font-size: 24px;
      line-height: 29px;
    }

    span {
      color: $color-blue;
    }
  }

  &__descriptions {
    max-width: 733px;
    margin-top: 75px;

    @include viewport--sm {
      margin-top: 45px;
    }
  }

  &__description {
    margin: 0 auto 30px;

    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: $color-comet;

    &:last-child {
      margin-bottom: 0;
    }

    @include viewport--sm {
      line-height: 32px;
    }

    span {
      font-weight: 700;
    }
  }

  .demo-sm-form {
    align-self: stretch;
    margin-top: 75px;

    @include viewport--sm {
      margin-top: 45px;
    }

    form {
      justify-content: center;

      @include viewport--sm {
        flex-direction: column;
        align-items: stretch;
      }
    }

    input {
      @include viewport--sm {
        width: 100%;
        max-width: 100%;
        margin: 0 0 12px;
      }
    }

    .btn {
      @include viewport--sm {
        width: 100%;
      }
    }
  }
}