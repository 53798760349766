.pages-cards {
  padding: 100px 0;
  background: #fff;

  @include viewport--md {
    padding: 68px 0;
  }

  @include viewport--sm {
    padding: 45px 0;
  }

  &__block-title {
    margin-top: 0;
    margin-bottom: 75px;

    font-weight: 700;
    font-size: 42px;
    line-height: 48px;
    color: $color-east-bay-2;
    text-align: center;

    span {
      // color: $color-torea-bay;
      color: $color-blue;
    }

    @include viewport--md {
      font-size: 36px;
      line-height: 44px;
    }

    @include viewport--sm {
      margin-bottom: 45px;
      font-size: 24px;
      line-height: 24px;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -39px;
    margin-top: -45px;
  }

  &__item {
    margin-left: 39px;
    margin-top: 45px;
  }

  &--solutions {
    background: linear-gradient(180deg, #FFFFFF 0%, #FAFBFF 100%);
  }

  &--optimisation {
    background: linear-gradient(180deg, #F9FAFF 0%, #FFFFFF 100%);

    @include viewport--sm {
      background: linear-gradient(180deg, #F9FAFF 0%, #FBFCFF 100%);
    }
  }

  &--main {
    background: linear-gradient(180deg, #F5F7FF 0%, #FFFFFF 100%);

    .container {
      @media(min-width: $viewport--xl) {
        padding: 0 60px;
      }
    }

    .pages-cards {
      &__block-title {
        max-width: 805px;
        margin-left: auto;
        margin-right: auto;
      }

      &__items {
        margin-left: -65px;
        margin-top: -65px;
      }
    
      &__item {
        margin-left: 65px;
        margin-top: 65px;
      }
    }
  }
}