.bigquery-page {
  .bigquery-intro {
    @include viewport--md {
      margin-bottom: 32px;
    }

    @include viewport--sm {
      margin-bottom: 13px;
    }
  }

  .trusted-brands--blue {
    padding-bottom: 9px;
  }

  .slider-steps {
    @include viewport--sm {
      margin-bottom: 35px;
    }
  }
}
