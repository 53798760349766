.introducing-solution {
  $root: &;
  background: linear-gradient(149deg, #252d72 36.8%, #10173a 70.69%);

  .container {
    padding: 80px 130px 0;

    @include viewport--1280 {
      padding-left: 60px;
      padding-right: 60px;
    }

    @include viewport--md {
      padding: 55px 20px 0;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 48px;

    @include viewport--md {
      gap: 0;
    }
  }

  .introducing-solution__video-wrapper {
    position: relative;
    z-index: 0;
    width: 100%;
    // padding-top: 30px;

    // @include viewport--sm {
    //   padding: 20px 10px 26px;
    // }
  }

  .introducing-solution__video {
    position: relative;
    z-index: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;

    @include viewport--md {
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;
    }

    img {
      transition: opacity $default-transition-settings;
    }

    @include hover-focus {
      & img {
        opacity: 0.7;
      }
    }
  }

  &__label {
    color: $color-perano-3;
    text-align: center;
    font-family: $font-family--inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__title {
    color: $color-default-white;
    text-align: center;
    font-family: $font-family--inter;
    font-size: 36px;
    font-weight: 700;
    line-height: normal;
    margin: 0;

    @include viewport--md {
      font-size: 24px;
      line-height: 120%;
      margin: 0 0 24px;
    }
  }

  &__btn-wrapper {
    display: flex;
    justify-content: center;
    gap: 24px;

    @include viewport--sm {
      flex-direction: column;
      gap: 16px;
      justify-content: initial;
    }
  }

  &__btn {
    color: $color-default-white;
    font-family: $font-family--inter;
    border-radius: 3px;
    padding: 3px 48px;
    font-size: 20px;
    line-height: 240%;
    font-weight: 600;
    text-align: center;

    &--grey {
      background-color: #5c6393;
    }
  }

  &__video-wrapper {
    @include viewport--md {
      margin-top: 45px;
      margin-bottom: 45px;
    }
    img {
      @include viewport--md {
        width: 50%;
        min-width: 374px;
      }

      @include viewport--sm {
        min-width: initial;
        width: 100%;
      }
    }
  }

  &__plate {
    padding: 80px 0;

    @include viewport--md {
      padding: 55px 0;
    }

    .solution-plate {
      &__inner {
        gap: 20px;

        @include viewport--md {
          gap: 34px;
        }
      }

      &__title {
        margin: 0 0 36px;

        @include viewport--1280 {
          font-size: 30px;
        }

        @include viewport--md {
          text-align: center;
          font-weight: 600;
          font-size: 24px;
          max-width: 470px;
        }
      }

      &__left {
        width: auto;

        @include viewport--md {
          max-width: none;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }

      &__description {
        line-height: 151%;
        margin: 0 0 35px;

        @include viewport--md {
          text-align: center;
          font-weight: 400;
          font-size: 18px;
          line-height: 137%;
          align-self: stretch;
        }
      }

      &__partners-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        flex-wrap: wrap;

        @include viewport--md {
          justify-content: center;
          gap: 8px;
        }

        @include viewport--sm {
          margin: 0;
          gap: 6px;
        }
      }

      &__rating {
        font-size: 13px;
        line-height: normal;
        font-weight: 400;
        margin-left: 4px;
      }

      &__partner-item {
        height: 48px;

        @include viewport--md {
          height: 40px;
        }

        img {
          max-height: 48px;
          width: 100%;
          height: auto;
          object-fit: cover;

          @include viewport--md {
            max-height: 40px;
          }
        }
      }

      &__slogan-wrap {
        gap: 40px;
        max-width: 560px;
        transition: transform $default-transition-settings;

        @include hover-focus {
          transform: translateY(-5px);
        }

        @include viewport--md {
          margin-left: 0;
          width: 40.6%;
          min-width: 312px;
          gap: 16px;
        }

        @include viewport--sm {
          align-items: center;
          gap: 10px;
        }
      }

      &__slogan-img-wrap {
        width: 120px;

        @include viewport--md {
          width: 80px;
          align-items: flex-start;
        }

        @include viewport--sm {
          display: block;
        }

        img {
          width: 100%;
          height: auto;
        }
      }

      &__rating-wrap {
        padding-top: 0;

        @include viewport--md {
          padding-top: 3px;
          margin-bottom: 11px;
        }

        @include viewport--sm {
          margin-bottom: 0;
        }
      }

      &__slogan-text {
        @include viewport--md {
          font-weight: 700;
          font-size: 24px;
          line-height: 154%;
        }
      }
    }

    .partner {
      &-cloud {
        width: 126px;

        @include viewport--md {
          width: 104px;
        }
      }

      &-google {
        width: 153px;

        @include viewport--md {
          width: 127px;
        }
      }

      &-meta {
        width: 96px;

        @include viewport--md {
          width: 80px;
        }
      }

      &-soc {
        width: 48px;

        @include viewport--md {
          width: 40px;
        }
      }
    }
  }

  &--marketing-mix-optimization {
    .container {
      @include viewport--sm {
        padding-top: 50px;
      }
    }
    #{$root}__inner {
      gap: 75px;

      @include viewport--md {
        gap: 45px;
      }

      @include viewport--sm {
        gap: 0;
      }
    }

    #{$root}__title {
      margin: 0;

      @include viewport--sm {
        margin: 0 auto 24px;
        max-width: 290px;
      }
    }

    #{$root}__video-wrapper {
      margin: 0;

      @include viewport--sm {
        margin: 45px 0;
      }
    }

    #{$root}__case-study {
      padding: 93px 0 75px;

      @include viewport--md {
        padding: 50px 0 40px;
      }
    }
  }

  &--ai-driven-attribution {
    #{$root}__inner {
      max-width: 780px;
      margin: 0 auto;
      gap: 50px;
      margin-bottom: 50px;

      @include viewport--md {
        gap: 0;
      }
    }

    #{$root}__label {
      font-size: 24px;
      line-height: 29px;
      font-family: $font-family--inter;
      font-weight: 400;
      margin: 0;

      @include viewport--md {
        margin-bottom: 24px;
      }
    }

    #{$root}__text {
      color: $color-default-white;
      text-align: center;
      margin: 0 auto;
      font-size: 20px;
      line-height: 28px;
      font-family: $font-family--inter;
      max-width: 755px;
    }

    .container {
      padding: 100px 60px 50px;

      @include viewport--md {
        padding: 55px 20px;
      }
    }
  }

  &--graph {
    #{$root}__inner {
      gap: 74px;

      @include viewport--md {
        gap: 40px;
      }
    }

    #{$root}__title {
      font-size: 30px;
      line-height: 36px;
      max-width: 740px;
      margin: 0 auto;

      @include viewport--md {
        font-size: 24px;
        line-height: 1.25;
        margin: 0;
      }
    }

    .container {
      padding: 64px 120px 92px;

      @include viewport--lg {
        padding: 60px;
      }

      @include viewport--md {
        padding: 60px 40px;
      }

      @include viewport--sm {
        padding: 60px 20px;
      }
    }

    #{$root}__img-wrap {
      padding-left: 96px;
      max-width: 1052px;
      margin: 0 auto;

      @include viewport--lg {
        padding: 0;
      }
    }
  }
}
