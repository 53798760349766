.faq {
  padding: 95px 0 90px;

  background-color: $color-ghost-white;

  @include viewport--md {
    padding: 105px 0 110px;
  }

  @include viewport--sm {
    padding: 78px 0 42px;
  }
}

.faq__title {
  margin: 0 0 75px;

  font-size: 36px;
  line-height: 48px;
  text-align: center;

  @include viewport--md {
    margin-bottom: 67px;
  }

  @include viewport--sm {
    margin-bottom: 56px;

    font-size: 24px;
    line-height: 34px;
  }
}

.faq__lists {
  display: flex;
  justify-content: space-between;
  max-width: 1100px;
  margin: 0 auto;

  @include viewport--md {
    display: block;
    width: 100%;
  }

  @include viewport--sm {
    max-width: none;
    width: 100%;
  }
}

.faq__list {
  margin: 0;
  padding: 0;

  list-style: none;

  @include viewport--md {
    display: block;
    // max-width: 600px;
  }

  @include viewport--sm {
    max-width: none;
    width: 100%;
  }

  &:first-child {
    margin-right: 30px;

    @include viewport--md {
      margin-right: 0;
    }
  }
}

.faq__item {
  position: relative;

  max-width: 530px;
  margin-bottom: 20px;

  background-color: $color-default-white;
  border-top: 1px solid $color-lavender;
  border-bottom: 1px solid $color-lavender;
  overflow: visible;

  transition: max-height $default-transition-settings;

  @include viewport--md {
    max-width: none;
    margin-bottom: 21px;
  }

  &.is-active {
    .faq__text {
      opacity: 1;
    }

    .faq__btn::after {
      transform: rotate(90deg);
    }
  }
}

.faq__item-title {
  position: relative;
  padding: 36px 70px 36px 40px;
  min-height: 128px;
  width: 100%;
  display: block;
  overflow: auto;

  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  text-align: left;
  border: none;
  box-shadow: none;
  border-radius: 0;
  background-color: $color-transparent;
  cursor: pointer;

  @include viewport--sm {
    padding-left: 33px;
  }
}

.faq__item-question {
  display: block;
}

.faq__text {
  margin: -35px 0 0;
  padding: 7px 35px 25px 40px;
  box-sizing: content-box;

  font-size: 18px;
  line-height: 26px;
  color: $color-comet;

  opacity: 0;
  max-height: 0;

  transition: opacity $default-transition-settings, max-height $default-transition-settings, padding $default-transition-settings;

  pointer-events: none;

  a {
    pointer-events: all;
  }

  @include viewport--sm {
    padding-left: 33px;
  }
}

.faq__btn {
  position: absolute;
  top: 55px;
  right: 35px;

  width: 24px;
  height: 24px;

  &::before {
    content: "";
    position: absolute;
    top: 50%;

    display: block;
    width: 100%;
    height: 2px;

    background-color: $color-blue;
    transform: translateY(-50%);
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;

    display: block;
    width: 2px;
    height: 100%;

    background-color: $color-blue;
    transform: translateX(-50%);

    transition: transform $default-transition-settings;
  }
}
