.reports-enhancement {
  border-top: 1px solid $color-jacksons-purple;
  background-color: $color-torea-bay;
  padding: 139px 0;

  @media (max-width: 1279px) {
    padding: 12% 0 60px;
  }

  @include viewport--md {
    padding: 72px 0 0;
  }

  @include viewport--sm {
    padding: 43px 0 0;
  }

  .container {
    position: relative;

    @include viewport--md {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__intro {
    margin: 0 0 44px;
    font-size: 24px;
    line-height: 26px;
    color: $color-orange;
    max-width: 440px;
    font-weight: bold;

    @include viewport--md {
      text-align: center;
      max-width: 560px;
      margin-bottom: 40px;
    }
  }

  &__title {
    margin: 0 0 44px;
    font-size: 36px;
    line-height: 48px;
    color: $color-default-white;
    max-width: 440px;

    @media (max-width: 1279px) {
      margin-bottom: 50px;
      font-size: 34px;
      line-height: 42px;
      max-width: 380px;
    }

    @include viewport--md {
      text-align: center;
      max-width: 560px;
      margin-bottom: 40px;
    }

    @include viewport--sm {
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 30px;
    }
  }

  &__description {
    margin: 0 0 44px;
    font-size: 18px;
    line-height: 26px;
    color: $color-default-white;
    max-width: 440px;
    font-weight: 600;

    @include viewport--md {
      text-align: center;
      max-width: 560px;
      margin-bottom: 40px;
    }
  }

  .btn {
    min-width: 290px;

    @include viewport--sm {
      width: 97%;
      min-width: 0;
    }
  }

  img {
    position: absolute;
    bottom: -139px;
    right: 110px;
    max-width: 733px;

    @include viewport--lg {
      right: 2vw;
    }

    @media (max-width: 1279px) {
      bottom: -60px;
      width: 52%;
      max-width: 580px;
    }

    @include viewport--md {
      position: static;
      width: 100%;
      margin-top: 40px;
      max-width: 733px;
    }
  }
}
