.introducing-optimization {
  .container {
    padding: 75px 60px;

    @include viewport--md {
      padding: 50px 20px;
    }
  }

  &__title {
    margin: 0;
    max-width: 500px;
    font-family: $font-family--inter;
    font-weight: 700;
    font-size: 36px;
    line-height: 133%;
    text-align: center;
    color: $color-deep-koamaru;

    @include viewport--md {
      font-size: 24px;
      line-height: 120%;
      max-width: 400px;
    }

  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 75px;

    @include viewport--md {
      gap: 45px;
    }
  }

  &__card-list {
    margin: 0;
    padding: 0;
    list-style: none;

    display: flex;
    flex-wrap: wrap;
    gap: 35px;
    justify-content: center;
  }

  &__reviews-block {

    .reviews-list {
      margin: 0;
      padding: 0;
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 370px));
      gap: 35px;

      @include viewport--md {
        gap: 30px;
      }

      @include viewport--sm {
        grid-template-columns: 1fr;
        gap: 35px;
      }


    }

    .review {
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;
      align-items: end;

      padding: 0 38px;
      gap: 35px;

      @include viewport--md {
        padding: 16px 0;
        gap: 16px;
      }

      @include viewport--sm {
        padding: 16px 35px;
      }

      &__quote {
        margin: 0;
        font-family: $font-family--lato;
        font-style: italic;
        font-weight: 700;
        font-size: 22px;
        text-align: center;
        color: $color-deep-koamaru;
        max-width: 270px;

        @include viewport--md {
          max-width: 200px;
        }

        @include viewport--sm {
          max-width: 270px;
        }
      }

      &__rating {
        width: 144px;
        height: 25px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}