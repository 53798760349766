.say-block {
  $root: &;

  padding: 60px 0 78px;

  @media (max-width: 1279px) {
    padding: 12% 0 60px;
  }

  @include viewport--md {
    padding: 72px 0 0;
  }

  @include viewport--sm {
    padding: 43px 0;
  }

  .container {
    position: relative;
    text-align: center;

    @include viewport--md {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__block-title {
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    text-align: center;
    margin: 0 0 30px;
    color: $color-black;

    @include viewport--md {
      font-size: 36px;
      line-height: 44px;
    }

    @include viewport--sm {
      padding: 0 20px;
      margin-bottom: 0;
      font-size: 28px;
      line-height: 36px;
    }
  }

  &__intro {
    margin: 49px auto;
    color: $color-new-grey;
    max-width: 614px;
    font-weight: 600;
    font-size: 20px;
    font-style: normal;
    line-height: 36px;
    text-align: center;

    @include viewport--md {
      text-align: center;
      max-width: 560px;
      margin-bottom: 40px;
    }
  }

  &__flex {
    display: flex;
    justify-content: center;

    @include viewport--sm {
      flex-direction: column;
    }
  }

  &__title {
    margin: 0 0 44px;
    font-size: 30px;
    line-height: 36px;
    color: $color-default-white;
    max-width: 500px;

    @include viewport--md {
      text-align: center;
      max-width: 560px;
      margin-bottom: 40px;
    }

    @include viewport--sm {
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 30px;
    }
  }

  &__description-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__description {
    margin: 0 0 44px;
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: $color-comet;
    max-width: 611px;

    @include viewport--md {
      text-align: left;
      max-width: 560px;
      margin-bottom: 40px;
    }
  }

  &__button {
    text-align: center;
    //margin-bottom: 49px;
  }

  &__name {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    text-align: right;
    color: $color-black;

    h3 {
      display: inline;
      margin: 0;

      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
      color: inherit;
    }
  }

  .btn {
    padding: 18px 32px 17px;
    min-width: 190px;
    margin-top: 39px;

    color: $color-default-white;
    text-align: center;
    font-family: $font-family--inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;

    @include viewport--sm {
      min-width: 0;
      margin-top: 26px;
    }
  }

  img {
    margin: 0 auto;
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -35px;
    // margin-top: -57px;
    margin-top: 10px;
    margin-bottom: 40px;

    @include viewport--sm {
      margin-top: 0;
      margin-bottom: 10px;
    }
  }

  &__card {
    margin-left: 35px;
    margin-top: 65px;

    @include viewport--sm {
      margin-top: 34px;
    }
  }

  &__cards-big {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
  }

  &__card-big {
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__card-big-detailed {
    margin: 0 auto;
  }

  &--form-page {
    padding-top: 50px;

    h2 {
      font-size: 2em;
      margin: 0.67em 0;

      @include viewport--sm {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }

  &--solutions {
    padding: 100px 0;

    @include viewport--md {
      padding: 68px 0;
    }

    @include viewport--sm {
      padding: 45px 0;
    }

    .say-block__block-title {
      font-size: 42px;
      font-weight: 700;
      line-height: 48px;
      text-align: center;
      margin: 0 0 75px;
      color: $color-east-bay-2;

      @include viewport--md {
        font-size: 36px;
        line-height: 44px;
      }

      @include viewport--sm {
        padding: 0 20px;
        margin-bottom: 0;
        font-size: 24px;
        line-height: 24px;
      }

      span {
        color: $color-blue;
      }
    }

    .say-block__flex:not(:last-child) {
      margin-bottom: 45px;

      @include viewport--sm {
        margin-bottom: 0;
      }
    }

    .say-block__logo {
      transition: opacity $default-transition-settings;

      &:hover {
        opacity: 0.7;
      }
    }

    .say-block__descr-title {
      margin: 0;

      max-width: 608px;
      margin-bottom: 30px;

      font-weight: 700;
      font-size: 28px;
      line-height: 48px;
      text-align: left;
      color: $color-blue;

      @include viewport--md {
        font-size: 25px;
        line-height: 30px;
      }

      @include viewport--sm {
        margin-bottom: 45px;

        font-size: 22px;
        line-height: 27px;
        text-align: center;
      }

      a {
        color: inherit;

        transition: opacity $default-transition-settings;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .say-block__description {
      margin-bottom: 30px;

      @include viewport--sm {
        margin-bottom: 45px;
      }
    }
  }

  &--measurement {
    background: linear-gradient(360deg, #ffffff 0%, #fafbff 100%);
  }

  &--ecommerce {
    background-color: $color-zircon;
  }

  &--solutions-alt {
    padding: 75px 0;

    @include viewport--sm {
      padding: 45px 0;
    }
  }

  &--main {
    background-color: $color-default-white;
    padding: 0;

    .container {
      padding: 80px 100px;
      text-align: initial;

      @include viewport--1280 {
        padding: 80px 60px;
      }

      @include viewport--md {
        padding: 55px 145px;
      }

      @include viewport--sm {
        padding: 55px 20px;
      }
    }

    #{$root}__block-title {
      width: 100%;
      text-align: center;
      margin: 0 auto 80px;
      font-family: $font-family--inter;
      color: $color-torea-bay-4;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 133%;

      @include viewport--1280 {
        font-size: 30px;
        margin-bottom: 60px;
      }

      @include viewport--md {
        font-size: 24px;
        line-height: 120%;
        margin-bottom: 45px;
      }
    }

    #{$root}__inner {
      display: flex;
      flex-direction: column;
      gap: 80px;

      @include viewport--1280 {
        gap: 60px;
      }

      @include viewport--md {
        gap: 45px;
      }
    }

    #{$root}__cards {
      margin: 0;
      gap: Max(calc((100% - 3 * 370px) / 2), 35px);

      @include viewport--sm {
        gap: 24px;
      }
    }

    #{$root}__card {
      @include viewport--md {
        margin: 0;
      }

      img {
        @include viewport--sm {
          width: 100%;
        }
      }

      .say-card__img-wrap {
        height: 132px;
        overflow: hidden;
      }

      .say-card__text-block {
        @include viewport--sm {
          display: block;
          padding: 25px 25px 38px 28px;
        }
      }
    }

    #{$root}__button {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 16px;

      @include viewport--md {
        margin-top: -35px;
      }

      .btn {
        margin-top: 0;
        min-width: 280px;
        line-height: 240%;
        padding: 3px 32px;

        @include viewport--md {
          min-width: 192px;
        }

        @include viewport--sm {
          width: 100%;
        }
      }
    }
  }

  &--optimisation {
    padding: 100px 0;

    background-color: $color-zircon-3;

    @include viewport--md {
      padding: 75px 0;
    }

    @include viewport--sm {
      padding: 45px 0;
    }

    .container {
      @media (min-width: $viewport--xl) {
        padding: 0 60px;
      }
    }

    .say-block__block-title {
      @include viewport--sm {
        display: none;
      }
    }

    .say-block__cards {
      @include viewport--sm {
        margin-top: -45px;
      }
    }
  }

  &--marketing-mix-optimization {
    padding: 75px 0;

    @include viewport--md {
      padding: 50px 0 25px;
    }

    .container {
      padding: 0 60px;

      @include viewport--md {
        padding: 0 20px;
      }
    }

    #{$root}__block-title {
      margin: 0 0 75px;

      @include viewport--md {
        margin: 0 0 35px;
        font-size: 24px;
        line-height: 120%;
      }
    }

    #{$root}__cards {
      margin: 0 0 75px;
      gap: 35px;

      @include viewport--md {
        flex-direction: column;
        align-items: center;
        margin: 0 0 35px;
      }
    }

    #{$root}__card {
      margin: 0;
      min-height: 283px;

      @include viewport--md {
        min-height: auto;
        border-radius: 18px;
      }

      @include viewport--sm {
        max-width: 370px;
      }
    }

    .say-card__text-block {
      padding: 35px 33px;

      @include viewport--md {
        padding: 24px 23px 39px 28px;
      }
    }

    .say-card__text {
      font-size: 18px;
      line-height: 133%;
    }

    #{$root}__button .btn {
      padding: 16px 32px;
      margin-top: 0;

      @include viewport--sm {
        width: 100%;
      }
    }

    .say-card__img {
      @include viewport--sm {
        display: block;
      }
    }
  }

  &--pricing {
    padding: 100px 0;
    padding-top: 50px;

    background: linear-gradient(180deg, #ffffff 0%, #eff3ff 133.47%);

    @include viewport--md {
      padding: 75px 0;
    }

    @include viewport--sm {
      padding: 45px 0;

      background: $color-zircon-3;
    }

    .container {
      @media (min-width: $viewport--xl) {
        padding: 0 60px;
      }
    }

    .say-block__block-title {
      max-width: 735px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &--financial-services-detailed {
    background-color: $color-zircon-2;
    background-image: url("../img/bg/financial-services-say-block-bg.png");
    background-position: center;
    background-size: 1440px 599px;
    background-repeat: no-repeat;

    @include viewport--sm {
      background: linear-gradient(180deg, #f9fbff 0%, #ffffff 100%);
    }
  }

  &--financial-services {
    padding: 100px 0;

    @include viewport--md {
      padding: 75px 0;
    }

    @include viewport--sm {
      padding: 45px 0;
    }

    .container {
      @media (min-width: $viewport--xl) {
        padding: 0 60px;
      }
    }
  }

  &--travel-detailed {
    padding-bottom: 79px;

    background-color: $color-zircon-2;
    background-image: url("../img/bg/travel-say-block-bg.png");
    background-position: center;
    background-size: 1440px 533px;
    background-repeat: no-repeat;

    @include viewport--sm {
      padding-bottom: 45px;

      background: linear-gradient(180deg, #f9fbff 0%, #ffffff 100%);
    }
  }

  &--ai-driven-attribution {
    background-color: $color-default-white;
    padding: 0;

    .container {
      padding: 80px 130px;
      text-align: initial;

      @include viewport--1280 {
        padding: 80px 60px;
      }

      @include viewport--md {
        padding: 55px 145px;
      }

      @include viewport--sm {
        padding: 55px 20px;
      }
    }

    #{$root}__block-title {
      width: 100%;
      text-align: center;
      margin: 0 auto 80px;
      font-family: $font-family--inter;
      color: $color-torea-bay-4;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 133%;

      @include viewport--1280 {
        font-size: 30px;
        margin-bottom: 60px;
      }

      @include viewport--md {
        font-size: 24px;
        line-height: 120%;
        margin-bottom: 45px;
      }
    }

    #{$root}__inner {
      display: flex;
      flex-direction: column;
      gap: 78px;

      @include viewport--1280 {
        gap: 60px;
      }

      @include viewport--md {
        gap: 45px;
      }
    }

    #{$root}__button {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 16px;

      .btn {
        margin-top: 0;
        min-width: 280px;
        line-height: 240%;
        padding: 3px 32px;

        @include viewport--md {
          min-width: 192px;
        }

        @include viewport--sm {
          width: 100%;
        }
      }
    }

    #{$root}__cards {
      margin: 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 35px;

      @include viewport--1280 {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }

    #{$root}__card {
      margin: 0;
    }

    .case-study--main .case-study__inner {
      column-gap: 55px;
      grid-template-columns: 38% 1fr;
      align-items: center;

      @include viewport--1280 {
        grid-template-columns: 41% 1fr;
        align-items: flex-start;
        column-gap: 25px;
      }
    }

    .case-study__img-wrap {
      margin-top: 0;
    }
  }
}
