.our-story {
  color: #33376b;
  font-family: $font-family--inter;

  &__inner {
    padding-top: 112px;
    padding-bottom: 112px;

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 20px;

    @include viewport--md {
      grid-template-columns: 100%;
      padding: 112px 20px;
      gap: 64px;
    }

    @include viewport--sm {
      padding: 88px 16px;
      gap: 56px;
    }
  }

  &__left {
    grid-column: 1 / 6;

    display: flex;
    flex-direction: column;

    @include viewport--md {
      grid-column: 1 / -1;
    }
  }

  &__right {
    grid-column: 7 / -1;

    @include viewport--md {
      grid-column: 1 / -1;
    }
  }

  &__title {
    @include title-h2;

    margin: 0 0 24px;

    @include viewport--md {
      margin-bottom: 64px;
    }

    @include viewport--sm {
      margin-bottom: 56px;
    }
  }

  &__image-wrap {
    margin-top: auto;
    position: relative;
    z-index: 1;

    @include viewport--1280 {
      margin-top: 0;
    }
  }

  &__logo {
    position: absolute;
    z-index: 1;
    top: -50px;
    right: -50px;
    width: 100px;
    height: 100px;
    background: $color-blue;
    border: 6px solid $color-default-white;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      width: 40px;
      height: 57px;
    }

    @include viewport--md {
      display: none;
    }
  }

  &__image {
    border-radius: 20px;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__intro {
    @include title-h3;

    font-family: $font-family--inter;
    margin: 0 0 24px;

    strong {
      font-weight: 600;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 16px;

    p {
      @include text-body;
      margin: 0;
    }
  }
}