.pricing-solution {
    display: flex;
    justify-content: space-between;
    padding: 75px 49px 22px;

    @include viewport--sm {
        flex-direction: column;
        align-items: center;
        padding: 0 0 21px;
    }


    & .pricing-solution__left {
        margin-right: 5%;
        width: 50%;

        @include viewport--sm {
            width: 344px;
            margin-bottom: 20px;
            margin-right: 0;
        }
    }

    &__title {
        margin-top: 0;
        margin-bottom: 34px;
        color: #27293A;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.25;

        @include viewport--sm {
            text-align: center;
            margin-bottom: 32px;
        }
    }

    & ul {
        margin: 0;
        padding: 0;
        padding-left: 15px;
        list-style: none;
    }

    &__point {
        display: flex;
        align-items: center;
        margin-bottom: 41px;
        color: #5E657C;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-family: $font-family--lato;

        @include viewport--sm {
            margin-bottom: 39px;
        }

        img {
            width: 13px;
            height: 9px;
            flex-shrink: 0;
            margin-right: 18px;
        }
    }

    & .pricing-solution__right {
        width: 40%;

        @include viewport--sm {
            width: 344px;
        }
    }

    & .quote-cards__quote {
        text-align: initial;
        color: #41475B;
        font-size: 17px;
        font-style: italic;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.17px;
        font-family: $font-family--lato;

    }

    & .quote-cards__author {
        color: #000;
        text-align: left;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.08px;
        font-family: $font-family--inter;
    }

    & .quote-cards__author-name {
        color: #000;
    }

    & .quote-cards__item {
        align-items: start;
        max-width: 440px;
        margin: 0;
        margin-bottom: 8px;
        padding: 21px;

        img {
            width: 105px;
            height: 18px;
            margin-bottom: 25px;
        }
    }

    & .quote-cards__quote-wrapper {
        margin-bottom: 24px;
    }
}

.pricing-plans .demo__btn {
    display: flex;
    justify-content: center;

    @include viewport--sm {
        display: none;
    }


    & .request-demo-btn {
        margin: 15px;
        min-width: 180px;
        padding-top: 17px;
        padding-bottom: 17px;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}
