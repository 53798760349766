.simple-hero {
  background: $color-midnight-express;
  color: $color-default-white;
  
  font-family: $font-family--inter;

  &__inner {
    padding: 70px 20px 110px;
    max-width: 620px;
    text-align: center;

    @include viewport--md {
      max-width: 100%;
      padding-top: 35px;
    }

    @include viewport--sm {
      padding-top: 60px;
      padding-bottom: 85px;
    }
  }

  &__title {
    @include title-h3;

    margin: 0 0 32px;
    color: #a6bbff;
  }

  &__subtitle {
    @include title-h2;

    margin: 0 0 24px;
  }

  &__description {
    @include text-body;

    margin: 0;
    color: #afb9d7;

    padding: 0 15px;

    @include viewport--md {
      padding: 0;
    }
  }
}