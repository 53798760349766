.trusted-brands {
    margin: 0 auto;

    text-align: center;

    padding: 75px 0 40px;

    .btn {
        min-width: 290px;
        margin-top: 33px;

        @include viewport--md {
            margin-top: 50px;
        }

        @include viewport--sm {
            margin-top: 22px;
        }
    }

    h2 {
        margin: 0;
        margin-bottom: 21px;

        font-size: 36px;
        line-height: 48px;

        @include viewport--md {
            margin-bottom: 45px;
        }

        @include viewport--sm {
            font-size: 24px;
            line-height: 34px;
        }
    }

    &--no-link {
        .btn {
            display: none;
        }
    }

    &--short {

        @media (min-width: 1024px) {
            padding-top: 18px;
            padding-bottom: 60px;

            .trusted-brands__list {
                max-width: 1440px;
                justify-content: space-between;
            }

            .trusted-brands__item {
                width: 14%;

                img {
                    width: 100%;

                    @include viewport--sm {
                        width: auto;
                        &:nth-child(3) {
                            order: 4;
                        }
                    }
                }

                img {
                    @media (min-width: 1024px) {
                        width: 80%;
                    }
                }

                @include viewport--md {
                    width: calc(100% / 3);
                }

                @include viewport--sm {
                    width: 50%;
                }
            }
        }

        @include viewport--md {
            padding-bottom: 60px;

            .trusted-brands__item {
                padding: 30px 0;
            }
        }

        @include viewport--sm {
            .trusted-brands__item {
                padding: 24px 14px;

                &:nth-child(3n+1) {
                    justify-content: flex-start;
                    padding-left: 0;
                }

                &:nth-child(3n) {
                    justify-content: flex-end;
                    padding-right: 0;
                }
            }
        }
    }

    &--blue {
        padding-top: 0;
        padding-bottom: 0;
        background-color: $color-alice-blue;
        // @include viewport--md {
        //   display: none;
        // }
        @include viewport--md {
            background-color: transparent;
        }

        & h2 {
            display: none;
        }

        & .container {
            padding: 0 78px;

            @include viewport--lg {
                padding: 0 60px;
            }

            @include viewport--md {
                padding: 0 40px;
            }

            @include viewport--sm {
                padding: 0 16px;
            }
        }

        .trusted-brands__list {
            @media (min-width: 1024px) {
                flex-wrap: wrap;
                justify-content: center;
                margin-left: -45px;
                padding-top: 25px;
                padding-bottom: 25px;
            }
        }

        & .trusted-brands__list--mob {
            flex-flow: wrap-reverse;
        }

        .trusted-brands__item {
            @media (min-width: 1024px) {
                flex-shrink: 1;
                width: 20%;
                min-width: 0;
                padding: 25px 0;
                padding-left: 45px;
            }

            @include viewport--sm {
                width: 33.33%;
                padding-bottom: 0;
            }

            img {
                @media (min-width: 1024px) {
                    // max-width: 80%;
                    width: auto;
                    min-width: 0;

                    object-fit: contain;
                }
            }
        }
    }

    &--md-visible {
        @include viewport--md {
            display: block;
        }
    }
}

.trusted-brands__list {
    display: flex;
    max-width: 982px;
    margin: 0 auto;
    padding: 0;
    justify-content: center;

    flex-wrap: wrap;

    list-style: none;

    &--desk-tabl {
        @include viewport--sm {
            display: none;
        }
    }

    &--mob {
        display: none;

        @include viewport--sm {
            display: flex;
        }
    }

    &--about {
        .trusted-brands__item {
            @include viewport--sm {
                width: 33%;
            }
        }
    }
}

.trusted-brands__item {
    display: flex;
    width: 33%;
    padding: 39px 0;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    @include viewport--md {
        width: 33.33%;
        padding: 14px 0;
    }

    @include viewport--sm {
        width: 50%;
        padding: 14px;
    }

    img {
        max-width: 174px;
        max-height: 77px;
        width: auto;
        height: auto;

        @include viewport--sm {
            max-width: 100%;
        }
    }
}
