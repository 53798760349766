.marketing-intro {
  background-color: #fff;
  padding: 84px 0;
  padding-bottom: 26px;
  //padding: 84px 0 21px;

  @include viewport--md {
    padding: 40px 0 0;
  }

  @include viewport--sm {
    padding: 52px 0 0;
  }

  .container {
    padding: 0 78px;

    @include viewport--lg {
      padding: 0 60px;
    }

    @include viewport--md {
      padding: 0 40px;
    }

    @include viewport--sm {
      padding: 0 16px;
    }
  }

  &__columns {
    display: flex;
    justify-content: space-between;
  }

  &__left {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 56%;

    @include viewport--md {
      align-items: center;
      width: 100%;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 39.8%;

    @include viewport--md {
      display: none;
    }
  }

  &__video-wrapper {
    position: relative;
    z-index: 0;
    margin-bottom: 35px;

    width: 100%;

    @include viewport--sm {
      margin-bottom: 0;
    }
  }

  &__video {
    position: relative;
    z-index: 0;

    display: block;
    width: 100%;
    padding-top: 56.3%;
    margin-top: 9px;

    &:hover,
    &:focus,
    &:active {
      & img {
        opacity: 0.7;
      }
    }

    // border: 2px solid $color-blue;
    // filter: drop-shadow(38px 36px 24.0148px rgba(166, 181, 228, 0.2));
    // box-shadow: 38px 36px 24.0148px rgba(166, 181, 228, 0.2);

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      pointer-events: none;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      width: 100%;
      height: 100%;

      border: 2px solid $color-blue;
    }
  }

  &__video-title {
    margin-top: 29px;

    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $color-comet-2;
  }

  &__title {
    font-size: 39px;
    line-height: 56px;
    color: $color-black;
    margin: 0 0 34px;
    font-weight: 700;
    max-width: 753px;

    @include viewport--md {
      font-size: 36px;
      line-height: 44px;
      margin: 0 0 26px;
      max-width: 620px;
      text-align: center;
    }

    @include viewport--sm {
      font-size: 28px;
      line-height: 36px;
      margin: 0 0 26px;
      max-width: 360px;
    }

    @media (max-width: 413px) {
      font-size: 26px;
      line-height: 34px;
    }
  }

  &__description {
    font-size: 23px;
    line-height: 36px;
    margin: 0 0 39px;
    color: $color-ship-cove;
    font-weight: 400;
    max-width: 678px;

    span {
      font-weight: bold;
      color: $color-comet;
    }

    h2 {
      display: inline;
      margin: 0;

      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
      color: inherit;
    }

    @include viewport--md {
      margin: 0 0 34px;
      max-width: 500px;
      text-align: center;
    }

    @include viewport--sm {
      font-size: 16px;
      line-height: 24px;
      max-width: 360px;
      margin: 0 0 38px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;

    .btn {
      margin-right: 10px;
    }

    @include viewport--sm {
      flex-direction: column;
    }
  }

  // &__form {
  //   align-self: stretch;
  //   display: flex;

  //   // @media (max-width: 413px) {
  //   //   flex-wrap: wrap;
  //   // }

  //   input {
  //     flex-grow: 1;
  //     align-self: flex-start;
  //     width: 200px;
  //     max-width: 318px;
  //     padding: 16px 22px 17px;
  //     margin-right: 14px;

  //     font-weight: 600;
  //     font-size: 16px;
  //     line-height: 19px;

  //     border: 1px solid $color-periwinkle-gray;
  //     border-radius: 5px;

  //     &::placeholder {
  //       color: $color-rock-blue-2;
  //     }

  //     @include viewport--sm {
  //       margin-right: 10px;
  //     }

  //     @media (max-width: 395px) {
  //       // width: 100%;
  //       // max-width: 100%;
  //       // margin-right: 0;
  //       // margin-bottom: 10px;
  //       width: 0;
  //       // padding: 11px 16px;
  //       // margin-right: 5px;

  //       // font-size: 14px;
  //       // line-height: 16px;
  //     }
  //   }

  //   .btn {
  //     @include viewport--sm {
  //       min-width: 152px;
  //     }

  //     @media (max-width: 395px) {
  //       min-width: max-content;
  //       // padding: 12px 16px;

  //       // font-size: 14px;
  //       // line-height: 16px;
  //     }
  //   }
  // }

  .demo-sm-form {
    align-self: stretch;
    margin-bottom: 35px;

    @include viewport--md {
      margin-bottom: 0;
    }

    form {
      @include viewport--md {
        justify-content: center;
      }

      @include viewport--sm {
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
      }
    }

    input {
      @include viewport--sm {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 12px;
      }
    }

    .btn {
      @include viewport--sm {
        width: 100%;
      }
    }
  }

  .btn {
    // min-width: 212px;
    margin-bottom: 45px;

    @include viewport--md {
      margin-bottom: 36px;
    }
  }

  &__partners {
    display: flex;
    align-items: start;
    // flex-wrap: wrap;
    // margin-left: -9px;

    @include viewport--md {
      margin-left: 0;
    }

    a[href] {
      transition: opacity $default-transition-settings;

      &:hover {
        opacity: 0.7;
      }
    }

    .marketing-intro__microsoft {
      margin-top: 3px;
      margin-left: 4px;
      margin-right: 4px;
      align-self: start;
      box-shadow: 0px 3.29px 6.58px 0px rgba(59, 70, 181, 0.05);

      @include viewport--md {
        margin-top: 2px;
      }
    }

    [alt="Meta Partner"] {
      margin-top: 2px;
      width: 95px;
    }

    [alt="Google Cloud Partner"] {
      width: 116px;
    }

    .marketing-intro__partner-g2 img {
      width: 123px;
    }

    .marketing-intro__partner-g2 {
      margin-left: -7px;

      @include viewport--md {
        margin-left: 0;
      }
    }

    &--desktop {
      flex-wrap: wrap;

      @include viewport--md {
        display: none;
      }
    }

    &--tablet {
      display: none;

      @include viewport--md {
        display: flex;
        margin-bottom: 38px;
      }
    }
  }

  &__partner-gmp {
    margin-top: 4px;
  }

  .google-partner {
    max-width: 510px;

    @include viewport--md {
      margin-bottom: 45px;
    }

    @include viewport--sm {
      margin-bottom: 30px;
    }
  }

  &__img {
    img {
      position: absolute;
      bottom: -81px;
      right: 0;
      width: 55%;
      max-width: 880px;

      @include viewport--xl {
        right: -216px;
      }

      @include viewport--lg {
        right: 0;
      }

      @include viewport--md {
        position: static;
        width: 100%;
        right: 0;
      }

    }

    &.img-fix {
      @include viewport--md {
        max-width: 730px;
        right: -16px;
      }
    }
  }
}
