.solutions-promo-cards {
  $root: &;

  padding-top: 100px;
  padding-bottom: 100px;

  background-color: $color-zircon;

  @include viewport--md {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  @include viewport--sm {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  &__items {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1200px) {
      flex-wrap: wrap;
      justify-content: center;
      margin-right: -30px;
      margin-top: -30px;
    }

    @include viewport--sm {
      margin-right: 0;
      margin-top: -45px;
    }
  }

  &__item {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30.6%;
    padding: 186px 30px 77px;

    border: 1px solid $color-lavender;
    border-radius: 9px;
    background-color: $color-default-white;
    box-shadow: 0px 4px 20px #EFEFFC;

    img {
      position: absolute;
      top: 75px;
      left: 50%;

      transform: translateX(-50%);

      @include viewport--sm {
        top: 48px;
      }
    }

    @media (max-width: 1200px) {
      width: 367px;
      margin-right: 30px;
      margin-top: 30px;
    }

    @include viewport--sm {
      padding: 155px 24px 50px;
      margin-right: 0;
      margin-top: 45px;
    }
  }

  &__item-title {
    max-width: 257px;

    font-size: 26px;
    line-height: 114.3%;
    text-align: center;
    color: $color-torea-bay-4;
    font-family: $font-family--inter;
    font-style: normal;
    font-weight: 600;
  }

  &__item-descr {
    margin-top: 33px;

    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: $color-comet-3;
    font-family: $font-family--lato;
    font-style: normal;
    letter-spacing: -0.18px;

    @include viewport--sm {
      margin-top: 18px;
    }
  }

  &__items--wide {
    justify-content: center;
    flex-wrap: wrap;
    margin-top: -30px;
    margin-right: -65px;

    @media (max-width: 1200px) {
      margin-right: -30px;
    }

    @include viewport--md {
      flex-direction: column;
      align-items: center;
      margin-right: 0;
    }

    @include viewport--sm {
      flex-direction: column;
      align-items: center;
      margin-top: -45px;
      margin-right: 0;
    }

    .solutions-promo-cards__item {
      width: 37.2%;
      max-width: 446px;
      margin-top: 30px;
      margin-right: 65px;
      padding: 140px 33px 53px;

      img {
        top: 28px;

        @include viewport--sm {
          top: 18px;
        }
      }

      @media (max-width: 1200px) {
        margin-right: 30px;
        margin-top: 30px;
        padding: 157px 30px 50px;
      }

      @include viewport--md {
        width: 100%;
        margin-right: 0;
      }

      @include viewport--sm {
        width: 92%;
        margin-right: 0;
        margin-top: 45px;
        padding: 117px 18px 20px;
      }
    }

    .solutions-promo-cards__item-title {
      max-width: 100%;

      @include viewport--sm {
        max-width: 98%;
      }
    }
  }

  &--solutions-page {
    background: $color-blue;
    padding-bottom: 50px;
    padding-top: 50px;

    @include viewport--sm {
      padding-top: 67px;
    }

    .container {
      padding: 0 100px;

      @include viewport--lg {
        padding: 0 60px;
      }

      @include viewport--md {
        padding:  0 30px;
      }

      @include viewport--sm {
        padding:  0 20px;
      }
    }

    #{$root}__items--wide {
      margin-right: -28px;

      @include viewport--md {
        margin-right: 0;
      }
    }

    #{$root}__item {
      margin-right: 28px;
      margin-top: 28px;
      max-width: 394px;
      width: 100%;
      box-shadow: none;
      padding-bottom: 47px;

      @include viewport--md {
        margin-right: 0;
      }

      @include viewport--sm {
        padding-bottom: 20px;
      }
    }
  }
}