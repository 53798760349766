.google-partner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 470px;

  @include viewport--md {
    justify-content: center;
  }

  &__title {
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: $color-ship-cove;
    margin: 0 0 10px;

    @include viewport--md {
      text-align: center;
      margin: 0 0 8px;
    }

    @include viewport--sm {
      font-size: 16px;
      line-height: 24px;
    }

    @media (max-width: 413px) {
      font-size: 14px;
      line-height: 22px;
    }
  }

  &__img {
    img {
      height: 100%;
    }

    &--gcp {
      @include viewport--md {
        margin-left: 12px;        
      }

      @include viewport--sm {
        max-width: 120px;
        margin-left: 15px;
      }

      @media (max-width: 413px) {
        &.img-fix {
          width: calc(42% - 15px);
        }
      }
    }

    &--gmp {
      @include viewport--sm {
        max-width: 198px;
      }

      @media (max-width: 413px) {
        &.img-fix {
          width: 58%;
        }
      }
    }

    &.img-fix {
      @media (min-width: 414px) and (max-width: 1023px) {
        width: auto;
      }
    }
  }
}
