.in-page-nav {
  position: static;
  font-weight: 600;
  padding: 32px 30px;
  box-shadow: 0px 16px 26px rgba(95, 95, 165, 0.28);
  border-radius: 10px;    
}

.sticky-nav {
  position: sticky;
  top: 20px;
}

.in-page-nav__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.in-page-nav__item {
  border-bottom: 1px solid #dee3f1;
  padding-top: 18px;
  padding-bottom: 18px;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border: none;
    padding-bottom: 0;
  }
}

.in-page-nav__link {
  text-decoration: none;  
}
