.blog-card {
  margin-bottom: 65px;
  display: flex;
  overflow: hidden;

  color: $color-ship-cove;
  background-color: $color-default-white;
  box-shadow: $card-shadow;

  border-radius: 10px;
  transition: transform $default-transition-settings;

  @include viewport--md {
    margin-bottom: 30px;
    flex-direction: column;
  }

  @include viewport--sm {
    margin-bottom: 35px;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      transform: translate(0, -5px);
    }
  }

  time {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: $color-waikawa-grey;
    font-family: $font-family--inter;
  }
}

.blog-card__title {
  margin: 0 0 14px;

  font-weight: 600;
  font-size: 28px;
  line-height: 32px;

  color: $color-black;

  @include viewport--md {
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 12px;
  }

  @include viewport--sm {
    font-size: 20px;
    line-height: 28px;
  }
}

.blog-card__image {
  position: relative;
  display: flex;
  width: 474px;
  flex-shrink: 0;

  justify-content: center;
  align-items: center;

  background: $dark-to-blue;

  @include viewport--md {
    width: 100%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  svg {
    width: 100%;
    height: auto;
    display: flex;
  }
}

.blog-card__text-wrapper {
  padding: 33px 42px 25px 48px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (max-width: 1279px) {
    padding: 33px 32px 25px;
  }

  @include viewport--md {
    padding: 23px 46px 37px 40px;
  }

  @include viewport--sm {
    padding: 23px 18px 23px 21px;
  }
}

.blog-card__text {
  margin: 0 0 20px;
  font-size: 16px;
  line-height: 22px;
  color: $color-comet;
  font-family: $font-family--lato;

  @include viewport--md {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 26px;
  }

  @include viewport--sm {
    margin-bottom: 18px;
    font-size: 16px;
    line-height: 24px;
  }
}

.blog-card__bottom-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include viewport--sm {
    flex-direction: column;
    align-items: flex-start;
  }
}

.blog-card__pub-info {
  display: flex;

  align-items: center;

  @include viewport--sm {
    margin-bottom: 27px;
  }
}

.blog-card__category {
  display: flex;
  padding: 6px 17px 6px 18px;

  font-weight: 700;
  font-size: 12px;
  line-height: 15px;

  font-family: $font-family--inter;
  color: $color-waikawa-grey;
  background-color: $color-lavender;
  border-radius: 100px;
}

.blog-card__category + time {
  margin-left: 29px;
}

.blog-card__btn {
  display: flex;
  align-items: center;
  font-family: $font-family--inter;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: $color-blue;

  svg {
    margin-left: 14px;
  }
}
