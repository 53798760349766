.request-links {
  margin: 0 auto;
  display: flex;
  width: max-content;

  @include viewport--sm {
    flex-direction: column;
    width: 100%;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: 16px;

    @include viewport--sm {
      margin-top: 27px;
      width: 100%;
      justify-content: center;

      &:first-child {
        margin-top: 0;
      }
    }

    &:first-child {
      margin-left: 0;
    }
  }

  .btn + .btn {
    margin-left: 16px;

    @include viewport--sm {
      margin-top: 27px;
      margin-left: 0;
    }
  }
}
