.our-leadership {
  background: #f2f5ff;
  font-family: $font-family--inter;
  text-align: center;
  color: #03113c;

  &__inner {
    padding: 112px 20px;
    max-width: 1020px;

    @include viewport--sm {
      padding: 88px 16px;
    }
  }

  &__title {
    @include title-h2;

    margin: 0 auto 24px;
    max-width: 550px;

    @include viewport--md {
      max-width: 480px;
    }
  }

  &__description {
    @include text-body;

    max-width: 550px;
    margin: 0 auto 64px;
    color: #33376b;

    @include viewport--sm {
      margin-bottom: 56px;
    }
  }

  &__cards {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    @include viewport--md {
      display: grid;
      grid-template-columns: repeat(2, auto);
      gap: 72px;
      justify-content: center;
    }

    @include viewport--sm {
      grid-template-columns: auto;
      gap: 48px;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    max-width: 195px;
  }
  
  &__card-image {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 24px;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__card-name {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 8px;
  }

  &__card-position {
    @include text-body;
    margin: 0 0 24px;
  }

  &__card-link {
    width: 32px;
    height: 32px;
    display: block;

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}