.in-media {
  background: #f2f5ff;


  &__inner {
    padding-top: 112px;
    padding-bottom: 112px;

    @include viewport--1280 {
      padding: 112px 20px;
    }

    @include viewport--sm {
      padding: 88px 16px;
    }
  }

  &__title {
    @include title-h2;

    margin: 0 auto 64px;

    font-family: $font-family--inter;
    text-align: center;
    color: #03113c;

    @include viewport--sm {
      margin-bottom: 56px;
    }
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    @include viewport--md {
      grid-template-columns: repeat(2, 1fr);
    }

    @include viewport--sm {
      grid-template-columns: 100%;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;

    border-radius: 16px;
    padding: 32px;
    min-height: 289px;
    background: $color-default-white;
    color: #33376b;

    transition: transform $default-transition-settings;

    @include viewport--sm {
      min-height: auto;
    }

    @include hover-focus {
      transform: translateY(-5px);
    }
  }

  &__image {
    margin-bottom: 20px;

    @include viewport--sm {
      margin-bottom: 56px;
    }
  }

  &__text {
    @include title-h3;

    margin: 0;
    margin-top: auto;
  }
}