.trusted-brands-main {
  .container {
    padding: 0 40px;

    @include viewport--md {
      padding: 0 20px;
    }

    @include viewport--sm {
      padding: 0 10px;
    }
  }

  &__inner {
    padding: 56px 0;

    @include viewport--md {
      padding: 65px 0;
    }

    @include viewport--sm {
      padding: 30px 0;
    }
  }

  &__title {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    clip: rect(0 0 0 0);
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px 50px;

    @include viewport--md {
      gap: 20px 30px;
    }
  }

  &__item {
    height: fit-content;
    display: flex;
    justify-content: center;

    // @include viewport--md {
    //   width: 33.33%;
    //   margin-bottom: 30px;
    // }

    // @include viewport--sm {
    //   max-width: 33.33%;
    //   margin-bottom: 17px;
    // }

    // img {
    //   @include viewport--sm {
    //     width: 75%;
    //   }
    // }

    // &--loreal {
    //   @include viewport--md {
    //     order: 2;
    //   }

    //   img {
    //     @include viewport--md {
    //       width: 168px;
    //       height: auto;
    //     }

    //     @include viewport--sm {
    //       width: 100%;
    //     }

    //     @include viewport--sm {
    //       width: 85%;
    //     }
    //   }
    // }

    // &--ribble {
    //   @include viewport--md {
    //     order: 5;
    //   }
    // }

    // &--carshop {
    //   @include viewport--md {
    //     order: 1;
    //     justify-content: flex-start;
    //   }

    //   img {
    //     @include viewport--md {
    //       width: 169px;
    //       height: auto;
    //     }

    //     @include viewport--sm {
    //       width: 100%;
    //     }

    //     @include viewport--sm {
    //       width: 86%;
    //     }
    //   }
    // }

    // &--simplisafe {
    //   @include viewport--md {
    //     order: 3;
    //     justify-content: flex-end;
    //   }
    // }

    // &--ridestore {
    //   @include viewport--md {
    //     order: 4;
    //   }

    //   @include viewport--sm {
    //     img {
    //       width: 66%;
    //     }
    //   }
    // }
  }

  &--blue {
    background-color: $color-alice-blue;
  }
}
