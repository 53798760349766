.promo-block {
  padding: 75px 0 80px;

  @include viewport--md {
    padding: 68px 0;
  }

  @include viewport--sm {
    padding: 45px 0;
  }

  &__title {
    max-width: 868px;
    margin: 0 auto 56px;

    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    color: $color-east-bay-2;

    span {
      color: $color-blue;
    }

    @include viewport--sm {
      display: none;
      margin: 0 auto 45px;

      font-size: 24px;
      line-height: 29px;
    }
  }

  &__title-mob {
    display: none;
    margin: 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.01em;
    color: $color-torea-bay-4;

    span {
      color: $color-blue;
    }

    @include viewport--sm {
      display: block;
      margin-bottom: 40px;
    }
  }

  &__description {
    margin: 0 auto 56px;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: $color-comet;
    max-width: 685px;

    @include viewport--sm {
      font-size: 18px;
      line-height: 26px;
    }

    &--desktop {
      @include viewport--sm {
        display: none;
      }
    }

    &--mob {
      display: none;

      @include viewport--sm {
        display: block;
        margin: 45px auto 0;
        padding: 0 14px;
      }
    }
  }

  &__img-desktop {
    width: 868px;
    max-width: 100%;
    height: auto;
    margin: 0 auto;

    @include viewport--sm {
      display: none;
    }
  }

  &__img-mob {
    display: none;

    @include viewport--sm {
      display: block;
      width: 100%;
      max-width: 400px;
      height: auto;
      margin: 0 auto;
    }

    &--lottie {
      max-width: 100%;
      width: 376px;
    }
  }

  &--cookieless-page {
    & .promo-block__title {
      @include viewport--sm {
        display: block;
        max-width: 344px; }
    }
  }
}
