.action-cta-alt {
  &__desktop {
    padding: 111px 0 75px;

    background: linear-gradient(180deg, #FBFCFF 0%, #FFFFFF 100%);

    @include viewport--md {
      display: none;
    }

    .request-demo-btn {
      min-width: 179px;
    }
  }

  &__desktop-wrapper {
    position: relative;

    padding: 64px 60px;

    background: linear-gradient(270.85deg, #D2DBFC -9.01%, #FFFFFF 101.51%);
    border: 1px solid $color-periwinkle-3;
    border-radius: 17px;
  }

  &__desktop-img {
    position: absolute;
    z-index: 0;
    bottom: 0;
    right: 0;
    left: 457px;

    height: 355px;

    border-bottom-right-radius: 20px;

    overflow: hidden;

    @media(max-width: 1200px) {
      display: none;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;

      object-fit: contain;
      object-position: bottom right;
    }
  }

  &__desktop-content {
    position: relative;
    z-index: 5px;

    max-width: 511px;
  }

  &__desktop-title {
    margin: 0;
    margin-bottom: 30px;

    font-weight: 700;
    font-size: 32px;
    line-height: 110.8%;
    color: $color-torea-bay-4;
  }

  &__desktop-text {
    max-width: 450px;
    margin-bottom: 25px;

    font-size: 18px;
    line-height: 28px;
    color: $color-comet;
  }

  &__mob {
    display: none;

    @include viewport--md {
      display: block;
    }

    .action-cta {
      h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.01em;
        color: $color-torea-bay-4;
      }
    }

    .demo-sm-form {  
      form {  
        @include viewport--sm {
          flex-direction: column;
          justify-content: flex-start;
          align-items: stretch;
        }
      }
  
      input {
        @include viewport--sm {
          width: 100%;
          max-width: 100%;
          margin-right: 0;
          margin-bottom: 12px;
        }
      }
  
      .btn {
        @include viewport--sm {
          width: 100%;
        }
      }
    }
  }
}