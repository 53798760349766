.main-resources-preview {
  padding-top: 75px;
  padding-bottom: 33px;

  background: linear-gradient(180deg, #fff 0%, #f6f8ff 100%);

  @include viewport--md {
    padding-top: 0;
    background: linear-gradient(0deg, #f9faff 66.53%, #fff 100%);
  }

  @include viewport--sm {
    padding-top: 40px;
    padding-bottom: 30px;
  }

  &__block-title {
    color: $color-bunting-2;
    margin-top: 0;
    font-family: $font-family--inter;
    margin-bottom: 74px;
    font-weight: 700;
    text-align: center;
    font-size: 36px;
    line-height: 48px;

    @include viewport--md {
      font-size: 24px;
      line-height: 120%;
      margin-bottom: 45px;
    }

    @include viewport--sm {
      color: $color-bunting-2;
      margin-bottom: 11px;
    }
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include viewport--sm {
      flex-direction: column;
      align-items: center;
    }

    & > * {
      width: calc(33% - 43.4px);

      @include viewport--md {
        width: calc(50% - 45px);
      }

      @include viewport--sm {
        width: calc(100% - 30px);
        margin-top: 30px;
      }

      &:not(:last-child) {
        margin-right: 65px;

        @include viewport--lg {
          margin-right: 30px;
        }

        @include viewport--md {
          margin-right: 0;
        }
      }

      &:first-child {
        @include viewport--md {
          margin-right: 30px;
        }

        @include viewport--sm {
          margin-right: 0;
        }
      }

      &:last-child {
        @include viewport--md {
          margin-top: 30px;
        }
      }

      &:nth-child(n + 3) {
        margin-right: 0;
      }

      &:nth-child(n + 4) {
        display: none;
      }
    }
  }

  &__btn {
    max-width: 400px;
    display: block;
    width: max-content;
    margin: 76px auto 0;
    color: #fff;
    text-align: center;
    font-family: $font-family--inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    padding: 17px 32px 18px;

    @include viewport--sm {
      max-width: 300px;
      margin-top: 30px;
    }
  }

  &--solutions,
  &--pricing {
    padding: 100px 0;

    @include viewport--md {
      padding: 68px 0;
    }

    @include viewport--sm {
      padding: 45px 0;
    }

    .main-resources-preview__block-title {
      color: $color-east-bay-2;
    }

    .main-resources-preview {
      @include viewport--sm {
        margin-top: 45px;
      }
    }
  }

  &--optimisation {
    background: linear-gradient(180deg, #f9faff 0%, #fafbff 100%);

    @include viewport--sm {
      background-color: $color-zircon-2;
    }
  }

  &--travel {
    background: linear-gradient(180deg, #ffffff 0%, #fafbff 100%);

    @include viewport--sm {
      background: rgba(225, 231, 255, 0.2);
    }
  }

  &--mix-optimization {
    padding: 0;

    @include viewport--md {
      padding: 0;
    }

    @include viewport--sm {
      padding: 0;
    }

    .container {
      padding: 35px 60px 75px;

      @include viewport--md {
        padding: 0 20px 45px;
      }
    }
  }

  &--ai-driven-attribution {
    // padding-top: 75px;
    // padding-bottom: 40px;

    // // background: $color-zircon;

    // @include viewport--sm {
    //   padding-top: 40px;
    //   padding-bottom: 40px;
    // }

    padding-top: 45px;
    padding-bottom: 70px;

    @include viewport--sm {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  &--incrementality-testing-page {
    padding-bottom: 50px;

    @include viewport--sm {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  &--podcasts {
    padding: 10px 20px 107px;

    @include viewport--sm {
      padding-bottom: 30px;
    }

    .main-resources-preview__block-title {
      margin-bottom: 48px;

      @include viewport--sm {
        margin-bottom: 11px;
      }
    }

    .main-resources-preview-card {
      flex-grow: 1;
      max-width: 435px;
      min-height: auto;
    }

    .main-resources-preview__cards {
      @include viewport--md {
        flex-direction: column;
        align-items: center;
      }
    }

    .main-resources-preview__cards > *:not(:last-child) {
      margin-right: 37px;

      @include viewport--md {
        margin-right: 0;
      }
    }

    .main-resources-preview__cards > * {
      @include viewport--md {
        width: auto;
      }
    }

    .main-resources-preview-card__photo-wrapper {
      max-height: 245px;
    }

    .main-resources-preview-card__text-wrapper {
      padding: 38px 31px;

      @include viewport--sm {
        padding: 22px 25px 25px;
      }
    }
    
    .main-resources-preview-card__type--webinar {
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 25px;
      
      &::before {
        width: 50px;
        height: 36px;
      }

      @include viewport--sm {
        font-size: 17.7907px;
        line-height: 22px;

        &::before {
          width: 42px;
          height: 30px;
        }
      }
    }

    .main-resources-preview-card__title {
      font-size: 25px;
      line-height: 31px;
      font-weight: 700;
      margin-bottom: 0;

      @include viewport--sm {
        font-size: 22px;
        line-height: 27px;
      }
    }
  }
}
