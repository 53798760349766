.pricing-plans {
  padding: 75px 0 50px;

  background: linear-gradient(180deg, #F9FAFD 0%, #F5F8FF 34.03%, #FFFFFF 60.16%);

  @include viewport--sm {
    padding: 85px 0;
    padding-bottom: 45px;

    background: $color-zircon-2;
  }

  &__title {
    margin: 0;
    margin-bottom: 75px;

    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    text-align: center;

    @include viewport--sm {
      margin-bottom: 84px;

      font-size: 28px;
      line-height: 36px;
    }
  }

  &__plan-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -35px;
    margin-left: -35px;

    @include viewport--sm {
      flex-direction: column;
      align-items: center;
      margin-left: 0;
    }
  }

  &__plan-card {
    margin-left: 35px;
    margin-top: 35px;

    @include viewport--sm {
      margin-left: 0;
    }
  }

  &__promo-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -58px;
    margin-top: 3px;

    @include viewport--sm {
      margin-top: 0;
    }
  }

  &__promo-card {
    margin-left: 58px;
    margin-top: 58px;

    @include viewport--sm {
      margin-top: 46px;
    }
  }

  // padding: 105px 0 92px;
  // background: $almost-white no-repeat;

  // @include viewport--md {
  //   padding: 96px 0 30px;
  // }

  // @include viewport--sm {
  //   padding: 60px 0 30px;
  // }

  // .container {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  // }

  // &__title {
  //   margin: 0 0 74px;

  //   font-weight: 700;
  //   font-size: 36px;
  //   line-height: 48px;
  //   text-align: center;
  //   color: $color-black;
  //   max-width: 640px;

  //   @include viewport--md {
  //     margin-bottom: 22px;
  //   }

  //   @include viewport--sm {
  //     font-size: 24px;
  //     line-height: 34px;
  //     margin-bottom: 38px;
  //     max-width: 400px;
  //   }
  // }

  // .question-banner {
  //   max-width: 1152px;
  //   margin-top: 55px;

  //   @include viewport--md {
  //     margin-top: 115px;
  //   }

  //   @include viewport--sm {
  //     margin-top: 60px;
  //   }
  // }
}
