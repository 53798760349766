.integration-cards {
  display: flex;
  flex-wrap: wrap;
  width: 790px;
  margin: 0 auto -30px;

  @media (max-width: 1279px) {
    width: 585px;
  }

  @include viewport--md {
    width: 100%;
  }

  @include viewport--sm {
    justify-content: space-between;
  }

  .integration-card {
    margin-right: 30px;
    margin-bottom: 30px;

    @media (min-width: 900px) and (max-width: 1023px) {
      width: calc(25% - 30px);
    }

    @media (min-width: 768px) and (max-width: 899px) {
      width: calc(33% - 30px);
    }

    @include viewport--sm {
      width: calc(50% - 8px);
      margin-right: 0;
    }

    &--lg-hidden {
      @media (min-width: 768px) and (max-width: 1279px) {
        display: none;

        & + .integration-card:not(.selector-hack) {
          margin-right: 0;
        }
      }
    }
  }

  &--leading {
    width: 995px;

    @media (max-width: 1279px) {
      width: 585px;
    }

    @include viewport--sm {
      justify-content: center;
      width: 100%;
      max-width: 335px;
      margin-bottom: -25px;
    }

    .integration-card {
      &:nth-child(4n) {
        margin-right: 30px;
      }

      &:nth-child(5n) {
        margin-right: 0;
      }

      @media (max-width: 1279px) {
        width: 175px;

        &:nth-child(3n) {
          margin-right: 0;
        }

        &:nth-child(5n) {
          margin-right: 30px;
        }
      }

      @include viewport--sm {
        justify-content: center;
        align-items: center;
        width: 95px;
        min-height: 95px;
        margin-right: 25px;
        margin-bottom: 25px;
        padding: 0;

        &__name,
        &__category,
        &__link {
          display: none;
        }

        &__img {
          max-width: 71px;
          height: 67px;
          max-height: 67px;
          margin: 0;
        }

        &:nth-child(4n),
        &:nth-child(5n) {
          margin-right: 25px;
        }

        &:last-child {
          display: none;
        }
      }

      @media (max-width: 413px) {
        width: 90px;
        min-height: 90px;
        margin-right: 8px;

        &__img {
          max-width: 65px;
          height: 62px;
          max-height: 62px;
          margin: 0;
        }

        &:nth-child(4n),
        &:nth-child(5n) {
          margin-right: 8px;
        }
      }
    }
  }
}
