.page-pagination {
  display: flex;
  margin: 0;
  padding: 0;

  flex-wrap: wrap;
  list-style: none;
}

.page-pagination__item {
  position: relative;

  &:nth-last-child(-2n+2) {
    margin-left: 50px;

    @media (max-width: 400px) {
      margin-left: 30px;
    }

    &::before {
      content: "...";
      position: absolute;
      bottom: 10px;
      left: -40px;

      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.3em;
      color: $color-echo-blue;

      @media (max-width: 400px) {
        font-size: 18px;
        left: -25px;
        bottom: 3px;
      }
    }
  }
}

.page-pagination__link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-family--inter;
  width: 32px;
  height: 50px;
  padding: 12px;

  font-size: 24px;
  line-height: 29px;
  color: $color-echo-blue;
  transition: color $default-transition-settings;

  @media (max-width: 400px) {
    padding: 12px 5px;
    height: 40px;
    width: 25px;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      color: $color-bright-blue;
    }
  }

  &--active {
    margin: 0 5px;
    width: 50px;
    font-weight: 700;

    color: $color-default-white;
    background-color: $color-blue;
    border-radius: 10px;

    @media (max-width: 400px) {
      width: 40px;
      height: 40px;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        cursor: auto;
        color: $color-default-white;
      }
    }
  }

  &--prev {
    margin-right: 5px;
    width: 50px;
    transition: transform $default-transition-settings;

    @media (max-width: 400px) {
      width: 30px;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        transform: scale(1.2);
      }
    }
  }

  &--next {
    margin-left: 5px;
    width: 50px;
    transition: transform $default-transition-settings;

    @media (max-width: 400px) {
      width: 30px;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        transform: scale(1.2);
      }
    }

    svg {
      transform: rotate(180deg);
    }
  }
}
