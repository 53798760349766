.marketing-page {
  $root: &;

  // &__introducing-solution {
  //   background: linear-gradient(149deg, #252D72 36.8%, #10173A 70.69%);
  // }

  &__optimal-marketing-plate {
    background: $color-zircon;

    @include viewport--md {
      background: linear-gradient(180deg, #F9FBFF 0%, #FFF 100%);
    }
  }

  &__intro-how-blocks {
    background: linear-gradient(180deg, #FFFFFF 13.42%, #F7F8FF 43.37%, #FFFFFF 60.55%, #F7F8FF 100%);

    @include viewport--sm {
      background: linear-gradient(180deg, #F9FAFF 0%, #FBFCFF 100%);
    }
  }

  // .marketing-intro {

  //   @include viewport--md {
  //     margin-bottom: 58px;
  //   }

  //   @include viewport--sm {
  //     margin-bottom: 34px;
  //   }
  // }

  .trusted-brands {
    //margin-left: -42px;
    //margin-right: -42px;

    @include viewport--lg {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .trusted-brands__item:first-child {
    justify-content: flex-start;

    @include viewport--md {
      justify-content: center;
    }

    @include viewport--sm {
      justify-content: flex-start;
    }
  }

  .reports-enhancement {
    margin-bottom: 80px;

    @include viewport--md {
      margin-bottom: 60px;
    }

    @include viewport--sm {
      margin-top: 20px;
      margin-bottom: 0;
    }
  }

  .inexpensive-lead {
    margin-top: 35px;
    //margin-bottom: 120px;

    //@include viewport--md {
    //  margin-bottom: 72px;
    //}
    //
    //@include viewport--sm {
    //  margin-bottom: 80px;
    //}
  }

  .more-products {
    margin-top: 145px;

    @include viewport--md {
      margin-top: 75px;
    }
  }

  &__intro-how-blocks--main {
    background: linear-gradient(180deg, #FFFFFF 13.42%, #F7F8FF 43.37%, #FFFFFF 60.55%, #FFFFFF 100%);

    @include viewport--sm {
      background: $color-default-white;
    }
  }

  #{$root}__video-wrapper {
    position: relative;
    z-index: 0;
    width: 100%;
    padding-top: 75px;

    @include viewport--sm {
      padding: 20px 10px 26px;
    }
  }

  #{$root}__video {
    position: relative;
    z-index: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;

    img {
      transition: opacity $default-transition-settings;
    }

    @include hover-focus {
      & img {
        opacity: 0.7;
      }
    }
  }

  .case-study__inner {
    padding: 55px 0 90px;
    
    @include viewport--md {
      padding: 25px 0 40px;
    }
  }

  .case-study__img-wrap {
    margin-top: 25px;

    @include viewport--md {
      margin-top: 0;
    }
  }

  .case-study__text {
    font-style: italic;
  }

  .case-study__text--author {
    color: #353B7A;
    font-weight: 600;
    font-size: 20px;
    font-style: normal;
    margin-top: 30px;

    span {
      color: #4D6DD3;
    }
  }

  .case-study__block-button {
    text-align: left;

    @include viewport--md {
      text-align: center;
    }
  }
}
