.get-started-steps {
  padding-bottom: 100px;

  @include viewport--xl {
    padding-bottom: 85px;
  }

  @include viewport--md {
    padding-bottom: 30px;
  }

  @include viewport--sm {
    padding-bottom: 50px;
  }

  &__container {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 64px 0;

    @include viewport--xl {
      max-width: 1280px;
      padding-left: 40px;
      padding-right: 40px;
    }

    @include viewport--lg {
      max-width: 1280px;
      padding-left: 40px;
      padding-right: 40px;
    }

    @include viewport--md {
      padding-left: 24px;
      padding-right: 24px;
    }

      @include viewport--sm {
        padding: 0 16px;
      }
  }

  &__content {
    @include viewport--md {
      display: grid;
      grid-template-columns: 1fr 57%;
      gap: 20px;
    }

    @include viewport--sm {
      display: block;
    }
  }

  &__title {
    font-family: $font-family--inter;
    font-weight: 600;
    font-size: 48px;
    padding: 64px 16px;
    margin: 0 auto 0;
    line-height: 64px;
    letter-spacing: -0.02em;
    text-align: center;
    color: #33376b;
    width: 100%;
    max-width: 926px;

    @include viewport--xl {
      padding: 21px 16px 45px;
      font-size: 36px;
      line-height: 48px;
    }

    @include viewport--md {
      padding: 64px 16px 22px;
      font-size: 32px;
      line-height: 40px;
      max-width: 720px;
      margin: 0 auto 8px;
    }

    @include viewport--sm {
      margin: 0 auto 30px;
      padding: 0;
      text-align: left;
      max-width: 343px;
    }

    span {
      background: linear-gradient(90deg, #4d6dd3 0%, #5f98ff 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &__image-wrap {
    margin-bottom: 55px;

    @include viewport--xl {
      margin-bottom: 33px;
    }

    @include viewport--md {
      margin-bottom: 55px;
      order: 1;
    }

    @include viewport--sm {
      display: none;
    }
  }

  &__image {
    @include viewport--xl {
      margin-right: 100px;
    }

    @include viewport--md {
      margin-right: 0;
    }
  }

  &__list-wrap {
    padding-left: 36px;
    padding-right: 36px;

    @include viewport--xl {
      padding-left: 75px;
      padding-right: 75px;
    }

    @include viewport--md {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 25px;
    list-style: none;
    padding: 0;
    margin: 0;

    @include viewport--xl {
      gap: 16px;
    }

    @include viewport--md {
      display: flex;
      flex-direction: column;
      gap: 25px;
    }

    @include viewport--sm {
      gap: 0;
    }
  }

  &__item {
    @include viewport--md {
      padding-top: 33px;
    }

    @include viewport--sm {
      padding-top: 0;
    }

    &--step-1 {
      @include viewport--md {
        aspect-ratio: 1/1.09;
      }

      @include viewport--sm {
        aspect-ratio: unset;
      }
    }

    &--step-2 {
      @include viewport--md {
        aspect-ratio: 1.32/1;
      }

      @include viewport--sm {
        aspect-ratio: unset;
      }
    }

    &:last-child {
      .get-started-steps__card-image-icon {
        display: none;
      }
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
  }

  &__card-text-wrap {
    width: 100%;
    max-width: 390px;

    @include viewport--xl {
      max-width: 300px;
    }

    @include viewport--md {
      max-width: none;
    }

    @include viewport--sm {
      border-radius: 20px;
      padding: 16px;
      box-shadow: 0 14px 32px 0 rgba(69, 90, 155, 0.12), 0 3px 12px 0 rgba(69, 90, 155, 0.12);
      background: #fff;
      max-width: 343px;
      align-self: center;
    }
  }

  &__card-title {
    display: block;
    font-family: $font-family--inter;
    margin:  0 0 16px;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: #4d6dd3;

    @include viewport--xl {
      font-size: 18px;
      line-height: 26px;
      margin:  0 0 12px;
    }

    @include viewport--md {
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.03em;
    }
  }

  &__card-text {
    font-family: $font-family--inter;
    margin: 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: #33376b;

    @include viewport--xl {
      font-size: 18px;
      line-height: 26px;
    }

    @include viewport--md {
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.03em;
    }

    & .get-started-steps__card-text {
      margin-top: 16px;
    }
  }

  &__card-text-icon {
    display: none;
    justify-content: center;
    align-items: center;
    width: 13px;
    height: 49px;
    align-self: center;

    @include viewport--sm {
      display: flex;
    }

    svg {
      width: 100%;
      height: 100%;
      flex: 0 0 auto;
    }
  }

  &__card-image-wrap {
    display: none;

    @include viewport--sm {
      display: flex;
      justify-content: center;
    }
  }

  &__card-image {
    @include viewport--sm {
      margin-left: -16px;
      margin-right: -16px;
      max-width: 375px;
    }

    img {
      @include viewport--sm {
        width: 100%;
      }
    }
  }

  &__card-image-icon {
    display: none;
    justify-content: center;
    align-items: center;
    width: 11px;
    height: 54px;
    align-self: center;
    margin-top: -8px;

    @include viewport--sm {
      display: flex;
    }

    svg {
      width: 100%;
      height: 100%;
      flex: 0 0 auto;
    }
  }
}
