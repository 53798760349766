.solutions-promo-block {
  background-color: $color-zircon;
  padding: 100px 0;

  @include viewport--md {
    padding: 68px 0;
  }

  @include viewport--sm {
    padding: 45px 0;
  }

  &__block-title {
    max-width: 954px;
    margin: 0 auto 75px;

    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    color: $color-east-bay-2;

    span {
      color: $color-blue;
    }

    @include viewport--sm {
      margin: 0 auto 45px;

      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.01em;
    }
  }

  &__textbox {
    max-width: 518px;
    min-width: 300px;
    margin-right: 30px;

    @include viewport--md {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 0;
    }

    &--descr-mob {
      display: none;

      @include viewport--md {
        display: flex;
        margin-top: 4px;
      }
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;

    @include viewport--sm {
      flex-direction: column;
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 43px;
    margin-bottom: 250px;

    &:nth-child(even) {
      flex-direction: row-reverse;

      .solutions-promo-block__textbox {
        margin-right: 0;
        margin-left: 30px;

        @include viewport--md {
          margin-left: 0;
        }
      }

      @include viewport--md {
        flex-direction: column;
      }

      .solutions-promo-block__item-img {
        object-position: left;

        @include viewport--md {
          object-position: center;
        }
      }
    }

    &:first-child {
      @include viewport--md {
        padding-top: 0;
      }
    }

    &:last-child {
      margin-bottom: 0;

      @include viewport--md {
        padding-bottom: 0;
        margin: 0;
      }
    }

    @include viewport--lg {
      padding: 0px 75px;
    }

    @include viewport--md {
      align-items: center;
      padding: 46px 24px;
      flex-direction: column;
      text-align: center;
      margin: 0;
    }

    @include viewport--sm {
      padding: 0;
      margin-bottom: 90px;
    }
  }

  &__title {
    margin: 0 0 35px;
    font-size: 30px;
    line-height: 37px;
    color: $color-east-bay-2;
    max-width: 500px;
    font-weight: bold;

    @include viewport--md {
      text-align: center;
      max-width: 560px;
      margin-bottom: 40px;
    }

    @include viewport--md {
      text-align: center;
      max-width: 560px;
      margin-bottom: 26px;
    }

    @include viewport--sm {
      font-size: 24px;
      line-height: 29px;
    }
  }

  &__subtitle {
    margin: 0 0 44px;
    font-size: 30px;
    line-height: 36px;
    color: $color-torea-bay-4;
    max-width: 500px;

    span {
      color: $color-blue;
    }

    @include viewport--md {
      text-align: center;
      max-width: 560px;
      // margin-bottom: 40px;
      margin-bottom: 0;
    }

    @include viewport--sm {
      font-size: 24px;
      line-height: 30px;
      // margin-bottom: 30px;
    }
  }

  &__description,
  &__lead {
    margin: 0 0 44px;
    font-size: 20px;
    line-height: 32px;
    color: $color-comet;
    max-width: 504px;
    font-weight: 400;

    @include viewport--md {
      max-width: 500px;
      text-align: center;
    }

    @include viewport--sm {
      font-size: 16px;
      line-height: 24px;
      max-width: 360px;
      width: 100%;
    }
  }

  &__description {
    margin: 0 0 35px;

    span {
      color: $color-blue;
    }

    &:last-child {
      margin: 0;
    }

    &--desktop {
      @include viewport--md {
        display: none;
      }
    }
  }

  &__quote {
    display: flex;
    align-items: center;
    padding: 20px;
    margin-top: 35px;

    text-align: left;

    background-color: $color-default-white;
    box-shadow: 0px 4px 20px rgba(77, 109, 211, 0.2);
    border-radius: 10px;

    img {
      flex-shrink: 0;
      margin-right: 26px;

      @include viewport--sm {
        width: 46px;
        height: 46px;
        margin-right: 5px;
      }
    }

    @include viewport--md {
      margin-top: 10px;
    }

    @include viewport--sm {
      padding: 13px;
      margin-top: 20px;

      box-shadow: 0px 2.7px 13.6px rgba(77, 109, 211, 0.2);
      border-radius: 6.8px;
    }

    &--desktop {
      @include viewport--md {
        display: none;
      }
    }

    &--mob {
      display: none;

      @include viewport--md {
        display: flex;
      }
    }
  }

  &__quote-text {
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: $color-black;

    @include viewport--sm {
      font-size: 16px;
      line-height: 19px;
    }
  }

  &__quote-author {
    padding-left: 2px;
    margin-top: 6px;

    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: $color-lynch;

    @include viewport--sm {
      font-size: 13px;
      line-height: 16px;
    }
  }

  &__item-img {
    max-width: 548px;
    min-width: 300px;
    max-height: 448px;
    object-fit: contain;
    object-position: right;
    box-shadow: none;

    @include viewport--md {
      position: static;
      transform: none;
      margin: 0 0 45px;
      object-position: center;
    }

    @include viewport--sm {
      // order: -1;
      max-width: 364px;
      max-height: 364px;
      width: 100%;
      //margin-top: 0;
    }

    &--desktop {
      @include viewport--sm {
        display: none;
      }
    }

    &--mob {
      display: none;

      @include viewport--sm {
        display: block;
      }
    }
  }

  .iconfixleft {
    margin-left: -40px;

    @include viewport--md {
      margin: 20px 0;
    }
  }

  .iconfixtop {
    margin-top: -55px;

    @include viewport--md {
      margin: 0 0 30px;
    }
  }

  &__full-img-desktop {
    width: 100%;
    max-width: 100%;
    height: auto;

    @include viewport--sm {
      display: none;
    }

    &--measurement-1 {
      transform: translateX(-3%);
    }
  }

  &__full-img-mob {
    display: none;

    @include viewport--sm {
      display: block;
      width: 100%;
      max-width: 400px;
      height: auto;
      margin: 0 auto;
    }
  }

  &__full-descr-wrapper {
    width: 100%;
    max-width: 683px;
    margin: 0 auto 75px;

    .solutions-promo-block__description {
      max-width: 100%;
      text-align: center;
    }

    &--desktop {
      @include viewport--sm {
        display: none;
      }
    }

    &--mob {
      display: none;

      @include viewport--sm {
        display: block;
        margin: 45px auto 0;
      }
    }
  }

  &--measurement-1 {
    background-color: $color-default-white;

    .solutions-promo-block__full-img-desktop {
      width: 810px;
      margin: 0 auto;
    }
  }

  &--measurement-2 {
    background: linear-gradient(180deg, #FFFFFF 0%, #FBFCFF 100%);

    .solutions-promo-block__block-title {
      max-width: 1020px;

      @include viewport--md {
        display: none;
      }
    }

    .solutions-promo-block__item {
      margin-bottom: 75px;
  
      &:last-child {
        margin-bottom: 0;
  
        @include viewport--md {
          margin: 0;
        }
      }
  
      @include viewport--md {
        margin: 0;
      }
  
      @include viewport--sm {
        margin-bottom: 90px;
      }
    }
  }

  &--ecommerce-1 {
    background: linear-gradient(180deg, #FAFBFF 0%, #FFFFFF 100%);

    .solutions-promo-block__block-title {
      max-width: 629px;
    }

    .solutions-promo-block__description {
      @include viewport--sm {
        font-size: 20px;
        line-height: 32px;
      }
    }

    .solutions-promo-block__full-img-desktop {
      width: 823px;
      margin: 0 auto;
    }
  }
}