.quote-banner {
  $root: &;

  color: $color-default-white;

  font-size: 28px;
  line-height: 1.2;

  font-family: $font-family--inter;
  // padding: 76px 0 25px;
  padding: 10px 0 25px;

  @include viewport--sm {
    font-size: 20px;
  }

  .container {
    padding: 0 40px;

    @include viewport--lg {
      padding: 0 40px;
    }

    @include viewport--md {
      padding: 0 20px;
    }

    @include viewport--sm {
      padding: 0 10px;
    }
  }

  &__inner {
    background: linear-gradient(93deg, #1b2d69 3.49%, #4d6dd3 99.11%);
    padding: 58px 183px;
    border-radius: 7px;

    display: flex;
    align-items: center;

    position: relative;
    z-index: 1;

    @include viewport--lg {
      padding: 58px 100px;
    }

    @include viewport--md {
      padding: 44px 20px;
      flex-direction: column;
    }

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      width: 248px;
      height: 146px;
      background: url("../img/svg/quote.svg") no-repeat;
      background-size: 100% 100%;
      left: 109px;
      top: 0;

      @include viewport--lg {
        left: 50px;
      }

      @include viewport--md {
        display: none;
      }
    }
  }

  &__text {
    margin: 0;
    max-width: 525px;
    margin-right: 40px;

    @include viewport--md {
      margin-right: 0;
      order: 2;
      text-align: center;
      max-width: 100%;
    }
  }

  &__image {
    width: 241px;
    margin-left: auto;
    flex-shrink: 0;

    img {
      width: 100%;
      height: auto;
    }

    @include viewport--md {
      width: 246px;
      margin-bottom: 33px;
      margin-left: 0;
    }

    @include viewport--sm {
      margin-bottom: 24px;
      width: 156px;
    }
  }

  &--synthetic-conversions {
    padding-bottom: 55px;

    #{$root}__text {
      font-family: $font-family--inter;
    }
  }
}
