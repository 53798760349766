.report-templates {
  background: $almost-white no-repeat;
  padding: 105px 0 50px;

  @include viewport--md {
    padding: 80px 0 35px;
  }

  @include viewport--sm {
    padding: 57px 0 43px;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    font-size: 36px;
    line-height: 48px;
    font-weight: 700;
    color: $color-black;
    margin: 0 0 32px;
    max-width: 700px;
    text-align: center;

    @include viewport--md {
      max-width: 420px;
      margin-bottom: 16px;
    }

    @include viewport--sm {
      font-size: 24px;
      line-height: 34px;
      max-width: 270px;
      margin-bottom: 25px;
    }
  }

  &__description {
    font-size: 18px;
    line-height: 26px;
    margin: 0 0 61px;
    color: $color-ship-cove;
    max-width: 680px;
    text-align: center;

    @include viewport--md {
      max-width: 540px;
      margin-bottom: 56px;
    }

    @include viewport--sm {
      font-size: 16px;
      line-height: 24px;
      max-width: 370px;
      margin-bottom: 52px;
    }
  }

  &__cards {
    display: flex;
    margin-bottom: 79px;

    @media (max-width: 1279px) {
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
    }

    @include viewport--sm {
      margin-bottom: 32px;
    }
  }

  .report-card {
    margin-right: 30px;
    width: calc(50% - 15px);

    &:nth-child(even) {
      margin-right: 0;
    }

    @media (max-width: 1279px) {
      margin-right: 0;
      margin-bottom: 45px;
      width: 100%;
    }

    @include viewport--sm {
      margin-bottom: 20px;
    }
  }

  .btn {
    min-width: 290px;

    @media (max-width: 413px) {
      width: 100%;
      max-width: 290px;
      min-width: 0;
    }
  }
}
