.product-card {
  padding: 65px 80px 70px;
  border-radius: 15px;
  box-shadow: $card-light-shadow;
  background-color: $color-default-white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include viewport--lg {
    padding: 65px 40px 70px;
  }

  @include viewport--md {
    padding: 65px 80px 70px;
  }

  @include viewport--sm {
    padding: 32px 30px 44px;
  }

  &__img {
    display: flex;
    align-items: flex-start;
    max-width: 123px;
    height: 106px;
    max-height: 106px;
  }

  &__title {
    font-size: 26px;
    line-height: 34px;
    font-weight: 700;
    color: $color-black;
    margin: 0 0 24px;
    max-width: 360px;

    @include viewport--sm {
      font-size: 24px;
      line-height: 32px;
      margin: 0 0 18px;
    }
  }

  &__description {
    font-size: 18px;
    line-height: 26px;
    color: $color-ship-cove;
    margin: 0 0 36px;
    max-width: 360px;

    @include viewport--sm {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 52px;
    }
  }

  .btn {
    white-space: pre;
    margin-top: auto;
    min-width: 192px;

    @include viewport--sm {
      width: 100%;
    }
  }
}
