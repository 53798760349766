.real-life-impact {
  $root: &;
  padding: 25px 0 110px;

  @include viewport--md {
    padding-top: 50px;
  }

  @include viewport--sm {
    padding: 30px 0 50px;
  }

  &__title {
    color: $color-torea-bay-4;
    text-align: center;
    font-family: $font-family--inter;
    font-size: 36px;
    line-height: 1.33;
    font-weight: 700;
    margin: 0 0 48px;

    @include viewport--1280 {
      margin-bottom: 32px;
    }

    @include viewport--md {
      font-size: 30px;
    }
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    @include viewport--1280 {
      gap: 35px;
    }

    @include viewport--md {
      grid-template-columns: repeat(2, 1fr);
    }

    @include viewport--sm {
      grid-template-columns: 100%;
      align-items: center;
    }
  }

  &--synthetic-conversions {
    padding-bottom: 95px;

    @include viewport--sm {
      padding: 30px 0 50px;
    }
  }
}

.real-life-impact-card {
  padding: 15px 35px;

  @include viewport--1280 {
    padding: 0;
  }

  &__img-wrap {
    min-height: 201px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 35px;

    @include viewport--md {
      font-size: 18px;
    }

    @include viewport--sm {
      align-items: center;
    }
  }

  &__text {
    font-family: $font-family--lato;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.25;
    color: $color-deep-koamaru;
    margin: 0;

    @include viewport--md {
      font-size: 18px;
    }

    @include viewport--sm {
      text-align: center;
    }
  }
}
