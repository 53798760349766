.grid-columns {
  display: flex;

  @include viewport--md {
    flex-direction: column;
  }

  &__item {
    width: 50%;
  }
}
