.visit-scoring {
  $root: &;

  padding-top: 85px;
  padding-bottom: 30px;

  &__title {
    color: $color-torea-bay-4;
    text-align: center;
    font-family: $font-family--inter;
    font-size: 36px;
    line-height: 1.25;
    font-weight: 700;
    margin: 0 0 55px;

    @include viewport--sm {
      font-size: 30px;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 435px 1fr;
    padding-right: 18px;
    gap: 70px;
    align-items: center;
    margin-bottom: 60px;

    @include viewport--md {
      grid-template-columns: 100%;
      padding-right: 0;
    }

    @include viewport--sm {
      gap: 40px;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.4;
    color: $color-comet;

    display: grid;
    grid-template-columns: 100%;
    gap: 24px;

    p {
      margin: 0;
    }

    @include viewport--md {
      order: 2;
    }
  }

  &__reviews-block {
    padding: 0 12px;

    .reviews-list {
      margin: 0;
      padding: 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 35px;

      @include viewport--md {
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
      }

      @include viewport--sm {
        grid-template-columns: 1fr;
      }
    }

    .review {
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;
      align-items: end;

      padding: 35px;
      gap: 35px;

      @include viewport--sm {
        padding: 16px 35px;
      }

      &__quote {
        margin: 0;
        font-family: $font-family--lato;
        font-style: italic;
        font-weight: 700;
        font-size: 22px;
        text-align: center;
        color: $color-deep-koamaru;
        max-width: 270px;
      }

      &__rating {
        width: 144px;
        height: 24px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
