.intro {
  padding-top: 148px;
  padding-bottom: 115px;

  @include viewport--xl {
    padding-top: 92px;
    padding-bottom: 85px;
  }

  @include viewport--md {
    padding-bottom: 64px;
    padding-top: 33px;
  }

  @include viewport--sm {
    padding-top: 24px;
    padding-bottom: 52px;
  }

  &__container {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;

    @include viewport--xl {
      max-width: 1280px;
      padding-left: 40px;
      padding-right: 40px;
    }

    @include viewport--lg {
      max-width: 1280px;
      padding-left: 40px;
      padding-right: 40px;
    }

    @include viewport--md {
      padding-left: 24px;
      padding-right: 24px;
    }

    @include viewport--sm {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__inner {
    display: grid;
    grid-template-columns: calc(50% - 15px) calc(50% - 15px);
    gap: 30px;

    @include viewport--xl {
      padding-left: 75px;
      gap: 6px;
      grid-template-columns: 46% 1fr;
    }

    @include viewport--md {
      padding-left: 0;
      grid-template-columns: 48% 1fr;
    }

    @include viewport--sm {
      display: flex;
      flex-direction: column;
    }
  }

  &__content {
    padding-left: 100px;

    @include viewport--xl {
      padding-left: 0;
    }

    @include viewport--sm {
      display: flex;
      flex-direction: column;
    }
  }

  &__title {
    font-family: $font-family--inter;
    font-weight: 700;
    font-size: 62px;
    line-height: 135%;
    letter-spacing: -0.025em;
    color: #33376b;
    margin: 0 0 24px;


    @include viewport--xl {
      font-size: 46px;
      line-height: 64px;
      margin-bottom: 18px;
    }

    @include viewport--md {
      font-size: 38px;
      line-height: 132%;
      letter-spacing: -0.02em;
      margin-bottom: 12px;
    }
  }

  &__description-wrap {
    font-weight: 400;
    font-size: 22px;
    line-height: 164%;
    color: #414a68;
    margin-bottom: 56px;

    @include viewport--xl {
      margin-bottom: 42px;
    }

    @include viewport--md {
      margin-bottom: 26px;
    }

    @include viewport--sm {
      margin-bottom: 19px;
    }
  }

  &__description {
    font-family: $font-family--inter;
    margin: 0;
    font-weight: 400;
    font-size: 22px;
    line-height: 164%;
    color: #414a68;

    @include viewport--xl {
      font-size: 18px;
      line-height: 30px;
    }

    @include viewport--md {
      font-size: 16px;
      line-height: 26px;
    }

    @include viewport--sm {
      font-size: 18px;
      line-height: 28px;
    }

    & + .intro__description {
      margin-top: 20px;

      @include viewport--xl {
        margin-top: 15px;
      }

      @include viewport--md {
        margin-top: 8px;
      }
    }
  }

  &__links-wrap {
    grid-column: span 2;
    display: flex;
    align-items: center;
    gap: 42px;
    flex-wrap: wrap;

    @include viewport--sm {
      width: 100%;
      flex-direction: column;
      gap: 26px;
      opacity: 0;
    }

    &.dynamic-adaptive {
      @include viewport--sm {
        opacity: 1;
      }
    }
  }

  &__btn {
    font-family: $font-family--inter;
    border-radius: 20px;
    padding: 24px 40px;
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    color: #fff;
    flex-shrink: 0;
    max-width: 320px;

    @include viewport--sm {
      font-size: 18px;
      line-height: 24px;
      width: 100%;
      padding: 18px 40px;
      border-radius: 16px;
      max-width: none;
    }
  }

  &__link {
    //display: flex;
    align-items: center;
    justify-self: center;
    height: fit-content;
    flex-shrink: 0;
    color: #27293a;
    transition: color $default-transition-settings;
    display: none;

    @include viewport--xl {
      gap: 11px;
    }

    @include hover-focus {
      color: #4d6dd3;
    }
  }

  &__link-text {
    font-family: $font-family--inter;
    font-weight: 600;
    font-size: 20px;
    color: inherit;

    @include viewport--sm {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #27293a;
    }
  }

  &__link-icon {
    width: 32px;
    height: 32px;
    display: flex;
    flex: 0 0 auto;

    svg {
      width: 100%;
      height: 100%;
      flex: 0 0 auto;
      color: inherit;
    }
  }

  &__image-wrap {
    @include viewport--xl {
      margin-right: -15px;
    }

    @include viewport--md {
      margin-right: -23px;
      margin-left: -15px;
    }

    @include viewport--sm {
      margin-bottom: 56px;
      margin-left: -17px;
      margin-right: -22px;
    }
  }

  &__image {
    margin-right: -20px;
    margin-top: -45px;

    @include viewport--xl {
      margin-right: 0;
      margin-left: 15px;
      margin-top: -15px;
    }

    @include viewport--md {
      margin-left: 0;
      margin-top: 10px;
    }

    @include viewport--sm {
      margin: 0 -16px 0 0;
    }
  }
}
