.custom-toggle {
  &__label {
    display: grid;
    grid-template-columns: 20px 1fr;
    align-items: center;
    gap: 20px;

    border: 1px solid #c0c7e8;
    border-radius: 8px;
    padding: 15px;
    color: #33376b;

    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    font-family: $font-family--inter;

    cursor: pointer;
  }

  &__icon {
    position: relative;
    z-index: 1;
    display: block;
    width: 24px;
    height: 24px;
    padding: 2px;

    &::before {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      border: 1px solid #c0c7e8;
      border-radius: 50%;
      transition: background-color $default-transition-settings;
    }

    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      opacity: 0;
      transition: opacity $default-transition-settings;
      background: $color-royal-blue-3;
    }
  }

  &__input:checked + &__label {
    .custom-toggle__icon {
      &::before {
        background: #edf1ff;
      }

      &::after {
        opacity: 1;
      }
    }
  }
}