.intro-heading {
  position: relative;

  display: flex;
  justify-content: center;
  padding: 99px 120px 81px;

  font-size: 18px;
  line-height: 26px;
  color: $color-periwinkle;

  background-color: $color-torea-bay;

  @include viewport--lg {
    padding: 99px 60px 81px;
  }

  @include viewport--md {
    padding: 85px 40px 133px;
  }

  @include viewport--sm {
    padding-top: 59px;
    padding-right: 16px;
    padding-bottom: 95px;
    padding-left: 16px;

    font-size: 16px;
    line-height: 24px;
  }

  .demo-sm-form {
    align-self: stretch;

    form {
      justify-content: center;
    }
  }

  &--resources {
    padding-top: 65px;
    padding-bottom: 114px;
    background-color: $color-lucky-point;

    @include viewport--md {
      padding-top: 170px;
      padding-bottom: 230px;
    }

    @include viewport--sm {
      padding-top: 85px;
      padding-bottom: 107px;
    }

    .intro-heading__wrapper {
      width: 100%;
    }

    .intro-heading__sub-title {
      margin-left: auto;
      margin-right: auto;
      max-width: 450px;
    }

    .intro-heading__filter {
      margin-top: 45px;

      @include viewport--sm {
        margin-top: 65px;
      }
    }
  }

  &--glossary {
    padding-top: 65px;
    padding-bottom: 90px;

    @include viewport--md {
      padding-top: 85px;
      padding-bottom: 110px;
    }

    @include viewport--sm {
      padding-top: 50px;
      padding-bottom: 40px;
    }

    .intro-heading__filter {
      @include viewport--sm {
        margin-top: 40px;
      }
    }
  }

  &--customers {
    padding-top: 104px;
    padding-bottom: 138px;

    @include viewport--md {
      padding-top: 115px;
      padding-bottom: 147px;
    }

    @include viewport--sm {
      padding-top: 70px;
      padding-bottom: 105px;
    }

    .intro-heading__title {
      font-size: 22px;
      line-height: 27px;

      @include viewport--md {
        font-size: 20px;
        line-height: 28px;
      }
    }

    .intro-heading__text {
      font-size: 18px;
      line-height: 24px;

      p {
        font-weight: normal;

        @include viewport--md {
          font-weight: 600;
        }
      }
    }
  }

  &--careers {
    padding-top: 81px;
    background-color: $color-lucky-point;

    @include viewport--md {
      padding-top: 85px;
      padding-bottom: 134px;
    }

    @include viewport--sm {
      padding-top: 60px;
      padding-bottom: 85px;
    }
  }

  &--company {
    padding-top: 75px;
    padding-bottom: 97px;
    background-color: $color-lucky-point;

    @include viewport--md {
      padding-top: 47px;
      padding-bottom: 87px;
    }

    @include viewport--sm {
      padding-top: 35px;
      padding-bottom: 70px;
    }

    .intro-heading__title {
      margin-bottom: 25px;
      max-width: 610px;

      @include viewport--md {
        max-width: none;
        margin-bottom: 14px;
        font-size: 26px;
        line-height: 34px;
      }

      @include viewport--sm {
        margin-bottom: 10px;
      }
    }

    .intro-heading__sub-title {
      margin-bottom: 44px;
      max-width: 610px;
      color: #fff;

      @include viewport--md {
        margin: 0 auto 28px;
        max-width: 500px;
      }

      @include viewport--sm {
        margin-bottom: 20px;
      }
    }

    .intro-heading__text {
      margin-left: 0;
      max-width: 500px;
      color: $color-periwinkle-2;

      @include viewport--md {
        margin-bottom: 32px;
        max-width: none;
        padding: 0 50px;
      }

      @include viewport--sm {
        margin-bottom: 28px;
        padding: 0;

        p, div {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    background-repeat: no-repeat;

    @include viewport--md {
      display: none;
    }
  }

  &::before {
    top: 0;
    right: 0;

    width: 450px;
    height: 384px;

    background-image: url("../img/bg/intro-heading-three-planet.svg");
    background-position: top -129px right;
    background-size: 443px 384px;
  }

  &::after {
    bottom: 0;
    left: 0;

    width: 300px;
    height: 218px;

    background-image: url("../img/bg/intro-heading-two-planet.svg");
    background-position: bottom left;
    background-size: 271px 218px;
  }
}

.intro-heading__wrapper {
  position: relative;
  z-index: 1;

  max-width: unset;

  text-align: center;

  @include viewport--md {
    max-width: none;
  }

  &--company {
    width: 100%;
    max-width: 1200px;
    padding: 0 0;

    text-align: left;

    @include viewport--md {
      padding: 0;
      max-width: none;
      text-align: center;
    }
  }
}


.intro-heading__title {
  margin: 0;
  margin-bottom: 36px;

  font-weight: 700;
  font-size: 26px;
  line-height: 34px;
  color: $color-orange;
  font-family: $font-family--inter;

  @include viewport--md {
    margin-bottom: 15px;

    font-size: 20px;
    line-height: 28px;
  }

  @include viewport--sm {
    margin-bottom: 10px;
  }
}

.intro-heading__sub-title {
  margin: 0;
  margin-bottom: 33px;

  font-weight: 600;
  font-size: 44px;
  line-height: normal;
  color: $color-default-white;
  font-family: $font-family--inter;

  @include viewport--md {
    margin-bottom: 47px;

    font-size: 36px;
    line-height: 44px;
  }

  @include viewport--sm {
    margin-bottom: 38px;

    font-size: 28px;
    line-height: 36px;
  }

  &--careers {
    // font-size: 56px;
    // line-height: 70px;

    @include viewport--md {
      margin-bottom: 38px;
    }
  }
}

.intro-heading__text {
  font-weight: 400;
  margin: 0 auto 52px;
  font-family: $font-family--lato;
  line-height: normal;
  width: 100%;
  max-width: 820px;

  @include viewport--md {
    padding: 0 120px;
  }

  @include viewport--sm {
    margin-bottom: 43px;
    max-width: 320px;
    padding: 0;
  }

  h3 {
    display: inline;
    margin: 0;

    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
  }

  p, div {
    margin: 23px 0 0;

    font-weight: 600;
    font-size: 18px;
    line-height: 24px;

    &:first-child {
      margin-top: 0;
    }
  }

  &--careers {
    max-width: 610px;
    margin-bottom: 48px;

    @include viewport--md {
      margin-bottom: 70px;
      padding: 0 90px;
    }

    @include viewport--sm {
      margin-bottom: 63px;
      padding: 0 25px;
    }
  }

  &--about-us {
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
    }
  }
}

.intro-heading__description {
  margin-bottom: 75px;
  display: flex;
  color: $color-default-white;

  @include viewport--md {
    margin-bottom: 80px;
    display: block;
  }

  @include viewport--sm {
    margin-bottom: 55px;
  }
}

.intro-heading__desc-item {
  max-width: 500px;

  @include viewport--md {
    max-width: none;
  }

  &:first-child {
    margin-right: 120px;

    @include viewport--md {
      margin-right: 0;
    }
  }

  p {
    margin: 0 0 23px;

    @include viewport--md {
      margin-bottom: 26px;
    }

    @include viewport--sm {
      margin-bottom: 24px;
    }
  }
}

.intro-heading__btn-demo {
  width: max-content;
  min-width: 210px;
}

.intro-heading__filter {
  max-width: 700px;
  margin: 63px auto 0;

  @include viewport--md {
    margin-top: 69px;
  }

  @include viewport--sm {
    margin-top: 56px;
  }

  &--careers {
    margin-top: 0;
  }

  &--resources {
    max-width: unset;

    .intro-heading__filter-list {
      @include viewport--sm {
        justify-content: flex-start;
      }
    }

    .intro-heading__filter-item {
      margin-left: 7px;
      margin-bottom: 7px;

      @include viewport--sm {
        margin-left: 8px;
        margin-bottom: 8px;

        &:nth-child(2n + 1) {
          margin-left: 0;
        }
      }
    }
  }
}

.intro-heading__filter-title {
  margin: 0;
  margin-bottom: 26px;

  font-weight: 700;
  font-size: 18px;
  line-height: 22px;

  @include viewport--sm {
    margin-bottom: 30px;
  }
}

.intro-heading__filter-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 0;

  list-style: none;

  @include viewport--sm {
    max-width: 329px;
    margin: 0 auto;
  }
}

.intro-heading__filter-item {
  margin-left: 13px;
  margin-bottom: 13px;
  height: inherit;

  @include viewport--sm {
    display: flex;
    justify-content: space-between;
    width: calc(50% - 4px);
    margin-left: 8px;
    margin-bottom: 8px;

    &:nth-child(2n + 1) {
      margin-left: 0;
    }
  }

  .btn {
    display: flex;
    justify-content: center;
    width: 162px;
    height: 100%;

    @include viewport--sm {
      width: 100%;
      min-width: 100%;
    }
  }

  &--active {
    .btn {
      background-color: $color-blue;
    }
  }
}

.intro-heading__bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 73px 0 0 34.4%;

  @include viewport--md {
    padding: 42px 0 0;
    width: 100vw;
    margin-left: -45px;
  }

  @include viewport--sm {
    padding: 40px 16px 0;
    margin-left: -16px;
  }

  svg,
  .intro-heading__sub-title {
    margin-bottom: 25px;

    @include viewport--md {
      margin-bottom: 15px;
    }

    @include viewport--sm {
      margin-bottom: 13px;
      font-size: 36px;
      line-height: 44px;
    }
  }
}

.intro-heading__bottom-text {
  max-width: 580px;
  font-family: $font-family--lato;

  @include viewport--md {
    margin: 0 auto;
  }
}
