.intro-block {
  padding: 77px 0 50px;

  @media (max-width: 1279px) {
    padding: 12% 0 50px;
  }

  @include viewport--md {
    padding: 72px 0 50px;
  }

  @include viewport--sm {
    padding: 43px 0 22px;
  }

  .container {
    position: relative;
    text-align: center;

    @include viewport--md {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__block-subtitle {
    margin-bottom: 45px;

    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: $color-blue;

    @include viewport--sm {
      font-size: 22px;
      line-height: 27px;
    }
  }

  &__block-title {
    margin: 0 auto;
    max-width: 718px;
    font-weight: 700;
    font-size: 42px;
    line-height: 48px;
    color: $color-torea-bay;
    text-align: center;

    span {
      color: $color-blue;
    }

    h3 {
      display: inline;
      margin: 0;

      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
      color: inherit;
    }

    @include viewport--md {

      font-size: 36px;
      line-height: 44px;
    }

    @include viewport--sm {
      font-size: 24px;
      line-height: 29px;
    }
  }

  &__intro {
    margin: 49px auto;
    margin-top: 10px;
    color: $color-new-grey;
    max-width: 752px;
    font-weight: 600;
    font-size: 20px;
    font-style: normal;
    line-height: 30px;
    text-align: center;


    @include viewport--md {
      text-align: center;
      max-width: 560px;
      margin: 40px auto;
      line-height: 28px;
    }

    @include viewport--sm {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }
  }

  &__title {
    margin: 0 0 44px;
    font-size: 30px;
    line-height: 36px;
    color: $color-default-white;
    max-width: 500px;

    @include viewport--md {
      text-align: center;
      max-width: 560px;
      margin-bottom: 40px;
    }

    @include viewport--sm {
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 30px;
    }
  }

  &__description {
    margin: 0 0 44px;
    font-size: 20px;
    line-height: 32px;
    color: $color-default-white;
    max-width: 463px;
    font-weight: 600;

    @include viewport--md {
      text-align: center;
      max-width: 560px;
      margin-bottom: 40px;
    }
  }

  // &__button {
  //   text-align: center;
  //   margin-bottom: 30px;
  // }

  // .btn {
  //   min-width: 190px;

  //   @include viewport--sm {
  //     min-width: 0;
  //   }
  // }

  .demo-sm-form {
    align-self: stretch;
    margin-bottom: 50px;

    @include viewport--md {
      margin-bottom: 0;
    }

    form {
      justify-content: center;

      @include viewport--sm {
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
      }
    }

    input {
      @include viewport--sm {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 12px;
      }
    }

    .btn {
      @include viewport--sm {
        width: 100%;
      }
    }
  }

  img {
    margin: 0 auto;
    position: relative;
    z-index: 3;
    margin-bottom: -152px;

    //@include viewport--sm {
    //  margin-bottom: -60px;
    //}
  }

  &__img-desk {
    width: 1051px;
    height: auto;
    top: 8px;
    left: 29px;
    margin-bottom: -122px;

    @include viewport--sm {
      display: none;
    }
  }

  & .intro-block__img-mob {
    display: none;
    width: 506px;
    height: auto;

    @include viewport--sm {
      display: initial;
      transform: translateX(13px) scale(1.4);
      margin-top: 80px;
      margin-bottom: -60px;
    }
  }

  &--cookieless-page .intro-block__img-mob  {
    @include viewport--sm {
      margin-bottom: -25px;
    }
  }

  &__animation {
    position: relative;
    z-index: 3;

    width: 1007px;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: -152px;
  }

  &__video-wrapper-desk {
    position: relative;
    z-index: 0;

    width: 748px;
    max-width: 100%;
    min-width: 0;
    margin: 0 auto;

    @include viewport--md {
      display: none;
    }
  }

  &__video-desk {
    position: relative;
    z-index: 0;

    display: block;
    width: 100%;
    max-width: 100%;
    min-width: 0;
    padding-top: 56.3%;

    &:hover,
    &:focus,
    &:active {
      & img {
        opacity: 0.7;
      }
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      width: 100%;
      height: 100%;
      border: 3px solid $color-blue;
      pointer-events: none;
    }
  }

  &__video-wrapper-tabl-mob {
    display: none;

    position: relative;
    z-index: 0;

    width: 100%;
    max-width: 556px;
    min-width: 0;
    margin: 0 auto 45px;

    background: linear-gradient(108.91deg, #5483EC 4.74%, #5789FF 20.36%, #5381FF 66.97%, #3D60CC 80.35%, #3B51AD 99.59%);
    border-radius: 10px;
    box-shadow: -5px -5px 18px rgba(38, 52, 109, 0.3);

    overflow: hidden;

    @include viewport--md {
      display: block;
    }
  }

  &__video-tabl-mob {
    position: relative;
    z-index: 0;

    display: block;
    width: 100%;
    padding-top: 56.3%;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 14px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 14px;
      object-fit: cover;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      width: 100%;
      height: 100%;
      border: 3px solid $color-blue;
      border-radius: 10px;
      pointer-events: none;
    }
  }

  &--main {
    padding: 90px 0 50px;

    @include viewport--md {
      padding: 45px 0;
    }

    .container {
      @include viewport--sm {
        padding: 0 32px;
      }
    }

    .intro-block {
      &__block-title {
        font-size: 36px;
        line-height: 66px;
        color: $color-torea-bay-4;

        @include viewport--sm {
          font-size: 24px;
          line-height: 29px;
          letter-spacing: 0.01;
        }
      }

      &__intro {
        max-width: 760px;
        color: $color-comet-3;

        @include viewport--sm {
          margin: 45px 0;
          margin-top: 18px;
          font-weight: 600;
          font-size: 18px;
          line-height: 26px;
        }
      }

      &__video-wrapper-desk {
        width: 582px;
      }
    }
  }

  &--cookieless-page {

    .intro-block {
      &__block-title {
        line-height: normal;
        color: $color-torea-bay-4;

        @include viewport--sm {
          line-height: 29px;
        }
      }

      &__intro {
        max-width: 760px;
        color: $color-comet-3;
        margin-top: 50px;

        @include viewport--sm {
          margin: 45px 0;
          margin-top: 18px;
          font-weight: 600;
          font-size: 18px;
          line-height: 26px;
        }
      }

      &__video-wrapper-desk {
        width: 582px;
      }
    }
  }
}
