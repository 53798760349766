.case-page {
  padding-top: 0;

  .text-page__pub-info + p {
    @include viewport--md {
      font-size: 20px;
      line-height: 30px;
    }
  }
}

.case-page__company-info {
  padding: 36px 43px 44px;

  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: $color-ship-cove;

  @include viewport--sm {
    padding: 36px 27px 44px;
  }
}

.case-page__company-value {
  padding-left: 5px;

  font-weight: 700;
}

.case-page__company-row {
  margin-top: 22px;

  @include viewport--sm {
    margin-top: 23px;
  }

  &:first-child {
    margin-top: 0;
  }
}
