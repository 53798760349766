.cookieless-intro {
    background: linear-gradient(172deg, $color-default-white 4.05%, $color-alice-blue 86.41%);

    @include viewport--sm {
        background: none;
    }

    &__partners {
        display: flex;
        align-items: start;

        @include viewport--md {
            margin-left: 0;
        }

        a[href] {
            transition: opacity $default-transition-settings;

            &:hover {
                opacity: 0.7;
            }
        }

        .cookieless-intro__microsoft {
            margin-top: 3px;
            margin-left: 4px;
            margin-right: 4px;
            align-self: start;
            box-shadow: 0px 3.29px 6.58px 0px rgba(59, 70, 181, 0.05);

            @include viewport--md {
                margin-top: 2px;
            }
        }

        [alt="Meta Partner"] {
            margin-top: 2px;
            width: auto;
            height: 61px;
        }

        [alt="Google Cloud Partner"] {
            width: 116px;
        }

        .cookieless-intro__partner-g2 img {
            height: 64px;
            width: auto;
        }

        .cookieless-intro__partner-g2 {
            margin-left: -7px;

            @include viewport--md {
                margin-left: 0;
            }
        }
    }

    &__partner-gmp {
        margin-top: 4px;
        height: 47px;
        width: auto;
    }

    &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 100px;
        padding-bottom: 100px;

        @include viewport--sm {
            padding-top: 50px;
            padding-bottom: 22px;
        }
    }

    &__title {
        margin-top: 0;
        margin-bottom: 40px;
        max-width: 700px;
        text-align: center;
        color: #272939;
        font-size: 42px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px;

        & span {
            color: $color-blue;
        }

        @include viewport--sm {
            max-width: 345px;
            margin-bottom: 38px;
            font-size: 28px;
            line-height: 36px;
        }
    }

    &__description {
        max-width: 700px;
        margin-bottom: 38px;
        color: $color-comet;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;

        @include viewport--sm {
            max-width: 345px;
            margin-bottom: 34px;
            font-size: 17px;
            line-height: 24px;
        }
    }

    &__partners {
        margin-bottom: 32px;
        margin-left: -19px;

        @include viewport--sm {
            margin-left: 0;
        }

        & a[href] {
            margin-right: 3px;
        }
    }

    & .demo-sm-form form {

        & input {
            width: 318px;

            @include viewport--sm {
                width: 100%;
                max-width: 100%;
                margin-right: 0;
                margin-bottom: 12px;
            }
        }

        @include viewport--sm {
            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;
        }
    }

    & .demo-sm-form {
        @include viewport--sm {
            align-self: stretch;
        }
    }

    &__buttons {
        display: flex;
        flex-direction: row;

        .btn {
            margin: 0 10px 10px 0;
        }

        .btn--bordered {
            color: $color-default-white;
            border: 2px solid $color-default-white;
        }

        @include viewport--sm {
            flex-direction: column;
        }
    }
}

.cookieless-page {
    .trusted-brands--blue {
        background-color: $color-alice-blue;

        @include viewport--sm {
            background-color: transparent;
        }
    }

    &__intro-how-blocks {
        background: linear-gradient(180deg, #FFFFFF 13.42%, #F7F8FF 43.37%, #FFFFFF 60.55%, #FFFFFF 100%);

        @include viewport--sm {
            background: none;
        }
    }
}
