.marketing-plate {
  padding-bottom: 100px;

  @include viewport--xl {
    padding-bottom: 75px;
  }

  @include viewport--md {
    padding-bottom: 5px;
  }

  @include viewport--sm {
    padding-bottom: 73px;
  }

  &__container {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 33px;

    @include viewport--xl {
      max-width: 1280px;
      padding-left: 40px;
      padding-right: 40px;
    }

    @include viewport--lg {
      max-width: 1280px;
      padding-left: 40px;
      padding-right: 40px;
    }

    @include viewport--md {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  &__inner {
    background: linear-gradient(90deg, #172455 0%, #2f4795 50%, #4568d0 100%);
    border-radius: 60px;
    padding: 92px 0 92px 100px;
    position: relative;
    overflow: hidden;

    @include viewport--xl {
      padding: 57px 0 58px 75px;
      border-radius: 45px;
    }

    @include viewport--md {
      padding: 33px 0 135px 24px;
      border-radius: 30px;
    }

    @include viewport--sm {
      padding: 33px 24px 0;
      background: linear-gradient(180deg, #172455 0%, #2f4795 50%, #4568d0 100%);
      border-radius: 20px;
    }
  }

  &__text {
    font-family: $font-family--inter;
    font-weight: 600;
    font-size: 48px;
    line-height: 64px;
    letter-spacing: -0.02em;
    color: #fff;
    margin: 0 0 64px;

    @include viewport--xl {
      line-height: 48px;
      font-size: 36px;
      margin: 0 0 45px;
    }

    @include viewport--md {
      font-size: 32px;
      line-height: 40px;
      margin: 0 0 32px;
    }

    @include viewport--sm{
      font-size: 32px;
      line-height: 40px;
      margin: 0 0 32px;
    }
  }

  &__content-wrap {
    width: 100%;
    max-width: 814px;

    @include viewport--xl {
      max-width: 55%;
    }

    @include viewport--lg {
      //max-width: 44%;
    }

    @include viewport--md {
      max-width: 52%;
    }

    @include viewport--sm {
      max-width: none;
      margin-bottom: 58px;
    }
  }

  &__image-wrap {
    position: absolute;
    bottom: 0;
    right: 0;

    @include viewport--xl {
      right: -40px;
      width: 455px;
    }

    @include viewport--md {
      right: 0;
      width: 58%;
    }

    @include viewport--sm {
      position: relative;
      width: 105%;
      right: -8px;
    }
  }

  &__btn {
    border-radius: 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    padding: 24px 40px;

    @include viewport--md {
      padding: 18px 24px;
      border-radius: 16px;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
    }

    @include viewport--sm {
      width: 100%;
    }
  }
}
