.how-block {
  padding: 50px 0 79px;

  @include viewport--sm {
    padding: 23px 0 43px;
  }

  .container {
    padding: 0px 109px;
    position: relative;

    @include viewport--lg {
      padding: 0 60px;
    }

    @include viewport--md {
      padding: 0 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @include viewport--sm {
      padding: 0 16px;
    }
  }

  &__block-title {
    margin-top: 0;
    margin-bottom: 75px;

    font-weight: 700;
    font-size: 42px;
    line-height: 48px;
    color: $color-blue;
    text-align: center;

    span {
      color: $color-torea-bay;
    }

    @include viewport--md {

      font-size: 36px;
      line-height: 44px;
    }

    @include viewport--sm {
      margin-bottom: 45px;
      font-size: 28px;
      line-height: 36px;
    }
  }

  &__block-subtitle {
    display: block;
    max-width: 774px;
    margin: 0 auto;
    margin-bottom: 75px;

    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: $color-blue;
    text-align: center;

    @include viewport--sm {
      font-size: 22px;
      line-height: 27px;
    }
  }

  &__title {
    margin: 0 0 44px;
    font-size: 30px;
    line-height: 36px;
    color: $color-default-white;
    max-width: 500px;

    @include viewport--md {
      text-align: center;
      max-width: 560px;
      margin-bottom: 40px;
    }

    @include viewport--sm {
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 30px;
    }
  }

  &__flex {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include viewport--md {
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__description-block {
    @include viewport--md {
      margin-bottom: 25px;
    }
  }

  &__description {
    margin: 18px auto;
    color: $color-new-grey;
    max-width: 485px;
    font-weight: 400;
    font-size: 20px;
    font-style: normal;
    line-height: 32px;
    text-align: left;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    @include viewport--md {
      text-align: center;
      max-width: 560px;
      margin-top: 20px;
      margin-bottom: 15px;

      &:last-child {
        margin: 0 auto 20px;
      }

    }

    @include viewport--sm {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }
  }

  &__img {
    &--desktop {
      @include viewport--sm {
        display: none;
      }
    }

    &--mob {
      display: none;

      @include viewport--sm {
        display: block;
      }
    }
  }

  // &__button {
  //   text-align: center;
  //   margin-top: 80px;

  //   @include viewport--sm {
  //     margin-top: 20px;
  //   }
  // }

  // .btn {
  //   min-width: 190px;

  //   @include viewport--sm {
  //     min-width: 0;
  //   }
  // }

  .demo-sm-form {
    align-self: stretch;
    margin-top: 80px;

    @include viewport--sm {
      margin-top: 20px;
    }

    form {
      justify-content: center;

      @include viewport--sm {
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
      }
    }

    input {
      @include viewport--sm {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 12px;
      }
    }

    .btn {
      @include viewport--sm {
        width: 100%;
      }
    }
  }

  img {
    margin: 0 0 0 auto;

    @include viewport--lg {
      max-width: 65%;
    }

    @include viewport--md {
      max-width: 557px;
      margin: 0 auto;
    }

    @include viewport--sm {
      max-width: 100%;
    }
  }

  &__video-wrapper {
    margin: 0 0 0 20px;

    position: relative;
    z-index: 0;

    // width: 100%;
    width: 620px;
    min-width: 0;

    background: linear-gradient(108.91deg, #5483EC 4.74%, #5789FF 20.36%, #5381FF 66.97%, #3D60CC 80.35%, #3B51AD 99.59%);
    border-radius: 15.5px;
    box-shadow: 0px 3.5px 18.5px rgba(66, 92, 176, 0.25);

    overflow: hidden;

    @include viewport--lg {
      max-width: 65%;
    }

    @include viewport--md {
      max-width: 557px;
      margin: 0 auto;
    }

    @include viewport--sm {
      width: 100%;
      max-width: 556px;
    }

    &--desktop {
      @include viewport--md {
        display: none;
      }
    }

    &--tablet-mob {
      display: none;

      @include viewport--md {
        display: block;
      }
    }
  }

  &__video {
    position: relative;
    z-index: 0;

    display: block;
    width: calc(100% - 8px);
    padding-top: 56.3%;
    margin-top: 4px;
    margin-left: 4px;
    margin-bottom: 4px;

    // border: 2px solid $color-blue;
    // filter: drop-shadow(38px 36px 24.0148px rgba(166, 181, 228, 0.2));
    // box-shadow: 38px 36px 24.0148px rgba(166, 181, 228, 0.2);

    border-radius: 11.5px;
    overflow: hidden;

    iframe {
      position: absolute;
      top: -2px;
      left: -5px;
      width: calc(100% + 10px);
      height: calc(100% + 5px);

      pointer-events: none;

      @include viewport--md {
        pointer-events: auto;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      width: 100%;
      height: 100%;

      // border: 2px solid $color-blue;

      @include viewport--md {
        display: none;
      }
    }
  }

  &--main {
    padding: 75px 0;

    @include viewport--sm {
      padding: 45px 0;
    }

    .container {
      padding: 0px 178px;

      @include viewport--lg {
        padding: 0 60px;
      }

      @include viewport--md {
        padding: 0 40px;
      }

      @include viewport--sm {
        padding: 0 32px;
      }
    }

    .demo-sm-form {
      margin-top: 60px;

      @include viewport--sm {
        margin-top: 26px;
      }
    }

    .how-block {
      &__img {
        max-width: 504px;

        @include viewport--sm {
          max-width: 100%;
        }
      }

      &__description-block {
        padding-right: 20px;

        @include viewport--md {
          padding-right: 0;
        }

        @include viewport--sm {
          margin: 23px 0 10px;
        }
      }

      &__description {
        font-size: 20px;
        line-height: 32px;
        color: $color-comet;

        @include viewport--sm {
          // margin-bottom: 25px;
          font-size: 16px;
          line-height: 24px;

          // &:last-child {
          //   margin: 0;
          // }
        }
      }

      &__flex {
        @include viewport--md {
          flex-direction: column-reverse;
        }
      }

      &__block-title {
        margin-bottom: 90px;
        font-weight: 700;
        font-size: 42px;
        line-height: 26px;
        color: $color-torea-bay-4;

        @include viewport--sm {
          margin-bottom: 45px;
          font-size: 24px;
          line-height: 29px;
          letter-spacing: 0.01em;
        }
      }

      &__block-subtitle {
        margin-bottom: 75px;
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        color: $color-blue;

        @include viewport--sm {
          margin-bottom: 45px;
          font-size: 18px;
          line-height: 26px;
        }
      }
    }
  }
}
