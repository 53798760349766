html[lang="ru"]:not(#hack-id) {
  .footer__agreements-wrapper {
    @media (min-width: 1024px) {
      margin-top: 15px;
    }
  }

  .main-nav__submenu--products {
    @media (min-width: 1024px) {
      .main-nav__submenu-item {
        padding-right: 30px;
      }

      .main-nav__submenu-item--xl {
        padding-right: 40px;
      }
    }
  }

  .main-page {
    //.slider-steps__slide-title {
    //  max-width: 450px;
    //}

    .slider-steps__title {
      max-width: none;
    }

    .slider-steps__description {
      max-width: 400px;
    }
  }

  .main-intro img {
    max-width: 669px;
  }

  .main-intro .img-fix {
    @include viewport--md {
      max-width: 669px;
    }
  }

  .trusted-brands--blue .trusted-brands__item img {
    width: auto;
  }

  //.trusted-brands__item img {
  //  max-width: 185px;
  //}

  .main-intro__title {
    width: auto;
    max-width: 560px;

    @media (min-width: $viewport--lg) and (max-width: 1200px) {
      max-width: 500px;
    }
  }

  .main-intro__description {
    width: auto;
    max-width: 480px;

    @media (min-width: $viewport--lg) and (max-width: 1200px) {
      max-width: 400px;
    }
  }

  .main-nav__action-wrapper {
    @include viewport--sm {
      a[data-modal="request"] {
        padding-left: 14px;
        padding-right: 14px;
      }
    }
  }

  .pricing-card {
    &__lead {
      width: 220px;
    }
  }

  .question-banner__wrapper {
    width: auto;
    max-width: 570px;

    p {
      width: auto;
      max-width: 500px;
    }
  }

  .integration-page__title {
    @media (min-width: 1280px) {
      width: 850px;
    }
  }

  .integration-lead__title {
    @include viewport--md {
      width: auto;
      max-width: 79%;
    }

    @include viewport--sm {
      max-width: none;
    }
  }

  .get-started {
    &__text {
      max-width: 500px;
    }

    @include viewport--sm {
      padding-left: 34px;
      padding-right: 34px;
    }
  }

  .integration-page__intro {
    background-image: url("../img/ru/bg/intro-integration-lg@1x.png");

    @include retina {
      background-image: url("../img/ru/bg/intro-integration-lg@2x.png");
    }

    @include viewport--md {
      background-image: url("../img/ru/bg/intro-integration-md@1x.png");

      @include retina {
        background-image: url("../img/ru/bg/intro-integration-md@2x.png");
      }
    }

    @include viewport--sm {
      background-image: url("../img/ru/bg/intro-integration-sm@1x.png");

      @include retina {
        background-image: url("../img/ru/bg/intro-integration-sm@2x.png");
      }
    }
  }

  .slider-steps__slide-title {
    max-width: 450px;
  }

  .marketing-page {
    .slider-steps__title {
      max-width: none;
    }

    .slider-steps__slide-title {
      max-width: 420px;
    }
  }

  .marketing-intro__description {
    max-width: 561px;
  }

  .marketing-intro__img {
    @media (min-width: $viewport--lg) {
      img {
        right: -260px;
        max-width: 1036px;
      }
    }

    img {
      @include viewport--xl {
        right: -216px;
      }

      @include viewport--lg {
        right: 0;
      }
    }
  }

  .report-templates__title {
    max-width: 850px;
  }

  .report-templates__description {
    max-width: 720px;
  }

  .progress-pagination__label {
    //width: 220px;
  }

  //.product-card__title {
  //  max-width: 300px;
  //}


  .slider-steps--ai {
    .slider-steps__slide-title {
      max-width: none;
    }

    .slider-steps__description {
      max-width: 860px;
    }
  }

  .slider-steps__check-list--flat {
    max-width: 500px;
  }

  .bigquery-page {
    .slider-steps__slide-title {
      max-width: 470px;
    }

    .slider-steps__description {
      max-width: 420px;
    }

    .slider-steps__check-list {
      max-width: 480px;
    }
  }

  .bigquery-intro .google-partner + .img-fix img {
    max-width: 618px;
  }

  .bigquery-intro .google-partner + .img-fix {    
    @include viewport--md {
      max-width: 618px;
    }
  }

  .ai-intro__title {
    max-width: 650px;
  }

  .ai-page {
    .advantages--per-3 .advantages__name {
      max-width: 290px;
    }
  }

  .bigquery-intro__title {
    //max-width: 500px;
  }

  .bigquery-intro__description {
    max-width: 530px;
  }

  .why-bigquery__subtitle {
    font-size: 18px;
  }

  .why-bigquery__item {
    &:nth-child(1) {
      max-width: 250px;

      img {
        //margin-bottom: -44px;
      }
    }

    &:nth-child(3) {
      .why-bigquery__subtitle {
        max-width: 240px;
      }
    }
  }

  .pricing__list-wrap {
    max-width: 100%;
  }

  .pricing__info a {
    line-height: 26px;
  }

  .interface__title {
    max-width: 700px;
  }

  .integration-lead--ga .integration-lead__title {
    max-width: 1020px;
  }

  .ga-page {
    .steps__name {
      @media (min-width: $viewport--lg) {
        min-height: 84px;
      }
    }
  }

  .ga-intro__container .ga-intro__wrap + .img-fix {
    @media (min-width: $viewport--lg) {
      margin-right: -30px;
      max-width: 55%;
    }
  }

  .intro-heading--company .intro-heading__sub-title {
    max-width: 570px;
  }

  .team__item {
    min-width: 220px;
    max-width: 350px;

    &:nth-child(2n+1) {
      margin-right: 72px;
    }

    @include viewport--sm {
      max-width: none;
    }

    p {
      font-size: 16px;
    }
  }

  .blue-banner__wrapper {
    max-width: 730px;
  }

  .blue-banner__wrapper--company {
    .blue-banner__title {
      @media (min-width: $viewport--lg) {
        font-size: 38px;
        line-height: 42px;
      }
    }
  }

  .intro-heading__text--careers {
    max-width: 700px;
  }

  .subscribe__text {
    max-width: 500px;
  }

  .case-page__company-param {
    padding-right: 5px;
  }

  .case-page__company-value {
    padding-left: 0;
  }

  .blue-banner__text--vacancy {
    max-width: 340px;
  }

  .text-page--vacancy {
    @include viewport--sm {
      .text-page__btns {
        flex-direction: column;
      }

      .text-page__btn {
        margin-right: 0;
        margin-bottom: 18px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .popup__title {
    max-width: 300px;
  }
}
