.say-card-big-detailed {
  width: 712px;
  max-width: 100%;
  min-width: 0;
  // min-height: 448px;
  padding: 58px 45px;

  text-align: left;

  background-color: $color-default-white;
  box-shadow: 0px 11.5px 38px rgba(66, 92, 176, 0.25);
  border-radius: 15.5px;

  transition: transform $default-transition-settings;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      transform: translate(0, -5px);
    }
  }

  @include viewport--sm {
    width: 350px;
    padding: 40px 24px;

    text-align: center;

    box-shadow: 0px 8px 26px rgba(66, 92, 176, 0.25);
    border-radius: 10.5px;
  }

  img {
    margin: 0;
  }

  &__author-block {
    display: flex;
    margin-bottom: 22px;

    @include viewport--sm {
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
    }
  }

  .say-card-big-detailed__author-pic {
    flex-shrink: 0;
    margin-right: 14px;

    @include viewport--sm {
      width: 53.5px;

      margin-right: 0;
      margin-bottom: 9px;
    }

    &--desk-tabl {
      @include viewport--sm {
        display: none;
      }
    }

    &--mob {
      display: none;

      @include viewport--sm {
        display: block;
      }
    }
  }

  &__author-text-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__author-title {
    font-weight: 700;
    font-size: 21.5px;
    line-height: 107.3%;

    @include viewport--sm {
      font-size: 15px;
    }

    &--desk-tabl {
      @include viewport--sm {
        display: none;
      }
    }

    &--mob {
      display: none;

      @include viewport--sm {
        display: block;
      }
    }
  }

  &__author-subtitle {
    margin-top: 10px;

    font-size: 21.5px;
    line-height: 107.3%;

    @include viewport--sm {
      margin-top: 7px;

      font-size: 12.5px;
    }
  }

  .say-card-big-detailed__stars {
    margin-bottom: 22px;

    @include viewport--sm {
      width: 111px;
      margin-bottom: 15px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__pre-text {
    margin-bottom: 35px;

    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: $color-trout;

    @include viewport--sm {
      margin-bottom: 22px;

      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      color: $color-mid-gray;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 33px;
    line-height: 40px;
    color: $color-tuna-2;

    @include viewport--sm {
      font-size: 22px;
      line-height: 27px;
    }
  }

  &__text {
    margin-top: 20px;

    font-size: 21.5px;
    line-height: 136.8%;
    color: $color-scarpa-flow;

    @include viewport--sm {
      margin-top: 14px;

      font-size: 17px;
    }
  }

  &--travel {
    width: 675px;
    padding: 49px 45px;

    @include viewport--sm {
      width: 350px;
      padding: 40px 24px;
    }

    .say-card-big-detailed {
      &__author-block {
        @include viewport--sm {
          margin-bottom: 22px;
        }
      }

      &__author-subtitle {
        font-size: 17px;
        color: $color-silver;
    
        @include viewport--sm {
          display: none;
        }
      }

      &__stars {
        @include viewport--sm {
          margin-bottom: 22px;
        }
      }

      &__title {
        max-width: 500px;

        @include viewport--sm {
          max-width: 100%;

          font-size: 23px;
          line-height: 27px;
        }
      }
    }
  }
}