.resources-page {
  .subscribe {
    margin-top: 69px;
  }
}

.resources-page__content {
  margin: 110px auto 0;

  @include viewport--md {
    margin-top: 92px;
  }

  @include viewport--sm {
    margin-top: 69px;
  }
}
