.main-page {
  .slider-steps {
    margin-bottom: 61px;

    @include viewport--lg {
      margin-bottom: 40px;
    }

    @include viewport--md {
      margin-bottom: 28px;
    }

    @include viewport--sm {
      margin-bottom: 0;
    }
  }

  .integration-lead {
    margin-bottom: 45px;

    @include viewport--md {
      margin-bottom: 0;
    }
  }

  .team-benefits {
    margin-bottom: 84px;

    @include viewport--md {
      margin-bottom: 70px;
    }

    @include viewport--sm {
      margin-bottom: 55px;
    }
  }

  .pricing-plans {
    margin-top: 151px;
    margin-bottom: -60px;

    @include viewport--md {
      margin-top: 90px;
      margin-bottom: 0;
    }

    @include viewport--sm {
      margin-top: 60px;
    }
  }
}
