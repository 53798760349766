.solutions-download-section {
  padding: 75px 0 100px;

  background-image: url("../img/bg/solutions-download-bg-desktop.svg");
  background-position: top -1px left;
  background-size: 100% 534px;
  background-repeat: no-repeat;

  @include viewport--sm {
    padding: 42px 0 45px;

    background-image: url("../img/bg/solutions-download-bg-mob.svg");
    background-size: 100% 739px;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    // max-width: 1200px;
    max-width: 992px;
    margin: 0 auto;

    @include viewport--md {
      flex-direction: column;
      align-items: center;
    }
  }

  &__left {
    max-width: 504px;
    // margin-left: 70px;

    @media(max-width: 1150px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 0;
    }
  }

  &__title {
    margin: 0;

    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: $color-torea-bay-4;

    @include viewport--md {
      text-align: center;
    }

    @include viewport--sm {
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.01em;
    }
  }

  &__descriptions {
    margin-top: 41px;

    @include viewport--sm {
      margin-top: 45px;
    }
  }

  &__description {
    margin-bottom: 30px;

    font-size: 20px;
    line-height: 30px;
    color: $color-comet;

    &:last-child {
      margin-bottom: 0;
    }

    @include viewport--md {
      text-align: center;
    }
  }

  &__right {
    flex-shrink: 0;
    max-width: 100%;
    margin-left: 30px;

    @include viewport--md {
      margin-left: 0;
      margin-top: 45px;
    }
  }
}