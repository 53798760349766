.ceo-quote {
  padding-top: 135px;
  padding-bottom: 210px;
  overflow: hidden;

  @include viewport--xl {
    padding-top: 77px;
    padding-bottom: 155px;
  }

  @include viewport--md {
    padding-top: 53px;
    padding-bottom: 168px;
  }

  @include viewport--sm {
    padding-top: 42px;
    padding-bottom: 170px;
  }

  &__container {
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 64px 0;
    position: relative;

    @include viewport--xl {
      max-width: 1280px;
    }

    @include viewport--md {
      padding: 0 24px;
    }

    @include viewport--sm {
      padding: 0 16px;
    }
  }

  &__bg {
    position: absolute;
    z-index: -1;

    &--left {
      width: 396px;
      bottom: -282px;
      left: 0;

      @include viewport--xl {
        width: 297px;
        bottom: -210px;
        left: 41px;
      }

      @include viewport--md {
        width: 247px;
        bottom: -216px;
        left: -2px;
      }

      @include viewport--sm {
        width: 213px;
        bottom: -209px;
        left: -38px;
      }
    }

    &--right {
      width: 496px;
      bottom: -230px;
      right: -68px;

      @include viewport--xl {
        right: -13px;
        bottom: -172px;
        width: 373px;
      }

      @include viewport--md {
        width: 311px;
        bottom: -186px;
        right: -25px;
      }

      @include viewport--sm {
        width: 247px;
        bottom: -173px;
        right: -41px;
      }
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    @include viewport--sm {
      align-items: flex-start;
    }
  }

  &__quote {
    margin: 0 0 48px;
    font-family: $font-family--inter;
    font-weight: 600;
    font-size: 48px;
    line-height: 64px;
    letter-spacing: -0.02em;
    text-align: center;
    color: #33376b;
    width: 100%;
    max-width: 1163px;

    @include viewport--xl {
      font-size: 36px;
      line-height: 48px;
      max-width: 870px;
      margin: 0 0 37px;
    }

    @include viewport--md {
      margin-bottom: 24px;
      font-weight: 600;
      font-size: 28px;
      line-height: 40px;
      letter-spacing: -0.02em;
    }

    @include viewport--sm {
      margin-bottom: 28px;
      text-align: left;
    }

    span {
      background: linear-gradient(90deg, #4d6dd3 0%, #5f98ff 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &__image {
    border-radius: 50%;
    overflow: hidden;
    width: 92px;
    height: 92px;
    flex-shrink: 0;
    margin-bottom: 24px;

    @include viewport--xl {
      width: 69px;
      height: 69px;
      margin-bottom: 19px;
    }

    @include viewport--md {
      width: 72px;
      height: 72px;
    }

    @include viewport--sm {
      grid-area: 1 / 1 / 3 / 2;
      width: 48px;
      height: 48px;
      margin-bottom: auto;
    }
  }

  &__author-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 60%;

    @include viewport--sm {
      display: grid;
      grid-template-columns: 48px 1fr;
      max-width: unset;
      gap: 0 15px;
    }
  }

  &__author-name {
    margin: 0 0 8px;
    font-family: $font-family--inter;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #27293a;

    @include viewport--xl {
      margin: 0 0 5px;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.03em;
    }

    @include viewport--md {
      font-size: 20px;
      line-height: 23px;
      margin: 0 0 6px;
      text-align: left;
      letter-spacing: -0.04em;
    }

    @include viewport--sm {
      margin: 0;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0;
    }
  }

  &__author-position {
    font-family: $font-family--inter;
    margin: 0;
    font-weight: 400;
    font-size: 22px;
    line-height: 36px;
    color: #414a68;

    @include viewport--xl {
      font-size: 16px;
      line-height: 26px;
    }

    @include viewport--md {
      font-size: 15px;
      line-height: 24px;
    }

    @include viewport--sm {
      font-size: 18px;
      line-height: 28px;
    }
  }
}
