.inexpensive-lead {
  background-color: $color-ghost-white;
  padding: 104px 0 127px;

  @include viewport--md {
    padding: 80px 0 96px;
  }

  @include viewport--sm {
    padding: 76px 0 53px;
  }

  &__title {
    font-size: 36px;
    line-height: 48px;
    font-weight: 700;
    color: $color-black;
    margin: 0 0 90px;
    text-align: center;

    @include viewport--md {
      margin-bottom: 76px;
    }

    @include viewport--sm {
      font-size: 24px;
      line-height: 34px;
      max-width: 250px;
      margin: 0 auto 66px;
    }
  }

  &__points-list {
    margin: 0 auto;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    max-width: 1200px;

    @media (max-width: 1279px) {
      max-width: 660px;
      margin: 0 auto;
      flex-wrap: wrap;
    }

    @include viewport--md {
      flex-direction: column;
      align-items: center;
    }
  }

  &__points-item {
    width: 300px;

    @media (max-width: 1279px) {
      margin-bottom: 60px;

      &:last-child {
        margin: 0 auto;
      }
    }

    @include viewport--md {
      &:last-child {
        margin: 0;
      }
    }

    @media (max-width: 413px) {
      width: 100%;
      max-width: 300px;
    }
  }

  &__point {
    position: relative;
    font-weight: 600;
    font-size: 60px;
    line-height: 42px;
    color: $color-blue;
    padding-bottom: 34px;
    margin: 0 0 27px;
    text-align: center;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 76px;
      height: 3px;
      background-color: $color-blue;
    }

    @media (max-width: 413px) {
      font-size: 56px;
    }
  }

  &__point-description {
    margin: 0;
    font-size: 18px;
    line-height: 26px;
    color: $color-storm-grey;
    text-align: center;

    @include viewport--sm {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
