.range {
  input[type=range] {
    max-width: 450px;
    width: 100%;
    margin: 8px auto;
    display: block;
    background-color: $color-transparent;
    -webkit-appearance: none;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: inset 0px 4px 10px rgba(77, 109, 211, 0.3);
    // border: 1px solid #010101;
    border-radius: 10px;
    width: 100%;
    height: 14px;
    cursor: pointer;
  }
  &--values-3 {
    input[type=range]::-moz-range-track {
      background-image: linear-gradient(to right,
        $color-default-white 50%,
        $color-tropical-blue 50%,
        $color-default-white calc(50% + 2px)
      );
    }
    input[type=range]::-webkit-slider-runnable-track {
      background-image: linear-gradient(to right,
        $color-default-white 50%,
        $color-tropical-blue 50%,
        $color-default-white calc(50% + 2px)
      );
    }
  }
  &--values-4 {
    input[type=range]::-moz-range-track {
      background-image: linear-gradient(to right,
        $color-default-white 33.3%,
        $color-tropical-blue 33.3%,
        $color-default-white calc(33.3% + 2px),
        $color-default-white 66.6%,
        $color-tropical-blue 66.6%,
        $color-default-white calc(66.6% + 2px)
      );
    }
    input[type=range]::-webkit-slider-runnable-track {
      background-image: linear-gradient(to right,
        $color-default-white 33.3%,
        $color-tropical-blue 33.3%,
        $color-default-white calc(33.3% + 2px),
        $color-default-white 66.6%,
        $color-tropical-blue 66.6%,
        $color-default-white calc(66.6% + 2px)
      );
    }
  }
  &--values-5 {
    input[type=range]::-moz-range-track {
      background-image: linear-gradient(to right,
        $color-default-white 25%,
        $color-tropical-blue 25%,
        $color-default-white calc(25% + 2px),
        $color-default-white 50%,
        $color-tropical-blue 50%,
        $color-default-white calc(50% + 2px),
        $color-default-white 75%,
        $color-tropical-blue 75%,
        $color-default-white calc(75% + 2px)
      );
    }
    input[type=range]::-webkit-slider-runnable-track {
      background-image: linear-gradient(to right,
        $color-default-white 25%,
        $color-tropical-blue 25%,
        $color-default-white calc(25% + 2px),
        $color-default-white 50%,
        $color-tropical-blue 50%,
        $color-default-white calc(50% + 2px),
        $color-default-white 75%,
        $color-tropical-blue 75%,
        $color-default-white calc(75% + 2px)
      );
    }
  }
  input[type=range]::-webkit-slider-thumb {
    margin-top: -9px;
    width: 30px;
    height: 30px;
    background-color: $color-blue;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border: 6px solid #ffffff;
    border-radius: 15px;
    cursor: pointer;
    -webkit-appearance: none;
    transition: transform $default-transition-settings;
  }
  input[type=range]:hover::-webkit-slider-thumb {
    transform: scale(1.1);
  }
  // input[type=range]:focus::-webkit-slider-runnable-track {
  //   background-color: #ffffff;
  // }
  input[type=range]::-moz-range-track {
    background-color: rgba(255, 255, 255, 0.9);
    // border: 1px solid #010101;
    border-radius: 10px;
    width: 100%;
    height: 14px;
    cursor: pointer;
  }
  input[type=range]::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background-color: $color-blue;
    border: 6px solid #ffffff;
    border-radius: 50%;
    cursor: pointer;
    transition: transform $default-transition-settings;
  }
  input[type=range]:hover::-moz-range-thumb {
    transform: scale(1.1);
  }
  input[type=range]::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 8px 0;
    color: transparent;
    width: 100%;
    height: 14px;
    cursor: pointer;
  }
  input[type=range]::-ms-fill-lower {
    background-color: $color-portage;
    border: none;
    // border: 1px solid #010101;
    border-radius: 20px;
  }
  input[type=range]::-ms-fill-upper {
    background-color: $color-portage;
    // border: 1px solid #010101;
    border: none;
    border-radius: 20px;
  }
  input[type=range]::-ms-thumb {
    width: 20px;
    height: 20px;
    background-color: $color-blue;
    border: 6px solid #ffffff;
    border-radius: 15px;
    cursor: pointer;
    transition: transform $default-transition-settings;
    margin-top: 0px;
    /*Needed to keep the Edge thumb centred*/
  }
  input[type=range]:hover::-ms-thumb {
    transform: scale(1.1);
  }
  input[type=range]:focus::-ms-fill-lower {
    // background-color: rgba(255, 255, 255, 0.9);
    background-color: $color-portage;
  }
  input[type=range]:focus::-ms-fill-upper {
    background-color: $color-portage;
  }
  /*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
  how to remove the virtical space around the range input in IE*/
  @supports (-ms-ime-align:auto) {
    /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
    input[type=range] {
      margin: 0;
      /*Edge starts the margin from the thumb, not the track as other browsers do*/
    }
  }
}

.range__subtitle {
  max-width: 250px;
  margin: 0 auto 22px;

  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
}

.range-value {
  width: max-content;
  min-width: 230px;
  margin: 25px auto 0;
  padding: 5px 15px;

  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  border: 2px solid $color-default-white;
  border-radius: 20px;
}
