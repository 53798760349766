.comparing-table {
  padding: 79px 0 80px;

  @include viewport--sm {
    display: none;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 69px;

    font-weight: 700;
    font-size: 42px;
    line-height: 48px;
    color: $color-torea-bay;
    text-align: center;

    @include viewport--md {

      font-size: 36px;
      line-height: 44px;
    }

    @include viewport--sm {

      font-size: 28px;
      line-height: 36px;
    }
  }

  table {
    width: 100%;

    border-spacing: 0;
  }

  tr {
    &:nth-child(even) {
      background-color: $color-ghost-white;

      @include viewport--sm {
        background-color: $color-transparent;
      }
    }

    @include viewport--sm {
      display: flex;
      flex-wrap: wrap;
      width: calc(100vw - 32px);
    }
  }

  th,
  td {
    height: 65px;
    padding: 15px 16px;

    &:not(:first-child) {
      width: 185px;

      @include viewport--md {
        width: 145px;
      }

      @include viewport--sm {
        width: 33.3333%;
      }
    }

    @include viewport--md {
      padding: 12px 20px;
    }

    @include viewport--sm {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      height: auto;
      min-height: 65px;
    }
  }

  th {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: $color-comet;

    @include viewport--md {
      font-size: 12px;
      line-height: 15px;
    }

    &:not(:first-child) {
      padding: 10px 14px;

      @include viewport--sm {
        display: none;
      }
    }

    &:first-child {
      position: relative;

      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      text-align: left;
      color: $color-blue;

      @include viewport--sm {
        width: 100%;
        order: 1;
      }

      //&::before {
      //  content: "";
      //  position: absolute;
      //  top: 50%;
      //  left: -2px;
      //
      //  width: 9px;
      //  height: 16px;
      //
      //  background: url("../img/svg/icon-blue-arrow.svg") no-repeat;
      //  transform: translateY(-50%);
      //}
    }
  }

  td {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    text-align: center;
    color: $color-ship-cove;

    &.blue {
      color: $color-blue;
    }

    @include viewport--md {
      font-size: 12px;
      line-height: 15px;
    }

    @include viewport--sm {
      justify-content: center;
      white-space: pre-wrap;
    }

    p {
      margin: 5px 0 10px;

      &:last-child {
        margin-bottom: 6px;
      }

      @include viewport--md {
        margin: 18px 0;
        width: 105%;

        &:last-child {
          margin-bottom: 17px;
        }
      }

      @include viewport--sm {
        margin: 6px 0 12px;

        &:last-child {
          margin-bottom: 4px;
        }
      }
    }

    b {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: $color-blue;
      width: 100%;

      & + span {
        display: block;

        font-size: 12px;
        line-height: 16px;
      }
    }

    &:nth-child(3) {
      border-right: 1px solid $color-quartz;
      border-left: 1px solid $color-quartz;
    }

    &:nth-child(4) {
      border-right: 1px solid $color-quartz;
    }

    &:not(:first-child) {
      @include viewport--sm {
        padding: 12px 8px;
      }
    }

    &:first-child {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      text-align: left;
      color: $color-black;

      border-radius: 10px 0 0 10px;

      @include viewport--md {
        font-size: 16px;
        line-height: 19px;
      }

      @include viewport--sm {
        width: 100%;
        background-color: $color-ghost-white;
        border-radius: 10px;
        justify-content: flex-start;
      }

      a {
        color: $color-blue;
        text-decoration: underline;
        transition: color $default-transition-settings;

        @media (hover: hover), screen and (min-width: 0\0) {
          &:hover,
          &:focus {
            color: $color-black;
          }
        }
      }
    }

    &:last-child {
      border-radius: 0 10px 10px 0;
      font-weight: 700;
      font-size: 16px;
    }

    &.cell-checked {
      background: url("../img/content/icon-ellipse-check.png") no-repeat;
      background-size: 30px 30px;
      background-position: center;
    }

    &.cell-nope {
      background: url("../img/content/nope.svg") no-repeat;
      background-size: 30px 30px;
      background-position: center;
    }

    &.cell-top {
      vertical-align: top;
      padding-top: 20px;

      @include viewport--md {
        padding-top: 30px;
      }

      @include viewport--sm {
        padding-top: 0;
      }
    }
  }

  &--heading {
    th {
      height: 68px;

      @include viewport--sm {
        height: auto;
        min-height: 68px;
      }

      &:not(:first-child) {
        @include viewport--sm {
          padding: 10px 6px;
          justify-content: center;
          display: flex;
        }
      }

      &:nth-child(2) {
        border: 1px solid $color-quartz;
        border-right: none;
        border-radius: 10px 0 0 10px;
      }

      &:nth-child(3) {
        border: 1px solid $color-quartz;
      }

      &:nth-child(4) {
        border: 1px solid $color-quartz;
        border-left: none;
      }

      &:nth-child(5) {
        border: 1px solid $color-quartz;
        border-left: none;
        border-radius: 0 10px 10px 0;
      }
    }
  }
}
