.promo-card {
  display: flex;
  flex-direction: column;
  width: 548px;
  max-width: 100%;
  min-width: 0;
  min-height: 439px;
  padding: 53px 66px;

  background-color: $color-torea-bay-4;
  border-radius: 12px;

  transition: transform $default-transition-settings;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      transform: translate(0, -5px);
    }
  }

  @include viewport--sm {
    align-items: center;
    width: 344px;
    min-height: 0;
    padding: 53px 38px;
  }

  &__title-tag-wrapper {
    display: flex;
    justify-content: space-between;

    @include viewport--sm {
      align-self: stretch;
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  &__title {
    margin: 0;

    font-weight: 700;
    font-size: 27px;
    line-height: 33px;
    color: $color-default-white;

    @include viewport--sm {
      text-align: center;
    }
  }

  &__tag {
    padding: 5px 16px;
    margin-right: -66px;
    margin-top: -4px;
    margin-bottom: -3px;
    margin-left: 10px;

    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    color: $color-default-white;
    font-family: $font-family--lato;

    background-color: rgba($color-periwinkle-4, 0.2);
    border: 0.6px solid $color-default-white;
    border-right: none;
    border-radius: 5px 0px 0px 5px;

    @include viewport--sm {
      align-self: flex-end;
      margin-top: -21px;
      margin-right: -38px;
      margin-bottom: 22px;
    }
  }

  &__text-wrapper {
    max-width: 408px;
    margin-top: 38px;
    margin-bottom: 38px;

    @include viewport--sm {
      max-width: 100%;
    }
  }

  &__text-item {
    margin-bottom: 24px;

    font-size: 16px;
    line-height: 24px;
    color: $color-titan-white;
    font-family: $font-family--lato;

    @include viewport--sm {
      text-align: center;
    }

    &:last-child {
      margin-bottom: 0;
    }

    span {
      font-weight: 700;
    }
  }

  &__list-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 38px;

    @include viewport--sm {
      flex-direction: column;
    }
  }

  &__list-column {
    &:first-child {
      max-width: 187px;

      @include viewport--sm {
        max-width: 100%;
      }
    }

    &:last-child {
      max-width: 210px;

      @include viewport--sm {
        max-width: 100%;
      }
    }
  }

  &__list-item {
    position: relative;

    padding-left: 20px;

    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    color: $color-default-white;

    &::before {
      content: "•";
      position: absolute;
      top: 0;
      left: 8px;
    }
  }

  &__contact-btn {
    align-self: flex-start;
    padding-left: 64px;
    padding-right: 64px;
    margin-top: auto;

    @include viewport--sm {
      align-self: center;
    }
  }

  &--digital-agencies {
    width: 559px;
    padding: 55px 52px 53px;

    background-color: $color-blue;
    background-image: url("../img/bg/promo-card-digital-agencies-bg.png");
    background-position: bottom right;
    background-size: 297px 251px;
    background-repeat: no-repeat;

    @include viewport--sm {
      width: 344px;
      padding: 53px 38px;

      background-image: none;
    }

    .promo-card {
      &__text-wrapper {
        max-width: 376px;
      }
    }
  }

  &--digital-agencies-wide {
    width: 100%;
    max-width: 1160px;
    min-height: 285px;
    padding: 48px 86px 32px;

    background-color: $color-blue;
    background-image: url("../img/bg/promo-card-digital-agencies-bg-wide.png");
    background-position: bottom right;
    background-size: 42%;
    background-repeat: no-repeat;

    @include viewport--lg {
      background-size: 35%;
    }


    @include viewport--sm {
      width: 344px;
      padding: 53px 38px;

      background-image: none;
    }

    .promo-card {
        &__text-wrapper {
        max-width: 100%;
        margin-top: 35px;
        margin-bottom: 35px;
      }

      &__text-item {
        margin-bottom: 0;
        color: $color-default-white;

        @include viewport--sm {
          margin-bottom: 24px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
