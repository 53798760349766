// Colors
//---------------------------------

// Default

$color-default-black:             #000000;
$color-default-white:             #ffffff;
$color-transparent:               rgba(0, 0, 0, 0);

// Base

$color-black:                     #27293a;
$color-orange:                    #fc5f20;
$color-blue:                      #4d6dd3;
$color-bright-blue:               #3d68ff;
$color-jacksons-purple:           #444c83;
$color-torea-bay:                 #323b75;
$color-east-bay:                  #49536f;
$color-ship-cove:                 #7f88a3;
$color-storm-grey:                #6b738a;
$color-waikawa-grey:              #637099;
$color-rock-blue:                 #97a0bb;
$color-echo-blue:                 #9eabc6;
$color-periwinkle:                #c6caeb;
$color-hawkes-blue:               #d1d7e3;
$color-quartz:                    #d8d8eb;
$color-soltitude:                 #e3e7f4;
$color-lavender:                  #e8ebf8;
$color-ghost-white:               #f7f8fc;
$color-governor-bay:              #5b6397;
$color-portage:                   #859ce0;
$color-link-water:                #cacedb;
$color-cerulean-blue:             #3555b4;
$color-comet:                     #5e657c;
$color-wild-blue-yonder:          #7d84b3;
$color-tropical-blue:             #b5c6e8;
$color-some-grey:                 #8C9BCB;
$color-new-grey:                  #5B637C;
$color-content-input-border:      rgba(191, 200, 212, 0.8);
$color-resource-input-border:     #dde4f2;
$color-content-input-placeholder: #bfc8d4;
$color-content-form-title:        #4F5979;
$color-bunting:                   #0D1632;
$color-melrose:                   #a0b7ff;
$color-malibu:                    #93adff;
$color-periwinkle-gray:           #cdd8ec;
$color-zircon:                    #fafbff;
$color-tuna:                      #343645;
$color-mischka:                   #d0d0df;
$color-manatee:                   #8188a1;
$color-lynch:                     #666f8f;
$color-fiord:                     #4c526d;
$color-dull-lavender:             #90A2E1;
$color-zumthor:                   #e7eeff;
$color-cornflower-blue:           #6a99ed;
$color-polo-blue:                 #8c9bcb;
$color-rose-white:                #fff8f7;
$color-scarlet:                   #eb2000;
$color-gun-powder:                #424457;
$color-titan-white:               #e1e7ff;
$color-french-gray:               #b8bbc5;
$color-rhino:                     #2d386c;
$color-crusta:                    #ff893d;
$color-scarpa-flow:               #52525f;
$color-silver:                    #c2c2c2;
$color-trout:                     #4f5060;
$color-mid-gray:                  #696a77;
$color-solitude:                  #ebf0ff;
$color-perano:                    #94afff;
$color-alice-blue:                #F6F9FF;
$color-midnight-express:          #03113C;
$color-port-gore:                 #414A68;
$color-deep-koamaru:              #33376B;
$color-resolution-blue:           #1A317B;
$color-lucky-point:               #1C245A;

// Дубли

$color-perano-2:                  #B3C5FD;
$color-perano-3:                  #ADC1FF;
$color-lavender-2:                #edeffa;
$color-lavender-3:                #E0E3FF;
$color-lavender-4:                #C8D4FF;
$color-echo-blue-2:               #a4aecb;
$color-royal-blue-2:              #4b6ace;
$color-royal-blue-3:              #4f70d0;
$color-royal-blue-4:              #4c70e1;
$color-royal-blue-5:              #5477E4;
$color-periwinkle-2:              #c8ccec;
$color-periwinkle-3:              #d2ddff;
$color-periwinkle-4:              #cdd9ff;
$color-torea-bay-2:               #374079;
$color-torea-bay-3:               #313976;
$color-torea-bay-4:               #353b7a;
$color-soltitude-2:               #e2e8ee;
$color-soltitude-3:               #eaebef;
$color-portage-2:                 #92a1ce;
$color-governor-bay-2:            #505a9d;
$color-rock-blue-2:               #95a2ca;
$color-east-bay-2:                #3d427f;
$color-comet-2:                   #5f6280;
$color-comet-3:                   #5b637c;
$color-comet-4:                    #66676E;
$color-manatee-2:                 #8487a0;
$color-periwinkle-gray-2:         #d3ddee;
$color-bunting-2:                 #0d1334;
$color-zircon-2:                  #f8fbff;
$color-zircon-3:                  #fbfcff;
$color-tuna-2:                    #32333b;
$color-melrose-2:                 #9eb5ff;
$color-cornflower-blue-2:         #7092FF;
$color-ghost-white-2:             #F7F9FF;
$color-midnight-express-2:        #131C45;
$color-midnight-express-3:        #141949;
$color-black-russian:             #040A1C;



// Shadow

$card-shadow:                       0 0 50px 5px rgba(39, 41, 58, 0.05);
$card-light-shadow:                 0 0 20px rgba(77, 109, 211, 0.1);
$card-tail-shadow:                  0 15px 30px rgba(47, 53, 116, 0.15);
$submenu-shadow:                    0 30px 40px rgba(30, 49, 108, 0.3);

// Gradient

$dark-to-blue:                      linear-gradient(157deg, rgb(32, 37, 89) 13.54%, rgb(70, 91, 155) 79.42%);
$almost-white:                      linear-gradient(180deg, rgb(247, 248, 252) 0%, #f7f8fc00 100%);
$mild-white:                        linear-gradient(270deg, rgb(255, 255, 255) 47.08%, rgb(248, 249, 251) 100%);

// Дубли

$almost-white-2:                    linear-gradient(180deg, rgb(244, 245, 250) 0%, #f4f5fa00 100%);
$almost-white-3:                    linear-gradient(180deg, #f7f8fc00 0%, rgb(247, 248, 252) 100%);

// Typography
//---------------------------------

$font-family--proxima:              "Proxima Nova", "Arial", sans-serif;
$font-family--roboto:               "Roboto Mono", "Arial", sans-serif;
$font-family--lato:                 "Lato", "Arial", sans-serif;
$font-family--inter:                "Inter", "Arial", sans-serif;

// Animation
//---------------------------------

$default-transition-settings:       0.3s ease;
$modal-transition-settings:         0.6s cubic-bezier(0.55, 0, 0.1, 1);

// Viewports
//---------------------------------

$viewport--sm:                      320px;
$viewport--md:                      768px;
$viewport--lg:                      1024px;
$viewport--1280:                    1280px;
$viewport--xl:                      1440px;
$viewport--xxl:                     1920px;

// Retina
//---------------------------------

$retina-dpi:                        144dpi;
$retina-dppx:                       1.5dppx;
