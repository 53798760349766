.page-card {
  position: relative;

  display: flex;
  width: 100%;
  max-width: 569px;
  padding: 51px 257px 50px 48px;

  background: $color-default-white;
  box-shadow: 0px 3.5px 18.5px rgba(66, 92, 176, 0.25);
  border-radius: 16px;

  overflow: hidden;
  transition: transform $default-transition-settings;

  @include viewport--sm {
    display: flex;
    flex-direction: column;
    max-width: 351px;
    padding: 43px 43px 0;

    border-radius: 18px;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      transform: translate(0, -5px);

      .page-card__link {
        &::after {
          transform: translateX(5px);
        }
      }
    }
  }

  &__text-block {
    display: flex;
    flex-direction: column;

    @include viewport--sm {
      margin-bottom: 29px;
    }
  }

  &__title {
    margin-bottom: 32px;

    font-weight: 700;
    font-size: 26px;
    line-height: 28px;
    letter-spacing: 0.01em;

    @include viewport--sm {
      margin-bottom: 29px;

      font-size: 22px;
      line-height: 25px;
      color: $color-blue;
    }
  }

  &__descr {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 32px;

    font-size: 18px;
    line-height: 24px;
    color: $color-comet;

    @include viewport--sm {
      font-size: 20px;
      line-height: 30px;
    }
  }

  &__link {
    // align-self: flex-start;
    display: flex;
    align-items: center;
    // height: 32px;
    margin-top: auto;

    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $color-blue;

    &::after {
      content: "";
      display: block;
      width: 7px;
      height: 12px;
      margin-left: 8px;

      background-image: url("/assets/img/svg/angle-blue-right.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

      transition: transform $default-transition-settings;
    }
  }

  &__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;

    width: auto;
    height: 100%;

    @include viewport--sm {
      display: none;
    }
  }

  &__img-mob {
    display: none;
    width: calc(100% + 86px);
    max-width: calc(100% + 86px);
    margin-left: -43px;
    margin-bottom: -1px;

    @include viewport--sm {
      display: block;
    }
  }

  &--optimisation {
    padding-bottom: 31px;

    @include viewport--sm {
      padding-bottom: 0;
    }

    .page-card {
      &__title {
        margin-bottom: 27px;

        @include viewport--sm {
          margin-bottom: 29px;
        }
      }

      &__descr {
        margin-bottom: 27px;

        font-size: 16px;
        line-height: 25px;
    
        @include viewport--sm {
          margin-bottom: 29px;

          font-size: 18px;
          line-height: 29px;
        }
      }

      &__link {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;

        @include viewport--sm {
          font-weight: 600;
        }
      }
    }
  }

  &--vertical {
    flex-direction: column;
    max-width: 370px;
    padding: 45px 29px 158px 48px;

    @include viewport--sm {
      max-width: 351px;
      padding: 43px 43px 125px 43px;
    }

    .page-card {
      &__text-block {
        flex-grow: 1;
      }

      &__title {
        line-height: 32px;
    
        @include viewport--sm {
          line-height: 25px;
        }
      }

      &__descr {
        font-size: 16px;
    
        @include viewport--sm {
          line-height: 24px;
        }
      }

      &__img {
        display: none;
      }

      &__img-mob {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -1px;

        display: block;
        // width: calc(100% + 77px);
        // max-width: calc(100% + 77px);
        // margin-left: -48px;
        width: 100%;
        max-width: 100%;
        margin-left: 0;
      }
    }
  }
}