.info-card {
  display: flex;
  flex-direction: column;
  max-width: 370px;
  overflow: hidden;

  text-decoration: none;

  border-radius: 10px;
  background-color: $color-default-white;
  box-shadow: $card-shadow;
  cursor: pointer;

  transition: transform $default-transition-settings;

  @include viewport--md {
    max-width: 470px;
    margin: 0 auto 47px;
  }

  @include viewport--sm {
    margin-bottom: 31px;

    min-width: 280px;
    max-width: 370px;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      transform: translate(0, -5px);
    }
  }

  &.swiper-slide {
    margin-bottom: 0;
    width: auto;
    flex-shrink: 1;

    @include viewport--md {
      flex-shrink: 0;
      height: auto;
      width: 470px;
      max-width: none;
      margin-right: 0;
      margin-bottom: 0;
    }
  }

  &--career {
    .info-card__title {
      margin: 0;

      font-size: 30px;
      line-height: 37px;
    }
  }
}

.info-card__title {
  margin: 0 0 30px;

  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  font-family: $font-family--lato;
}

.info-card__photo-wrapper {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 225px;
  overflow: hidden;

  @include viewport--md {
    width: 470px;
  }

  @include viewport--sm {
    width: auto;
    height: auto;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.info-card__text-wrapper {
  position: relative;

  display: flex;
  flex-grow: 1;
  flex-direction: column;
  min-height: 237px;
  padding: 40px 34px 30px;

  @include viewport--md {
    padding: 50px 34px 28px;
  }

  @include viewport--sm {
    padding: 38px 34px 29px;
  }
}

.info-card__category {
  position: absolute;
  top: 0;
  left: 50%;

  display: flex;
  justify-content: center;
  width: max-content;
  min-width: 131px;
  max-width: 90%;
  margin: 0;
  padding: 8px 20px;
  transform: translate(-50%, -50%);

  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: $color-waikawa-grey;
  text-align: center;

  background-color: $color-lavender;
  border-radius: 100px;
}


.info-card__location {
  position: relative;

  margin: 18px 0;
  padding-left: 25px;

  font-size: 18px;
  line-height: 22px;
  color: $color-ship-cove;

  @include viewport--sm {
    margin-top: 20px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    width: 16px;
    height: 24px;

    background-image: url("../img/svg/icon-pin.svg");
    background-repeat: no-repeat;
  }
}

.info-card__btn {
  display: flex;
  align-items: center;
  margin-top: auto;
  font-family: $font-family--lato;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: $color-blue;

  svg {
    margin-left: 14px;
  }
}
