.solutions-intro-alt {
  &__main-section {
    padding: 120px 0 75px;

    background: linear-gradient(93.88deg, #374079 1.75%, #4D6DD3 97.02%);

    @include viewport--md {
      background: linear-gradient(180deg, #38427D 0%, #4D6DD2 100%);
    }

    @include viewport--sm {
      padding: 84px 0 42px;
    }
  }

  &__main-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;

    @include viewport--md {
      flex-direction: column;
    }
  }

  &__main-left {
    max-width: 649px;
  }

  &__title {
    margin: 0;

    font-weight: 400;
    font-size: 48px;
    line-height: 58px;
    color: $color-default-white;

    @include viewport--sm {
      max-width: 306px;

      font-size: 28px;
      line-height: 36px;
      text-align: center;
    }

    span {
      display: inline-block;
      padding-left: 5px;
      padding-right: 5px;
      margin-left: -5px;
      margin-right: -5px;

      color: $color-default-white;

      background-color: rgba(77, 109, 211, 0.8);
      border-radius: 7px;

      @include viewport--sm {
        padding-left: 3px;
        padding-right: 3px;
        margin-left: -3px;
        margin-right: -3px;
      }
    }
  }

  &__say-card-mini {
    margin-top: 60px;

    @include viewport--sm {
      margin-top: 84px;
    }

    &--desk {
      @include viewport--md {
        display: none;
      }
    }

    &--mob {
      display: none;

      @include viewport--md {
        display: flex;
      }
    }
  }

  &__partners {
    margin-top: 60px;

    &--desk {
      @include viewport--md {
        display: none;
      }
    }
  }

  &__main-right {
    flex-shrink: 0;
    flex-grow: 1;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    margin-left: 30px;

    @include viewport--md {
      margin-left: 0;
      margin-top: 84px;
    }

    .content-form {
      margin-left: auto;
      margin-bottom: auto;

      @include viewport--md {
        margin-right: auto;
      }

      @include viewport--sm {
        width: 342px;
      }
    }
  }

  &__media-section {
    padding: 84px 0;

    background-image: url("../img/bg/solutions-alt-media-bg-desktop.svg");
    background-position: top -1px left;
    background-size: 100% 267px;
    background-repeat: no-repeat;

    @include viewport--sm {
      padding: 30px 0 45px;

      background-image: url("../img/bg/solutions-alt-media-bg-mob.svg");
      background-size: 100% 401px;
    }
  }

  &__media-wrapper {
    display: flex;
    justify-content: space-between;

    @include viewport--md {
      flex-direction: column;
      align-items: center;
    }
  }

  &__media-left {
    max-width: 554px;
    width: 49.9%;

    @include viewport--md {
      width: 100%;
    }

    @include viewport--sm {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__media-title {
    margin: 0;

    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    color: $color-default-white;

    @include viewport--sm {
      max-width: 305px;

      font-size: 24px;
      line-height: 48px;
      letter-spacing: 0.01em;
      text-align: center;
    }
  }

  &__media-subtitle {
    margin: 0;
    min-height: 64px;
    max-width: 535px;
    margin-top: 21px;

    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: $color-default-white;

    @include viewport--sm {
      max-width: 305px;
      margin-top: 20px;

      text-align: center;
    }
  }

  &__media-descr {
    margin-top: 57px;

    font-size: 20px;
    line-height: 30px;
    color: $color-comet;

    @include viewport--md {
      max-width: 554px;

      text-align: center;
    }

    @include viewport--sm {
      margin-top: 45px;
    }

    &--desktop {
      @include viewport--md {
        display: none;
      }
    }

    &--tablet-mob {
      display: none;

      @include viewport--md {
        display: block;
      }
    }
  }

  &__media-right {
    width: 50.1%;
    margin-left: 25px;

    @include viewport--md {
      width: 100%;
      max-width: 650px;
      margin-top: 55px;
      margin-left: 0;
    }

    @include viewport--sm {
      margin-top: 45px;
    }
  }

  &__video-wrapper {
    position: relative;
    z-index: 0;

    width: 100%;

    background: linear-gradient(108.91deg, #5483EC 4.74%, #5789FF 20.36%, #5381FF 66.97%, #3D60CC 80.35%, #3B51AD 99.59%);
    border-radius: 16px;
    box-shadow: -8px -8px 30px rgba(38, 52, 109, 0.3);

    overflow: hidden;

    // &::before {
    //   content: "";
    //   position: absolute;
    //   top: 8.36%;
    //   left: 5.3%;
    //   z-index: -1;

    //   width: 100%;
    //   height: 100%;

    //   background: rgba(230, 235, 249, 0.7);
    //   border-radius: 2px;
    // }

    &--desktop {
      @include viewport--md {
        display: none;
      }
    }

    &--tablet-mob {
      display: none;

      @include viewport--md {
        display: block;
      }
    }
  }

  &__video {
    position: relative;
    z-index: 0;
    display: block;
    width: 100%;
    padding-top: 56.3%;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 20px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
      cursor: pointer;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      border: 4px solid $color-blue;
      border-radius: 16px;
      pointer-events: none;

      @include viewport--md {
        border: 3px solid $color-blue;
      }
    }
  }

  &--financial-services,
  &--travel {
    .content-form__partners {
      align-self: center;
      max-width: 297px;
      margin-left: -7px;
      margin-right: 0;
    }

    .solutions-intro-alt {
      &__title {
        max-width: 580px;

        font-weight: 300;
        font-size: 43px;
        line-height: 51px;
        letter-spacing: -0.025em;
        font-style: italic;
    
        @include viewport--sm {
          max-width: 260px;

          font-weight: 400;
          font-size: 28px;
          line-height: 36px;
          text-align: center;
        }

        b {
          font-weight: 700;
          font-size: 46px;
          line-height: 54px;
          letter-spacing: normal;
          font-style: normal;

          @include viewport--sm {
            font-size: 28px;
            line-height: 36px;
          }
        }
    
        span {
          display: inline-block;
          padding-left: 5px;
          padding-right: 5px;
          margin-left: -5px;
          margin-right: -5px;
    
          color: $color-default-white;
    
          background-color: rgba(77, 109, 211, 0.8);
          border-radius: 7px;
    
          @include viewport--sm {
            padding-left: 3px;
            padding-right: 3px;
            margin-left: -3px;
            margin-right: -3px;
          }
        }
      }
    }
  }

  &--travel {
    .solutions-intro-alt {
      &__media-left {
        max-width: 530px;

        @include viewport--md {
          max-width: 554px;
        }
      }

      &__title {    
        @include viewport--sm {
          max-width: 366px;
        }
      }
    }
  }
}