.solutions-page {
    .trusted-brands__item:first-child {
        justify-content: flex-start;

        @include viewport--md {
            justify-content: center;
        }
    }

    .demo-sm-form {
        @include viewport--md {
            display: flex;
            justify-content: center;
        }
    }


    .action-cta {
        padding-top: 100px;
        padding-bottom: 100px;

        @include viewport--md {
            padding-top: 68px;
            padding-bottom: 68px;
        }

        @include viewport--sm {
            padding-top: 45px;
            padding-bottom: 45px;
        }

        h2 {
            color: $color-east-bay-2;
        }

        .demo-sm-form {
            form {
                @include viewport--sm {
                    flex-direction: column;
                }
            }

            input {
                @include viewport--sm {
                    width: 100%;
                    max-width: 100%;
                    margin-right: 0;
                    margin-bottom: 12px;
                }
            }

            .btn {
                @include viewport--sm {
                    width: 100%;
                }
            }
        }
    }

    &--b2b-and-saas {
        .trusted-brands {
            @include viewport--sm {
                display: none;
            }
        }
    }

    &--optimisation {
        .action-cta-alt__desktop {
            background: $color-default-white;
        }
    }
}