.new-pricing-page {
  background-color: #03113C;
  color: $color-default-white;

  padding-top: 72px;
  padding-bottom: 72px;

  @include viewport--md {
    padding-top: 56px;
  }

  &__main {
    display: grid;
    grid-template-columns: 1fr 480px;
    gap: 0 40px;
    align-items: flex-start;
    
    @include viewport--1280 {
      grid-template-columns: 1fr 420px;
    }

    @include viewport--md {
      grid-template-columns: 100%;
    }
  }

  &__inner {
    display: grid;
    grid-template-columns: 100%;
    gap: 72px;

    @include viewport--md {
      padding: 0 20px;
    }

    @include viewport--sm {
      padding: 0 16px;
    }
  }

  &__label {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: #a6bbff;
    margin: 0 0 24px;
    font-family: $font-family--inter;
    max-width: 580px;
    grid-column: 1;

    @include viewport--md {
      grid-column: 1;
      grid-row: 1;
      max-width: 100%;
    }
  }

  &__title {
    font-family: $font-family--inter;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;

    margin: 0 0 24px;
    max-width: 580px;
    grid-column: 1;

    @include viewport--md {
      grid-column: 1;
      grid-row: 2;
      max-width: 100%;
    }
  }

  &__description {
    font-family: $font-family--inter;
    grid-column: 1;
    color: #afb9d7;

    font-size: 16px;
    line-height: 24px;
    margin: 0 0 24px;
    max-width: 580px;

    @include viewport--md {
      grid-column: 1;
      grid-row: 3;
      max-width: 100%;
      margin-bottom: 56px;
    }
  }

  &__accordion {
    grid-column: 1;
    max-width: 580px;

    @include viewport--md {
      grid-column: 1;
      grid-row: 5;
      max-width: 100%;
    }
  }
  
  &__form {
    grid-row: 1 / span 5;
    grid-column: 2;

    @include viewport--md {
      grid-column: 1;
      grid-row: 4;
      
      margin-bottom: 40px;
    }
  }

  &__brands {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
    gap: 20px;

    @include viewport--md {
      margin: 0 auto;
      width: 100%;
      justify-content: center;
    }

    @include viewport--sm {
      width: auto;
      margin: 0;
    }
  }

  &__brands-item {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;

    img {
      max-width: 189px;
      height: auto;
      max-height: 43px;
      object-fit: contain;
    }
  }
}