.ai-intro {
  border-bottom: 1px solid $color-jacksons-purple;
  background-color: $color-torea-bay;

  padding: 80px 0 110px;

  @include viewport--lg {
    padding: 140px 0 120px;
  }

  @include viewport--md {
    padding: 59px 0 79px;
  }

  @include viewport--sm {
    padding: 40px 0 67px;
  }

  .container {
    position: relative;

    @include viewport--md {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;

    .btn {
      margin: 0 10px 10px 0;
    }

    .btn--bordered {
      color: #fff;
      border: 2px solid #fff;
    }

    @include viewport--sm {
      flex-direction: column;
    }
  }

  &__title {
    margin: 0 0 50px;
    font-size: 48px;
    line-height: 56px;
    color: $color-default-white;
    max-width: 582px;

    span {
      color: $color-orange;
    }

    @include viewport--lg {
      font-size: 42px;
      line-height: 48px;
      max-width: 430px;
    }

    @include viewport--md {
      font-size: 36px;
      line-height: 44px;
      text-align: center;
      margin-bottom: 24px;
    }

    @include viewport--sm {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 22px;
      max-width: 380px;
    }
  }

  &__description {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: $color-default-white;
    max-width: 522px;
    margin: 0 0 40px;

    @include viewport--md {
      max-width: 480px;
      text-align: center;
      margin: 0 0 18px;
    }

    @include viewport--sm {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      max-width: 380px;
    }
  }

  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 60px;
    max-width: 616px;

    @include viewport--lg {
      width: 50%;
      right: 2vw;
    }

    @include viewport--md {
      position: static;
      transform: none;
      width: 100%;
      margin-bottom: 14px;
    }
  }

  .img-fix {
    @include viewport--md {
      max-width: 616px;
      margin-bottom: 14px;
    }
  }

  .btn {
    @include viewport--md {
      padding: 25px 26px 27px;
      min-width: 268px;

      font-size: 20px;
      line-height: 24px;
    }
  }
}
