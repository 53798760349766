.optimal-marketing-plate {
  $root: &;

  padding-top: 50px;
  padding-bottom: 75px;

  @include viewport--sm {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .container {
    padding: 0 100px;

    @include viewport--lg {
      padding: 0 60px;
    }

    @include viewport--md {
      padding: 0 21px;
    }

    @include viewport--sm {
      padding: 0 10px;
    }
  }

  &__title {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    clip: rect(0 0 0 0);
  }

  &__wrapper {
    border-radius: 7px;
    border: 1px solid #d2ddff;
    background: linear-gradient(90deg, #172455 0.15%, #4568d0 97.03%);
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    @include viewport--md {
      flex-direction: column;
      border-radius: 7px;
      background: linear-gradient(107deg, #4a69cd 3.72%, #15275e 67.8%);
    }

    @include viewport--sm {
      border: none;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding-top: 78px;
    padding-left: 76px;
    padding-bottom: 65px;

    @include viewport--lg {
      padding-left: 40px;
    }

    @include viewport--md {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      padding-left: 8px;
      padding-top: 43px;
      padding-bottom: 46px;
    }

    @include viewport--sm {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: 49px;
      padding-left: 0;
    }
  }

  &__text {
    color: $color-default-white;
    font-family: $font-family--inter;
    font-size: 29px;
    font-style: normal;
    font-weight: 500;
    line-height: 110.8%; /* 32.132px */
    margin: 0;
    width: 100%;
    max-width: 524px;

    @include viewport--lg {
      max-width: 360px;
      padding: 0 10px;
    }

    @include viewport--md {
      max-width: unset;
      font-size: 24px;
      padding-right: 40px;
      margin-left: 20px;
    }

    @include viewport--sm {
      text-align: center;
      font-size: 24px;
      margin-left: 0;
      margin-right: 0;
      padding: 0 15px;
      width: auto;
    }

    & + p {
      margin-top: 10px;
    }
  }

  &__btn {
    padding: 16px 32px;
    margin-top: 50px;
    width: fit-content;
    height: fit-content;
    flex-shrink: 0;

    color: $color-default-white;
    text-align: center;
    font-family: $font-family--inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;

    @include viewport--md {
      margin-top: 0;
      margin-right: 25px;
      margin-left: 10px;
    }

    @include viewport--sm {
      min-width: 0;
      margin-top: 44px;
      margin-left: 25px;
    }
  }

  &__image-wrap {
    margin-top: auto;
    position: relative;
    overflow: hidden;
    width: 50%;
    height: min-content;
    margin-right: -1px;
    margin-bottom: -1px;

    @include viewport--lg {
      width: 60%;
    }

    @include viewport--md {
      margin-left: auto;
      width: 79.4%;
    }

    @include viewport--sm {
      width: 95%;
    }

    &::before {
      content: "";
      display: block;
      padding-bottom: calc(310 / 617 * 100%); /* H/W */

      @include viewport--md {
        padding-bottom: calc(371 / 576 * 100%); /* H/W */
      }

      @include viewport--sm {
        padding-bottom: calc(284 / 374 * 100%); /* H/W */
      }
    }
  }

  &__image-wrap img {
    clip-path: inset(1px 1px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &--marketing-mix-optimization {
    background-color: #f6f8ff;

    #{$root}__btn {
      border-radius: 3px;
      min-width: 220px;
    }

    #{$root}__text {
      @include viewport--lg {
        padding: 0;
        max-width: 524px;
      }

      @include viewport--1280 {
        max-width: 360px;
      }
    }
  }

  &--about {
    padding: 0;

    .container {
      padding: 112px 130px;

      @include viewport--lg {
        padding: 112px 60px;
      }

      @include viewport--md {
        padding: 0;
      }
    }

    .optimal-marketing-plate__wrapper {
      @include viewport--md {
        border-radius: 0;
      }
    }
  }

  &--ai-driven-attribution {
    // padding-top: 35px;
    // padding-bottom: 35px;
    // background: $color-zircon;

    .container {
      @include viewport--sm {
        padding-left: 0;
        padding-right: 0;
      }
    }

    #{$root}__wrapper {
      border-radius: 6px;
      border: none;

      @include viewport--sm {
        border-radius: 0;
      }
    }
  }

  &--solutions-page {
    .container {
      @include viewport--sm {
        padding-left: 0;
        padding-right: 0;
      }
    }

    #{$root}__wrapper {
      border-radius: 6px;
      border: none;

      @include viewport--sm {
        border-radius: 0;
      }
    }
  }

  &--blog {
    #{$root}__wrapper {
      max-width: 1066px;
      margin-left: auto;
      margin-right: auto;
    }

    #{$root}__image-wrap {
      width: 88%;

      @include viewport--lg {
        width: 60%;
      }

      @include viewport--md {
        margin-left: auto;
        width: 79.4%;
      }

      @include viewport--sm {
        width: 95%;
      }
    }
  }

  &--synthetic-conversions {
    padding-top: 55px;
    padding-bottom: 75px;
    background: $color-zircon;

    .container {
      padding: 0 105px;

      @include viewport--lg {
        padding: 0 60px;
      }

      @include viewport--md {
        padding: 0 21px;
      }

      @include viewport--sm {
        padding-left: 0;
        padding-right: 0;
      }
    }

    #{$root}__wrapper {
      border-radius: 6px;
      border: none;

      @include viewport--sm {
        border-radius: 0;
      }
    }
  }
}
