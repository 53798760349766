.plans-compare {
  width: 100%;

  &__title {
    font-size: 36px;
    line-height: 48px;
    font-weight: 700;
    color: $color-black;
    margin: 0 0 52px;
    text-align: center;

    @include viewport--md {
      margin-bottom: 42px;
    }

    @include viewport--sm {
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 24px;
    }
  }

  .comparing-table + .comparing-table {
    margin-top: 70px;

    @include viewport--md {
      margin-top: 45px;
    }
  }
}
