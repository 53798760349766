.thank-you {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 163px;
  padding-bottom: 278px;

  @include viewport--md {
    padding-top: 157px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__logo {
    margin-bottom: 43px;
  }

  &__title {
    margin-bottom: 14px;

    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    color: $color-black;

    @include viewport--md {
      margin-bottom: 10px;

      font-size: 34px;
      line-height: 41px;
    }
  }

  &__subtitle {
    margin-bottom: 64px;

    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: $color-black;

    @include viewport--md {
      margin-bottom: 54px;
    }
  }
}