.resource-form-card {
  width: 386px;
  max-width: 100%;

  background-color: $color-default-white;
  border-radius: 24px;
  box-shadow: 0 3.7px 27px rgba(46, 52, 118, 0.4);

  overflow: hidden;

  @include viewport--sm {
    width: 346px;
  }

  &__img {
    width: 100%;
    max-height: 244px;

    object-fit: cover;
  }

  &__form {
    display: flex;
    flex-direction: column;
    padding: 42px 29px 30px;

    @include viewport--sm {
      padding: 38px 26px 27px;
    }
  }

  &__item {
    margin-bottom: 10px;

    input {
      width: 100%;
      padding: 15px 23px;

      font-weight: 600;
      font-size: 16px;
      line-height: 19px;

      border: 1px solid $color-periwinkle-gray;
      border-radius: 4.5px;

      @include viewport--sm {
        padding: 14px 20px;

        border-radius: 4px;
      }

      &::placeholder {
        color: $color-rock-blue-2;
      }
    }
  }

  &__disclaimer {
    align-self: center;
    width: 255px;
    margin-top: 22px;

    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: $color-ship-cove;

    @include viewport--sm {
      width: 215px;

      font-size: 14px;
      line-height: 17px;
    }

    a {
      color: $color-cerulean-blue;
      text-decoration: underline;
  
      transition: color $default-transition-settings;
  
      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-black;
        }
      }
    }
  }
}