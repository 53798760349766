.blog-filter {
  max-width: 843px;
  margin: 0 auto;
  margin-bottom: 71px;

  @include viewport--md {
    max-width: 620px;
    margin-bottom: 54px;
  }

  @include viewport--sm {
    margin-bottom: 41px;
  }
}

.blog-filter__select-wrap {
  display: none;
  padding: 0 20px;
  box-sizing: content-box;
  margin: 0 auto;
  max-width: 532px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 17px;
    height: 9px;
    top: 50%;
    transform: translateY(-50%);
    right: 38px;
    background-image: url("../img/svg/icon-dropdown.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }

  @include viewport--sm {
    display: block;
  }
}

.blog-filter__select {
  // position: relative;
  width: 100%;
  padding: 18px 40px 18px 23px;

  font-size: 16px;
  color: $color-ship-cove;

  background-color: $color-lavender;
  border: none;
  border-radius: 5px;

  -webkit-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}


.blog-filter__list {
  position: relative;
  display: flex;
  margin: 0;
  padding: 0;

  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;

  @include viewport--sm {
    display: none;
  }

  &::after {
    content: "";

    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 4px;

    background-color: $color-lavender;
    border-radius: 10px;
  }
}

.blog-filter__item {
  flex-grow: 1;

  a {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    padding-top: 11px;
    padding-bottom: 16px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    font-family: $font-family--lato;

    justify-content: center;

    color: $color-portage;
    transition: color $default-transition-settings;

    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;

      display: none;
      width: 100%;
      height: 4px;

      background-color: $color-blue;
      border-radius: 10px;
    }


    @include viewport--md {
      font-size: 16px;
      line-height: 19px;
      padding-bottom: 18px;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-black;
      }
    }
  }

  &--active {
    a {
      cursor: auto;
      color: $color-blue;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-blue;
        }
      }
    }

    a::after {
      display: block;
    }
  }
}
