.rate {
  position: relative;
  width: 100%;
  text-align: center;

  &.monthly {
    .rate__discount {
      display: none;
    }
  }

  &--pricing {
    .rate__price-wrap {
      min-height: 111px;
    }
  }

  &.is-last {
    .rate__last {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.rate__toggles {
  width: max-content;
  margin: 0 auto 10px;
  display: flex;
  justify-content: center;
  border: 1px solid $color-hawkes-blue;
  border-radius: 17px;
  padding: 3px 5px;

  @include viewport--sm {
    margin-bottom: 8px;
  }

  label {
    font-size: 14px;
    line-height: 18px;
    padding: 4px 15px;
    border-radius: 13px;
    white-space: nowrap;
    color: $color-comet;
    font-weight: bold;
    cursor: pointer;
  }

  input:checked + label {
    background-color: $color-blue;
    color: $color-default-white;
  }
}

.rate__offer {
  margin-bottom: 25px;
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  color: $color-blue;
}

.rate__price-wrap {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 107px;
  max-width: 340px;
  padding: 10px 10px 12px;
  // border: 1px solid $color-hawkes-blue;
  border: 3px solid $color-lavender;
  // border-radius: 5px;
  border-radius: 10px;
  background-color: $color-default-white;
}

.rate__price-value {
  font-size: 48px;
  line-height: 58px;
  font-weight: bold;
  color: $color-blue;
}

.rate__price-period {
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
  color: $color-ship-cove;
}

.rate__discount {
  margin-top: 5px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: $color-orange;
}

.rate__last {
  padding: 85px 10px 105px;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  pointer-events: none;
  background-color: $color-lavender-2;
  transition: opacity $default-transition-settings;

  .rate__btn {
    margin-top: 38px;
    padding: 19px 23px;
    min-width: 236px;
  }
}

.rate__last-title {
  position: relative;
  margin: 0 auto;
  padding-bottom: 73px;
  font-size: 30px;
  line-height: 37px;
  font-weight: bold;
  color: $color-blue;
  max-width: 350px;

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 24px;
    height: 36px;
    transform: translateX(-50%);
    display: block;
    background-image: url("../img/svg/icon-arrow.svg");background-repeat: no-repeat;
    background-size: contain;
  }
}
