.agreement-text {
  h1 {
    font-size: 24px;
    text-align: center;
  }
  h2 {
    font-size: 20px;
    margin-top: 30px;
  }
  a {
    color: dodgerblue;
  }
  p {
    margin: 10px 0;
  }
  .section {
    margin-bottom: 20px;
  }
  .definition {
    font-weight: bold;
  }
  ul {
    margin: 10px 0 20px 20px;
  }
  .indent {
    text-indent: 20px;
  }
  .clause {
    margin-top: 20px;
  }
  .clause h2 {
    margin-bottom: 10px;
  }
  .clause p, .clause ul {
    margin-left: 20px;
  }
  .clause ul {
    list-style-type: disc;
  }

  //
  //ol {
  //  counter-reset: item
  //}
  //li {
  //  display: block
  //}
  //li:before {
  //  content: counters(item, ".") ". ";
  //  counter-increment: item;
  //  font-weight: bold;
  //}
  //
  //ol ol > li:before {
  //  font-weight: normal;
  //}
  //
  table {
    border-collapse: collapse;
    width: 100%;
  }
  th, td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  th {
    background-color: #f2f2f2;
  }
}

//.terms-text {
//  ol {
//    counter-reset: item
//  }
//  //li {
//  //  display: block
//  //}
//  //li:before {
//  //  content: counters(item, ".") ". ";
//  //  counter-increment: item;
//  //  font-weight: bold;
//  //}
//
//  ol ol > li:before {
//    font-weight: normal;
//  }
//}
