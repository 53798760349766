.slider-pricing {
  @include viewport--md {
    width: 100vw;
  }

  @include viewport--sm {
    width: 100%;
  }

  &__slider {
    overflow: visible;

    @include viewport--md {
      overflow: hidden;
      padding: 40px 0;
    }

    @include viewport--sm {
      overflow: visible;
      padding: 0;
    }
  }

  .swiper-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -46px;

    @include viewport--md {
      flex-wrap: nowrap;
    }

    @include viewport--sm {
      flex-direction: column;
    }
  }

  .pricing-card {
    width: calc(33.3333% - 20px);
    margin-right: 30px;
    margin-bottom: 46px;
    height: auto;

    &:nth-child(3n) {
      margin-right: 0;
    }

    @include viewport--md {
      width: 510px;
      max-width: none;
      margin-right: 0;
    }

    @include viewport--sm {
      width: 100%;
    }
  }

  .slider-arrows {
    display: none;

    @include viewport--md {
      display: flex;
      margin-top: 30px;
    }

    @include viewport--sm {
      display: none;
    }
  }
}
