.team {
  position: relative;

  padding: 109px 0 120px;
  max-width: 1200px;
  margin: 0 auto;

  @include viewport--md {
    padding: 78px 0 90px;
  }

  @include viewport--sm {
    padding: 30px 0 55px;
  }
}

.team__title {
  margin: 0 0 55px;

  font-weight: bold;
  font-size: 36px;
  line-height: 48px;

  // @include viewport--sm {
  //   margin-bottom: 71px;

  //   font-size: 47px;
  //   line-height: 63px;
  // }

  // @include viewport--sm {
  //   margin-bottom: 25px;
  //   margin: 17px auto 25px;
  //   max-width: 500px;
  // }

  @include viewport--sm {
    margin-bottom: 25px;
    margin: 0 auto 25px;
    max-width: 500px;
  }
}

.team__values {
  display: flex;
  justify-content: space-between;

  @include viewport--sm {
    display: block;
    max-width: 500px;
    margin: 0 auto;
  }

  &--top {
    margin-bottom: 98px;

    @include viewport--md {
      margin-bottom: 49px;
    }

    @include viewport--sm {
      margin-bottom: 30px;
    }

    .team__values-picture {
      margin-right: 40px;
    }

    .team__values-text {
      margin-right: 127px;

      @media (max-width: 1199px) {
        margin-right: 0;
      }
    }
  }

  &--bottom {
    .team__values-text {
      margin-left: 48px;

      @media (max-width: 1199px) {
        margin-left: 0;
      }
    }

    .team__values-picture {
      margin-left: 40px;
    }
  }
}

.team__values-picture {
  display: flex;
  align-items: center;

  @include viewport--sm {
    display: none;
  }
}

.team__values-text {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.team__item {
  width: 100%;
  max-width: 310px;

  @include viewport--sm {
    max-width: none;
  }

  // &:nth-child(2n + 1) {
  //   margin-right: 70px;

  //   @include viewport--md {
  //     margin-right: 20px;
  //   }
  // }

  &:last-child {
    p:last-child {
      margin-bottom: 0;
    }
  }

  &--little {
    max-width: 280px;

    @include viewport--md {
      max-width: 310px;
    }

    @include viewport--sm {
      max-width: none;
    }
  }

  &--wide {
    max-width: 380px;

    @include viewport--sm {
      max-width: none;
    }
  }

  &--smaller-paragraph {
    p {
      max-width: 293px;

      @include viewport--sm {
        max-width: none;
      }
    }
  }

  h3 {
    margin: 0 0 10px;

    font-weight: bold;
    font-size: 26px;
    line-height: 34px;

    @include viewport--sm {
      font-size: 24px;
      line-height: 32px;
    }

    @include viewport--sm {
      margin-bottom: 12px;
    }
  }

  p {
    margin: 0 0 49px;

    font-size: 18px;
    line-height: 26px;
    color: $color-ship-cove;

    @include viewport--sm {
      font-size: 16px;
      line-height: 24px;

      margin-bottom: 30px;
    }
  }
}

.team__values-picture-mob {
  display: none;

  @include viewport--sm {
    display: block;
    margin: 62px auto 0;
  }

  img {
    margin: 0 auto;
  }
}

.team__decor {
  margin-top: -160px;
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @include viewport--md {
    padding-top: 30px;
    margin-top: 0;
    flex-direction: row-reverse;
  }

  @include viewport--sm {
    padding-top: 5px;
  }
}

.team__decor-top {
  position: absolute;
  top: 86px;
  right: 0;

  width: 425px;
  height: 268px;
  overflow: hidden;

  background-color: $color-torea-bay;
  border-radius: 100px 134px 134px 0;

  @media (max-width: 1300px) {
    width: 376px;
    height: 232px;

    border-radius: 100px 116px 116px 0;
  }

  @include viewport--md {
    position: relative;
    top: 0;
  }

  &::after {
    content: "";

    display: block;
    width: 294px;
    height: 268px;

    background-image: url("../img/content/team-top.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media (max-width: 1300px) {
      width: 232px;
      height: 232px;
    }
  }
}

.team__decor-left {
  width: 126px;
  height: 126px;
  border-radius: 50%;
  background-color: $color-torea-bay;
  margin-right: auto;

  @include viewport--md {
    display: none;
  }
}

.team__decor-bottom {
  width: 290px;
  height: 197px;
  border-radius: 98px 0 0 98px;
  background-image: url("../img/content/team-bottom.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @include viewport--sm {
    display: none;
  }
}

.team__decor-right {
  position: relative;
  margin-left: 104px;
  width: 369px;
  height: 308px;

  @include viewport--md {
    display: none;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 154px;
    height: 154px;
    background-color: $color-blue;
    border-radius: 50% 50% 0 50%;
  }

  &::after {
    content: '';
    position: absolute;
    left: 161px;
    bottom: 0;
    width: 88px;
    height: 88px;
    background-color: $color-ghost-white;
    border-radius: 50% 50% 50% 0;
  }
}

.team__decor-photo {
  margin-left: auto;
  width: 215px;
  height: 215px;

  background-image: url("../img/content/team-right.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 50% 50% 50% 0;
}
