.free-try {
  padding: 90px 0 100px;
  text-align: center;

  @include viewport--md {
    padding: 80px 0 110px;
  }

  @include viewport--sm {
    padding: 55px 0;
  }
}

.free-try__title {
  margin: 0 0 32px;
  font-size: 36px;
  line-height: 48px;

  @include viewport--md {
    margin-bottom: 35px;
  }

  @include viewport--sm {
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 34px;
  }
}

.free-try__description {
  margin: 0 0 40px;
  font-size: 18px;
  line-height: 26px;
  color: $color-comet;

  @include viewport--md {
    margin-bottom: 50px;
  }

  @include viewport--sm {
    max-width: 300px;
    margin: 0 auto 35px;
    font-size: 16px;
    line-height: 24px;;
  }

  span {
    display: block;
    font-weight: bold;
  }
}

.free-try__btn {
  @include viewport--sm {
    width: 100%;
    max-width: 330px;
  }
}
