.visit-scoring-attribution {
  $root: &;
  padding: 100px 0 90px;

  @include viewport--md {
    padding: 60px 0;
  }

  @include viewport--sm {
    padding: 40px 0;
  }

  &__title {
    color: $color-deep-koamaru;
    text-align: center;
    font-family: $font-family--inter;
    font-size: 36px;
    line-height: 1.2;
    font-weight: 700;
    margin: 0 auto 48px;
    max-width: 600px;

    @include viewport--md {
      font-size: 30px;
    }
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 45px;

    @include viewport--md {
      grid-template-columns: repeat(2, 1fr);
    }

    @include viewport--sm {
      grid-template-columns: 100%;
      align-items: center;
    }
  }

  &--synthetic-conversions {
    padding-top: 30px;

    #{$root}__title {
      margin-bottom: 75px;
    }
  }
}

.visit-scoring-attribution-card {
  $root: &;

  padding: 35px;
  color: $color-deep-koamaru;

  @include viewport--1280 {
    padding: 0;
  }

  &__img-wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 17px;
    min-height: 180px;

    @include viewport--md {
      min-height: auto;

      img {
        margin: 0 auto;
      }
    }
  }

  &__title {
    font-size: 20px;
    line-height: 24px;
    font-family: $font-family--inter;
    margin: 0 0 17px;
    font-weight: 700;
  }

  &__text {
    font-family: $font-family--lato;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin: 0;
  }

  &__text-wrap {
    display: grid;
    grid-template-columns: 100%;
    gap: 25px;
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__list {
    font-size: 18px;
    line-height: 25px;
    font-family: $font-family--lato;

    padding-left: 30px;
    margin: 0;
  }

  &--synthetic-conversions {
    #{$root}__title {
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 27px;

      @include viewport--md {
        font-size: 20px;
        line-height: 24px;
      }
    }

    #{$root}__img-wrap {
      margin-bottom: 27px;

      min-height: 179px;
    }

    #{$root}__text {
      font-size: 18px;
      line-height: 25px;
      max-width: 295px;

      @include viewport--md {
        max-width: 100%;
      }
    }
  }
}
