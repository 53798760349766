.team-benefits {
  padding: 30px 0 84px;

  @include viewport--md {
    padding: 30px 0 70px;
  }

  @include viewport--sm {
    padding: 30px 0;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    margin: 0 0 30px;
    text-align: center;

    font-size: 36px;
    line-height: 48px;
    color: $color-black;

    @include viewport--sm {
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 16px;
    }
  }

  &__description {
    margin: 0 0 18px;
    max-width: 570px;
    text-align: center;

    font-size: 18px;
    line-height: 26px;
    color: $color-ship-cove;

    span {
      font-weight: 600;
    }

    @include viewport--md {
      margin-bottom: 12px;
    }

    @include viewport--sm {
      max-width: 400px;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
