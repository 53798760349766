.lead-scoring-cards {
  padding-top: 100px;
  padding-bottom: 96px;

  @include viewport--xl {
    padding-top: 25px;
  }

  @include viewport--md {
    padding-top: 20px;
    padding-bottom: 46px;
  }

  @include viewport--sm {
    padding-top: 57px;
  }

  &__container {
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 64px 0;

    @include viewport--md {
      padding: 0 24px;
    }

    @include viewport--sm {
      padding: 0 16px;
    }
  }

  &__container {
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 64px 0;

    @include viewport--xl {
      max-width: 1280px;
      padding: 0 40px 0;
    }

    @include viewport--lg {
      max-width: 1280px;
      padding-left: 40px;
      padding-right: 40px;
    }

    @include viewport--md {
      padding-left: 24px;
      padding-right: 24px;
    }

    @include viewport--sm {
      padding: 0 16px;
    }
  }

  &__title {
    font-family: $font-family--inter;
    font-weight: 600;
    font-size: 48px;
    padding: 64px 16px;
    margin: 0 auto 100px;
    line-height: 64px;
    letter-spacing: -0.02em;
    text-align: center;
    color: #33376b;
    width: 100%;
    max-width: 730px;

    @include viewport--xl {
      margin: 0 auto 57px;
      padding: 64px 16px 60px;
      font-size: 36px;
      line-height: 48px;
      max-width: 520px;
    }

    @include viewport--md {
      padding: 0 16px 22px;
      font-size: 32px;
      line-height: 40px;
      max-width: 720px;
      margin: 0 auto 67px;
    }

    @include viewport--sm {
      margin: 0 auto 65px;
      padding: 0;
    }

    span {
      background: linear-gradient(90deg, #4d6dd3 0%, #5f98ff 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 64px;

    @include viewport--xl {
      gap: 38px;
    }

    @include viewport--md {
      gap: 35px;
    }

    @include viewport--sm {
      gap: 23px;
    }
  }

  &__item {
    padding-left: 36px;
    padding-right: 36px;

    @include viewport--xl {
      padding-left: 75px;
      padding-right: 75px;
    }

    @include viewport--md {
      padding-left: 0;
      padding-right: 0;
    }

    &:first-child {
      .lead-scoring-cards__card-image-wrap {
        padding: 10px 0 0;

        @include viewport--xl {
          margin-right: -15px;
        }

        @include viewport--md {
          padding: 14px 0 0;
          margin-right: -12px;
        }

        @include viewport--sm {
          flex: 0 1 100%;
          padding: 20px 0 0;
          margin: 0 -28px 0 -17px;
        }
      }
    }

    &:nth-child(even) {
      .lead-scoring-cards__card-image-wrap {
        order: 1;
        flex: 0 1 688px;

        @include viewport--md {
          flex: 0 1 48.6%;
        }

        @include viewport--sm {
          flex: 0 1 100%;
          margin: 0 -16px;
        }
      }

      .lead-scoring-cards__card {
        @include viewport--xl {
          display: grid;
          grid-template-columns: 40.67% 49.14%;
          gap: 0;
        }

        @include viewport--md {
          grid-template-columns: 48.00% 49.00%;
        }

        @include viewport--sm {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  &__card {
    display: flex;
    justify-content: space-between;
    gap: 50px;

    @include viewport--xl {
      display: grid;
      grid-template-columns: 49.14%	40.67%;
      gap: 0;
    }

    @include viewport--md {
      grid-template-columns: 49.00% 48.00%;
      gap: 0;
    }

    @include viewport--sm {
      display: flex;
      flex-direction: column;
    }
  }

  &__card-image-wrap {
    flex: 0 1 710px;
    padding: 16px 0;

    @include viewport--md {
      flex: 0 1 48.6%;
    }

    @include viewport--sm {
      flex: 0 1 100%;
      margin: 0 -16px;
      order: 1;
    }
  }

  &__card-image {
    img {
      @include viewport--sm {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &__card-text-wrap {
    flex: 0 1 569px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include viewport--xl {
      flex: 0 1 502px;
    }

    @include viewport--md {
      flex: 0 1 48.7%;
    }
  }

  &__card-title {
    font-family: $font-family--inter;
    font-weight: 600;
    font-size: 48px;
    margin: 0 auto 20px;
    line-height: 64px;
    letter-spacing: -0.02em;
    color: #33376b;

    @include viewport--xl {
      font-size: 36px;
      line-height: 48px;
      margin: 0 0 15px;
    }

    @include viewport--md {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 12px;
    }

    @include viewport--sm {
      margin-bottom: 8px;
    }

    span {
      background: linear-gradient(90deg, #4d6dd3 0%, #5f98ff 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &__card-text {
    font-family: $font-family--inter;
    font-weight: 400;
    font-size: 22px;
    line-height: 36px;
    color: #414a68;
    margin: 0;

    @include viewport--xl {
      font-size: 18px;
      line-height: 30px;
    }

    @include viewport--md {
      font-size: 16px;
      line-height: 26px;
    }

    @include viewport--sm {
      font-size: 18px;
      line-height: 28px;
    }

    & .lead-scoring-cards__text {
      margin-top: 16px;
    }
  }
}
