.pricing {
  padding: 100px 0 65px;

  @include viewport--md {
    padding: 65px 0 100px;
  }

  @include viewport--sm {
    padding: 37px 0 50px;
  }

  &--bigquery {
    .pricing__range {
      padding-bottom: 40px;
    }

    .range__subtitle {
      max-width: none;
      margin-bottom: 40px;
    }

    .range-value {
      margin-top: 30px;
    }
  }
}

.pricing__container {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;

  @include viewport--md {
    display: block;
  }
}

.pricing__info {
  width: 100%;
  max-width: 500px;
  margin-right: 30px;
  padding-top: 10px;

  @include viewport--md {
    margin: 0 auto 50px;

    text-align: center;
  }

  @include viewport--sm {
    margin-bottom: 35px;
  }

  img {
    display: block;
    width: 62px;
    height: 62px;

    @include viewport--md {
      margin: 0 auto;
    }
  }

  h2 {
    margin: 52px 0 30px;

    font-size: 36px;
    line-height: 48px;

    @include viewport--sm {
      margin-top: 42px;
      margin-bottom: 45px;
      padding: 0 25px;

      font-size: 24px;
      line-height: 32px;
    }
  }

  p {
    max-width: 440px;
    margin: 0 0 42px;

    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: $color-ship-cove;

    @include viewport--md {
      margin: 0 auto 42px;
    }

    @include viewport--sm {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }
  }

  h3 {
    margin: 0 0 20px;

    font-size: 20px;
    line-height: 26px;

    @include viewport--md {
      text-align: left;
    }
  }

  a {
    font-weight: 600;
    font-size: 18px;
    line-height: 40px;
    color: $color-blue;
    text-decoration: underline;

    transition: color $default-transition-settings;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-black;
      }
    }
  }
}

.pricing__list-wrap {
  @include viewport--md {
    width: max-content;
    margin: 0 auto;
  }
}

.pricing__list {
  margin: 0 0 27px;
  padding: 0;

  list-style: none;
  text-align: left;

  @include viewport--md {
    margin-bottom: 22px;
  }

  @include viewport--sm {
    margin-bottom: 25px;
  }
}

.pricing__item {
  position: relative;

  margin-bottom: 13px;
  padding-left: 23px;

  font-size: 18px;
  line-height: 26px;
  color: $color-ship-cove;

  @include viewport--sm {
    margin-bottom: 15px;
    padding-left: 25px;

    font-size: 16px;
    line-height: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 9px;
    left: 0;

    display: block;
    width: 13px;
    height: 9px;

    background-image: url("../img/svg/icon-tick.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.pricing__wrap {
  width: 100%;
  max-width: 570px;
  overflow: hidden;

  border-radius: 15px;
  box-shadow: 0 0 20px rgba(77, 109, 211, 0.15);

  @include viewport--md {
    margin: 0 auto;
  }
}

.pricing__range {
  padding: 47px 50px 34px;

  color: $color-default-white;

  background-color: $color-blue;

  @include viewport--sm {
    padding-right: 25px;
    padding-left: 25px;
  }
}

.pricing__rate {
  padding: 32px 50px 48px;

  @include viewport--sm {
    padding-right: 25px;
    padding-left: 25px;
  }

  .rate__price-wrap {
    max-width: 340px;
  }

  &.is-last {
    .pricing__last {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.pricing__btn {
  margin-top: 30px;
  margin-bottom: 18px;
  padding: 19px 23px;
  min-width: 236px;

  font-size: 18px;
  line-height: 22px;
}

.pricing__additional {
  font-size: 14px;
  line-height: 20px;
  color: $color-ship-cove;
}

.dynamic-currency {
  display: none;
}
