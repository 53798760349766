.brands-section {
  padding: 100px 0;

  background: linear-gradient(180deg, #FFFFFF 0%, #F0F4FF 100%);

  @include viewport--md {
    padding: 75px 0;
  }

  @include viewport--sm {
    padding: 45px 0;

    background: linear-gradient(180deg, #FFFFFF 0%, #F8FBFF 100%);
  }

  &__title {
    margin: 0;

    max-width: 735px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;

    font-weight: 700;
    font-size: 36px;
    line-height: 114.3%;
    text-align: center;
    color: $color-torea-bay-4;

    @include viewport--sm {
      font-size: 24px;
      line-height: 29px;
    }

    @include viewport--sm {
      margin-bottom: 45px;
    }
  }

  &__logos-desktop {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: -60px;
    margin-left: -114px;

    @include viewport--sm {
      display: none;
    }
  }

  &__logo {
    margin-top: 60px;
    margin-left: 114px;

    @include viewport--sm {
      min-width: 0;
      margin-top: 0;
      margin-left: 20px;
    }
  }

  &__logos-mob {
    display: none;
    max-width: 350px;
    margin-top: 45px;
    margin-left: auto;
    margin-right: auto;

    @include viewport--sm {
      display: block;
    }
  }

  &__logos-line {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -20px;
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__say-cards {
    display: flex;
    max-width: 1014px;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;

    @include viewport--md {
      flex-direction: column;
      align-items: center;
    }

    @include viewport--sm {
      margin-top: 45px;
    }
  }

  &__say-card {
    margin-right: 14px;

    @include viewport--md {
      margin-right: 0;
      margin-bottom: 32px;
    }

    &:last-child {
      margin-right: 0;

      @include viewport--md {
        margin-bottom: 0;
      }
    }

    &--grows {
      flex-grow: 1;
    }
  }

  &__resource-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;

    @include viewport--sm {
      margin-top: 45px;
    }
  }

  &__resource-card {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__btn-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 100px;

    @include viewport--sm {
      display: none;
    }
  }
}