.plan-features {
  padding: 35px 0 70px;

  @include viewport--md {
    padding: 30px 0;
  }

  &__title {
    font-size: 36px;
    line-height: 48px;
    color: $color-black;
    margin: 0 0 50px;
    font-weight: 700;
    text-align: center;

    @include viewport--md {
      font-size: 30px;
      line-height: 42px;
      margin-bottom: 66px;
    }

    @include viewport--sm {
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 54px;
    }
  }

  &__list {
    margin: 0 0 -40px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    margin-right: 40px;
    margin-bottom: 40px;
    padding: 46px 50px 43px;
    width: calc(50% - 20px);
    border-radius: 10px;
    border: 1px solid $color-lavender;
    min-height: 191px;

    &:nth-child(even) {
      margin-right: 0;
    }

    @include viewport--md {
      width: 100%;
      margin-right: 0;
    }

    @include viewport--sm {
      padding: 46px 35px 43px;
      min-height: 247px;
    }
  }

  &__subtitle {
    font-size: 26px;
    line-height: 34px;
    color: $color-blue;
    margin: 0 0 14px;

    @include viewport--md {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 20px;
    }
  }

  &__description {
    margin: 0;
    font-size: 18px;
    line-height: 26px;
    color: $color-black;

    @include viewport--md {
      line-height: 24px;
    }

    a {
      color: $color-blue;
      transition: color $default-transition-settings;
      text-decoration: underline;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-black;
        }
      }
    }
  }
}
