.solution-plate {
  background: linear-gradient(266deg, #1f3477 13.56%, #0f1536 91.43%);
  padding: 75px 0;

  @include viewport--sm {
    padding: 40px 0;
  }

  .container {
    @include viewport--sm {
      padding: 0 30px;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include viewport--md {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__left {
    width: 100%;
    max-width: 462px;
  }

  &__title {
    color: $color-default-white;
    font-family: $font-family--inter;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0 0 34px;

    @include viewport--sm {
      font-size: 24px;
      text-align: center;
    }
  }

  &__description {
    color: $color-lavender-4;
    font-family: $font-family--lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30.2px;
    margin: 0 0 34px;

    @include viewport--sm {
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 30px;
      text-align: center;
    }
  }

  &__partners-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;

    @include viewport--sm {
      gap: 2px;
      margin-bottom: 30px;
      justify-content: center;
    }
  }

  &__partner-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    flex-shrink: 0;

    img {
      width: auto;
      max-height: 44px;

      @include viewport--sm {
        max-height: 37px;
      }
    }

    &--gmp {
      img {
        max-height: 56px;

        @include viewport--sm {
          max-height: 46px;
        }
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
  }

  &__rating-wrap {
    padding-top: 17px;
    display: flex;
    gap: 11px;
    align-items: center;
    margin-bottom: 10px;

    @include viewport--sm {
      order: 1;
      padding-top: 0;
      margin-bottom: 7px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__rating-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    img {
      width: 49px;
      height: 49px;
    }
  }

  &__rating-stars {
    color: #ff492c;
    display: flex;
    justify-content: center;
    align-items: baseline;
    height: 15px;

    svg {
      height: auto;
      width: 79px;
    }
  }

  &__rating {
    color: $color-default-white;
    font-family: $font-family--proxima;
    font-size: 13.8px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    margin: 0;
  }

  &__slogan-wrap {
    display: grid;
    grid-template-columns: min-content 1fr;
    gap: 20px;
    width: 100%;
    max-width: 575px;
    height: fit-content;

    @include viewport--md {
      width: 100%;
      margin-left: -37px;
      max-width: 462px;
    }

    @include viewport--sm {
      display: flex;
      flex-direction: column;
      margin-left: 0;
    }
  }

  &__slogan-img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 161px;

    @include viewport--sm {
      display: none;
    }

    img {
      width: 161px;
      height: 206px;
    }
  }

  &__slogan-text {
    color: $color-default-white;
    font-family: $font-family--lato;
    font-size: 27px;
    font-style: normal;
    font-weight: 700;
    line-height: 37px;
    margin: 0;

    @include viewport--sm {
      font-size: 23px;
      line-height: 37px;
      margin-bottom: 8px;
      text-align: center;
    }

    & + p {
      margin-top: 7px;
    }
  }
}
