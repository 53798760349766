.text-blocks {
  padding-top: 100px;
  padding-bottom: 100px;

  background-color: $color-blue;

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -75px;
    margin-top: -75px;

    @include viewport--sm {
      margin-left: -38px;
      margin-top: -38px;
    }
  }

  &__item {
    position: relative;

    width: 100%;
    max-width: 348px;
    padding: 4px 0 5px 27px;
    margin-left: 75px;
    margin-top: 75px;

    @include viewport--sm {
      margin-left: 38px;
      margin-top: 38px;
    }

    &::before {
      content: "";
      position: absolute;
      top: -2px;
      left: -2px;
      bottom: -2px;

      width: 4px;

      background-color: $color-cornflower-blue;
      border-radius: 2px;
      transition: background-color $default-transition-settings;
    }

    &:hover {
      &::before {
        background-color: $color-torea-bay-4;
      }
    }
  }

  &__item-text {
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: $color-default-white;
  }
}