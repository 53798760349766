.btn {
  padding: 16px 32px 16px;
  display: inline-block;
  vertical-align: top;

  font-family: $font-family--inter;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: $color-default-white;

  background-color: $color-blue;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background $default-transition-settings, color $default-transition-settings, border $default-transition-settings, opacity $default-transition-settings;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover:not(.focus-visible),
    &.focus-visible:focus {
      background-color: $color-cerulean-blue;
    }

    &:active:not(.focus-visible) {
      background-color: $color-portage;
    }
  }

  &--bordered {
    padding: 15px 26px 16px;

    color: $color-blue;

    background-color: $color-transparent;
    border: 2px solid $color-blue;

    transition: color $default-transition-settings, border-color $default-transition-settings;

    &.btn--sm {
      min-width: 120px;
      padding: 12px 19px 13px;

      border-width: 1px;

      @media (min-width: 1024px) and (max-width: 1279px) {
        min-width: 50px;
      }
    }

    &.btn--xl {
      padding: 24px 26px;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover:not(.focus-visible),
      &.focus-visible:focus {
        color: $color-portage;
        border-color: $color-portage;
        background-color: $color-transparent;
      }

      &:active:not(.focus-visible) {
        color: $color-bright-blue;
        border-color: $color-bright-blue;
      }
    }
  }

  &--bordered-white {
    color: $color-default-white;
    border-color: $color-default-white;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover:not(.focus-visible),
      &.focus-visible:focus {
        color: rgba($color-default-white, 0.6);
        border-color: rgba($color-default-white, 0.6);
      }

      &:active:not(.focus-visible) {
        color: rgba($color-default-white, 0.3);
        border-color: rgba($color-default-white, 0.3);
      }
    }
  }

  &--filter {
    display: flex;
    align-items: center;
    padding: 19px 18px 18px;
    background-color: $color-governor-bay;
    font-size: 18px;
    line-height: 22px;
    min-width: 185px;
    border-radius: 10px;

    &--active {
      background-color: rgba($color-blue, 0.6);
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover:not(.focus-visible),
      &.focus-visible:focus {
        background-color: $color-blue;
      }

      &:active:not(.hack-selector) {
        background-color: rgba($color-blue, 0.6);
        color: rgba($color-default-white, 0.6);
      }
    }
  }

  &--white {
    color: $color-torea-bay-4;

    background-color: $color-default-white;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover:not(.focus-visible),
      &.focus-visible:focus,
      &:active:not(.focus-visible) {
        background-color: $color-default-white;
        opacity: 0.7;
      }
    }
  }

  &--white-transparent {
    background-color: rgba($color-default-white, 0.1);
    border: 1px solid $color-default-white;
    padding: 16px 25px 17px;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover:not(.focus-visible),
      &.focus-visible:focus,
      &:active:not(.focus-visible) {
        background-color: rgba($color-default-white, 0.25);
      }
    }
  }

  &--red {
    color: $color-default-white;

    background-color: #ff492c;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover:not(.focus-visible),
      &.focus-visible:focus {
        background-color: #ff492c;
        opacity: 0.7;
      }

      &:active:not(.focus-visible) {
        background-color: #ff492c;
        opacity: 0.3;
      }
    }
  }

  &--xl {
    padding: 26px;
    min-width: 268px;

    font-size: 20px;
    line-height: 24px;
  }

  &--lg {
    padding: 23px 26px 25px;
    min-width: 226px;

    font-size: 18px;
    line-height: 22px;
  }

  &--md {
    padding: 19px 26px 20px;
    min-width: 236px;

    font-size: 18px;
    line-height: 22px;
  }

  &--sm {
    padding: 13px 20px 14px;
    min-width: 120px;

    font-size: 14px;
    line-height: 17px;

    border-radius: 3px;
  }

  &--disabled {
    color: $color-soltitude;

    background-color: $color-link-water;

    pointer-events: none;

    &.btn--bordered {
      color: rgba($color-default-white, 0.2);
      border-color: rgba($color-default-white, 0.2);
      background-color: $color-transparent;
    }
  }
}
