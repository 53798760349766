.success-stories {
  h2 {
    margin: 0 0 63px;
    font-family: $font-family--inter;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    text-align: center;

    @include viewport--md {
      margin: 0 0 52px;
    }

    @include viewport--sm {
      margin: 0 20px 46px;

      font-size: 24px;
      line-height: 34px;
    }
  }

  &--customers {
    margin-top: 23px;
    margin-bottom: 100px;
    padding-top: 50px;

    @include viewport--md {
      margin-top: 35px;
      margin-bottom: 46px;
      padding-top: 40px;
    }

    @include viewport--sm {
      margin-top: 17px;
      margin-bottom: 36px;
      padding-top: 30px;
    }
  }

  &--home {
    margin-bottom: 0;
    padding: 0px 120px 100px;

    h2 {
      max-width: 1200px;
      margin: 0 auto 100px;
    }

    h2 span {
      color: $color-blue;
    }

    &-btn-wrapper {
      text-align: center;
      margin-top: 100px;

      .btn {
        padding: 21px 72px 22px;
      }
    }

    @include viewport--md {
      margin-top: 35px;
      margin-bottom: 46px;
      padding: 40px 40px;
    }

    @include viewport--sm {
      margin-top: 17px;
      margin-bottom: 36px;
      padding: 30px 16px;
    }
  }

  &--case-study {
    margin-top: 26px;
    margin-bottom: 135px;
    padding-top: 80px;

    @include viewport--md {
      margin-top: 24px;
      margin-bottom: 64px;
      padding-top: 40px;
    }

    @include viewport--sm {
      margin-top: 0;
      margin-bottom: 86px;
      padding-top: 30px;
    }

    h2 {
      margin-bottom: 80px;

      @include viewport--md {
        margin-bottom: 64px;
      }

      @include viewport--sm {
        margin-bottom: 40px;

        font-size: 24px;
        line-height: 34px;
      }
    }
  }

  &--ghost {
    background-image: $almost-white;
  }

  &--article {
    margin-top: 40px;
    margin-bottom: 69px;
    padding-top: 82px;

    @include viewport--md {
      margin-top: 35px;
      margin-bottom: 64px;
      padding-top: 40px;
    }

    @include viewport--sm {
      margin-top: 40px;
      margin-bottom: 86px;
      padding-top: 30px;
    }

    h2 {
      margin-bottom: 80px;

      @include viewport--md {
        margin-bottom: 64px;
      }

      @include viewport--sm {
        margin-bottom: 40px;

        font-size: 24px;
        line-height: 34px;
      }
    }
  }

  &--vacancy {
    margin-top: 60px;
    padding-top: 80px;

    @include viewport--md {
      margin-top: 35px;
      padding-top: 40px;

      .success-stories__link {
        display: none;
      }
    }

    @include viewport--sm {
      margin-top: 40px;
      padding-top: 30px;
    }

    .success-stories__arrows {
      padding-top: 50px;

      @include viewport--sm {
        padding-top: 30px;
      }
    }
  }

  &--blog {
    margin-top: 20px;

    @include viewport--md {
      margin-top: 35px;
    }

    @include viewport--sm {
      margin-top: 40px;
    }
  }
}

.success-stories__slider {
  margin-bottom: 80px;
  overflow: visible;

  @include viewport--md {
    margin-bottom: 54px;
  }

  @include viewport--sm {
    width: calc(100% - 40px);
    margin: 0 20px 37px;
  }
}

.success-stories__arrows {
  display: none;
  justify-content: center;
  padding-top: 32px;

  @include viewport--md {
    display: flex;
  }

  .slider-arrow--prev {
    transform: rotate(180deg);
  }

  .slider-arrow--next {
    margin-left: 40px;
  }
}


.success-stories__arrow {
  cursor: pointer;

  svg {
    display: block;
    width: 39px;
    height: 34px;
  }
}

.success-stories__link {
  display: block;
  width: max-content;
  margin: 0 auto;
}
