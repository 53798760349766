.get-started {
  width: 100%;
  padding: 69px 30px 69px;

  margin-top: 69px;
  font-size: 20px;
  line-height: 30px;
  color: $color-ship-cove;
  background-color: $color-ghost-white;

  text-align: center;


  @include viewport--md {
    padding: 69px 119px 69px 97px;
  }

  @include viewport--sm {
    padding: 69px 42px 69px 38px;
  }

  &--ghost-md {
    @include viewport--md {
      padding-top: 116px;
      padding-bottom: 105px;
      color: $color-ship-cove;
      background-color: $color-ghost-white;
    }

    @include viewport--sm {
      padding-top: 56px;
      padding-bottom: 63px;
    }
  }

  h2 {
    margin: 0;
    margin-bottom: 46px;

    font-weight: 700;
    font-size: 42px;
    line-height: 42px;
    color: $color-black;

    @include viewport--md {
      margin-bottom: 37px;

      font-size: 36px;
      line-height: 44px;
    }

    @include viewport--sm {
      margin-bottom: 20px;

      font-size: 28px;
      line-height: 36px;
    }
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;

    color: $color-ship-cove;

    :not(:last-child) {
      margin-bottom: 20px;
    }

    @include viewport--md {
      font-size: 18px;
      line-height: 26px;
    }

    @include viewport--sm {
      font-size: 16px;
      line-height: 24px;
    }
  }

  // .btn {
  //   @include viewport--sm {
  //     min-width: 0;
  //   }
  // }

  .demo-sm-form {
    align-self: stretch;

    @include viewport--sm {
      margin-left: -22px;
      margin-right: -26px;
    }

    form {
      justify-content: center;
    }
  }
}

.get-started__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include viewport--md {
    width: 100%;
  }
}

.get-started__text {
  max-width: 700px;
  margin: 0 auto 43px;

  @include viewport--md {
    margin-bottom: 51px;
  }

  @include viewport--sm {
    width: 100%;
    margin-bottom: 32px;
  }
}
