.careers-page__content {
  margin-top: 91px;
  margin-bottom: 63px;

  @include viewport--md {
    margin-top: 59px;
    margin-bottom: 30px;
  }

  @include viewport--sm {
    margin-top: 45px;
    margin-bottom: 14px;
  }
}

.careers-page__title {
  margin: 0 0 63px;

  font-weight: 700;
  font-size: 36px;
  line-height: 44px;

  text-align: center;

  @include viewport--md {
    margin-bottom: 55px;
  }

  @include viewport--sm {
    margin-bottom: 40px;

    font-size: 28px;
    line-height: 36px;
  }
}
