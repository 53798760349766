.solutions-explanations {
  background-color: $color-zircon;
  padding: 100px 0;

  @include viewport--md {
    padding: 22px 0;
  }

  @include viewport--sm {
    padding: 45px 0;
  }

  h1 {
    margin: 0;
    margin-bottom: 90px;

    font-weight: 700;
    font-size: 42px;
    line-height: 42px;
    color: $color-tuna;
    text-align: center;

    span {
      color: $color-blue;
    }

    @include viewport--md {
      margin-bottom: 37px;

      font-size: 36px;
      line-height: 44px;
    }

    @include viewport--sm {
      // margin: 0;
      display: none;

      font-size: 28px;
      line-height: 36px;
    }
  }

  &__block-title {
    margin: 0;
    margin-bottom: 150px;

    font-weight: 700;
    font-size: 36px;
    line-height: 114.3%;
    text-align: center;
    color: $color-east-bay-2;

    @include viewport--md {
      margin-bottom: 90px;
    }

    @include viewport--sm {
      margin-bottom: 45px;

      font-size: 32px;
      line-height: 37px;
    }

    span {
      color: $color-blue;
    }

    &--hide-mob {
      @include viewport--sm {
        display: none;
      }
    }
  }

  .container {
    position: relative;
  }

  .progress-pagination {
    align-items: flex-start;
    margin-bottom: 30px;

    @include viewport--sm {
      display: none;
    }
  }

  &__block-subtitle {
    margin: 0 auto 150px;

    font-weight: 700;
    font-size: 18px;
    line-height: 46px;
    text-align: center;
    color: $color-blue;

    @include viewport--md {
      margin-bottom: 65px;
    }
  }

  &__textbox {
    max-width: 500px;
    min-width: 300px;
    width: 50%;
    margin-right: 30px;

    @include viewport--md {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: auto;
      margin-right: 0;
    }

    &--descr-mob {
      display: none;

      @include viewport--md {
        display: flex;
        margin-top: 4px;
      }
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;

    @include viewport--sm {
      flex-direction: column;
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 43px;
    margin-bottom: 200px;

    img, .solutions-explanations__animation {
      width: 50%;

      transform: scale(1.114);

      @include viewport--md {
        width: auto;
      }
    }

    .solutions-explanations__animation {
      transform: none;
    }

    &:nth-child(odd) {
      flex-direction: row-reverse;

      .solutions-explanations__textbox {
        margin-right: 0;
        margin-left: 30px;

        @include viewport--md {
          margin-left: 0;
        }
      }

      @include viewport--md {
        flex-direction: column;
      }

      img {
        object-position: left;

        @include viewport--md {
          object-position: center;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;

      @include viewport--md {
        margin: 0;
      }
    }

    @include viewport--lg {
      padding: 0px 75px;
    }

    @include viewport--md {
      align-items: center;
      padding: 46px 24px;
      flex-direction: column;
      text-align: center;
      margin: 0;
    }

    @include viewport--sm {
      padding: 0;
      margin-bottom: 90px;
    }

    &--normal-size-img {
      img {
        transform: scale(1);
      }
    }

    &--shorter-text {
      .solutions-explanations__textbox {
        max-width: 433px;

        @include viewport--md {
          max-width: 100%;
        }
      }
    }

    &--bigger-mob-img {
      img {
        @include viewport--md {
          transform: scale(1.114) !important;
        }

        @include viewport--sm {
          max-width: 382px;
        }
      }
    }

    &--optimisation-1 {
      .solutions-explanations {
        &__subtitle,
        &__description {
          max-width: 460px;

          @include viewport--md {
            max-width: 560px;
          }
        }
      }
    }

    &--optimisation-2 {
      .solutions-explanations {
        &__subtitle,
        &__description {
          max-width: 453px;

          @include viewport--md {
            max-width: 560px;
          }
        }
      }
    }
  }

  &__img-desktop {
    @include viewport--sm {
      display: none;
    }
  }

  &__img-mob {
    display: none;

    @include viewport--sm {
      display: block;
    }
  }

  &__title {
    margin: 0 0 44px;
    font-size: 24px;
    line-height: 26px;
    color: $color-blue;
    max-width: 500px;
    font-weight: bold;

    @include viewport--md {
      text-align: center;
      max-width: 560px;
      margin-bottom: 26px;
    }

    @include viewport--sm {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__subtitle {
    margin: 0 0 44px;
    font-size: 30px;
    line-height: 36px;
    color: $color-torea-bay-4;
    max-width: 500px;

    span {
      color: $color-blue;
    }

    @include viewport--md {
      text-align: center;
      max-width: 560px;
      // margin-bottom: 40px;
      margin-bottom: 0;
    }

    @include viewport--sm {
      font-size: 24px;
      line-height: 30px;
      // margin-bottom: 30px;
    }
  }

  &__description,
  &__lead {
    margin: 0 0 44px;
    font-size: 20px;
    line-height: 32px;
    color: $color-comet;
    max-width: 495px;

    @include viewport--md {
      max-width: 500px;
      text-align: center;
    }

    @include viewport--sm {
      font-size: 16px;
      line-height: 24px;
      max-width: 360px;
      width: 100%;
    }
  }

  &__description {
    margin: 0 0 25px;

    span {
      font-weight: 600;
    }

    &:last-child {
      margin: 0;
    }

    &--desktop {
      @include viewport--md {
        display: none;
      }
    }
  }

  img {
    max-width: 508px;
    min-width: 300px;
    max-height: 448px;
    object-fit: contain;
    object-position: right;
    box-shadow: none;

    @include viewport--md {
      position: static;
      transform: none;
      margin: 20px 0;
      object-position: center;
    }

    @include viewport--sm {
      // order: -1;
      max-width: 364px;
      max-height: 364px;
      width: 100%;
      //margin-top: 0;
    }
  }

  &__animation {
    max-width: 508px;
    min-width: 300px;

    @include viewport--md {
      margin: 20px 0;
    }

    @include viewport--sm {
      max-width: 364px;
      width: 100%;
    }

    &--desktop {
      @include viewport--sm {
        display: none;
      }
    }
  }

  .iconfixleft {
    margin-left: -40px;

    @include viewport--md {
      margin: 20px 0;
    }
  }

  .iconfixtop {
    margin-top: -55px;

    @include viewport--md {
      margin: 0 0 30px;
    }
  }

  &--odd-text-left {
    .solutions-explanations__item {
      // display: flex;
      // flex-direction: row;
      // justify-content: space-between;
      // align-items: center;
      // padding: 0 43px;
      // margin-bottom: 200px;

      &:nth-child(odd) {
        flex-direction: row;
  
        .solutions-explanations__textbox {
          margin-right: 30px;
          margin-left: 0;
  
          @include viewport--md {
            margin-right: 0;
          }
        }
  
        @include viewport--md {
          flex-direction: column;
        }
  
        img {
          object-position: right;
  
          @include viewport--md {
            object-position: center;
          }
        }
      }
  
      &:nth-child(even) {
        flex-direction: row-reverse;
  
        .solutions-explanations__textbox {
          margin-right: 0;
          margin-left: 30px;
  
          @include viewport--md {
            margin-left: 0;
          }
        }
  
        @include viewport--md {
          flex-direction: column;
        }
  
        img {
          object-position: left;
  
          @include viewport--md {
            object-position: center;
          }
        }
      }
  
      &:last-child {
        margin-bottom: 0;
  
        @include viewport--md {
          margin: 0;
        }
      }
  
      // @include viewport--lg {
      //   padding: 0px 75px;
      // }
  
      // @include viewport--md {
      //   align-items: center;
      //   padding: 46px 24px;
      //   flex-direction: column;
      //   text-align: center;
      //   margin: 0;
      // }
  
      // @include viewport--sm {
      //   padding: 0;
      //   margin-bottom: 90px;
      // }
  
    }
  }

  &--marketing-reporting {
    background: linear-gradient(180deg, #FFFFFF 0%, #FAFBFF 100%);

    .solutions-explanations__item {
      margin-bottom: 150px;

      &:last-child {
        margin-bottom: 0;
  
        @include viewport--md {
          margin: 0;
        }
      }

      @include viewport--md {
        margin-bottom: 0;
      }
  
      @include viewport--sm {
        margin-bottom: 90px;
      }
    }

    .solutions-explanations__description {
      @include viewport--sm {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  &--ecommerce {
    background: linear-gradient(180deg, #FFFFFF 0%, #FAFBFF 100%);

    .solutions-explanations__item {
      margin-bottom: 150px;

      &:last-child {
        margin-bottom: 0;

        @include viewport--md {
          margin: 0;
        }
      }

      @include viewport--md {
        margin-bottom: 0;
      }

      @include viewport--sm {
        margin-bottom: 90px;
      }
    }

    .solutions-explanations__title {
      margin: 0 0 35px;
      font-size: 15px;
      line-height: 114.3%;
  
      @include viewport--md {
        margin-bottom: 26px;
      }
  
      @include viewport--sm {
        margin-bottom: 18px;

        font-size: 14px;
      }
    }

    .solutions-explanations__subtitle {
      margin: 0 0 35px;
      line-height: 34px;
  
      @include viewport--md {
        margin-bottom: 0;
      }
  
      @include viewport--sm {
        line-height: 29px;
      }
    }

    .solutions-explanations__description {
      line-height: 30px;

      @include viewport--sm {
        font-size: 20px;
        line-height: 32px;
      }
    }
  }

  &--b2b-and-saas {
    background: linear-gradient(180deg, #FFFFFF 0%, #F7FAFF 100%);

    .solutions-explanations__item {
      margin-bottom: 150px;

      &:last-child {
        margin-bottom: 0;

        @include viewport--md {
          margin: 0;
        }
      }

      @include viewport--md {
        margin-bottom: 0;
      }

      @include viewport--sm {
        margin-bottom: 90px;
      }
    }

    .solutions-explanations__title {
      margin: 0 0 35px;
      font-size: 15px;
      line-height: 114.3%;
  
      @include viewport--md {
        margin-bottom: 26px;
      }
  
      @include viewport--sm {
        margin-bottom: 18px;

        font-size: 14px;
      }
    }

    .solutions-explanations__subtitle {
      margin: 0 0 35px;
      line-height: 34px;
  
      @include viewport--md {
        margin-bottom: 0;
      }
  
      @include viewport--sm {
        line-height: 29px;
      }
    }

    .solutions-explanations__description {
      line-height: 30px;

      @include viewport--sm {
        font-size: 20px;
        line-height: 32px;
      }
    }
  }

  &--digital-agencies {
    background: linear-gradient(180deg, #FFFFFF 0%, #F7FAFF 100%);

    .solutions-explanations__block-title {
      @include viewport--sm {
        max-width: 250px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .solutions-explanations__item {
      padding: 0 28px;
      margin-bottom: 150px;

      &:last-child {
        margin-bottom: 0;

        @include viewport--md {
          margin: 0;
        }
      }

      @include viewport--lg {
        padding: 0 85px;
      }

      @include viewport--md {
        margin-bottom: 0;
        padding: 46px 24px;
      }

      @include viewport--sm {
        padding: 0;
        margin-bottom: 90px;
      }

      img {
        transform: scale(1.020);

        @include viewport--md {
          transform: 0;
        }
      }

      &--digital-agencies-1 {
        // .solutions-explanations__textbox {
        //   max-width: 425px;
        // }

        .solutions-explanations__description {
          max-width: 425px;

          @include viewport--md {
            max-width: 500px;
          }

          @include viewport--sm {
            max-width: 360px;
          }
        }
      }

      &--digital-agencies-2 {
        .solutions-explanations__description {
          max-width: 434px;

          @include viewport--md {
            max-width: 500px;
          }

          @include viewport--sm {
            max-width: 360px;
          }
        }
      }

      &--digital-agencies-3 {
        .solutions-explanations__description {
          max-width: 448px;

          @include viewport--md {
            max-width: 500px;
          }

          @include viewport--sm {
            max-width: 360px;
          }
        }
      }
    }

    .solutions-explanations__title {
      margin: 0 0 35px;
      font-size: 15px;
      line-height: 114.3%;
  
      @include viewport--md {
        margin-bottom: 26px;
      }
  
      @include viewport--sm {
        margin-bottom: 18px;

        font-size: 14px;
      }
    }

    .solutions-explanations__subtitle {
      max-width: 431px;
      margin: 0 0 35px;
      line-height: 34px;
  
      @include viewport--md {
        max-width: 560px;
        margin-bottom: 0;
      }
  
      @include viewport--sm {
        line-height: 29px;
      }
    }

    .solutions-explanations__description {
      line-height: 30px;

      @include viewport--sm {
        font-size: 20px;
        line-height: 32px;
      }
    }
  }

  &--optimisation {
    background: linear-gradient(180deg, #FAFBFF 0%, #FFFFFF 100%);

    @include viewport--sm {
      background: $color-zircon-2;
    }
  }

  &--financial-services {
    background: linear-gradient(180deg, #FFFFFF 0%, #F7FAFF 100%);

    @include viewport--md {
      padding: 60px 0;
    }
  
    @include viewport--sm {
      padding: 45px 0;
    }

    .solutions-explanations {
      &__block-title {
        margin-bottom: 10px;

        @include viewport--sm {
          max-width: 295px;
          margin: 0 auto 45px;
        }
      }

      &__block-subtitle {
        @include viewport--sm {
          display: none;
        }
      }

      &__item {
        padding: 0 28px;
        margin-bottom: 150px;

        &:last-child {
          margin-bottom: 0;

          @include viewport--md {
            margin: 0;
          }
        }

        @include viewport--lg {
          padding: 0 85px;
        }

        @include viewport--md {
          margin-bottom: 0;
          padding: 46px 24px;
        }

        @include viewport--sm {
          padding: 0;
          margin-bottom: 90px;
        }

        img {
          transform: scale(1.020);

          @include viewport--md {
            transform: 0;
          }
        }

        &--financial-services-1 {
          // .solutions-explanations__textbox {
          //   max-width: 425px;
          // }

          .solutions-explanations__subtitle {
            max-width: 400px;

            @include viewport--md {
              max-width: 560px;
            }
          }

          .solutions-explanations__description {
            max-width: 400px;

            @include viewport--md {
              max-width: 500px;
            }

            @include viewport--sm {
              max-width: 360px;
            }
          }
        }

        &--financial-services-2 {
          .solutions-explanations__subtitle {
            max-width: 400px;

            @include viewport--md {
              max-width: 560px;
            }
          }

          .solutions-explanations__description {
            max-width: 464px;

            @include viewport--md {
              max-width: 500px;
            }

            @include viewport--sm {
              max-width: 360px;
            }
          }
        }

        &--financial-services-3 {
          .solutions-explanations__subtitle {
            max-width: 400px;
        
            @include viewport--md {
              max-width: 560px;
            }
          }

          .solutions-explanations__description {
            max-width: 414px;

            @include viewport--md {
              max-width: 500px;
            }

            @include viewport--sm {
              max-width: 360px;
            }
          }
        }
      }

      &__subtitle {
        font-weight: 700;
      }

      &__description {
        @include viewport--sm {
          font-size: 20px;
          line-height: 32px;
        }
      }
    }
  }

  &--travel {
    background: linear-gradient(180deg, #FFFFFF 0%, #F7FAFF 100%);

    @include viewport--md {
      padding: 60px 0;
    }

    @include viewport--sm {
      padding: 45px 0;
    }

    .solutions-explanations {
      &__block-title {
        margin-bottom: 10px;

        @include viewport--sm {
          max-width: 295px;
          margin: 0 auto 45px;
        }
      }

      &__block-subtitle {
        @include viewport--sm {
          display: none;
        }
      }

      &__item {
        padding: 0 28px;
        margin-bottom: 150px;

        &:last-child {
          margin-bottom: 0;

          @include viewport--md {
            margin: 0;
          }
        }

        @include viewport--lg {
          padding: 0 85px;
        }

        @include viewport--md {
          margin-bottom: 0;
          padding: 46px 24px;
        }

        @include viewport--sm {
          padding: 0;
          margin-bottom: 90px;
        }

        img {
          transform: scale(1.020);

          @include viewport--md {
            transform: 0;
          }
        }

        &--travel-1 {
          .solutions-explanations__subtitle {
            max-width: 381px;
        
            @include viewport--md {
              max-width: 560px;
            }
          }

          .solutions-explanations__description {
            max-width: 483px;

            @include viewport--md {
              max-width: 500px;
            }

            @include viewport--sm {
              max-width: 360px;
            }
          }
        }

        &--travel-2 {
          .solutions-explanations__subtitle {
            max-width: 442px;
        
            @include viewport--md {
              max-width: 560px;
            }
          }

          .solutions-explanations__description {
            max-width: 464px;
  
            @include viewport--md {
              max-width: 500px;
            }

            @include viewport--sm {
              max-width: 360px;
            }
          }
        }

        &--travel-3 {
          .solutions-explanations__subtitle {
            max-width: 400px;

            @include viewport--md {
              max-width: 560px;
            }
          }

          .solutions-explanations__description {
            max-width: 414px;

            @include viewport--md {
              max-width: 500px;
            }

            @include viewport--sm {
              max-width: 360px;
            }
          }
        }
      }

      &__subtitle {
        font-weight: 700;
      }

      &__description {
        @include viewport--sm {
          font-size: 20px;
          line-height: 32px;
        }
      }
    }
  }
}
