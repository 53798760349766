@mixin retina {
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    @content;
  }
}

// Desktop first
@mixin viewport--xl {
  @media (max-width: $viewport--xxl - 1) {
    @content;
  }
}

@mixin viewport--lg {
  @media (max-width: $viewport--xl - 1) {
    @content;
  }
}

@mixin viewport--1280 {
  @media (max-width: $viewport--1280 - 1) {
    @content;
  }
}

@mixin viewport--md {
  @media (max-width: $viewport--lg - 1) {
    @content;
  }
}

@mixin viewport--sm {
  @media (max-width: $viewport--md - 1) {
    @content;
  }
}

@mixin hover {
  @media (hover: hover) {
    &:hover:not(.focus-visible) {
      @content;
    }
  }
}

@mixin focus {
  &.focus-visible:focus {
    @content;
  }
}

@mixin active {
  &.focus-visible:active {
    @content;
  }
}

@mixin hover-focus {
  @media (hover: hover) {
    &:hover:not(.focus-visible) {
      @content;
    }
  }

  &.focus-visible:focus {
    @content;
  }
}