.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 120px;

  &--md {
    padding: 0 130px;
  }

  &--sm {
    padding: 0 105px;
  }

  @include viewport--lg {
    padding: 0 60px;
  }

  @include viewport--md {
    padding: 0 40px;
  }

  @include viewport--sm {
    padding: 0 16px;
  }
}
