.featured {
  padding: 67px 0 50px;

  background-color: rgba(225, 231, 255, 0.2);

  @include viewport--md {
    padding-bottom: 75px;
  }

  @include viewport--sm {
    padding: 30px 0 40px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h2,
  &__block-title {
    color: $color-bunting-2;
    text-align: center;
    font-family: $font-family--inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px; /* 133.333% */
    margin: 0 0 100px;

    @include viewport--md {
      margin-bottom: 37px;

      font-size: 36px;
      line-height: 44px;
    }

    @include viewport--sm {
      margin-bottom: 20px;

      font-size: 24px;
      line-height: 1.2;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1184px;
    margin-top: -74px;
    margin-right: -77px;

    @include viewport--md {
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 0;
      margin-right: 0;
    }

    &--desktop {
      @include viewport--sm {
        display: none;
      }
    }

    &--mob {
      display: none;

      @include viewport--sm {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .featured__item {
        &--adexchanger {
          @include viewport--sm {
            width: 82px;
          }
        }

        &--forbes{
          @include viewport--sm {
            width: 72px;
          }
        }

        &--startups {
          @include viewport--sm {
            width: 71px;
          }
        }

        &--pmw {
          @include viewport--sm {
            width: 71px;
          }
        }

        &--ecounsultancy {
          @include viewport--sm {
            width: 113px;
          }
        }

        &--dtc{
          @include viewport--sm {
            width: 52px;
          }
        }

        &--drum {
          @include viewport--sm {
            width: 81px;
          }
        }

        &--yahoo {
          @include viewport--sm {
            width: 68px;
          }
        }

        &--cxl {
          @include viewport--sm {
            width: 53px;
          }
        }

        &--venture-beat{
          @include viewport--sm {
            width: 101px;
          }
        }
      }
    }
  }

  &__items-line {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 328px;
    max-width: 100%;

    @include viewport--sm {
      margin-bottom: 16px;
      gap: 46px;
    }

    &:last-child {
      @include viewport--sm {
        margin-bottom: 0;
      }
    }
  }

  &__item {
    margin-top: 50px;
    margin-right: 77px;

    transition: opacity $default-transition-settings;

    @include viewport--md {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      padding: 20px 15px;
      margin-top: 0;
      margin-right: 0;
    }

    @include viewport--sm {
      width: auto;
      padding: 0;
    }
  }

  &--hide-title-desktop {
    h2,
    .featured__block-title {
      display: none;

      @include viewport--sm {
        display: block;
      }
    }
  }

  &--about {
    padding: 93px 0 41px;

    background-color: $color-default-white;

    @include viewport--sm {
      padding: 52px 0 11px;
    }
  }

  &--solutions {
    padding: 75px 0;

    background: $color-zircon-3;

    @include viewport--sm {
      padding: 35px 0;
    }

    h2,
    .featured__block-title {
      color: $color-torea-bay-4;

      @include viewport--sm {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }

  &--main {
    background: $color-ghost-white-2;

    @include viewport--md {
      background: $color-zircon;
    }
  }

  &--marketing-mix-optimization {
    background-color: #f6f8ff;

    @include viewport--md {
      background-color: #f6f8ff;
    }
  }
}
