.social:not(.hack-selector) {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 -8px;
  padding: 0;

  list-style: none;
}

.social__item:not(.hack-selector) {
  margin-bottom: 8px;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }

  &:nth-child(3) {
    svg {
      top: -1px;
      right: -1px;
      position: relative;
    }
  }
}

.social__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  background-color: $color-default-white;
  border: 1px solid $color-hawkes-blue;
  border-radius: 50%;

  svg {
    fill: $color-ship-cove;
    transition: fill $default-transition-settings;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      svg {
        fill: $color-blue;
      }
    }
  }
}
