.hero {
  $root: &;

  .container {
    padding: 0 40px;

    @include viewport--lg {
      padding: 0 40px;
    }

    @include viewport--md {
      padding: 0 20px;
    }

    @include viewport--sm {
      padding: 0 10px;
    }
  }

  &--main {
    .container {
      @include viewport--md {
        padding: 0;
      }
    }
  }

  &__inner {
    position: relative;
    background: linear-gradient(218deg, #0e1435 22.32%, #1e3376 84.78%);
    padding: 85px 90px;
    overflow: hidden;
    border-radius: 10px;

    &--main {
      border-radius: 7px;
      background: linear-gradient(149deg, #252d72 36.8%, #10173a 70.69%);
    }

    @include viewport--1280 {
      &--main {
        padding: 85px 50px;
      }
    }

    @include viewport--md {
      padding: 25px 25px 0;
      min-height: auto;
      margin-top: 20px;

      &--main {
        margin-top: 0;
        padding: 45px 45px 0;
        border-radius: 0;
      }
    }

    @include viewport--sm {
      padding: 20px 15px 0;
      margin-top: 15px;
    }

    @include viewport--sm {
      margin-top: 20px;
      padding: 20px 15px 0;

      &--main {
        margin-top: 0;
        padding: 40px 25px 20px;
      }
    }
  }

  &__content {
    width: 100%;
    max-width: 650px;
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 33px;

    // @include viewport--lg {
    //   max-width: 50%;
    // }

    @include viewport--md {
      display: flex;
      flex-direction: column;
      padding-top: 0;
      max-width: unset;
    }
  }

  &__title {
    color: $color-default-white;
    font-family: $font-family--inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 46px;

    margin: 0 0 24px;

    grid-column: span 2;

    // @include viewport--lg {
    //   margin: 0 -150px 50px 0;
    // }

    @include viewport--md {
      text-align: center;
      font-size: 39px;
      // font-weight: 600;
      line-height: normal;
      margin: 0 0 50px;
      order: 2;
    }

    @include viewport--sm {
      font-size: 28px;
      margin: 0 0 16px;
      line-height: initial;
      text-align: left;
    }

    &--main {
      max-width: 550px;
      margin-top: 2px;

      @include viewport--md {
        max-width: initial;
        font-size: 36px;
      }

      @include viewport--sm {
        font-size: 28px;
        margin: 0 0 20px;
        line-height: initial;
        text-align: left;
      }
    }
  }

  &__description-wrap {
    grid-column: span 2;
    margin: 0 0 37px;

    @include viewport--md {
      margin: 0 0 50px;
      order: 3;
    }

    @include viewport--sm {
      margin: 0 0 40px;
    }

    // @include viewport--lg {
    //   margin: 0 -20px 37px 0;
    // }

    @include viewport--xl {
      margin: 0 0 37px;
    }

    &--main {
      max-width: 530px;
      margin-bottom: 57px;

      @include viewport--1280 {
        max-width: 500px;
      }

      @include viewport--md {
        align-self: center;
        max-width: 594px;
        margin-bottom: 50px;
      }

      @include viewport--sm {
        margin-bottom: 38px;
        align-self: left;
      }

      #{$root}__description {
        font-size: 21px;
        font-weight: 400;
        line-height: 143%;

        @include viewport--sm {
          font-size: 20px;
          line-height: initial;
          text-align: left;
        }
      }
    }
  }

  &__description {
    color: $color-lavender-4;
    font-family: $font-family--lato;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin: 0;

    @include viewport--md {
      text-align: center;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    @include viewport--sm {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: left;
    }
  }

  &__btn {
    height: fit-content;
    color: $color-default-white;
    text-align: center;
    font-family: $font-family--inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    padding: 17px 33px 18px;

    @include viewport--md {
      order: 4;
      font-size: 23px;
      // font-style: normal;
      // font-weight: 600;
      line-height: 32px;
      margin-bottom: 42px;
    }

    @include viewport--sm {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      padding: 15px 33px 16px;
    }

    &--main {
      padding: 3px 45px;
      border-radius: 3px;
      line-height: 2.4;

      @include viewport--md {
        padding: 8px 45px;
        margin-bottom: 364px;
      }

      @include viewport--sm {
        margin-bottom: 42px;
        padding: 3px 45px;
      }
    }
  }

  &__g2-link {
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;

    @include viewport--md {
      display: none;
    }
  }

  &__icon {
    display: flex;
    flex-shrink: 0;
    color: $color-default-white;
    transition: color $default-transition-settings;

    &--g2 {
      margin-right: 10px;

      @include viewport--md {
        margin-right: 7px;
      }

      @include viewport--sm {
        margin-right: 4px;
      }

      svg {
        width: 28px;
        height: auto;

        @include viewport--md {
          width: 40px;
        }

        @include viewport--sm {
          width: 26px;
        }
      }
    }

    &--five-stars {
      svg {
        width: 78px;
        height: auto;

        @include viewport--md {
          width: 114px;
        }

        @include viewport--sm {
          width: 76px;
        }
      }
    }
  }

  &__image-wrap {
    position: absolute;
    right: 0;
    bottom: 0;

    @include viewport--md {
      position: relative;
      order: 100;
      width: 100%;
      padding: 0 9%;
    }

    @include viewport--sm {
      width: calc(100% + 50px);
      padding: 0;
      margin: 0 -25px;
    }

    &--main {
      right: 53px;
      bottom: 5px;
      width: 39.3%;

      @include viewport--1280 {
        right: 20px;
        width: 38%;
      }

      @include viewport--md {
        position: absolute;
        padding: 0;
        width: 510px;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
      }

      @include viewport--sm {
        width: 100%;
        position: static;
        transform: none;
        margin: 0;
      }

      #{$root}__image {
        @include viewport--md {
          padding-bottom: 315px;
        }

        @include viewport--sm {
          padding: 0;

          img {
            position: static;
            width: 100%;
            height: auto;
            max-width: 400px;
            object-fit: unset;
            margin: 0 auto;
          }
        }
      }
    }
  }

  &__image {
    position: relative;
    overflow: hidden;

    padding-bottom: calc(507 / 518 * 100%);

    // @include viewport--lg {
    //   padding-bottom: calc(380 / 471 * 100%);
    // }

    @include viewport--md {
      padding-bottom: calc(360 / 471 * 100%);
    }
  }

  &__image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__label {
    margin: 0 0 46px;
    font-family: $font-family--inter;
    font-weight: 600;
    font-size: 22px;
    color: #7d9cff;
    grid-column: 1 / -1;

    @include viewport--md {
      text-align: center;
    }

    @include viewport--sm {
      text-align: left;
    }
  }

  &--marketing-mix-optimization {
    padding-top: 110px;
    background: linear-gradient(173deg, #252d72 0%, #10173a 100%);

    @include viewport--md {
      padding-top: 0;
    }

    .container {
      @include viewport--sm {
        padding: 0 20px;
      }
    }

    #{$root}__inner {
      background: transparent;
      padding: 50px 60px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      border-radius: 0;

      @include viewport--1280 {
        padding-left: 0;
        padding-right: 0;
      }

      @include viewport--md {
        flex-direction: column;
        gap: 40px;
        padding-top: 40px;
        margin-top: 0;
      }

      @include viewport--sm {
        padding-top: 33px;
        margin-top: 0;
      }
    }

    #{$root}__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 46px;
      max-width: 540px;

      @include viewport--1280 {
        max-width: 500px;
      }

      @include viewport--md {
        max-width: unset;
        gap: 42px;
        align-items: center;
      }

      @include viewport--sm {
        align-items: flex-start;
        gap: 41px;
      }
    }

    #{$root}__label {
      margin: 0;
      font-family: $font-family--inter;
      font-weight: 600;
      font-size: 22px;
      color: #7d9cff;
    }

    #{$root}__title {
      margin: 0;

      @include viewport--md {
        order: initial;
        max-width: 580px;
        font-size: 36px;
      }

      @include viewport--sm {
        font-size: 26px;
      }
    }

    #{$root}__description {
      margin: 0;
      font-size: 20px;
      line-height: 150%;

      @include viewport--md {
        max-width: 580px;
        font-size: 21px;
        line-height: 143%;
      }

      @include viewport--sm {
        max-width: initial;
        font-size: 20px;
        line-height: normal;
      }

      br {
        @include viewport--md {
          display: initial;
        }

        @include viewport--sm {
          display: none;
        }
      }
    }

    #{$root}__image-wrap {
      position: relative;
      width: 49.3%;

      @include viewport--md {
        width: 60%;
        padding: 0;
      }

      @include viewport--sm {
        width: 100%;
      }
    }

    #{$root}__image {
      padding-bottom: calc(449 / 611 * 100%);
    }

    #{$root}__btn-wrapper {
      display: flex;
      align-items: center;
      gap: 32px;

      @include viewport--sm {
        align-self: stretch;
        flex-direction: column;
        gap: 24px;
      }
    }

    #{$root}__g2-link {
      margin: 0;

      #{$root}__icon--g2 {
        @include viewport--md {
          margin-right: 10px;
        }

        svg {
          @include viewport--md {
            width: 28px;
          }
        }
      }

      #{$root}__icon--five-stars svg {
        @include viewport--md {
          width: 78px;
        }
      }
    }

    #{$root}__btn {
      min-width: 200px;
      line-height: normal;
      padding: 15px 32px;

      @include viewport--md {
        order: initial;
        margin: 0;
        font-size: 20px;
      }

      @include viewport--sm {
        align-self: stretch;
      }
    }

    #{$root}__trusted-brands {
      .trusted-brands-main__inner {
        padding: 90px 0 65px;

        @include viewport--md {
          padding: 53px 0 38px;
        }

        @include viewport--sm {
          padding: 48px 0 38px;
        }
      }

    }
  }

  &--ai-driven-attribution {
    padding-top: 110px;
    background: linear-gradient(149deg, #252d72 36.8%, #10173a 70.69%);

    @include viewport--md {
      padding-top: 10px;
    }

    .container {
      padding: 0 100px 18px;

      @include viewport--lg {
        padding: 0 40px;
      }

      @include viewport--sm {
        padding: 0 20px;
      }
    }

    #{$root}__content {
      max-width: 515px;
      height: fit-content;

      @include viewport--md {
        max-width: unset;
      }
    }

    #{$root}__inner {
      background: transparent;
      padding: 90px 0 0;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 15px;
      border-radius: 0;

      @media (max-width: 1280px) {
        padding-left: 0;
        padding-right: 0;
      }

      @include viewport--md {
        flex-direction: column;
      }

      @include viewport--sm {
        padding-top: 53px;
        gap: 0;
      }
    }

    #{$root}__title {
      margin: 0 0 46.7px;

      @include viewport--sm {
        font-size: 26px;
        margin-bottom: 40px;
      }
    }

    #{$root}__description-wrap {
      @include viewport--xl {
        margin-bottom: 45px;
      }
    }

    #{$root}__description {
      margin: 0;
      font-size: 20px;

      & + #{$root}__description {
        margin-top: 30px;

        @include viewport--sm {
          margin-top: 24px;
        }
      }
    }

    #{$root}__g2-link {
      @include viewport--md {
        display: none;
      }
    }

    #{$root}__image-wrap {
      position: relative;
      width: 46%;
      right: 10px;
      bottom: -25px;

      @include viewport--sm {
        margin: 0;
      }

      @include viewport--md {
        width: 100%;
      }
    }

    #{$root}__image {
      padding-bottom: calc(390 / 578 * 100%);

      @include viewport--sm {
        padding-bottom: calc(267 / 374 * 100%);
      }
    }

    .trusted-brands-main__inner {
      padding: 100px 0 52px;

      @include viewport--1280 {
        padding: 120px 0 100px;
      }

      @include viewport--md {
        padding: 53px 0 38px;
      }

      @include viewport--sm {
        padding: 48px 0 38px;
      }
    }
  }

  &--solutions-page {
    padding-top: 110px;
    background: linear-gradient(149deg, #252d72 36.8%, #10173a 70.69%);

    @include viewport--md {
      padding-top: 10px;
    }

    @include viewport--sm {
      padding-bottom: 40px;
      margin-bottom: 13px;
    }

    .container {
      padding: 0 100px;

      @include viewport--lg {
        padding: 0 60px;
      }

      @include viewport--sm {
        padding: 0 20px;
      }
    }

    #{$root}__content {
      max-width: 530px;
      height: fit-content;
      width: 57%;

      @include viewport--md {
        max-width: unset;
        width: auto;
      }
    }

    #{$root}__inner {
      background: transparent;
      padding: 90px 0 0;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 15px;

      @media (max-width: 1280px) {
        padding-left: 0;
        padding-right: 0;
      }

      @include viewport--md {
        flex-direction: column;
      }

      @include viewport--sm {
        padding-top: 53px;
        gap: 0;
      }
    }

    #{$root}__title {
      margin: 0 -120px 46.7px 0;

      @include viewport--md {
        margin: 0 0 46.7px;
      }

      @include viewport--sm {
        font-size: 26px;
        margin: 0 0 40px;
      }

      span {
        color: $color-melrose-2;
      }
    }

    #{$root}__description {
      margin: 0;
      font-size: 20px;

      & + #{$root}__description {
        margin-top: 30px;

        @include viewport--sm {
          margin-top: 24px;
        }
      }
    }

    #{$root}__g2-link {
      @include viewport--md {
        display: none;
      }
    }

    #{$root}__image-wrap {
      position: relative;
      width: 41.5%;
      margin-right: 30px;

      @include viewport--lg {
        margin-right: 0;
      }

      @include viewport--sm {
        margin: 0;
      }

      @include viewport--md {
        width: 100%;
      }
    }

    #{$root}__image {
      padding-bottom: calc(375 / 514 * 100%);

      @include viewport--sm {
        padding-bottom: calc(283 / 374 * 100%);
      }
    }
  }

  &--incrementality-testing-page {
    padding-top: 110px;
    background: linear-gradient(149deg, #252d72 36.8%, #10173a 70.69%);

    @include viewport--md {
      padding-top: 10px;
    }

    @include viewport--sm {
      padding-bottom: 40px;
      margin-bottom: 13px;
    }

    .container {
      padding: 0 100px;

      @include viewport--lg {
        padding: 0 60px;
      }

      @include viewport--sm {
        padding: 0 20px;
      }
    }

    #{$root}__content {
      max-width: 530px;
      height: fit-content;
      width: 57%;

      @include viewport--md {
        max-width: unset;
        width: 100%;
      }
    }

    #{$root}__inner {
      background: transparent;
      padding: 90px 0 0;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 15px;
      min-height: 440px;
      border-radius: 0;

      @media (max-width: 1280px) {
        padding-left: 0;
        padding-right: 0;
      }

      @include viewport--md {
        flex-direction: column;
        min-height: auto;
      }

      @include viewport--sm {
        padding-top: 53px;
        gap: 0;
      }
    }

    #{$root}__title {
      margin: 0 0 46.7px;

      @include viewport--sm {
        font-size: 26px;
        margin-bottom: 40px;
      }

      span {
        color: $color-melrose-2;
      }
    }

    #{$root}__description {
      margin: 0;
      font-size: 20px;
      width: 100%;
      max-width: 500px;
      font-weight: 400;

      @include viewport--lg {
        min-width: auto;
      }

      & + #{$root}__description {
        margin-top: 30px;

        @include viewport--sm {
          margin-top: 24px;
        }
      }
    }

    #{$root}__g2-link {
      @include viewport--md {
        display: none;
      }
    }

    #{$root}__image-wrap {
      position: relative;
      width: 45%;
      margin-right: 22px;
      margin-top: -25px;

      @include viewport--sm {
        margin: 0;
      }

      @include viewport--md {
        width: 100%;
        margin-top: 0;
        margin-right: 0;
      }
    }

    #{$root}__image {
      padding-bottom: calc(333 / 557 * 100%);
    }
  }

  &--synthetic-conversions,
  &--ltv-forecasting-solution {
    padding-top: 110px;
    background: linear-gradient(149deg, #252d72 36.8%, #10173a 70.69%);

    @include viewport--md {
      padding-top: 10px;
    }

    .container {
      padding: 0 100px 18px;

      @include viewport--lg {
        padding: 0 40px;
      }

      @include viewport--sm {
        padding: 0 20px;
      }
    }

    #{$root}__content {
      max-width: 515px;
      height: fit-content;

      grid-template-columns: 200px 1fr;

      @include viewport--md {
        max-width: unset;
      }
    }

    #{$root}__inner {
      background: transparent;
      padding: 70px 0 0;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 15px;
      border-radius: 0;

      @media (max-width: 1280px) {
        padding-left: 0;
        padding-right: 0;
      }

      @include viewport--md {
        flex-direction: column;
      }

      @include viewport--sm {
        padding-top: 53px;
        gap: 0;
      }
    }

    #{$root}__title {
      margin: 0 0 46.7px;

      @include viewport--sm {
        font-size: 26px;
        margin-bottom: 40px;
      }
    }

    #{$root}__description-wrap {
      @include viewport--xl {
        margin-bottom: 45px;
      }
    }

    #{$root}__description {
      margin: 0;
      font-size: 20px;

      & + #{$root}__description {
        margin-top: 30px;

        @include viewport--sm {
          margin-top: 24px;
        }
      }
    }

    #{$root}__btn {
      width: 200px;

      @include viewport--md {
        width: 100%;
      }
    }

    #{$root}__g2-link {
      @include viewport--md {
        display: none;
      }
    }

    #{$root}__image-wrap {
      width: 42%;
      max-width: 519px;
      right: 15px;
      bottom: -7px;

      @include viewport--sm {
        margin: 0;
      }

      @include viewport--md {
        max-width: 100%;
        width: 100%;
        right: auto;
        bottom: auto;
      }
    }

    #{$root}__image {
      padding-bottom: 0;

      img {
        position: static;
        height: auto;
        object-fit: unset;
      }
    }

    .trusted-brands-main__inner {
      padding: 100px 0 52px;

      @include viewport--1280 {
        padding: 120px 0 100px;
      }

      @include viewport--md {
        padding: 53px 0 38px;
      }

      @include viewport--sm {
        padding: 48px 0 38px;
      }
    }
  }

  &--ltv-forecasting-solution {
    @include viewport--md {
      margin-top: -107px;
      padding-top: 107px;
    }

    @include viewport--sm {
      margin-top: -77px;
      padding-top: 77px;
    }

    .container {
      padding: 0 120px 18px;

      @include viewport--lg {
        padding: 0 40px;
      }

      @include viewport--sm {
        padding: 0 20px;
      }
    }

    #{$root}__content {
      max-width: 555px;

      @include viewport--1280 {
        max-width: 515px;
      }

      @include viewport--md {
        max-width: unset;
      }
    }

    #{$root}__image-wrap {
      width: 51%;
      max-width: 602px;
      right: -60px;
      bottom: -37px;

      @include viewport--lg {
        right: -45px;
      }

      @include viewport--sm {
        margin: 0;
      }

      @include viewport--md {
        max-width: 100%;
        width: 100%;
        right: auto;
        bottom: auto;
      }
    }

    #{$root}__inner {
      @include viewport--sm {
        padding-top: 30px;
      }
    }

    #{$root}__label {
      @include viewport--sm {
        margin-bottom: 41px;
      }
    }

    #{$root}__description-wrap {
      @include viewport--sm {
        margin-bottom: 40px;
      }
    }

    #{$root}__btn {
      @include viewport--md {
        margin-bottom: 25px;
      }
    }

    #{$root}__g2-link {
      @include viewport--md {
        display: flex;
        order: 4;
        margin: 0 auto 40px;
      }
    }

    .trusted-brands-main__list {
      gap: 20px 19px;

      @include viewport--md {
        gap: 20px 30px;
      }

      @include viewport--sm {
        gap: 4px;
      }
    }
  }
}
