.pricing-plan-card {
  display: flex;
  flex-direction: column;
  width: 375px;
  min-height: 500px;
  max-width: 100%;
  min-width: 0;

  background: linear-gradient(271.09deg, #4d6dd3 -14.35%, #353b7a 117.24%);
  border-radius: 12.5px;

  @include viewport--sm {
    width: 342px;
  }

  &__top-text {
    padding: 0 16px;
    margin: 16px 0 13px;

    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: $color-default-white;

    span {
      font-weight: 700;
    }
  }

  &__main-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 57px 39px 50px;

    background: $color-default-white;
    border: 1px solid $color-blue;
    box-shadow: 0px 3.5px 19px rgba(66, 92, 176, 0.1);
    border-radius: 12.5px;

    @include viewport--sm {
      padding: 39px 19px;
    }
  }

  &__title {
    margin: 0;
    margin-bottom: 37px;

    font-weight: 700;
    font-size: 27px;
    line-height: 33px;
    text-align: center;

    @include viewport--sm {
      margin-bottom: 25px;
    }
  }

  &__descr {
    width: 250px;
    max-width: 100%;
    min-height: 100px;
    margin-bottom: 37px;

    font-size: 16.5px;
    line-height: 25px;
    text-align: center;
    color: $color-comet;

    @include viewport--sm {
      min-height: 0;
      margin-bottom: 25px;
    }
  }

  &__price-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    min-height: 63px;
    margin-bottom: 37px;
    margin-top: 20px;
    opacity: 0;

    @include viewport--sm {
      margin-bottom: 25px;
    }
  }

  &__from {
    font-weight: 700;
    font-size: 16.5px;
    line-height: 31px;
    text-align: center;
    color: $color-blue;
  }

  &__price {
    font-weight: 700;
    font-size: 27px;
    line-height: 31px;
    text-align: center;
    color: $color-blue;
  }

  &__spend {
    font-weight: 100;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: $color-blue;
    font-style: italic;
    opacity: 0;
  }

  &__period {
    font-weight: 100;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: $color-blue;
    font-style: italic;
  }

  &__demo-btn {
    padding-left: 32px;
    padding-right: 32px;
    min-width: 214px;
    margin-bottom: 37px;

    @include viewport--sm {
      margin-bottom: 25px;
    }
  }

  &__separator {
    width: 268px;
    max-width: 100%;
    height: 1px;
    margin-bottom: 37px;

    background-color: $color-french-gray;

    @include viewport--sm {
      margin-bottom: 25px;
    }
  }

  &__details-wrapper {
    width: 285px;
    max-width: 100%;
    min-height: 244px;
    margin-bottom: 20px;

    h3 {
      display: inline;
      margin: 0;

      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
      color: inherit;
    }

    @include viewport--sm {
      margin-bottom: 25px;
      min-height: 100px;
    }
  }

  &__details-title {
    margin-bottom: 30px;

    font-weight: 700;
    font-size: 14px;
    line-height: 23px;
    color: $color-comet;

    @include viewport--sm {
      margin-bottom: 18px;
    }
  }

  &__details-item {
    position: relative;

    padding-left: 20px;
    margin-bottom: 25px;

    font-size: 14px;
    line-height: 18px;
    color: $color-comet;

    @include viewport--sm {
      margin-bottom: 16px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: "•";
      position: absolute;
      top: 0;
      left: 7px;
    }

    span {
      font-weight: 700;
    }
  }

  &__quote-block {
    display: flex;
    align-items: center;
    width: 285px;
    max-width: 100%;
    min-height: 73px;
    padding: 10px 25px;
    margin-top: 20px;
    margin-bottom: 41px;

    background: $color-rose-white;
    border: 1px solid $color-scarlet;
    border-radius: 5px;

    img {
      flex-shrink: 0;
      margin-right: 24px;
    }

    @include viewport--sm {
      margin-top: 0;
      margin-bottom: 25px;
    }
  }

  &__quote-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: $color-gun-powder;

    span {
      font-weight: 700;
    }
  }

  &__partners {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-left: -8px;

    @include viewport--md {
      display: none;
    }

    a[href] {
      transition: opacity $default-transition-settings;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &__partner-gmp {
    margin-bottom: 7px;
  }

  &__link {
    display: flex;
    align-items: center;
    margin-top: auto;

    font-weight: 600;
    font-size: 16.5px;
    line-height: 25px;
    color: $color-blue;

    &::after {
      content: "";
      display: block;
      flex-shrink: 0;
      width: 7px;
      height: 12px;
      margin-left: 8px;

      background-image: url("/assets/img/svg/angle-blue-right.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

      transition: transform $default-transition-settings;
    }

    &:hover {
      &::after {
        transform: translateX(5px);
      }
    }
  }

  &--standard {
    .pricing-plan-card__quote-wrapper
      > .pricing-plan-card__quote-block:first-child {
      margin-top: 85px;
      margin-bottom: 0px;

      @include viewport--sm {
        margin-top: 0px;
        margin-bottom: 25px;
      }
    }
  }

  &--professional {
    width: 381px;

    @include viewport--sm {
      width: 342px;
    }

    .pricing-plan-card {
      &__title {
        @include viewport--sm {
          margin-bottom: 44px;
        }
      }

      &__descr {
        width: 268px;

        @media (max-width: 1297px) {
          margin-bottom: 5px;
        }

        //@include viewport--sm {
        //  margin-bottom: 44px;
        //}
      }

      &__price-wrapper {
        @include viewport--sm {
          margin-bottom: 44px;
        }
      }

      &__demo-btn {
        @include viewport--sm {
          margin-bottom: 44px;
        }
      }

      &__details-title {
        margin-bottom: 15px;

        @include viewport--sm {
          margin-bottom: 18px;
        }
      }

      &__details-item {
        margin-bottom: 12px;

        @include viewport--sm {
          margin-bottom: 16px;
        }
      }

      &__quote-block {
        display: none;

        @include viewport--sm {
          display: flex;
          margin-bottom: 40px;
        }
      }
    }
  }

  &--basic {
    width: 338px;

    background: none;

    @include viewport--sm {
      width: 342px;
    }

    .pricing-plan-card {
      &__top-text {
        visibility: hidden;

        @include viewport--sm {
          display: none;
        }
      }

      &__main-wrapper {
        // @include viewport--sm {
        //   padding: 55px 40px;
        // }

        border-color: $color-default-white;
      }

      &__descr {
        width: 220px;

        //@include viewport--sm {
        //  margin-bottom: 42px;
        //}
      }

      &__price-wrapper {
        @include viewport--sm {
          margin-bottom: 42px;
        }
      }

      &__demo-btn {
        padding-left: 56px;
        padding-right: 56px;
      }

      &__details-wrapper {
        @include viewport--sm {
          min-height: 260px;
          margin-bottom: 30px;
        }
      }

      &__details-title {
        margin-bottom: 15px;

        @include viewport--sm {
          margin-bottom: 18px;
        }
      }

      &__details-item {
        margin-bottom: 20px;

        @include viewport--sm {
          margin-bottom: 16px;
        }
      }
    }
  }
}
