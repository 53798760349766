.ai-page {
  .advantages {
    margin-top: 56px;
    margin-bottom: 60px;

    @include viewport--md {
      margin-top: 27px;
      margin-bottom: 44px;
    }

    @include viewport--sm {
      margin-top: 0;
      margin-bottom: 4px;
    }
  }

  .get-started {
    margin-top: 26px;
    margin-bottom: 12px;

    @include viewport--md {
      margin-top: 117px;
      margin-bottom: 0;
    }

    @include viewport--sm {
      margin-top: 96px;
    }
  }
}
