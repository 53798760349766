.popup {
    min-height: 600px;

    @include viewport--sm {
        min-height: 560px;
    }

    &.is-success {
        min-width: 420px;
        min-height: 490px;

        .popup__container {
            height: 0;

            opacity: 0;

            pointer-events: none;
        }

        .popup__success {
            opacity: 1;
        }
    }

    &--vacancy {
        svg {
            display: block;
            width: 59px;
            height: 77px;
        }
    }

    &--request {
        .popup__left svg {
            display: block;
            width: 59px;
            height: 77px;

            @include viewport--sm {
                display: none;
            }
        }

        .popup__left-title {
            margin-bottom: 32px;
        }
    }

    &--pricing {
        .popup__left {
            padding: 50px 70px 20px;
            width: 440px;

            @include viewport--md {
                padding: 45px 45px;
            }

            @include viewport--sm {
                padding: 28px 20px 32px;
                width: 100%;
            }
        }

        .popup__right {
            width: 460px;

            @include viewport--sm {
                width: 100%;
            }
        }

        img {
            display: block;
            width: 62px;
            height: 62px;
            object-fit: contain;
        }

        .popup__left-title {
            margin-top: 33px;
            margin-bottom: 55px;

            @include viewport--md {
                margin-top: 40px;
                font-size: 22px;
                line-height: 27px;
                max-width: 200px;
            }

            @include viewport--sm {
                margin-top: 25px;
                margin-bottom: 15px;
            }
        }
    }

    &--bigquery {
        .popup__left {
            padding: 50px 70px;
            width: 450px;

            @include viewport--md {
                padding: 45px 45px;
            }

            @include viewport--sm {
                padding: 28px 20px 32px;
                width: 100%;
            }
        }

        .popup__left-list {
            margin-bottom: 18px;
        }

        .popup__right {
            width: 450px;

            background-color: $color-default-white;
            background-image: linear-gradient(180deg, #F7F8FC 0%, rgba(247, 248, 252, 0) 100%);

            @include viewport--sm {
                padding: 35px 20px;
                width: 100%;
            }
        }

        img {
            display: block;
            width: 62px;
            height: 62px;
            object-fit: contain;
        }

        .popup__left-title {
            margin-top: 28px;
            margin-bottom: 45px;

            @include viewport--md {
                margin-top: 38px;
                font-size: 22px;
                line-height: 27px;
                max-width: 305px;
            }

            @include viewport--sm {
                margin-top: 18px;
                margin-bottom: 15px;
            }
        }
    }

    &--request {
        min-height: auto;

        .popup__container {
            min-height: auto;
        }

        .popup__left {
            padding-right: 50px;

            @include viewport--md {
                padding-right: 35px;
            }

            @include viewport--sm {
                padding-bottom: 8px;
                padding-right: 27px;
            }
        }

        .popup__left-title {
            @include viewport--md {
                font-size: 24px;
                line-height: 29px;
                margin-bottom: 57px;
            }

            @include viewport--sm {
                margin-bottom: 23px;
                font-size: 22px;
                line-height: 27px;
                max-width: 269px;
            }
        }

        .popup__left-item {
            @include viewport--sm {
                margin-bottom: 20px;
                font-size: 16px;
                line-height: 19px;
            }
        }

        .popup__right {
            padding-bottom: 50px;

            @include viewport--md {
                padding-top: 42px;
            }

            @include viewport--sm {
                padding-top: 27px;
                padding-bottom: 20px;
            }
        }

        .popup__title {
            margin-bottom: 37px;

            @include viewport--sm {
                //margin-bottom: 12px;
                display: none;
            }
        }

        .popup__form {
            margin-bottom: 0;
        }

        .popup__btn-wrap {
            //margin-top: 24px;

            @include viewport--md {
                margin-top: 20px;
            }

            @include viewport--sm {
                margin-top: 12px;
            }
        }
    }
}

.popup__container {
    position: relative;

    display: flex;
    min-height: 600px;
    overflow: hidden;

    background-color: white;
    border-radius: 10px;

    transition: opacity $default-transition-settings;

    @include viewport--sm {
        display: block;
        min-height: 560px;
    }
}

.popup__left {
    width: 400px;
    padding: 55px 70px;

    @include viewport--md {
        width: 360px;
        padding: 41px 44px;
    }

    @include viewport--sm {
        width: 100%;
        padding: 25px 20px 47px;
    }

    @media (max-width: 350px) {
        padding-top: 50px;
    }
}

.popup__left-title {
    margin-top: 52px;
    margin-bottom: 22px;

    font-weight: bold;
    font-size: 26px;
    line-height: 32px;
    color: $color-black;

    @include viewport--md {
        margin-top: 60px;
        margin-bottom: 30px;
    }

    @include viewport--sm {
        margin-top: 0px;
        margin-bottom: 28px;
    }
}

.popup__left-subtitle {
    margin-bottom: 25px;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;

    @include viewport--sm {
        margin-bottom: 17px;
        font-size: 18px;
        line-height: 22px;
    }
}

.popup__left-text {
    font-size: 16px;
    line-height: 19px;
    color: $color-ship-cove;
}

.popup__left-list {
    list-style: none;
    padding: 0;
    margin: 0 0 45px;

    @include viewport--sm {
        margin-bottom: 17px;
    }
}

.popup__left-item {
    position: relative;
    font-size: 16px;
    line-height: 19px;
    color: $color-ship-cove;
    padding-left: 23px;
    margin-bottom: 20px;

    @include viewport--sm {
        margin-bottom: 22px;
        padding-left: 25px;
        font-size: 14px;
        line-height: 17px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &:before {
        content: '';
        position: absolute;
        display: block;
        width: 13px;
        height: 9px;
        top: 5px;
        left: 0;
        background-image: url("../img/svg/icon-tick.svg");
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.popup__left-link {
    color: $color-blue;
    font-size: 18px;
    line-height: 40px;
    font-weight: 600;
    text-decoration: underline;

    transition: color $default-transition-settings;

    @media (hover: hover), screen and (min-width: 0 \0
    ) {
        &:hover,
        &:focus {
            color: $color-black;
        }
    }
}

.popup__right {
    width: 500px;
    padding: 65px 40px;

    background-color: $color-lavender-2;

    @include viewport--md {
        width: 380px;
        padding: 60px 20px;
    }

    @include viewport--sm {
        width: 100%;
        padding: 42px 20px;
    }

    &--price {
        padding: 0;
    }
}

.popup__title {
    max-width: 295px;
    margin: 0 auto 67px;

    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: $color-ship-cove;

    @include viewport--md {
        margin-bottom: 48px;
    }

    @include viewport--sm {
        max-width: none;
        margin-bottom: 25px;

        font-size: 22px;
    }
}

.popup__right-title {
    margin: 0 auto 25px;
    font-size: 20px;
    line-height: 1.4;
    font-weight: bold;
    max-width: 300px;
    text-align: center;

    @include viewport--md {
        margin-bottom: 20px;
        line-height: 24px;
    }

    @include viewport--sm {
        margin-bottom: 30px;
        font-size: 18px;
    }
}

.popup__form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 65px;

    @include viewport--md {
        margin-bottom: 60px;
    }

    @include viewport--sm {
        flex-direction: column;
        margin-bottom: 16px;
    }

    &--price {
        margin-bottom: 0;
    }
}

//
//@include viewport--sm {
//  .field-company, .field-phone {
//    display: none;
//  }
//}


.popup__item {
    position: relative;

    width: 100%;
    margin-bottom: 12px;

    @include viewport--sm {
        width: 100%;
    }

    @include viewport--sm {
        margin-bottom: 13px;
    }

    label,
    .custom-select__lab {
        position: absolute;
        top: 9px;
        left: 11px;
        height: 15px;
        overflow: hidden;

        font-weight: 400;
        font-size: 12px;
        line-height: 1.2;
        color: $color-ship-cove;
    }

    input,
    select {
        width: 100%;
        padding: 30px 11px 8px;

        font-weight: 600;
        font-size: 16px;
        line-height: 1.2;
        color: $color-black;

        background-color: $color-default-white;
        border: 1px solid $color-hawkes-blue;
        text-overflow: ellipsis;
        border-radius: 5px;
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
    }

    &--select {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            width: 11px;
            height: 6px;
            top: 50%;
            transform: translateY(-50%);
            right: 12px;
            background-image: url("../img/svg/icon-select-dropdown.svg");
            background-size: contain;
            background-repeat: no-repeat;
            pointer-events: none;

            @include viewport--md {
                right: 15px;
            }
        }
    }
}

.popup__select {
    margin-bottom: 25px;
    position: relative;
    width: 100%;

    select {
        width: 100%;
        padding: 18px 40px 18px 24px;
        font-size: 18px;
        line-height: 30px;
        font-weight: 600;
        color: $color-ship-cove;
        border: 1px solid $color-hawkes-blue;
        border-radius: 5px;
        background-image: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 100%);
        appearance: none;
        cursor: pointer;

        @include viewport--md {
            padding: 17px 40px 17px 17px;
        }

        @include viewport--sm {
            padding: 12px 35px 12px 16px;
            font-size: 16px;
        }
    }

    &::after {
        content: "";
        position: absolute;
        width: 17px;
        height: 9px;
        top: 50%;
        transform: translateY(-50%);
        right: 26px;
        background-image: url("../img/svg/icon-dropdown.svg");
        background-size: contain;
        background-repeat: no-repeat;
        pointer-events: none;

        @include viewport--md {
            right: 15px;
        }
    }
}

.popup__btn-wrap {
    //margin: 13px auto 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    //@include viewport--md {
    //  flex-direction: column-reverse;
    //}

    p {
        //max-width: 165px;
        margin: 25px 15px;

        font-size: 12px;
        line-height: 15px;

        @include viewport--md {
            max-width: none;
            margin: 0;
        }
    }

    a {
        color: $color-cerulean-blue;
        text-decoration: underline;

        transition: color $default-transition-settings;

        @media (hover: hover), screen and (min-width: 0 \0
        ) {
            &:hover,
            &:focus {
                color: $color-black;
            }
        }
    }

    button,
    a.btn {
        width: 100%;
        min-width: 236px;
        padding: 20px 26px;

        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: $color-default-white;
        text-decoration: none;

        @include viewport--md {
            // width: 100%;
            margin-bottom: 28px;
        }

        @include viewport--sm {
            width: 100%;
            margin-bottom: 23px;
        }
    }

    &--price {
        display: block;
        text-align: center;

        button {
            width: 100%;

            @include viewport--md {
                margin-bottom: 0;
            }
        }

        .popup__card {
            margin-top: 15px;
            font-size: 14px;
            line-height: 24px;
            font-weight: 600;
            color: $color-ship-cove;
        }
    }
}

.popup__close {
    position: absolute;
    top: 28px;
    right: 25px;

    padding: 0;

    background-color: $color-transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;

    @include viewport--md {
        top: 17px;
        right: 19px;
    }

    svg {
        display: block;
    }
}

.popup__success {
    position: relative;
    position: absolute;
    top: 50%;
    left: 50%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //width: 900px;
    //height: 600px;
    overflow: hidden;

    color: $color-black;

    background-color: $color-default-white;
    border-radius: 10px;
    transform: translate(-50%, -50%);
    opacity: 0;

    @include viewport--md {
        //width: 740px;
    }

    @include viewport--sm {
        padding: 0 20px;
        text-align: center;
        width: 320px;
        //height: 560px;
    }

    & > svg {
        display: block;
        width: 59px;
        height: 77px;
        margin-bottom: 42px;

        @include viewport--md {
            margin-bottom: 34px;
        }

        @include viewport--sm {
            margin-bottom: 20px;
        }
    }

    &--more {
        //justify-content: flex-start;

        //& > svg {
        //  margin-top: 75px;
        //
        //  @include viewport--md {
        //    margin-top: 80px;
        //  }
        //
        //  @include viewport--sm {
        //    margin-top: 55px;
        //    margin-bottom: 37px;
        //  }
        //}

        min-height: 420px;

        .popup__success-title {
            margin-bottom: 15px;

            @include viewport--sm {
                margin-bottom: 10px;
            }
        }

        & .popup__success-text {
            margin-bottom: 43px;
        }
    }
}

.popup__success-title {
    margin-bottom: 10px;

    font-weight: bold;
    font-size: 48px;
    line-height: 58px;

    @include viewport--sm {
        font-size: 34px;
        line-height: 41px;
    }
}

.popup__success-text {
    font-size: 18px;
    line-height: 22px;
}

.popup__success-subtitle {
    margin-top: 60px;
    font-size: 24px;
    line-height: 1.2;
    font-weight: bold;
    color: $color-ship-cove;

    @include viewport--md {
        font-size: 25px;
        margin-top: 43px;
    }

    @include viewport--sm {
        margin-top: 30px;
    }
}

.popup__success-book {
    margin-top: 5px;
    font-size: 18px;
    line-height: 1.2;
    color: $color-ship-cove;

    @include viewport--sm {
        max-width: 240px;
        margin-top: 17px;
    }
}

.popup__success-link {
    font-size: 18px;
    line-height: 22px;
    padding: 20px 32px;
    margin-top: 37px;

    @include viewport--sm {
        margin-top: 43px;
        width: 100%;
    }
}

.popup__back-btn {
    display: flex;
    align-items: center;
    margin-top: 27px;
    margin-left: 25px;

    font-weight: 600;
    font-size: 18px;
    line-height: 31px;
    color: $color-some-grey;
    background-color: transparent;

    border: none;
    cursor: pointer;
    transition: color $default-transition-settings, border $default-transition-settings, opacity $default-transition-settings;

    & svg {
        width: 23px;
        height: 18px;
        margin-right: 15px;
        pointer-events: none;
        fill: $color-some-grey;
        transition: fill $default-transition-settings;
    }

    @media (hover: hover), screen and (min-width: 0 \0
    ) {
        &:hover,
        &:focus {
            color: $color-blue;

            svg {
                fill: $color-blue;
            }
        }

        &:active {
            color: $color-portage;

            svg {
                fill: $color-portage;
            }
        }
    }
}

.popup--hubspot-form {
    min-height: initial;
}

.popup__container--hubspot-form {
    display: initial;
    min-height: initial;

    & .meetings-iframe-container {
        padding-top: 70px;

        @include viewport--md {
            padding-top: 55px;
        }
    }

    & iframe {
        max-height: 730px;

        @include viewport--sm {
            height: auto !important;
            min-height: 700px !important;
        }
    }

    .popup__back-btn {
        @include viewport--md {
            margin-left: 18px;
            margin-top: 17px;
        }
    }
}

.popup--choose-request {
    min-height: initial;
}

.popup--meeting-aniway {
    min-height: unset;

    .popup__title {
        color: $color-blue;
        font-family: $font-family--lato;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0 0 30px 0;
        max-width: unset;
        text-align: left;
    }

    .popup__content {
        margin-bottom: 37px;
    }

    .popup__text {
        color: #353B7A;
        font-family: $font-family--inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin: 0;

        &--mb {
            margin-bottom: 26px;
        }

        &--understanding {
            font-size: 17px;
            font-style: italic;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0;
        }
    }

    .popup__btn {
        width: fit-content;
        margin-bottom: 38px;
        padding-left: 34px;
        padding-right: 34px;
    }

}

.popup__container--choose-request {
    flex-direction: column;
    align-items: center;
    padding: 50px;
    min-height: 390px;

    @include viewport--md {
        display: flex;
    }

    & .popup__title--choose-request {
        max-width: 85%;
        text-align: center;
        margin-bottom: 50px;
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;

        color: $color-torea-bay-4;
    }

    & .popup__choose-btns {
        display: flex;

        & > button + button {
            margin-left: 30px;
        }

        @include viewport--sm {
            flex-direction: column;
            align-items: center;

            & > button + button {
                margin-left: 0;
                margin-top: 20px;
            }
        }
    }

    & .popup__btn-choose {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 1.15;

        background-color: transparent;
        cursor: pointer;
        transition: color $default-transition-settings, box-shadow $default-transition-settings, border $default-transition-settings, opacity $default-transition-settings;
        color: $color-black;

        padding: 40px;
        width: 265px;
        height: 203px;
        border: 1px solid $color-solitude;
        border-radius: 10px;
        box-shadow: $card-light-shadow;

        @media (hover: hover), screen and (min-width: 0 \0
        ) {
            &:hover,
            &:focus {
                border: 1px solid $color-perano;
                box-shadow: 0px 3px 20px rgba(44, 66, 135, 0.15);
            }

            //&:active {
            //}
        }
    }

    & .popup__btn-choose--schedule {
        & svg {
            width: 80px;
            height: 80px;
        }
    }

    & .popup__btn-choose--contact {
        & svg {
            width: 97px;
            height: 97px;
        }
    }
}

.popup__container--request-form {
    flex-direction: column;
    align-items: center;
    width: 600px;
    min-height: initial;
    padding: 50px;
    padding-bottom: 30px;
    padding-top: 25px;

    @include viewport--md {
        //width: 380px;
        padding: 25px 20px;
    }

    @include viewport--sm {
        width: 100%;
        padding: 25px 20px;
    }

    & .popup__back-btn {
        align-self: start;
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 25px;
        padding-left: 0;
    }

    & form {
        background-color: transparent;
        //width: 500px;
        width: 100%;
        //padding: 65px 40px;

        @include viewport--md {
            //width: 380px;
            //padding: 0 20px;
        }

        @include viewport--sm {
            //width: 100%;
            //padding: 0;
        }
    }

    .field-text {
        padding: 30px 11px 5px;
        border: 1px solid $color-hawkes-blue;
        border-radius: 5px;
    }

    textarea[name="text"] {
        min-height: 80px;
        width: 100%;
        padding: 0;

        font-weight: 600;
        font-size: 16px;
        line-height: 1.2;
        color: $color-black;

        background-color: $color-default-white;
        border: none;
        text-overflow: ellipsis;
    }

    .popup__btn-wrap {
        margin-top: 0;
    }
}

.popup__container--meeting-aniway {
    display: flex;
    flex-direction: column;
    min-height: auto;
}

.modal--request {
    .modal__content {
        width: initial;
        padding: 0;
        min-height: initial;

        border-radius: 10px;

        @include viewport--md {
            width: initial;
        }

        @include viewport--sm {
            width: 100%;
        }
    }

    .modal__close-btn {
        position: absolute;
        top: 28px;
        right: 25px;

        width: 30px;
        height: 30px;
        padding: 0;

        background-color: $color-transparent;
        background-image: url("../img/svg/icon-close-popup.svg");
        background-repeat: no-repeat;
        background-size: contain;

        @include viewport--md {
            top: 17px;
            right: 19px;
        }

        svg {
            display: none;
        }
    }
}


.modal--vacancy,
.modal--analytics,
.modal--bigquery,
.modal--choose-request-type,
.modal--hubspot-form {
    .modal__content {
        width: 900px;
        padding: 0;

        border-radius: 10px;

        @include viewport--md {
            width: 740px;
        }

        @include viewport--sm {
            width: 100%;
        }
    }

    .modal__close-btn {
        position: absolute;
        top: 28px;
        right: 25px;

        width: 30px;
        height: 30px;
        padding: 0;

        background-color: $color-transparent;
        background-image: url("../img/svg/icon-close-popup.svg");
        background-repeat: no-repeat;
        background-size: contain;

        @include viewport--md {
            top: 17px;
            right: 19px;
        }

        svg {
            display: none;
        }
    }
}

.modal--meeting-aniway-modal {
    .modal__content {
        width: 700px;
        padding: 45px 68px 48px 61px;
        border-radius: 10px;

        @include viewport--sm {
            width: 100%;
            padding: 45px 20px 38px;
        }
    }

    .modal__close-btn {
        top: 25px;
        right: 25px;

        svg {
            width: 18px;
            height: auto;
            fill: $color-comet;
        }
    }
}

.modal--choose-request-type {
    & .modal__content {
        width: initial;
    }
}

.popup__range {
    padding: 45px 40px 38px;
    background-color: $color-blue;
    color: $color-default-white;
    width: 100%;

    @include viewport--sm {
        padding: 45px 20px 38px;
    }

    .range__subtitle {
        margin-bottom: 15px;
    }

    .range-value {
        margin-top: 20px;
    }
}

.popup__rate {
    padding: 27px 20px 33px;
    min-height: 358px;

    .rate__offer {
        margin-bottom: 15px;
    }

    .rate__price-wrap {
        padding: 8px 10px;
    }
}

.popup__custom-select {

    .custom-select__button {
        width: 100%;
        padding: 30px 11px 8px;
        padding-right: 21px;

        font-weight: 600;
        font-size: 16px;
        line-height: 1.2;
        color: $color-black;

        background-color: $color-default-white;
        border: 1px solid $color-hawkes-blue;
        text-overflow: ellipsis;
        border-radius: 5px;
    }

    .custom-select__lab {
        pointer-events: none;
        max-width: 95%;
    }
}
