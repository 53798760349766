.founder {
  $root: &;
  color: $color-midnight-express;

  &__description {
    display: grid;
    grid-template-columns: 54px 1fr;
    gap: 15px;
    align-items: center;


    max-width: 365px;

    font-size: 18px;
    line-height: 22px;

    font-family: $font-family--inter;
    
    p {
      font-family: $font-family--inter;
    }

    margin-bottom: 20px;

    @include viewport--lg {
      margin: 0 0 20px;
    }
  }

  &__image {
    border: 1px solid #a6bbff;
    border-radius: 50%;
    width: 54px;
    height: 54px;
    padding: 5px;

    img {
      width: 42px;
      height: 42px;
    }
  }

  &__text {
    margin: 0;
  }

  &__links {
    padding: 0;
    margin: 0;
    list-style: none;

    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-items: stretch;
    gap: 10px;
  }

  &__link {
    background: $color-default-white;
    border-radius: 8px;
    padding: 10px;

    display: flex;
    align-items: center;
    gap: 5px;
    min-height: 100%;

    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: $color-midnight-express;

    font-family: $font-family--inter;
    min-height: 49px;

    text-align: center;

    box-shadow: 0 0 10px 0 rgba(13, 19, 52, 0.2);

    img {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
    }

    @include viewport--lg {
      justify-content: center;
    }


  }

  &__links-item {
    &--email {
      position: relative;
      z-index: 1;

      img {
        width: 26px;
        height: 19px;
      }

      .demo-form-section__link {
        position: relative;
        z-index: 1;

        &::before {
          content: "";
          position: absolute;
          z-index: 1;
          background: url("/assets/img/svg/copy.svg");
          width: 16px;
          height: 16px;
          right: 12px;
          top: 50%;
          transform: translateY(-50%);
          opacity: 0;
          transition: opacity $default-transition-settings;
        }

        @include hover-focus {
          &::before {
            opacity: 1;
          }
        }
      }

      

    }

    &--whatsapp {
      img {
        width: 29px;
        height: 29px;
      }
    }
  }

  &__link-text {
    flex-grow: 1;

    @include viewport--lg {
      display: none;
    }
  }

  &__tooltip {
    position: absolute;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    top: calc(100% + 18px);
    background: #364b8a;
    border-radius: 8px;
    padding: 10px 16px;
    width: 212px;

    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: $color-default-white;
    font-family: $font-family--inter;
    opacity: 0;
    transition: opacity $default-transition-settings;
    pointer-events: none;


    &::before {
      content: "";
      position: absolute;
      z-index: 1;
      background: url("/assets/img/svg/tooltip-arrow.svg");
      width: 15px;
      height: 10px;
      left: 50%;
      transform: translateX(-50%);
      top: -7px;
    }

    strong {
      font-weight: 600;
    }

    &.show {
      opacity: 1;
      pointer-events: auto;
    }

    @include viewport--sm {
      left: 0;
      transform: none;

      &::before {
        left: 10px;
        transform: none;
      }
    }
  }

  &--white {
    color: $color-default-white;

    #{$root}__link {
      box-shadow: none;
    }
  }
}