.header {
  background-color: $color-default-white;
  //border-bottom: 1px solid $color-lavender;

  @include viewport--md {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10000;
  }

  &__wrapper {
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
    padding: 32px 35px 34px 31px;
    display: flex;
    justify-content: space-between;

    @include viewport--lg {
      padding: 32px 40px 34px;
    }

    @include viewport--md {
      padding: 32px 38px 32px 23px;
    }

    @include viewport--sm {
      padding: 25px;
      padding-left: 17px;
    }
  }

  &__logo {
    flex-shrink: 0;
    display: flex;
    //margin-right: 6.67vw;
    width: 215px;
    max-width: 215px;
    position: relative;

    svg {
      width: 100%;
      height: 100%;
    }

    @include viewport--lg {
      width: 18.7%;
      margin-right: 24px;
      margin-left: 0;
    }

    @include viewport--md {
      width: 215px;
      margin-right: 24px;
    }

    @include viewport--sm {
      width: 193px;
      height: 39px;
    }
  }

  &__logo-svg-white {
    position: absolute;
    z-index: 1;
    opacity: 0;
    top: 0;
    left: 0;
    height: 100%;
    transition: opacity $default-transition-settings;
  }


  &--ghost {
    background-color: $color-ghost-white;

    .main-nav__wrapper {
      background-color: $color-ghost-white;
    }

    .main-nav__submenu {
      @include viewport--md {
        background-color: $color-ghost-white;
      }
    }
  }

  &--blue {
    background-color: $color-torea-bay;

    .header__logo {
      margin-left: 0;
    }

    .header__logo-svg-white {
      opacity: 1;
    }

    .main-nav__toggle {
      svg {
        &:first-child,
        &:last-child {
          fill: $color-default-white;
        }
      }

      @media (hover: hover), screen and (min-width: 0 \0
      ) {
        &:hover,
        &:focus {
          svg {
            fill: rgba($color-default-white, 0.6);
          }
        }
      }
    }

    .main-nav__wrapper,
    .main-nav__submenu {
      @include viewport--md {
        background-color: $color-torea-bay;
      }
    }

    .main-nav__link {
      color: $color-default-white;

      svg {
        stroke: $color-default-white;
      }

      @media (hover: hover), screen and (min-width: 0 \0
      ) {
        &:not(.main-nav__link--active):hover,
        &:not(.main-nav__link--active):focus {
          color: rgba($color-default-white, 0.6);

          svg {
            stroke: rgba($color-default-white, 0.6);
          }
        }
      }

      &--active {
        color: rgba($color-default-white, 0.6);
      }
    }

    @include viewport--md {
      .main-nav__submenu-backwards:not(.hack-selector),
      .main-nav__submenu-link:not(.hack-selector) {
        color: $color-default-white;

        svg {
          stroke: $color-default-white;
        }

        @media (hover: hover), screen and (min-width: 0 \0
        ) {
          &:not(.main-nav__submenu-link--active):hover,
          &:not(.main-nav__submenu-link--active):focus {
            color: rgba($color-default-white, 0.6);

            svg {
              stroke: rgba($color-default-white, 0.6);
            }
          }
        }
      }

      .main-nav__submenu-link--active:not(.hack-selector) {
        color: rgba($color-default-white, 0.6);
      }

      .main-nav__submenu-item:not(.hack-selector) {
        .main-nav__submenu-title,
        .main-nav__submenu-description {
          color: $color-default-white;
        }

        &::before {
          background-color: $color-jacksons-purple;
        }

        .main-nav__submenu-more {
          color: $color-orange;

          @media (hover: hover), screen and (min-width: 0 \0
          ) {
            &:not(.main-nav__submenu-more--active):hover,
            &:not(.main-nav__submenu-more--active):focus {
              color: $color-default-white;
            }
          }

          &--active {
            color: rgba($color-default-white, 0.4);
          }
        }
      }
    }

    .btn--bordered {
      color: $color-default-white;
      border-color: $color-default-white;
    }
  }

  &--transparent-white {
    background-color: transparent;
    border-bottom: none;

    .header__logo {
      margin-left: 0;
    }

    .main-nav__toggle {
      svg {
        &:first-child,
        &:last-child {
          fill: $color-default-white;
        }
      }

      @media (hover: hover), screen and (min-width: 0 \0
      ) {
        &:hover,
        &:focus {
          svg {
            fill: rgba($color-default-white, 0.6);
          }
        }
      }
    }

    .main-nav__wrapper,
    .main-nav__submenu {
      @include viewport--md {
        background-color: $color-torea-bay;
      }
    }

    .main-nav__link {
      color: $color-default-white;

      svg {
        stroke: $color-default-white;
      }

      @media (hover: hover), screen and (min-width: 0 \0
      ) {
        &:not(.main-nav__link--active):hover,
        &:not(.main-nav__link--active):focus {
          color: rgba($color-default-white, 0.6);

          svg {
            stroke: rgba($color-default-white, 0.6);
          }
        }
      }

      &--active {
        color: rgba($color-default-white, 0.6);
      }
    }

    @include viewport--md {
      .main-nav__submenu-backwards:not(.hack-selector),
      .main-nav__submenu-link:not(.hack-selector) {
        color: $color-default-white;

        svg {
          stroke: $color-default-white;
        }

        @media (hover: hover), screen and (min-width: 0 \0
        ) {
          &:not(.main-nav__submenu-link--active):hover,
          &:not(.main-nav__submenu-link--active):focus {
            color: rgba($color-default-white, 0.6);

            svg {
              stroke: rgba($color-default-white, 0.6);
            }
          }
        }
      }

      .main-nav__submenu-link--active:not(.hack-selector) {
        color: rgba($color-default-white, 0.6);
      }

      .main-nav__submenu-item:not(.hack-selector) {
        .main-nav__submenu-title,
        .main-nav__submenu-description {
          color: $color-default-white;
        }

        &::before {
          background-color: $color-jacksons-purple;
        }

        .main-nav__submenu-more {
          color: $color-orange;

          @media (hover: hover), screen and (min-width: 0 \0
          ) {
            &:not(.main-nav__submenu-more--active):hover,
            &:not(.main-nav__submenu-more--active):focus {
              color: $color-default-white;
            }
          }

          &--active {
            color: rgba($color-default-white, 0.4);
          }
        }
      }
    }

    .btn--bordered {
      color: $color-default-white;
      border-color: $color-default-white;
    }
  }

  &--absolute {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: transparent;

    @include viewport--md {
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 10000;
    }

    .header__logo {
      margin-left: 0;
    }

    .header__logo-svg-white {
      opacity: 1;
    }

    .main-nav__link {
      color: $color-default-white;

      svg {
        stroke: $color-default-white;

        @include viewport--md {
          stroke: $color-torea-bay-4;
        }
      }

      @media (hover: hover), screen and (min-width: 0 \0
      ) {
        &:not(.main-nav__link--active):hover,
        &:not(.main-nav__link--active):focus {
          color: rgba($color-default-white, 0.6);

          @include viewport--md {
            color: $color-blue;
          }

          svg {
            stroke: rgba($color-default-white, 0.6);

            @include viewport--md {
              stroke: $color-blue;
            }
          }
        }
      }

      &--active {
        color: rgba($color-default-white, 0.6);
      }

      &--current {
        color: $color-blue;
      }

      @include viewport--md {
        color: $color-torea-bay-4;
      }
    }

    @include viewport--md {
      background-color: $color-default-white;
    }
  }

  &--bg-dark-blue {
    background-color: $color-lucky-point;

    &.is-open-menu {
      @include viewport--md {
        background-color: $color-default-white;
      }
    }
  }

  &--book-demo,
  &--bg-dark-blue-2 {
    background-color: $color-midnight-express;

    &.is-open-menu {
      @include viewport--md {
        background-color: $color-default-white;
      }
    }
  }

  &--bg-scroll-mobile {
    @include viewport--md {
      background-color: #252d72;
      transition: background-color $default-transition-settings;

      
      .header__logo-svg-white {
        opacity: 1;
      }
      
      &.is-open-menu,
      &.is-scroll {
        background-color: $color-default-white;

        .header__logo-svg-white {
          opacity: 0;
        }
      }


      &:not(.is-open-menu):not(.is-scroll) {
        .main-nav__toggle {
          svg {
            &:first-child,
            &:last-child {
              fill: $color-default-white;
            }
          }
    
          @media (hover: hover), screen and (min-width: 0 \0
          ) {
            &:hover,
            &:focus {
              svg {
                fill: rgba($color-default-white, 0.6);
              }
            }
          }
        }
      }
    }
  }
}
