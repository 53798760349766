.info-cards {
  // test
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 45px;
  grid-row-gap: 60px;
  // display: flex;
  // justify-content: space-between;
  // flex-wrap: wrap;
  //
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;

  list-style: none;

  @include viewport--md {
    margin: 0 auto;
    // flex-direction: column;
    display: block;
    grid-column-gap: 0;
    // align-items: center;
    // margin-right: 0;
    // margin-bottom: -48px;
  }

  @include viewport--sm {
    // margin-bottom: -35px;
  }

  // &--wrapped {
  //   flex-wrap: wrap;
  // }

  &.swiper-wrapper {
    padding: 0 60px;
    // display: flex;
    // justify-content: space-between;
    // flex-wrap: nowrap;
    width: calc(100% - 120px);

    @include viewport--md {
      padding: 0;
      display: flex;
      align-items: stretch;
      width: max-content;
      flex-direction: row;
    }
  }
}

.info-cards__item {
  margin-right: 44px;
  margin-bottom: 60px;

  @include viewport--md {
    max-width: none;
    margin-right: 0;
    margin-bottom: 48px;
    padding: 0;
  }

  @include viewport--sm {
    margin-bottom: 35px;
  }

  &:last-child {
    margin-right: 0;
  }
}
