.leads-cards {
  &__container {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 1600px;
    margin: 50px auto 0;

    @include viewport--xl {
      max-width: 1280px;
      padding-left: 40px;
      padding-right: 40px;
    }

    @include viewport--lg {
      max-width: 1280px;
      padding-left: 40px;
      padding-right: 40px;
    }

    @include viewport--md {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__inner {
    padding: 72px 100px 140px;
    border-radius: 60px;
    background: linear-gradient(180.00deg, rgba(245, 246, 252, 0),rgb(245, 246, 252) 100%),rgb(227, 235, 255);

    @include viewport--xl {
      padding: 56px 75px 75px;
      border-radius: 45px;
    }

    @include viewport--md {
      padding: 40px 24px 39px;
      border-radius: 30px;
    }

    @include viewport--sm {
      padding: 25px 16px 48px;
    }
  }

  &__bg {
    position: absolute;
    width: 778px;
    top: -270px;
    right: -290px;

    @include viewport--xl {
      width: 441px;
      top: -206px;
      right: -75px;
    }

    @include viewport--md {
      width: 320px;
      top: -164px;
      right: -25px;
    }

    @include viewport--sm {
      width: 246px;
      top: -150px;
      right: -16px;

    }
  }

  &__bg-image {
    aspect-ratio: 778/437;
    width: 100%;

    @include viewport--md {
      aspect-ratio: 318/244;
    }
  }

  &__title {
    position: relative;
    z-index: 1;
    font-family: $font-family--inter;
    font-size: 48px;
    font-weight: 600;
    line-height: 64px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #33376B;
    margin: 0 0 72px;
    width: 100%;
    max-width: 674px;

    span {
      background: linear-gradient(90deg, #4d6dd3 0%, #5f98ff 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    @include viewport--xl {
      font-size: 36px;
      line-height: 48px;
      max-width: 555px;
      margin-bottom: 54px;
    }

    @include viewport--md {
      font-size: 32px;
      line-height: 40px;
      max-width: 490px;
      margin: 0 0 40px;
    }

    @include viewport--sm {
      max-width: 80%;
      margin: 0 0 70px;
    }
  }

  &__list-wrap {
    position: relative;
  }

  &__list {
    position: relative;
    z-index: 1;
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;

    @include viewport--xl {
      gap: 16px;
    }

    @include viewport--md {
      gap: 8px
    }

    @include viewport--sm {
      display: flex;
      flex-direction: column;
    }
  }

  &__item {
    height: 100%;
  }

  &__card {
    background-color: #fff;
    border-radius: 40px;
    padding: 24px;
    height: 100%;

    @include viewport--xl {
      border-radius: 30px;
      padding: 18px;
    }

    @include viewport--md {
      padding: 12px 12px 18px;
      border-radius: 20px;
    }

    @include viewport--sm {
      padding: 12px;
    }
  }

  &__card-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    margin-bottom: 40px;

    @include viewport--xl {
      width: 112px;
      height: 112px;
      margin-bottom: 32px;
    }

    @include viewport--md {
      margin-bottom: 24px;
    }

    @include viewport--sm {
      margin-bottom: 16px;
      width: 150px;
      height: 150px;
    }

    img {
      width: 100%;
      height: 100%;
      flex: 0 0 auto;
    }
  }

  &__card-text-wrap {
    padding: 8px;

    @include viewport--xl {
      padding: 6px;
    }

    @include viewport--md {
      padding: 4px;
    }
  }

  &__card-title {
    font-family: $font-family--inter;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: #33376b;
    margin: 0 0 12px;

    @include viewport--xl {
      font-size: 21px;
      line-height: 30px;
      margin: 0 0 9px;
    }

    @include viewport--md {
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.03em;
      color: #33376b;
      margin: 0 0 12px;
    }
  }

  &__card-text {
    font-family: $font-family--inter;
    margin: 0;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #414a68;

    @include viewport--xl {
      font-size: 16px;
      line-height: 26px;
    }

    @include viewport--md {
      font-size: 15px;
      line-height: 24px;
    }

    @include viewport--sm {
      font-size: 16px;
    }
  }
}
