.understanding-roas {
  padding-top: 75px;
  padding-bottom: 20px;

  @include viewport--md {
    padding-top: 50px;
  }

  &__container {
    padding: 0 100px;

    @include viewport--md {
      padding: 0 55px;
    }

    @include viewport--sm {
      padding: 0 20px;
    }
  }



  &__inner {
    display: flex;
    flex-direction: column;
    max-width: 960px;
    margin: 0 auto;
  }

  &__title {
    font-family: $font-family--inter;
    font-size: 36px;
    line-height: 150%;
    color: $color-deep-koamaru;
    text-align: center;
    font-weight: 700;
    margin: 0 auto 40px;

    @include viewport--md {
      font-size: 24px;
      line-height: 120%;
    }

    br {
      display: none;

      @include viewport--1280 {
        display: block;
      }
    }
  }

  &__text {
    margin: 0;
    font-family: $font-family--lato;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: $color-port-gore;

    @include viewport--md {
      font-size: 18px;
    }

    span {
      font-weight: 600;
      color: $color-blue;
    }
  }

  &__info-block-title {
    margin: 36px 0 0;
    font-family: $font-family--lato;
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;

    @include viewport--md {
      font-size: 20px;
    }
  }

  &__graph {
    width: calc(100% - 14px);
    border-radius: 24px;
    margin: 18px 0 30px;

    @include viewport--md {
      width: 100%;
    }

    @include viewport--sm {
      border-radius: 10px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 24px;
      box-shadow: 0 5px 9px 0 rgba(62, 72, 149, 0.08), 0 15px 15px 0 rgba(62, 72, 149, 0.05);

      @include viewport--sm {
        border-radius: 10px;     
      }
    }
  }
}