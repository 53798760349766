.say-card-big {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 686px;
  min-height: 294px;
  padding: 35px 60px 35px 66px;

  background-image: url("../img/bg/say-card-big-blue-bg.jpg");
  background-size: 100% 100%;
  border-radius: 15px;
  box-shadow: 0px 3.5px 28px rgba(39, 71, 176, 0.45);

  @include viewport--sm {
    flex-direction: column-reverse;
    max-width: 330px;
    min-height: 0;
    padding: 44px 20px;

    background-image: url("../img/bg/say-card-big-blue-bg-mob.jpg");
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 329px;

    @include viewport--sm {
      align-items: center;
      width: 100%;
    }
  }

  &__text {
    font-size: 24px;
    line-height: 29px;
    text-align: left;
    color: $color-default-white;

    span {
      font-weight: 700;
    }

    @include viewport--sm {
      text-align: center;
    }
  }

  .say-card-big__bottom-logo {
    margin: 45px 0 0;

    @include viewport--sm {
      display: none;
    }
  }

  .say-card-big__bottom-logo-mob {
    display: none;
    margin: 43px 0 0;

    @include viewport--sm {
      display: block;
    }
  }

  &__main-logo-wrapper {
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 156px;
    height: 147px;

    @include viewport--sm {
      width: auto;
      height: auto;
      margin-bottom: 43px;
    }
  }
}