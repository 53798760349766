.info-plate {
  $root: &;

  &__inner {
    display: grid;
    padding: 58px 0 0 88px;
    grid-template-columns: 1fr 53.3%;
    gap: 15px;
    border-radius: 7px;
    overflow: hidden;
    background: linear-gradient(80deg, #0e1435 9.81%, #1e3376 69.77%);

    @media (max-width: 1280px) {
      padding: 30px 20px 0 70px;
    }

    @include viewport--md {
      display: flex;
      flex-direction: column;
      padding-left: 55px;
      padding-right: 55px;
    }

    @include viewport--sm {
      padding-bottom: 30px;
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  &__text-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    margin-top: 10px;
    height: fit-content;

    @include viewport--md {
      max-width: 515px;
      align-self: center;
    }

    @include viewport--sm {
      max-width: unset;
    }
  }

  &__title {
    color: $color-default-white;
    font-family: $font-family--inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0 0 30px;

    @include viewport--sm {
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 0 0 40px;
    }
  }

  &__text {
    color: $color-lavender-4;
    font-family: $font-family--lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 131.4%; /* 23.652px */
    margin: 0;

    &:last-child {
      margin-bottom: 30px;

      @include viewport--sm {
        margin-bottom: 22px;
      }
    }

    @include viewport--md {
      text-align: center;
    }

    @include viewport--sm {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 133.2%; /* 23.976px */
      text-align: left;
    }

    & + p {
      margin-top: 30px;
    }
  }

  &__img-wrap {
    position: relative;
    overflow: hidden;
    clip-path: inset(1px 1px);
    width: 100%;
    align-self: center;
    height: fit-content;

    @include viewport--md {
      max-width: 600px;
    }

    @include viewport--sm {
      max-width: unset;
    }

    &::before {
      content: "";
      display: block;
      padding-bottom: calc(785 / 1228 * 100%); /* H/W */
    }
  }

  &__img-wrap img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity $default-transition-settings;
  }

  &--marketing-mix-optimization,
  &--synthetic-conversions {
    .container {
      padding: 0 100px;

      @include viewport--1280 {
        padding: 0 60px;
      }

      @include viewport--md {
        padding: 0;
      }
    }

    #{$root}__inner {
      padding: 0;
      padding-left: 88px;

      @include viewport--1280 {
        padding: 40px;
        padding-right: 0;
      }

      @include viewport--md {
        border-radius: unset;
        padding: 43px 20px 0;
        gap: 40px;
      }
    }

    #{$root}__text-wrap {
      margin: 0;
      align-self: center;
      padding: 35px 0;

      @include viewport--md {
        max-width: initial;
        align-items: center;
        padding: 0;
      }
    }

    #{$root}__title {
      @include viewport--md {
        font-size: 28px;
        text-align: center;
        margin: 0 0 40px;
        max-width: 720px;
      }

      @include viewport--sm {
        text-align: left;
      }
    }

    #{$root}__text {
      @include viewport--md {
        max-width: 720px;
      }
    }

    #{$root}__text:last-child {
      margin: 30px 0 0;
    }

    #{$root}__img-wrap {
      align-self: end;
      margin-top: 58px;
      margin-bottom: auto;

      @include viewport--1280 {
        align-self: center;
        margin: 0;
      }

      @include viewport--md {
        width: 61%;
      }

      @include viewport--sm {
        width: 120%;
      }
    }
  }

  &--incrementality-testing {
    background: linear-gradient(80deg, #0e1435 9.81%, #1e3376 69.77%);
    padding: 115px 0 100px;

    @include viewport--md {
      position: relative;
    }

    @include viewport--sm {
      padding-top: 50px;
      padding-bottom: 100px;
    }

    .container {
      padding: 0 100px;

      @include viewport--lg {
        padding: 0 60px;
      }

      @include viewport--md {
        padding: 0 20px;
      }
    }

    .info-plate__inner {
      padding: 0;
      background: none;
    }

    .info-plate__img-wrap img {
      width: auto;
      height: auto;
      object-fit: contain;
    }

    .info-plate__text {
      & + p {
        margin: 35px 0;
      }
    }

    #{$root}__btn {
      width: fit-content;

      @include viewport--md {
        position: absolute;
        left: 20px;
        width: calc(100% - 40px);
        bottom: 40px;
      }

      @include viewport--sm {
        bottom: 50px;
      }
    }
  }

  &--ai-driven-attribution {
    background: linear-gradient(80deg, #0e1435 9.81%, #1e3376 69.77%);
    padding-top: 50px;
    padding-bottom: 57px;

    @include viewport--md {
      position: relative;
    }

    @include viewport--sm {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .container {
      padding: 0 100px;

      @include viewport--lg {
        padding: 0 60px;
      }

      @include viewport--md {
        padding: 0 20px;
      }
    }

    #{$root}__video-wrapper {
      position: relative;
      z-index: 0;
      margin-bottom: 35px;

      width: 100%;

      @include viewport--md {
        display: none;
      }
    }

    #{$root}__video {
      position: relative;
      z-index: 0;

      display: block;
      width: 100%;
      padding-top: 56.3%;
      margin-top: 9px;

      @include hover-focus {
        & img {
          opacity: 0.7;
        }
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        pointer-events: none;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
        border-radius: 8px;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        border-radius: 8px;
        overflow: hidden;

        width: 100%;
        height: 100%;

        border: 5px solid #5483ec;

        @include viewport--sm {
          border: 3px solid #5483ec;
        }
      }
    }

    #{$root}__icon-wrap--play {
      display: flex;
      place-content: center;
      width: 77px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      pointer-events: none;

      @include viewport--sm {
        width: 56px;
      }

      svg {
        flex-shrink: 0;
        width: 150%;
        height: auto;
      }
    }

    #{$root}__title {
      @include viewport--md {
        text-align: center;
      }
    }

    #{$root}__inner {
      background: transparent;
      padding: 0;
      grid-template-columns: 1fr 49.5%;
    }

    #{$root}__text-wrap {
      max-width: 450px;

      @include viewport--sm {
        max-width: unset;
      }
    }

    #{$root}__btn {
      width: fit-content;

      @include viewport--md {
        position: absolute;
        left: 20px;
        width: calc(100% - 40px);
        bottom: 40px;
      }

      @include viewport--sm {
        bottom: 30px;
      }
    }

    #{$root}__text--bold {
      margin-bottom: 30px;
    }

    #{$root}__img-wrap {
      margin-top: 35px;

      @include viewport--md {
        margin-bottom: 55px;
        max-width: unset;
      }

      @include viewport--sm {
        margin-top: 0;
      }

      &:before {
        padding-bottom: 0; /* H/W */

        @include viewport--sm {
          padding-bottom: 0; /* H/W */
        }
      }
    }

    .main-video-mobile {
      margin-bottom: 20px;
      padding-left: 0;
      padding-right: 0;

      @include viewport--sm {
        margin-bottom: 30px;
      }

      &__wrapper {
        max-width: unset;
      }
    }
  }

  &--ai-driven-attribution-2 {
    background: linear-gradient(80deg, #0e1435 9.81%, #1e3376 69.77%);
    padding: 115px 0 65px;

    @include viewport--1280 {
      padding: 60px 0;
    }

    @include viewport--md {
      position: relative;
    }

    @include viewport--sm {
      padding-top: 50px;
      padding-bottom: 100px;
    }

    .container {
      padding: 0 105px;
      padding-right: 95px;

      @include viewport--lg {
        padding: 0 60px;
      }

      @include viewport--md {
        padding: 0 20px;
      }
    }

    .info-plate__inner {
      padding: 0;
      background: none;
      grid-template-columns: 1fr 49.7%;

      @include viewport--1280 {
        gap: 30px;
      }
    }

    .info-plate__img-wrap {
      @include viewport--md {
        &::before {
          padding-bottom: calc(785 / 1190 * 100%);
        }
      }
    }

    .info-plate__img-wrap img {
      width: auto;
      height: auto;
      object-fit: contain;
    }

    .info-plate__text-wrap {
      margin-top: 0;
    }

    .info-plate__title {
      margin-bottom: 37px;
    }

    .info-plate__text {
      & + p {
        margin: 25px 0 35px;
      }
    }

    #{$root}__btn {
      width: fit-content;

      @include viewport--md {
        position: absolute;
        left: 20px;
        width: calc(100% - 40px);
        bottom: 40px;
      }

      @include viewport--sm {
        bottom: 50px;
      }
    }
  }

  &--synthetic-conversions {
    #{$root}__inner {
      padding-right: 63px;
      grid-template-columns: 1fr 44.5%;

      @include viewport--md {
        padding: 60px 20px;
        grid-template-columns: 100%;
      }

      @include viewport--sm {
        padding: 50px 16px;
      }
    }

    #{$root}__title {
      max-width: 470px;
      font-weight: 700;
    }

    #{$root}__text-wrap {
      padding: 72px 0;

      @include viewport--md {
        padding: 0;
        max-width: 515px;
      }
    }

    #{$root}__img-wrap {
      margin-top: 20px;

      &::before {
        display: none;
      }

      img {
        height: auto;
        position: static;
      }

      @include viewport--sm {
        width: 100%;
        margin-top: 0;
      }
    }
  }
}
