.content-form {
  padding: 34px 25px;

  background-color: $color-ghost-white;
  border-radius: 7px;

  @include viewport--md {
    width: 304px;
    max-width: 100%;
  }

  &__form {
    display: flex;
    flex-direction: column;

    &--hidden {
      display: none;
    }
  }

  &__title {
    align-self: center;
    margin-bottom: 14px;
    font-family: $font-family--inter;
    font-weight: 700;
    font-size: 25px;
    line-height: 134.8%;
    text-align: center;
    color: $color-content-form-title;
  }

  &__subtitle {
    align-self: center;
    margin-bottom: 28px;

    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: $color-comet;
  }

  &__item {
    margin-bottom: 21px;

    input {
      width: 100%;
      padding: 10px 12px 9px;

      font-weight: 400;
      font-size: 14px;
      line-height: 27px;;

      background-color: $color-default-white;
      border: 1px solid $color-content-input-border;
      border-radius: 5px;

      &::placeholder {
        color: $color-content-input-placeholder;
      }
    }
  }

  &__btn {
    align-self: center;
    padding: 13px 32px 14px;
    margin-top: 14px;

    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }

  &__disclaimer {
    align-self: center;
    width: 227px;
    margin-top: 17px;

    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: $color-ship-cove;

    a {
      color: $color-cerulean-blue;
      text-decoration: underline;

      transition: color $default-transition-settings;

      @media (hover: hover), screen and (min-width: 0 \0
      ) {
        &:hover,
        &:focus {
          color: $color-black;
        }
      }
    }
  }

  &__resource-btn {
    display: block;
    width: fit-content;
    max-width: 100%;
    margin: 35px auto 0;

    &--hidden {
      display: none;
    }
  }

  &__success-message {
    display: flex;
    flex-direction: column;

    &--hidden {
      display: none;
    }
  }

  &__partners {
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-left: -18px;
    margin-right: -18px;

    @include viewport--md {
      margin-left: 0;
    }

    a[href] {
      transition: opacity $default-transition-settings;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &__partner-gmp {
    margin-bottom: 7px;
  }

  &--hidden {
    display: none;
  }

  &--demo-sm {
    .content-form {
      &__item {
        margin-bottom: 12px;
      }

      &__btn {
        align-self: stretch;
        margin-top: 0;
      }
    }
  }

  &--resources {
    padding: 35px 30px;

    background-color: $color-default-white;
    border-radius: 10px;

    .content-form {
      &__item {
        position: relative;

        margin-bottom: 14px;

        label {
          position: absolute;
          top: 7px;
          left: 10px;

          font-weight: 600;
          font-size: 11px;
          line-height: 13px;
          color: $color-ship-cove;
        }

        input {
          padding: 24px 10px 3px;

          font-weight: 600;
          font-size: 15px;
          line-height: 28px;
          color: $color-black;

          border-color: $color-resource-input-border;
        }
      }

      &__btn {
        align-self: stretch;
        padding-top: 16px;
        padding-bottom: 17px;

        font-size: 20px;
        line-height: 24px;

        @include viewport--md {
          font-size: 18px;
          line-height: 22px;
        }
      }

      &__disclaimer {
        width: auto;
      }
    }
  }

  &--webinar-guide {
    max-width: 416px;
    padding: 38px 24px 54px;

    box-shadow: 0px 15.4828px 26.8912px rgba(95, 95, 165, 0.28);
    border-radius: 8.14885px;

    .content-form {
      &__item {
        position: relative;

        margin-bottom: 14px;

        input {
          padding: 9px 24px 8px;

          font-weight: 400;
          font-size: 14.6679px;
          line-height: 31px;
          color: $color-black;

          border-color: $color-resource-input-border;
          box-shadow: 0px 0px 4px 1px rgba(54, 70, 118, 0.04);
          border-radius: 4.88931px;

          &::placeholder {
            color: $color-ship-cove;
          }
        }
      }

      &__success-block {
        display: none;
      }
    }

    &--success {
      padding-top: 82px;
      padding-bottom: 124px;

      form {
        display: none;
      }

      .content-form {
        &__success-block {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        &__thank-you {
          margin-top: 30px;
          font-weight: 700;
          font-size: 39px;
          line-height: 48px;
        }
      }
    }
  }

  &--lead {
    width: 397px;
    max-width: 100%;
    padding: 45px 40px;

    background-color: $color-default-white;
    border-radius: 10px;

    @include viewport--sm {
      padding: 36px 34px;
    }

    .content-form {
      &__title {
        margin-bottom: 10px;

        font-size: 26px;
        line-height: 32px;
        color: $color-bunting-2;

        @include viewport--sm {
          font-size: 22px;
          line-height: 27px;
        }
      }

      &__subtitle {
        max-width: 296px;

        font-size: 22px;
        line-height: 27px;
        color: $color-polo-blue;

        @include viewport--sm {
          font-size: 18px;
          line-height: 22px;
        }
      }

      &__item {
        position: relative;

        margin-bottom: 10px;

        label {
          position: absolute;
          top: 7px;
          left: 10px;

          font-weight: 600;
          font-size: 11px;
          line-height: 13px;
          color: $color-ship-cove;
        }

        input {
          padding: 16px 22px;

          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: $color-black;

          border-color: $color-periwinkle-gray;
          border-radius: 5px;

          @include viewport--sm {
            padding: 14px 19px;

            border-radius: 4px;
          }

          &::placeholder {
            color: $color-rock-blue-2;
          }
        }
      }

      &__btn {
        align-self: stretch;
        padding-top: 16px;
        padding-bottom: 17px;

        font-size: 20px;
        line-height: 24px;

        @include viewport--md {
          font-size: 18px;
          line-height: 22px;
        }
      }

      &__disclaimer {
        width: auto;
        max-width: 227px;
        margin-top: 30px;
      }

      &__success-block {
        display: none;

        padding-top: 60px;
        padding-bottom: 27px;
      }

      &__thank-you {
        margin-top: 30px;

        font-weight: 700;
        font-size: 48px;
        line-height: 58px;
        text-align: center;

        @include viewport--sm {
          font-size: 34px;
          line-height: 41px;
        }
      }

      &__success-text {
        margin-top: 25px;

        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: $color-comet;

        @include viewport--sm {
          margin-top: 7px;
        }
      }
    }

    &--section {
      width: 362px;
      max-width: 100%;
      padding: 30px 22px;

      .content-form__disclaimer {
        margin-top: 14px;
      }

      .content-form__success-block {
        padding: 0;
      }
    }

    &--success {
      padding-top: 82px;
      padding-bottom: 124px;

      form {
        display: none;
      }

      .content-form {
        &__success-block {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}
