.integration-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  width: 175px;
  min-height: 175px;

  background-color: $color-default-white;
  border: 1px solid $color-soltitude;
  border-radius: 10px;

  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 112px;
    max-width: 140px;
    max-height: 112px;
  }

  &__name {
    margin: 0 0 7px;

    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: $color-black;

    @media (max-width: 413px) {
      font-size: 14px;
    }
  }

  &__category {
    margin: 0;

    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: $color-ship-cove;
  }

  &__pin {
    position: absolute;
    top: 0;
    left: 50%;

    width: 96px;
    padding: 4px 4px 5px;

    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: $color-ship-cove;

    background-color: $color-soltitude;
    border-radius: 100px;
    transform: translate(-50%, -50%);
  }


  &--show-more {
    transition: box-shadow $default-transition-settings;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        box-shadow: 0 7px 10px 0 rgba(24, 87, 195, 0.12);
      }
    }

    .integration-card__img {
      margin-top: 64px;
      margin-bottom: 32px;
      height: auto;
      max-height: none;
    }

    .integration-card__link {
      margin: 0;
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      color: $color-ship-cove;
      text-align: center;
      width: 100px;
    }
  }
}
