.main-resources-preview-card {
  display: flex;
  flex-direction: column;
  max-width: 370px;
  min-height: 461px;

  background: $color-default-white;
  box-shadow: 0px 5px 50px rgba(40, 41, 58, 0.05);
  border-radius: 10px 10px 10px 10px;

  overflow: hidden;

  transition: transform $default-transition-settings;

  @include viewport--sm {
    max-width: 352px;
    min-height: 443px;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      transform: translate(0, -5px);

      .main-resources-preview-card__link {
        &::after {
          transform: translateX(5px);
        }
      }
    }
  }

  &__photo-wrapper {
    max-height: 227px;
      position: relative;
      overflow: hidden;
      clip-path: inset(1px 1px);

      &::before {
        content: "";
        display: block;
        padding-bottom: calc(227 / 370 * 100%); /* H/W */
      }
    }

    &__photo-wrapper img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  &__text-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 22px 25px 25px;
  }

  &__type {
    display: flex;
    align-items: center;
    margin-bottom: 22px;

    font-family: $font-family--lato;
    color: $color-black;
    font-weight: 600;
    font-size: 17.7907px;
    line-height: 22px;

    @include viewport--sm {
      margin-bottom: 15px;
    }

    &--ebook {
      &::before {
        flex-shrink: 0;
        content: "";
        display: block;
        width: 24px;
        height: 30px;
        margin-right: 13px;

        background-image: url("/assets/img/svg/icon-preview-ebook.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }

    &--webinar {
      &::before {
        flex-shrink: 0;
        content: "";
        display: block;
        width: 42px;
        height: 30px;
        margin-right: 13px;

        background-image: url("/assets/img/svg/icon-preview-webinar.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }

    &--blog {
      &::before {
        flex-shrink: 0;
        content: "";
        display: block;
        width: 20px;
        height: 30px;
        margin-right: 13px;

        background-image: url("/assets/img/svg/icon-preview-blog.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }
    &--article {
      &::before {
        flex-shrink: 0;
        content: "";
        display: block;
        width: 26px;
        height: 30px;
        margin-right: 13px;

        background-image: url("/assets/img/svg/icon-preview-article.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }
  }

  &__title {
    margin-bottom: 27px;
    font-family: $font-family--lato;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: $color-black;

    br {
      display: inline;
    }
  }

  &__link {
    align-self: flex-start;
    display: flex;
    align-items: center;
    min-height: 32px;
    margin-top: auto;
    padding-right: 20px;
    overflow: hidden;

    font-family: $font-family--lato;
    font-weight: 700;
    font-size: 19px;
    line-height: 23px;
    color: $color-blue;

    @include viewport--sm {
      font-family: $font-family--proxima;
      font-size: 18.388px;
      font-style: normal;
      font-weight: 700;
      line-height: 23px;
    }

    &::after {
      flex-shrink: 0;
      content: "";
      display: block;
      width: 12px;
      height: 13px;
      margin-left: 16px;

      background-image: url("/assets/img/svg/link-triangle.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

      transition: transform $default-transition-settings;
    }
  }

  &--in-blog-block {
    .main-resources-preview-card__text-wrapper {
      min-height: 223px;
      padding-top: 42px;
    }

    .main-resources-preview-card__link {
      &::after {
        width: 7px;
        height: 12px;
        margin-left: 8px;
        background-image: url("/assets/img/svg/angle-blue-right.svg");
      }
    }
  }
}
