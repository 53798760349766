// Vendor
//---------------------------------

@import "vendor/normalize";
@import "vendor/swiper";
@import "vendor/fancybox";

// Global
//---------------------------------

@import "variables";
@import "mixins";
@import "global/fonts";
@import "global/typography";
@import "global/reboot";
@import "global/elements";
@import "global/utils";
@import "global/container";
@import "global/ru-fix";
@import "vendor/custom-select";

// Blocks
//---------------------------------
//
@import "blocks/header";
@import "blocks/footer";
@import "blocks/main-nav";
@import "blocks/modal";
@import "blocks/btn";
@import "blocks/social";
@import "blocks/request-links";
@import "blocks/get-started";
@import "blocks/action-cta";
@import "blocks/sticky-top";
@import "blocks/trusted-brands";
@import "blocks/grid-columns";
@import "blocks/intro-heading";
@import "blocks/integration-page";
@import "blocks/integration-card";
@import "blocks/integration-cards";
@import "blocks/integration-lead";
@import "blocks/info-card";
@import "blocks/info-cards";
@import "blocks/subscribe";
@import "blocks/blue-banner";
@import "blocks/customers-page";
@import "blocks/resources-page";
@import "blocks/careers-page";
@import "blocks/main-page";
@import "blocks/main-intro";
@import "blocks/blog-filter";
@import "blocks/page-pagination";
@import "blocks/blog-card";
@import "blocks/blog-page";
@import "blocks/question-banner";
@import "blocks/pricing-card";
@import "blocks/pricing-plans";
@import "blocks/pricing-intro";
@import "blocks/slider-pricing";
@import "blocks/team-benefits";
@import "blocks/text-page";
@import "blocks/slider-team";
@import "blocks/case-page";
@import "blocks/slider-arrows";
@import "blocks/slider-steps";
@import "blocks/progress-pagination";
@import "blocks/pricing-page";
@import "blocks/plans-compare";
@import "blocks/comparing-table";
@import "blocks/link-back";
@import "blocks/vacancy-popup";
@import "blocks/team";
@import "blocks/popup";
@import "blocks/rate";
@import "blocks/marketing-page";
@import "blocks/marketing-intro";
@import "blocks/inexpensive-lead";
@import "blocks/more-products";
@import "blocks/success-stories";
@import "blocks/product-card";
@import "blocks/report-templates";
@import "blocks/report-card";
@import "blocks/advantages";
@import "blocks/google-partner";
@import "blocks/ai-page";
@import "blocks/ai-intro";
@import "blocks/ai-powered-measurement";
@import "blocks/reports-enhancement";
@import "blocks/intro-block";
@import "blocks/say-block";
@import "blocks/how-block";
@import "blocks/bigquery-page";
@import "blocks/bigquery-intro";
@import "blocks/plan-features";
@import "blocks/why-bigquery";
@import "blocks/pricing";
@import "blocks/rate-range";
@import "blocks/ga-intro";
@import "blocks/interface";
@import "blocks/steps";
@import "blocks/home-steps";
@import "blocks/new-page-steps";
@import "blocks/free-try";
@import "blocks/faq";
@import "blocks/content-form";
@import "blocks/demo-form-section";
@import "blocks/thank-you";
@import "blocks/privacy";
@import "blocks/main-resources-preview.scss";
@import "blocks/main-resources-preview-card.scss";
@import "blocks/demo-sm-form.scss";
@import "blocks/solutions-page.scss";
@import "blocks/solutions-intro.scss";
@import "blocks/solutions-explanations.scss";
@import "blocks/solutions-introducing.scss";
@import "blocks/solutions-promo-block.scss";
@import "blocks/featured.scss";
@import "blocks/influence-block.scss";
@import "blocks/influence-card.scss";
@import "blocks/introductory-meeting.scss";
@import "blocks/main-video-mobile.scss";
@import "blocks/quote-cards.scss";
@import "blocks/solutions-introducing-split.scss";
@import "blocks/solutions-promo-cards.scss";
@import "blocks/text-blocks.scss";
@import "blocks/pages-cards.scss";
@import "blocks/page-card.scss";
@import "blocks/hide-cookies-popup.scss";
@import "blocks/say-card.scss";
@import "blocks/solutions-intro-alt.scss";
@import "blocks/say-card-big.scss";
@import "blocks/solutions-description-section.scss";
@import "blocks/solutions-download-section.scss";
@import "blocks/resource-form-card.scss";
@import "blocks/brands-section.scss";
@import "blocks/say-card-simple.scss";
@import "blocks/resource-card-big.scss";
@import "blocks/request-demo-section.scss";
@import "blocks/action-cta-alt.scss";
@import "blocks/pricing-plan-card.scss";
@import "blocks/promo-card.scss";
@import "blocks/say-card-big-detailed.scss";
@import "blocks/in-page-nav.scss";
@import "blocks/promo-block.scss";
@import "blocks/pricing-solution";
@import "blocks/glossary-page.scss";
@import "blocks/cookieless-intro";
@import "blocks/hero";
@import "blocks/quote-banner";
@import "blocks/trusted-brands-main";
@import "blocks/benefits";
@import "blocks/introducing-solution";
@import "blocks/solution-plate";
@import "blocks/optimal-marketing-plate";
@import "blocks/traditional-marketing";
@import "blocks/marketing-card";
@import "blocks/info-plate";
@import "blocks/starting-plan";
@import "blocks/case-study";
@import "blocks/introducing-segmentstream";
@import "blocks/introducing-optimization";
@import "blocks/intro";
@import "blocks/leads-cards";
@import "blocks/ai-cards";
@import "blocks/simple-card";
@import "blocks/get-started-steps";
@import "blocks/lead-scoring-cards";
@import "blocks/ceo-quote";
@import "blocks/marketing-plate";
@import "blocks/unlock-potential";
@import "blocks/understanding-roas.scss";
@import "blocks/real-life-impact.scss";
@import "blocks/visit-scoring.scss";
@import "blocks/visit-scoring-attribution.scss";
@import "blocks/before-after.scss";
@import "blocks/founder.scss";
@import "blocks/data-accordion";
@import "blocks/accordion.scss";
@import "blocks/new-pricing-page.scss";
@import "blocks/custom-toggle.scss";
@import "blocks/custom-input.scss";
@import "blocks/form-steps";

//
//@import "global/img-fix";

// используйте это для отключения ховеров на тач устройствах
// ! но не используйте это для текстовых полей ( input, textarea )
// также для любых transition обязательно указывайте transition-property
// transition: color $default-transition-settings, а не transition: $default-transition-settings

/* @media (hover: hover), screen and (min-width: 0\0) {
  &:hover,
  &:focus {
    color: red;
  }
} */
