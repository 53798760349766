.integration-lead {
  padding: 114px 0 45px;
  background: $almost-white no-repeat;

  @include viewport--md {
    padding: 81px 0 36px;
  }

  @include viewport--sm {
    padding: 52px 0 36px;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    font-size: 36px;
    line-height: 48px;
    color: $color-black;
    font-weight: 700;
    margin: 0 0 57px;
    text-align: center;
    width: 900px;

    @include viewport--md {
      width: 510px;
      margin-bottom: 39px;
    }

    @include viewport--sm {
      font-size: 24px;
      line-height: 34px;
      width: 100%;
      max-width: 380px;
      margin-bottom: 46px;
    }
  }

  &--ga {
    @include viewport--md {
      padding-top: 77px;
    }

    .integration-lead__title {
      max-width: 1000px;
      width: auto;

      @include viewport--md {
        max-width: 510px;
      }

      @include viewport--sm {
        margin-bottom: 68px;
      }
    }
  }

  .btn {
    min-width: 254px;
    margin-top: 68px;

    @include viewport--md {
      margin-top: 55px;
    }

    @include viewport--sm {
      margin-top: 45px;
    }
  }
}
