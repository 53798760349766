.slider-steps {
  padding: 60px 0;

  @include viewport--sm {
    padding: 30px 0;
  }

  .container {
    position: relative;
  }

  .progress-pagination {
    align-items: flex-start;
    margin-bottom: 30px;

    @include viewport--sm {
      display: none;
    }
  }

  &__pagination-labels {
    display: none;
  }

  &__slider {
    max-width: 1080px;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;

    @include viewport--sm {
      flex-direction: column;
    }
  }

  &__item {
    min-height: 638px;
    display: flex;
    flex-direction: column;
    padding: 150px 45px;

    &:nth-child(even) {
      text-align: right;
    }

    @include viewport--lg {
      min-height: 540px;
      padding: 100px 45px;
    }

    @include viewport--md {
      align-items: center;
      padding: 46px 24px;
    }

    @include viewport--sm {
      padding: 32px 0 26px;
    }
  }

  &__count {
    font-size: 40px;
    line-height: 36px;
    color: $color-orange;
    font-weight: 700;
    margin: 0 0 50px;
    font-family: $font-family--roboto;

    @include viewport--md {
      margin-bottom: 30px;
    }

    @include viewport--sm {
      margin-bottom: 20px;
      order: -2;
    }
  }

  &__title {
    text-align: center;
    font-size: 36px;
    line-height: 48px;
    font-weight: 700;
    margin: 0 auto 68px;
    color: $color-black;
    max-width: 1000px;

    @include viewport--md {
      width: 550px;
      margin-bottom: 55px;
    }

    @include viewport--sm {
      font-size: 24px;
      line-height: 34px;
      width: 100%;
      margin-bottom: 0;
    }
  }

  &__slide-title {
    font-size: 26px;
    line-height: 34px;
    font-weight: 700;
    margin: 0 0 26px;
    color: $color-black;
    max-width: 515px;

    span {
      color: $color-blue;
    }

    @include viewport--md {
      font-size: 26px;
      line-height: 34px;
      margin-bottom: 20px;
      max-width: 500px;
      text-align: center;
    }

    @include viewport--sm {
      font-size: 24px;
      margin-bottom: 24px;
      max-width: 280px;
      width: 100%;
    }
  }

  &__description,
  &__lead {
    max-width: 500px;
    font-size: 18px;
    line-height: 26px;
    color: $color-comet;

    @include viewport--md {
      max-width: 500px;
      text-align: center;
    }

    @include viewport--sm {
      font-size: 16px;
      line-height: 24px;
      max-width: 360px;
      width: 100%;
    }
  }

  &__description {
    margin: 0 0 25px;

    span {
      font-weight: 600;
    }
  }

  &__lead {
    font-weight: 600;
    margin: 0 0 25px;

    @include viewport--md {
      margin: 0;
    }
  }

  img+right {
    left: 5vw;
  }

  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 45px;

    max-width: 532px;
    max-height: 532px;
    object-fit: contain;
    object-position: right;

    @include viewport--lg {
      max-width: 34vw;
      right: 5vw;
    }

    @include viewport--md {
      position: static;
      transform: none;
      max-width: 532px;
      margin-top: 20px;
      margin-bottom: 25px;
      object-position: center;
    }

    @include viewport--sm {
      order: -1;
      max-width: 364px;
      max-height: 364px;
      width: 100%;
      margin-top: 0;
    }
  }

  picture {
    @include viewport--sm {
      order: -1;
    }
  }

  &__action {
    margin-top: auto;
    width: 390px;
    display: flex;
    align-items: center;

    @include viewport--md {
      width: 470px;
      justify-content: center;
      margin-top: 68px;
    }

    @include viewport--sm {
      width: 100%;
      flex-direction: column;
      margin-top: 42px;
    }

    .btn {
      min-width: 168px;

      @include viewport--sm {
        min-width: 236px;
      }
    }
  }

  &__more-link {
    margin-left: 40px;
    margin-right: 32px;

    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: $color-blue;
    transition: color $default-transition-settings;
    text-decoration: underline;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-black;
      }
    }

    @include viewport--md {
      margin-left: 28px;
      margin-right: 22px;
    }

    @include viewport--sm {
      margin-top: 26px;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__action-links {
    margin-top: 26px;
    margin-bottom: -20px;
    display: flex;
    flex-wrap: wrap;
    max-width: 400px;

    @include viewport--md {
      margin-top: 37px;
    }

    @include viewport--sm {
      align-self: flex-start;
    }

    @media (max-width: 413px) {
      flex-direction: column;
      align-items: center;
      align-self: center;
    }
  }

  &__action-link {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: $color-blue;
    margin-right: 30px;
    margin-bottom: 20px;
    transition: color $default-transition-settings;
    text-decoration: underline;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-black;
      }
    }

    &--gray {
      color: $color-ship-cove;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-black;
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 413px) {
      margin-right: 0;
    }
  }

  &__check-list {
    margin: 0 0 -12px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    max-width: 440px;

    &--flat {
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 17px;
      max-width: 400px;

      @include viewport--md {
        justify-content: center;
      }

      @include viewport--sm {
        margin-bottom: 0;
      }

      .slider-steps__check-item {
        margin-right: 12px;
        margin-bottom: 7px;
        color: $color-comet;

        &:last-child {
          margin-right: 0;
        }

        @media (max-width: 413px) {
          margin-right: 9px;
          font-size: 15px;
        }

        @media (max-width: 359px) {
          margin-right: 11px;
        }

        svg {
          @include viewport--sm {
            margin-right: 5px;
          }
        }
      }
    }

    &--vertical-extended {
      margin-bottom: -20px;

      .slider-steps__check-item {
        margin-bottom: 20px;
        align-items: flex-start;
      }
    }
  }

  &__check-item {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 12px;
    color: $color-ship-cove;
    display: flex;
    align-items: center;

    @include viewport--md {
      font-size: 16px;
      line-height: 24px;
    }

    svg {
      position: relative;
      align-self: flex-start;
      top: 8px;
      flex-shrink: 0;
      margin-right: 8px;
      fill: $color-transparent;

      @include viewport--md {
        top: 7px;
      }
    }
  }

  .slider-arrows__btn {
    position: absolute;
    top: 530px;
    transform: translateY(-50%);
    z-index: 1;

    @include viewport--lg {
      top: 540px;
    }

    @include viewport--md {
      top: 770px;
    }

    @include viewport--sm {
      display: none;
    }

    svg {
      width: 28px;
      height: 17px;
    }

    &--left {
      left: 120px;
      transform: translateY(-50%) rotate(-180deg);

      @include viewport--lg {
        left: 50px;
      }

      @include viewport--md {
        left: 5.2vw;
      }
    }

    &--right {
      right: 120px;

      @include viewport--lg {
        right: 50px;
      }

      @include viewport--md {
        right: 5.2vw;
      }
    }
  }

  &--main {
    .slider-steps {
      &__lead {
        margin-bottom: 25px;
      }

      &__action {
        margin-top: 0;
      }
    }
  }

  &--four-slides {
    .progress-pagination {
      @include viewport--md {
        justify-content: center;
      }

      &__item {
        &:not(:last-child) {
          &::after {
            width: 274px;

            @include viewport--lg {
              width: 260px;
            }

            @media (max-width: 1279px) {
              width: 176px;
            }
          }
        }
      }

      .swiper-pagination-bullet {
        @include viewport--md {
          display: none;
        }
      }

      .swiper-pagination-bullet-active {
        @include viewport--md {
          display: flex;

          &::before,
          &::after,
          .progress-pagination__bullet {
            display: none;
          }

          .progress-pagination__label {
            font-size: 20px;
            line-height: 28px;
            font-weight: 700;
            width: 43vw;
          }
        }

        &::before {
          width: 274px;

          @include viewport--lg {
            width: 260px;
          }

          @media (max-width: 1279px) {
            width: 176px;
          }
        }
      }
    }

    .slider-steps__wrapper {
      @include viewport--md {
        position: relative;
      }
    }

    .slider-arrows__btn {
      top: 590px;

      @include viewport--lg {
        top: 570px;
      }

      @include viewport--md {
        top: 28px;

        &--left {
          left: 18vw;
        }

        &--right {
          right: 18vw;
        }
      }
    }
  }

  &--marketing {
    padding-bottom: 26px;

    @include viewport--md {
      padding-bottom: 0;
    }

    .slider-steps {
      &__title {
        margin-bottom: 60px;

        @include viewport--md {
          margin-bottom: 46px;
        }

        @include viewport--sm {
          margin-bottom: 0;
        }
      }

      &__item {
        padding-top: 102px;

        @include viewport--md {
          padding-top: 30px;
        }

        @include viewport--sm {
          padding-top: 35px;
        }

        img {
          @include viewport--sm {
            margin-top: 10px;
          }
        }
      }

      &__slide-title {
        margin-bottom: 16px;

        @include viewport--md {
          margin-bottom: 29px;
        }

        @include viewport--sm {
          max-width: 360px;
        }
      }

      &__description {
        margin-bottom: 27px;

        @include viewport--md {
          margin-bottom: 30px;
        }

        @include viewport--sm {
          margin-bottom: 15px;
        }
      }

      &__more-link {
        max-width: 400px;
        margin-left: 0;
        margin-right: 0;

        @include viewport--sm {
          margin-top: 10px;
          max-width: 280px;
          text-align: center;
        }
      }
    }
  }

  &--blue {
    background-color: $color-torea-bay;

    .slider-steps {
      &__title {
        color: $color-wild-blue-yonder;
      }

      &__count {
        color: $color-orange;
      }

      &__slide-title,
      &__description {
        color: $color-default-white;
      }
    }

    .progress-pagination {
      &__item:not(.hack-selector) {
        &::before {
          background-color: $color-orange;
        }

        @media (hover: hover), screen and (min-width: 0\0) {
          &:not(.swiper-pagination-bullet-active) {
            &:hover,
            &:focus {
              .progress-pagination__label {
                color: $color-orange;
              }

              .progress-pagination__bullet {
                background-color: rgba($color-orange, 0.6);
                box-shadow: 0 0 0 1px rgba($color-orange, 0.6);
              }
            }
          }
        }
      }

      &__bullet {
        border-color: $color-torea-bay;
        background-color: $color-hawkes-blue;
        box-shadow: 0 0 0 1px rgba($color-hawkes-blue, 0.5);
      }

      &__label {
        color: $color-default-white;
      }

      .swiper-pagination-bullet-active {
        .progress-pagination__bullet {
          background-color: $color-orange;
          box-shadow: 0 0 0 1px rgba($color-orange, 0.5);
        }

        .progress-pagination__label {
          color: $color-orange;
        }
      }
    }
  }

  &--ai {
    padding-bottom: 48px;

    @include viewport--md {
      padding-top: 55px;
      padding-bottom: 20px;
    }

    @include viewport--sm {
      padding-top: 53px;
    }

    .swiper-pagination-bullet-active:not(.hack-selector) {
      .progress-pagination__label {
        @include viewport--md {
          font-size: 18px;
          line-height: 18px;
        }
      }
    }

    .slider-steps {
      &__slider {
        max-width: 1200px;
      }

      &__title {
        @include viewport--md {
          margin-bottom: 11px;
        }

        @include viewport--sm {
          margin-bottom: 7px;
        }
      }

      &__item {
        padding: 90px 45px 45px;
        min-height: 820px;
        align-items: center;

        @include viewport--md {
          padding: 22px 5px 45px;
          min-height: 0;
        }

        @include viewport--sm {
          padding-bottom: 14px;
        }

        img {
          position: static;
          transform: none;
          max-width: 900px;
          max-height: none;

          @include viewport--lg {
            max-width: 820px;
            width: 100%;
          }

          @include viewport--md {
            margin-top: 29px;
            margin-bottom: 0;
          }

          @include viewport--sm {
            margin-top: 10px;
            order: initial;
            margin-bottom: 0;
          }
        }

        picture {
          @include viewport--sm {
            order: initial;
            margin-bottom: 0;
          }
        }
      }

      &__count {
        font-size: 36px;
        line-height: 48px;
        margin-bottom: 32px;

        @include viewport--md {
          margin-bottom: 25px;
        }

        @include viewport--sm {
          margin-bottom: 21px;
        }
      }

      &__slide-title {
        max-width: none;
        text-align: center;
        margin-bottom: 22px;
        font-size: 36px;
        line-height: 48px;

        @include viewport--md {
          max-width: 500px;
          margin-bottom: 27px;
        }

        @include viewport--sm {
          margin-bottom: 25px;
          font-size: 24px;
          line-height: 34px;
          max-width: 300px;
        }
      }

      &__description {
        margin-bottom: 36px;
        max-width: 900px;
        text-align: center;

        @include viewport--md {
          max-width: 500px;
        }
      }
    }

    .slider-arrows__btn {
      top: 73.5%;

      @include viewport--md {
        top: 25px;
      }
    }
  }

  &--bigquery {
    padding-bottom: 10px;

    .slider-steps {
      &__title {
        margin-bottom: 56px;

        @include viewport--md {
          margin-bottom: 40px;
        }

        @include viewport--sm {
          margin-bottom: 0;
        }
      }

      &__item {
        padding: 100px 45px;

        @include viewport--md {
          padding: 49px 45px;
        }

        @include viewport--sm {
          padding: 35px 24px;
        }

        img {
          @include viewport--md {
            margin-bottom: 0;
          }

          @include viewport--sm {
            margin-bottom: 25px;
          }
        }
      }

      &__slide-title {
        max-width: 370px;
        margin-bottom: 16px;

        @include viewport--md {
          font-size: 32px;
          line-height: 42px;
          max-width: none;
          margin-bottom: 20px;
        }

        @include viewport--sm {
          font-size: 24px;
          line-height: 34px;
          margin-bottom: 24px;
          max-width: 280px;
        }
      }

      &__description {
        margin-bottom: 31px;
        max-width: 380px;

        @include viewport--md {
          max-width: 400px;
        }

        @include viewport--sm {
          margin-bottom: 36px;
          max-width: 300px;
        }
      }
    }

    .slider-arrows__btn {
      top: 620px;

      @include viewport--lg {
        top: 650px;
      }

      @include viewport--md {
        top: auto;
        bottom: 335px;
      }
    }
  }
}
