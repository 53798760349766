.integration-page {
  &__intro {
    position: relative;
    background: url("../img/bg/intro-integration-lg@1x.png") no-repeat;
    background-size: contain;
    background-position: center;
    background-color: $color-torea-bay;
    min-height: 734px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 82px;

    @include retina {
      background-image: url("../img/bg/intro-integration-lg@2x.png");
    }

    @include viewport--lg {
      background-size: 110%;
    }

    @include viewport--md {
      margin-bottom: 63px;
      min-height: 759px;
      background-image: url("../img/bg/intro-integration-md@1x.png");
      background-repeat: no-repeat;
      background-color: $color-torea-bay;
      background-position: center;
      background-size: contain;

      @include retina {
        background-image: url("../img/bg/intro-integration-md@2x.png");
        background-size: 708px 710px;
      }
    }

    @include viewport--sm {
      padding: 0 16px;
      margin-bottom: 45px;
      min-height: 678px;
      background-image: url("../img/bg/intro-integration-sm@1x.png");
      background-repeat: no-repeat;
      background-color: $color-torea-bay;
      background-position: center;
      background-size: contain;

      @include retina {
        background-image: url("../img/bg/intro-integration-sm@2x.png");
        background-size: contain;
      }
    }

    @media (max-width: 413px) {
      min-height: 500px;
    }
  }

  &__title {
    margin: 0 0 86px;
    font-size: 38px;
    line-height: 60px;
    font-weight: 700;
    color: $color-default-white;
    text-align: center;
    width: 800px;

    @include viewport--lg {
      margin-bottom: 60px;
    }

    @include viewport--md {
      width: 520px;
      font-size: 36px;
      line-height: 44px;
      margin-bottom: 40px;
    }

    @include viewport--sm {
      width: 100%;
      max-width: 520px;
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 16px;
    }

    @media (max-width: 413px) {
      font-size: 22px;
      line-height: 30px;
    }
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include viewport--lg {
      justify-content: center;
    }
  }

  &__nav-wrapper {
    width: 280px;
    margin-right: 38px;

    @media (max-width: 1279px) {
      width: 250px;
    }

    @include viewport--md {
      display: none;
    }

    &--fixed {
      .integration-page__sticky-wrapper {
        position: fixed;
        top: 20px;
        width: 280px;

        @media (max-width: 1279px) {
          width: 250px;
        }

        @include viewport--md {
          display: none;
        }
      }
    }
  }

  &__nav-title {
    font-size: 38px;
    line-height: 42px;
    color: $color-black;
    font-weight: 700;
    margin: 10px 0 42px;
  }

  &__nav-list {
    padding: 0;
    list-style: none;
    margin: 0 0 46px;
  }

  &__nav-item + &__nav-item {
    margin-top: 10px;
  }

  &__nav-link {
    font-weight: 600;
    font-size: 20px;
    line-height: 42px;
    color: $color-ship-cove;
    position: relative;
    transition: color $default-transition-settings;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-blue;
      }
    }

    &--active {
      color: $color-blue;

      &::before {
        content: "";
        width: 3px;
        height: 23px;
        border-radius: 100px;
        background-color: $color-blue;
        position: absolute;
        top: -2px;
        left: -17px;
      }
    }
  }

  &__hint {
    border-radius: 5px;
    background-color: $color-ghost-white;
    padding: 22px 22px 14px;
  }

  &__hint-question {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: $color-black;
    margin: 0 0 6px;
  }

  &__hint-link {
    font-weight: 600;
    font-size: 16px;
    line-height: 42px;
    color: $color-blue;
    transition: color $default-transition-settings;
    text-decoration: underline;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-black;
      }
    }
  }

  &__content-wrapper {
    width: 820px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: 1279px) {
      width: 615px;
    }

    @include viewport--md {
      margin: 0 auto;
      width: 100%;
    }
  }

  &__category-title {
    font-size: 28px;
    line-height: 42px;
    font-weight: 700;
    color: $color-black;
    margin: 10px 0 0;

    @include viewport--md {
      display: none;
    }
  }

  &__filter-form {
    margin-right: 30px;

    @include viewport--md {
      width: 100%;
      margin-right: 0;
    }

    form {
      @include viewport--md {
        display: flex;
        flex-direction: column;
      }
    }

    label {
      position: relative;

      & + label {
        display: none;

        @include viewport--md {
          margin-top: 20px;
          display: block;
        }
      }
    }

    input[type="search"],
    select {
      &:hover,
      &:focus {
        box-shadow: 0 0 0 1px rgba($color-comet, 0.3);
      }
    }

    input[type="search"] {
      font-size: 16px;
      line-height: 42px;
      color: $color-black;
      border-radius: 5px;
      background-color: $color-ghost-white;
      padding: 6px 50px 6px 17px;
      border: none;
      min-width: 277px;

      &::placeholder {
        color: $color-ship-cove;
      }

      @include viewport--md {
        width: 100%;
        padding-left: 23px;
      }

      & + svg {
        pointer-events: none;
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);

        @include viewport--md {
          right: 20px;
        }
      }
    }

    select {
      display: none;

      @include viewport--md {
        width: 100%;
        display: block;
        font-size: 16px;
        line-height: 42px;
        color: $color-ship-cove;
        border-radius: 5px;
        background-color: $color-ghost-white;
        padding: 6px 50px 6px 23px;
        border: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        & + svg {
          pointer-events: none;
          position: absolute;
          top: 50%;
          right: 22px;
          transform: translateY(-50%);
          fill: $color-transparent;
          stroke: $color-ship-cove;
        }
      }
    }
  }

  .integration-cards {
    width: 100%;
    min-height: 410px;
    align-items: flex-start;
    margin-top: 70px;

    @include viewport--md {
      margin-top: 57px;
      width: calc(100% + 30px);
      margin-right: -30px;
    }

    @include viewport--sm {
      width: 100%;
      margin-right: 0;
      margin-top: 34px;
    }
  }

  .question-banner {
    width: 100%;
    margin-top: 115px;
    margin-bottom: 65px;

    @include viewport--md {
      margin-top: 110px;
      margin-bottom: 20px;
    }

    @include viewport--sm {
      margin-top: 40px;
      margin-bottom: 30px;
    }
  }
}
