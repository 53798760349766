.before-after {
  $root: &;

  padding-top: 100px;
  padding-bottom: 66px;

  @include viewport--1280 {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  @include viewport--sm {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    margin-bottom: 65px;

    @include viewport--md {
      grid-template-columns: 100%;
    }
  }

  &__img-wrap {
    padding: 0 15px;

    img {
      width: 100%;
    }
  }
}

.before-after-card {
  $root: &;

  box-shadow: 0 4px 19px 0 rgba(66, 92, 176, 0.25);
  background: $color-default-white;
  padding: 35px;
  padding-right: 45px;
  border-radius: 10px;

  &__title {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: $color-comet;
    font-family: $font-family--inter;
    margin: 0 0 30px;

    @include viewport--md {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__text {
    font-size: 20px;
    line-height: 1.2;
    color: $color-deep-koamaru;
    margin: 0;

    @include viewport--md {
      font-size: 18px;
    }
  }

  &--after {
    #{$root}__title {
      color: $color-blue;
    }
  }
}
