.report-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: $card-tail-shadow;
  border-radius: 10px;
  background-color: $color-blue;
  padding: 43px 43px 39px;
  min-height: 335px;
  max-width: 630px;
  overflow: hidden;

  @include viewport--md {
    padding-top: 36px;
  }

  @include viewport--sm {
    min-height: 300px;
    padding: 30px;
  }

  &__title {
    font-size: 26px;
    line-height: 34px;
    font-weight: 700;
    color: $color-default-white;
    margin: 0 0 16px;

    @include viewport--sm {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__description {
    color: $color-default-white;
    font-size: 18px;
    line-height: 26px;
    margin: 0 0 24px;
    width: 45%;

    @include viewport--lg {
      font-size: 16px;
      line-height: 24px;
    }

    @include viewport--md {
      font-size: 18px;
      line-height: 26px;
    }

    @include viewport--sm {
      font-size: 16px;
      line-height: 24px;
      width: 100%;
    }

    & + .btn {
      white-space: pre;
    }
  }

  .btn {
    margin-top: auto;
    min-width: 192px;

    @include viewport--sm {
      width: 100%;
    }
  }

  img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 52%;

    @include viewport--sm {
      display: none;
    }
  }
}
