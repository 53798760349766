.text-page {
  padding-top: 104px;

  font-size: 19px;
  line-height: 26px;
  color: $color-black;

  @include viewport--md {
    padding-top: 66px;
  }

  @include viewport--sm {
    padding-top: 39px;

    font-size: 16px;
    line-height: 24px;
  }

  h1,
  h2,
  h3,
  h4,
  p,
  span,
  b,
  li,
  blockquote {
    a {
      color: $color-blue;
      text-decoration: underline;
      transition: 0.3s linear;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-black;
        }
      }
    }
  }

  h1 {
    margin: 0;
    font-family: $font-family--inter;
    font-weight: 700;
    font-size: 45px;
    line-height: 56px;

    @include viewport--md {
      font-size: 36px;
      line-height: 44px;
    }

    @include viewport--sm {
      font-size: 28px;
      line-height: 36px;
    }
  }

  h2 strong {
    font-weight: inherit;
  }

  h3 strong {
    font-weight: inherit;
  }

  h2 {
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    font-family: $font-family--inter;

    @include viewport--md {
      font-size: 36px;
      line-height: 48px;
    }

    @include viewport--sm {
      font-size: 24px;
      line-height: 34px;
    }
  }

  h3 {
    font-weight: 700;
    font-size: 26px;
    line-height: 34px;
    font-family: $font-family--inter;

    @include viewport--sm {
      font-size: 24px;
      line-height: 32px;
    }
  }

  h4 {
    font-size: 22px;
    line-height: 30px;

    @include viewport--sm {
      font-size: 20px;
      line-height: 28px;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    padding-right: 46px;

    list-style: none;

    li {
      position: relative;

      padding-left: 31px;

      @include viewport--md {
        padding-left: 55px;
      }

      @include viewport--sm {
        padding-left: 38px;
      }

      &::before {
        content: "";
        position: absolute;
        top: 7px;
        left: 2px;

        width: 10px;
        height: 10px;

        background-color: $color-blue;
        border-radius: 50%;

        @include viewport--md {
          left: 15px;
        }

        @include viewport--sm {
          left: 5px;
        }
      }
    }

    li + li {
      margin-top: 15px;

      &:last-child {
        margin-bottom: 15px;
      }
    }
  }

  ol {
    margin: 0;
    margin-top: 46px;
    padding: 0;
    padding-left: 20px;

    h3:not(.hack-selector) {
      margin: 0 0 30px;
    }

    li + li {
      margin-top: 20px;
    }

    img {
      margin-top: 35px;
    }
  }

  blockquote {
    position: relative;

    margin: 0;
    margin-bottom: 30px;
    padding-left: 50px;

    font-style: italic;
    font-size: 20px;
    line-height: 30px;
    color: $color-blue;

    @include viewport--sm {
      padding-left: 50px;
      font-size: 18px;
      line-height: 26px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 9px;
      left: 0;

      width: 34px;
      height: 25px;

      background-image: url("../img/svg/icon-quote.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }

    p {
      margin: 0;
      margin-left: 2px;
      font-family: $font-family--lato;
    }

    p + p {
      margin-top: 23px;
    }

    cite {
      display: block;
      margin-top: 19px;

      font-style: normal;
      font-size: 18px;
      line-height: 29px;
      color: $color-black;
    }
  }

  .text-page__main-quote {
    //background: #F7F8FD;
    padding: 10px 10px 0 10px;

    blockquote {
      max-width: 1000px;
      font-size: 30px;
      line-height: 40px;

      @include viewport--sm {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }

  .newsletter-subscribe {
    margin: 0 auto;
  }

  .video-container {
    position: relative;
    padding-bottom: 51.14%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &--resources {

    .fancybox-link {
      position: relative;

      display: block;

      &::before {
        content: "";
        position: absolute;
        top: 35px;
        right: 35px;
        z-index: 1;

        width: 30px;
        height: 30px;

        background-image: url("/assets/img/content/icon-zoom.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        opacity: 0;

        transition: opacity 0.3s;
      }

      &:hover::before {
        opacity: 1;
      }
    }

    .text-page {
      &__image-wrap {
        position: relative;

        margin: 0;

        border-radius: 0;
        box-shadow: none;

        img {
          // position: absolute;
          flex-shrink: 0;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        svg {
          width: 100%;
          height: auto;
          display: flex;
        }
      }
    }
  }

  &--webinar-guide {
    .text-page {
      &__columns {
        display: flex;
      }

      &__column-left {
        width: 56%;

        @include viewport--md {
          width: 100%;
        }

        .content-form {
          display: none;
          width: 100%;
          margin-top: -117px;
          margin-left: auto;
          margin-right: auto;

          @include viewport--md {
            display: block;

            &--hidden {
              display: none;
            }
          }
        }
      }

      &__ghost {
        position: relative;

        padding-bottom: 90px;

        color: $color-default-white;

        background: transparent;

        @include viewport--md {
          padding-bottom: 120px;
        }
      }

      &__top-bg-desktop {
        position: absolute;
        top: 0;
        left: calc(-120px - (var(--width-without-scrollbar, 100vw) - var(--container-width, 1440px)) / 2);
        z-index: -1;

        max-width: 100vw;
        width: var(--width-without-scrollbar, 100vw);
        height: 100%;

        @include viewport--lg {
          left: -60.5px;
        }
      
        @include viewport--md {
          display: none;
        }
      }

      &__top-bg-mobile {
        position: absolute;
        top: 0;
        z-index: -1;

        display: none;
        max-width: 100vw;
        width: var(--width-without-scrollbar, 100vw);
        height: 100%;

        @include viewport--md {
          display: block;

          left: -40px;
        }

        @include viewport--sm {
          left: -16px;
        }
      }

      &__head-text {
        width: 100%;
      }

      &__category-date {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 54px;

        @include viewport--md {
          margin-bottom: 33px;
        }
      }

      &__category {
        margin-bottom: 0;

        color: $color-default-white;

        background-color: $color-melrose;
        box-shadow: 0px 4px 4px #4D63AA;
      }

      &__date-string-desktop {
        margin-left: 25px;

        font-weight: 700;
        font-size: 17px;
        line-height: 20px;

        @include viewport--md {
          display: none;
        }
      }

      &__date-string-mobile {
        display: none;
        margin-top: 30px;

        font-weight: 700;
        font-size: 16px;
        line-height: 20px;

        @include viewport--md {
          display: block;
        }
      }

      &__content {
        padding-left: 0;
        padding-right: 0;
      }

      &__column-right {
        width: 44%;
        padding-top: 123px;
        padding-left: 67px;

        .content-form {
          position: sticky;
          top: 20px;
        }

        @include viewport--md {
          display: none;
        }
      }
    }
  }

  &--webinar {
    .text-page {
      &__column-left {
        .content-form {
          display: none;
          width: 100%;
          margin-top: -174px;
          margin-left: auto;
          margin-right: auto;

          @include viewport--md {
            display: block;
          }

          &--hidden {
            display: none;
          }
        }
      }

      &__ghost {
        padding-bottom: 49px;

        @include viewport--md {
          padding-bottom: 225px;
        }
      }

      &__top-bg-desktop {
        top: -110px;

        z-index: -1;
        height: calc(100% + 110px);
      }

      &__top-bg-mobile {
        top: -108px;

        height: calc(100% + 108px);

        @include viewport--sm {
          top: -78px;
          left: -16px;

          height: calc(100% + 78px);
        }

        img:first-child {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        img:last-child {
          position: absolute;
          bottom: -5px;
          left: 0;
          width: 100%;
          height: 361px;
        }

        &--two-speakers {
          @include viewport--sm {
            img:last-child {
              bottom: 80px;
            }
          }
        }
      }

      &__head {
        margin-top: 50px;
      }

      &__head-text {
        padding-right: 0;

        @include viewport--md {
          margin-bottom: 0;
        }

        h1 {
          margin-bottom: 22px;
        }
      }

      &__category-date {
        margin-bottom: 45px;
      }

      &__category {
        margin-bottom: 0;

        color: $color-default-white;

        background-color: $color-blue;
        box-shadow: none;
      }

      &__date-string-desktop {
        display: flex;
        align-items: center;
        margin-left: 25px;

        font-weight: 300;
        font-size: 17px;
        line-height: 26px;

        @include viewport--md {
          display: none;
        }

        // &::before {
        img {
          // content: "";
          display: block;
          width: 23px;
          height: 21px;
          margin-right: 14px;

          // background-image: url("../img/svg/icon-calendar-blue.svg");
          // background-size: contain;
          // background-repeat: no-repeat;
        }
      }

      &__duration {
        display: flex;
        align-items: center;
        margin-left: 18px;

        font-weight: 300;
        font-size: 17px;
        line-height: 26px;

        // &::before {
        img {
          // content: "";
          display: block;
          width: 24px;
          height: 24px;
          margin-right: 13px;

          // background-image: url("../img/svg/icon-clock-blue.svg");
          // background-size: contain;
          // background-repeat: no-repeat;
        }
      }

      &__date-string-mobile {
        display: none;
        margin-top: 43px;

        font-weight: 300;
        font-size: 17px;
        line-height: 26px;

        @include viewport--md {
          display: flex;
          align-items: center;
        }

        // &::before {
        img {
          // content: "";
          display: block;
          width: 23px;
          height: 21px;
          margin-right: 14px;

          // background-image: url("../img/svg/icon-calendar-blue.svg");
          // background-size: contain;
          // background-repeat: no-repeat;
        }
      }

      &__speakers {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 24px;

        @include viewport--sm {
          flex-direction: column;
          align-items: flex-start;
          margin-top: 41px;
        }
      }

      &__speaker {
        display: flex;
        align-items: center;
        margin-top: 18px;
        margin-right: 30px;
      }

      &__speaker-photo {
        flex-shrink: 0;
        width: 56px;
        height: 56px;
        margin-right: 17px;

        border-radius: 50%;
        overflow: hidden;

        @include viewport--md {
          width: 68px;
          height: 68px;
          margin-right: 20px;
        }
      }

      &__speaker-name {
        font-weight: 700;
        font-size: 19px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: $color-malibu;

        @include viewport--md {
          font-size: 23px;
          line-height: 30px;
        }
      }

      &__speaker-job {
        font-weight: 300;
        font-size: 15px;
        line-height: 20px;
        color: $color-default-white;

        @include viewport--md {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }

  &--ebook {
    .text-page {
      &__column-left {
        .content-form {
          display: none;
          width: 100%;
          margin-top: -256px;
          margin-left: auto;
          margin-right: auto;

          @include viewport--md {
            display: block;
          }
        }
      }

      &__ghost {
        @include viewport--md {
          padding-bottom: 291px;
        }

        &--no-form {
          @include viewport--md {
            padding-bottom: 120px;
          }

          .text-page__top-bg-mobile {
            img:last-child {
              display: none;
            }
          }
        }
      }

      &__top-bg-desktop {
        top: -110px;

        z-index: -1;
        height: calc(100% + 110px);
      }

      &__top-bg-mobile {
        top: -108px;

        height: calc(100% + 108px);

        @include viewport--sm {
          top: -78px;
          left: -16px;

          height: calc(100% + 78px);
        }

        img:first-child {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        img:last-child {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 294px;
        }
      }

      &__head {
        margin-top: 50px;

        @include viewport--md {
          margin-top: 24px;
        }
      }

      &__head-text {
        padding-right: 0;

        @include viewport--md {
          margin-bottom: 0;
        }

        h1 {
          margin-bottom: 22px;

          font-weight: 700;
          font-size: 45px;
          line-height: 61px;

          @include viewport--md {
            font-size: 36px;
            line-height: 44px;
          }

          @include viewport--sm {
            font-size: 28px;
            line-height: 36px;
          }
        }

        p {
          font-weight: 400;
          font-size: 17px;
          line-height: 26px;
          font-family: $font-family--lato;
        }
      }

      &__category-date {
        margin-bottom: 45px;

        @include viewport--md {
          margin-bottom: 28px;
        }
      }

      &__category {
        margin-bottom: 0;

        color: $color-default-white;
        font-family: $font-family--inter;
        background-color: $color-blue;
        box-shadow: none;
      }

      &__ebook-type {
        display: flex;
        align-items: center;
        margin-left: 23px;

        font-weight: 300;
        font-size: 17px;
        line-height: 22px;

        img {
          display: block;
          width: 18px;
          height: 20px;
          margin-right: 13px;
        }
      }

      &__ebook-read-time {
        display: flex;
        align-items: center;
        margin-top: 39px;
        font-family: $font-family--lato;

        font-weight: 300;
        font-size: 17px;
        line-height: 22px;

        @include viewport--md {
          margin-top: 34px;
        }

        img {
          display: block;
          width: 24px;
          height: 24px;
          margin-right: 13px;
        }
      }
    }
  }

  &--ebook-no-form {
    .text-page {
      &__ghost {
        padding-bottom: 150px;
        margin-bottom: 68px;

        @include viewport--md {
          padding-bottom: 20px;
          margin-bottom: 46px;
        }
      }

      &__head-text {
        width: 56%;
        padding-right: 60px;

        @include viewport--lg {
          padding-right: 30px;
        }

        @include viewport--md {
          width: auto;
          margin-bottom: 40px;
          padding-right: 0;
        }
      }

      &__head-wrapper {
        width: auto;
        filter: drop-shadow(0px 4px 10px rgba(32, 43, 80, 0.4)) drop-shadow(0px 4px 50px rgba(25, 47, 118, 0.46));
      }

      &__image-wrap img {
        object-position: right;
      }

      &__column-left {
        display: block;
        width: 28%;
        margin-right: 116px;
        
        @include viewport--md {
          display: none;
        }
      }

      &__column-right {
        display: block;
        width: auto;
        padding: 0;
      }

      &__category-date--mobile {
        display: none;

        @include viewport--md {
          display: flex;
          margin-bottom: 32px;
        }
      }

      &__category-date--desktop {
        margin-top: 46px;
        margin-bottom: 0;

        @include viewport--md {
          margin-top: 46px;
          margin-bottom: 0;
        }
      }

      &__category {
        margin-right: 17px;
      }

      &__category--desktop {
        display: block;

        @include viewport--md {
          display: none;
        }
      }

      &__ebook-read-time {
        margin-top: 0;
      }

      &__content {
        padding: 0;
      }
    }
  }

  &--blog {
    flex-direction: column;

    .fancybox-link {
      position: relative;

      display: block;

      &::before {
        content: "";
        position: absolute;
        top: 35px;
        right: 35px;
        z-index: 1;

        width: 30px;
        height: 30px;

        background-image: url("/assets/img/content/icon-zoom.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        opacity: 0;

        transition: opacity 0.3s;
      }

      &:hover::before {
        opacity: 1;
      }
    }

    .text-page {
      &__wrap {
        display: block;
      }

      &__head {
        @include viewport--md {
          width: 100%;
          margin-bottom: 23px;
        }
      }

      &__head-text {
        width: 56.5%;

        @include viewport--md {
          width: 100%;
          margin-bottom: 0;
        }

        h1 {
          @include viewport--md {
            order: 1;
            margin-bottom: 0;
            margin-top: 21px;
          }
        }
      }

      &__category-date-tablet {
        display: none;
        flex-wrap: wrap;
        row-gap: 10px;
        align-items: center;
        margin-bottom: 30px;

        @include viewport--md {
          display: flex;
        }

        time {
          margin-left: 25px;

          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          color: $color-waikawa-grey;
        }
      }

      &__subtitle {
        margin-top: -10px;
        margin-bottom: 28px;

        font-size: 19px;
        line-height: 27px;

        @include viewport--md {
          order: 1;
          margin-top: 20px;
          margin-bottom: 18px;
        }
      }

      &__head-info {
        display: flex;
        flex-wrap: wrap;
        row-gap: 10px;
        align-items: center;
        margin-top: 10px;

        @include viewport--md {
          margin-top: 30px;
        }
      }

      &__author {
        display: flex;
        align-items: center;
        margin-right: 30px;

        font-weight: 700;
        font-size: 18px;
        line-height: 30px;
        color: $color-waikawa-grey;

        img {
          flex-shrink: 0;
          width: 38px;
          height: 38px;
          margin-right: 15px;

          object-fit: cover;

          border-radius: 50%;
          box-shadow: 0px 4px 4px rgba(94, 101, 124, 0.15);
        }
      }

      &__category {
        margin: 0 19px 0 0;

        &--desktop {
          @include viewport--md {
            display: none;
          }
        }
      }

      &__head-wrapper {
        max-width: 40.5%;
        box-shadow: 0px 5px 40px rgba(43, 47, 86, 0.4);

        @include viewport--md {
          display: none;
          max-width: none;
        }
      }

      &__image-wrap {
        position: relative;

        margin: 0;

        &--tablet {
          align-self: center;
        }

        img {
          // position: absolute;
          flex-shrink: 0;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        svg {
          width: 100%;
          height: auto;
          display: flex;
        }
      }

      &__date-time-desktop {
        display: flex;
        justify-content: space-between;
        padding: 7px 0;
        margin-top: 40px;
        margin-bottom: 40px;

        font-weight: 700;
        font-size: 14px;
        line-height: 30px;
        color: $color-waikawa-grey;

        border-top: 1px solid rgba(217, 218, 222, 0.8);
        border-bottom: 1px solid rgba(217, 218, 222, 0.8);

        @include viewport--md {
          display: none;
        }
      }

      &__left {
        width: 100%;
        display: block;

        h1 + .text-page__content {
          padding-top: 0;
        }
      }

      &__right {
        float: right;
        margin-bottom: 60px;
        margin-left: 60px;
      }

      &__content-form-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;
      }

      &__content {
        max-width: 807px;
        padding: 0;

        & + .text-page__social {
          display: none;
        }
      }

      &__form-wrapper {
        flex-shrink: 0;
        width: 304px;
        margin-left: 30px;

        @include viewport--md {
          display: none;
        }

        .content-form {
          position: sticky;
          top: 30px;
        }
      }
    }

    @include viewport--md {
      .text-page {
        &__wrap {
          margin-bottom: 0;
        }

        &__content {
          & + .text-page__social {
            margin-top: 30px;
            display: flex;
          }
        }

        &__left {
          margin-bottom: 0;
          display: flex;
        }

        &__right {
          display: none;
        }
      }
    }
  }

  &--blog-no-form {
    .text-page {
      &__head {
        margin-top: 36px;

        @include viewport--md {
          margin-top: 24px;
        }
      }

      &__category-date-tablet {
        @include viewport--md {
          display: none;
        }
      }

      &__head-info {
        @include viewport--md {
          display: none;
        }
      }

      &__head-text {
        padding-top: 12px;
        padding-right: 20px;

        @include viewport--md {
          padding-top: 0;
          padding-right: 0;
        }
      }                                                                         

      &__head-wrapper {
        margin-left: 30px;
      }

      &__subtitle {
        margin-top: -10px;
        margin-bottom: 55px;

        @include viewport--md {
          order: 1;
          margin-top: 20px;
          margin-bottom: 18px;
        }
      }

      &__in-page-nav {
        flex-shrink: 0;
        width: 25%;
        margin-right: 30px;

        @include viewport--md {
          display: none;
        }
      }

      &__content-form-wrapper {
        margin-bottom: 80px;
        padding-top: 10px;

        @include viewport--md {
          margin-bottom: 50px;
          padding-top: 0;
        }
      }

      &__social {
        padding-left: 10px;

        @include viewport--md {
          padding-left: 0;
        }
      }
    }
  }

  &--success-story {
    .text-page {
      &__head-text {
        p {
          @include viewport--md {
            padding-right: 0;
          }
        }
      }

      &__content-form-wrapper {
        display: flex;
        justify-content: space-between;
        // max-width: 1200px;
        margin-top: 52px;
        // margin-left: auto;
        // margin-right: auto;
        margin-bottom: 50px;
      }

      &__content {
        max-width: 807px;
        padding: 0;

        & + .text-page__social {
          display: none;
        }
      }

      &__form-wrapper {
        flex-shrink: 0;
        width: 304px;
        margin-left: 30px;

        @include viewport--md {
          display: none;
        }

        .content-form {
          position: sticky;
          top: 30px;
        }
      }

      &__date-time-desktop {
        display: flex;
        justify-content: space-between;
        padding: 7px 0;
        margin-top: 40px;
        margin-bottom: 40px;

        font-weight: 700;
        font-size: 14px;
        line-height: 30px;
        color: $color-waikawa-grey;

        border-top: 1px solid rgba(217, 218, 222, 0.8);
        border-bottom: 1px solid rgba(217, 218, 222, 0.8);

        @include viewport--md {
          display: none;
        }
      }
    }
  }

  &--article {
    margin: 0 auto;
    width: calc(100% - 120px);
    max-width: 1200px;
    padding: 0 60px;
    box-sizing: content-box;
    display: flex;
    justify-content: space-between;

    h1 {
      margin-bottom: 51px;

      @include viewport--md {
        margin-bottom: 34px;
      }

      @include viewport--sm {
        margin-bottom: 23px;
      }
    }

    p {
      margin: 0 0 26px;

      @include viewport--sm {
        font-size: 18px;
        line-height: 26px;
      }
    }

    @include viewport--md {
      padding: 0 40px;
      width: auto;
      display: block;
    }

    @include viewport--sm {
      padding: 0 18px;
    }
  }

  &--vacancy {
    // margin: 0 auto 25px;
    // width: calc(100% - 120px);
    // max-width: 1200px;
    // padding: 3px 60px 0;
    margin: 0;
    padding: 0;
    box-sizing: content-box;

    h1 {
      margin-bottom: 34px;

      @include viewport--sm {
        margin-bottom: 20px;
      }
    }

    p {
      margin: 0 0 26px;

      @include viewport--sm {
        margin-bottom: 24px;
      }
    }

    @include viewport--md {
      // margin-bottom: 48px;
      // padding: 0 40px;
      // width: auto;
    }

    @include viewport--sm {
      // margin-bottom: 38px;
      // padding: 0 18px;
    }
  }

  .in-page-nav {
    ul {
      padding: 0;
    }

    li {
      margin: 0;
      padding-left: 0;
      padding-top: 18px;
      padding-bottom: 18px;
    
      &:first-child {
        padding-top: 0;
      }
    
      &:last-child {
        padding-bottom: 0;
      }
    }

    li::before {
      content: none;
    }

    a {
      color: $color-torea-bay-4;
      text-decoration: none;
      font-family: $font-family--inter;
      font-size: 18px;
      &:hover,
      &:focus,
      &:active {
        color: $color-blue;
      }
    }
  }
}

.text-page__ghost {
  background-image: linear-gradient(180deg, #F7F8FC 0%, rgba(247, 248, 252, 0) 100%);
  padding-top: 45px;

  @include viewport--md {
    padding-top: 50px;
  }

  @include viewport--sm {
    padding-top: 30px;
  }
}

.text-page__wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;

  @include viewport--md {
    margin-bottom: 48px;
    display: block;
  }

  @include viewport--sm {
    margin-bottom: 38px;
  }
}

.text-page__left {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  width: 54%;
  flex-shrink: 0;

  & > :last-child {
    margin-bottom: 0;
  }

  @include viewport--md {
    margin-top: 53px;
    margin-bottom: 44px;
    width: 100%;
  }

  @include viewport--sm {
    margin-top: 40px;
    margin-bottom: 38px;
  }

  &--vacancy {
    margin-top: 60px;

    @include viewport--md {
      margin-bottom: 31px;
    }

    @include viewport--sm {
      margin-top: 30px;
      margin-bottom: 25px;
    }
  }
}

.text-page__right {
  margin-left: 30px;
  width: 40%;
  flex-shrink: 0;

  @include viewport--md {
    width: 100%;
    margin-left: 0;
  }
}

.text-page__image-wrap {
  max-width: 483px;
  margin-bottom: 69px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: max-content;
  background: linear-gradient(157deg, #202559 13.54%, #455a9b 79.42%);
  box-shadow: 0 5px 40px rgba(43, 47, 86, 0.4);
  border-radius: 10px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }

  @include viewport--md {
    margin: 0 auto 53px;
    min-height: auto;
  }

  @include viewport--sm {
    margin-bottom: 41px;
  }

  &--tablet {
    display: none;

    @include viewport--md {
      display: flex;
    }
  }

  &--desktop {
    @include viewport--md {
      display: none;
    }
  }

  &--vacancy {
    margin-bottom: 53px;
    height: max-content;
    padding: 0;

    @include viewport--md {
      margin-bottom: 42px;
      min-height: auto;
    }

    @include viewport--sm {
      margin-bottom: 37px;

      img {
        width: 100%;
        height: auto;
        max-width: none;
        max-height: none;
        // max-width: 100%;
        // max-height: 100%;
        object-fit: cover;
      }
    }
  }
}

.text-page__skills {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;

  @include viewport--md {
    max-width: 500px;
  }

  @include viewport--sm {
    margin-bottom: 17px;
  }
}

.text-page__skill {
  padding: 4px 18px;
  font-size: 16px;
  line-height: 1.5;
  background-color: $color-blue;
  color: $color-default-white;
  border-radius: 20px;
  margin-right: 10px;
  margin-bottom: 18px;

  @include viewport--md {
    margin-right: 17px;
  }

  @include viewport--sm {
    margin-right: 10px;
    font-size: 12px;
    line-height: 2;
  }
}

.text-page__btns {
  display: flex;
  margin-top: auto;

  @include viewport--sm {
    width: 100%;
  }
}

.text-page__btn {
  min-width: 166px;
  padding-left: 35px;
  padding-right: 35px;
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }

  @include viewport--sm {
    min-width: auto;
    flex-grow: 1;
    padding: 16px 10px;
    min-height: none;
  }
}

.text-page__head {
  display: flex;
  // max-width: 1200px;
  margin-top: 60px;
  // margin-left: auto;
  // margin-right: auto;

  @include viewport--md {
    margin-top: 30px;
    flex-direction: column;
  }
}

.text-page__head-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 57.1%;
  padding-right: 41px;

  @include viewport--lg {
    padding-right: 0;
  }

  @include viewport--md {
    width: 100%;
    margin-bottom: 41px;
    padding-right: 5px;
  }

  @include viewport--sm {
    margin-bottom: 47px;
  }

  h1 {
    margin-bottom: 38px;

    @include viewport--sm {
      margin-bottom: 14px;
    }
  }

  p {
    margin: 0;

    @include viewport--md {
      padding-right: 30px;
      font-size: 20px;
      line-height: 30px;
    }

    @include viewport--sm {
      font-size: 18px;
      line-height: 26px;
    }
  }
}

.text-page__head-wrapper {
  align-self: flex-start;
  max-width: 39.5%;
  margin-left: 40px;
  overflow: hidden;

  border-radius: 10px;
  box-shadow: $card-shadow;

  @include viewport--md {
    max-width: none;
  }

  img {
    @include viewport--sm {
      object-fit: cover;
    }
  }

  @include viewport--md {
    align-self: center;
    width: auto;
    margin-left: 0;
  }

  &--form {
    flex-grow: 1;
  }
}

.text-page__pub-info {
  display: flex;
  align-items: center;
  order: -1;
  margin-bottom: 54px;

  font-size: 14px;
  line-height: 17px;
  color: $color-waikawa-grey;

  @include viewport--md {
    margin-bottom: 37px;
  }

  .text-page__category {
    margin-bottom: 0;

    @media (max-width: 400px) {
      padding: 7px 15px;
    }
  }

  time {
    margin-left: 25px;

    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }

  &--article,
  &--vacancy {
    margin-bottom: 27px;

    @include viewport--md {
      margin-bottom: 36px;
    }

    @include viewport--sm {
      margin-bottom: 33px;
    }
  }

  &--vacancy {
    margin-bottom: 27px;

    @include viewport--md {
      margin-bottom: 38px;
    }

    @include viewport--sm {
      margin-bottom: 34px;
    }
  }
}

.text-page__category {
  margin: 0 0 54px;
  padding: 7px 29px;
  width: max-content;

  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: $color-waikawa-grey;

  background-color: $color-lavender;
  border-radius: 100px;

  @include viewport--md {
    margin-bottom: 37px;
  }

  @include viewport--sm {
    margin-bottom: 33px;
  }
}

.text-page__location {
  position: relative;

  margin-left: 30px;
  padding-left: 29px;

  font-size: 18px;
  line-height: 22px;

  @media (max-width: 400px) {
    margin-left: 15px;
    font-size: 16px;
    padding-left: 25px;
  }

  &::before {
    content: "";
    position: absolute;
    top: -3px;
    left: 2px;

    width: 16px;
    height: 24px;

    background-image: url("../img/svg/icon-pin.svg");
    background-repeat: no-repeat;
  }
}

.text-page__request-btn {
  min-width: 212px;
  margin-top: 62px;

  @include viewport--md {
    margin-top: 39px;
  }

  @include viewport--sm {
    margin-top: 34px;
  }
}

.text-page__content {
  flex-grow: 1;
  padding-top: 50px;
  padding-left: 16px;
  padding-right: 16px;
  max-width: 840px;

  &.text-page__content {
    @include viewport--sm {
      max-width: 100%;
    }
  }

  & > :first-child {
    margin-top: 0;
  }

  h2 {
    margin-top: 52px;
    margin-bottom: 26px;
    font-weight: 700;

    & + p,
    & + ul,
    & + ol,
    & + h3,
    & + h4,
    & + code,
    & + div,
    & + blockquote {
      margin-top: 0;
    }
  }

  h3,
  h4 {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  img {
    margin: 0 auto;
  }

  table {
    text-align: left;
    border: 1px solid #d8d8eb;
    border-collapse: collapse;

    th,
    td {
      vertical-align: top;
      padding: 10px;
      border: 1px solid #d8d8eb;
    }
  }

  .language-plaintext {
    max-width: 100%;
    overflow: auto;
  }

  p + .text-page__table {
    margin-top: 20px;
  }

  .text-page__table {
    position: relative;
    margin: 40px 0;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      width: 80px;
      height: 100%;
      opacity: 0;
      transition: opacity $default-transition-settings;
      pointer-events: none;

      @include viewport--sm {
        width: 40px;
      }
    }

    &::after {
      right: 0;
      background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    }

    &::before {
      left: 0;
      background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    }

    &--blur-right::after {
      opacity: 1;
    }

    &--blur-left::before {
      opacity: 1;
    }
  }

  .text-page__table-inner {
    overflow: auto;
  }

  pre {
    white-space: pre-wrap;

    @include viewport--sm {
      white-space: pre;
    }
  }

  p {
    margin: 0;
    font-family: $font-family--lato;

    .fancybox-link + em,
    .text-page__img-link + em {
      display: block;
      text-align: center;
      margin-top: 34px;

      font-style: italic;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: $color-ship-cove;

      @include viewport--md {
        margin-top: 28px;
      }

      @include viewport--sm {
        margin-top: 10px;

        font-size: 14px;
        line-height: 22px;
      }
    }

    img {
      margin: 35px auto 0;

      @include viewport--md {
        margin: 60px auto 0;
      }

      @include viewport--sm {
        margin: 32px auto 0;
      }

      & + em {
        display: block;
        text-align: center;
        margin-top: 34px;

        font-style: italic;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: $color-ship-cove;

        @include viewport--md {
          margin-top: 28px;
        }

        @include viewport--sm {
          margin-top: 10px;

          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }

  p + p {
    margin-top: 26px;
  }

  p + blockquote {
    margin-top: 30px;
  }

  ol + p {
    margin-top: 40px;
  }

  ul {
    margin: 26px 0;
    padding: 0;
    padding-right: 46px;

    list-style: none;

    li {
      position: relative;
      font-family: $font-family--lato;
      padding-left: 38px;

      @include viewport--md {
        padding-left: 55px;
      }

      @include viewport--sm {
        padding-left: 38px;
      }

      &::before {
        content: "";
        position: absolute;
        top: 8px;
        left: 0;

        width: 10px;
        height: 10px;

        background-color: $color-blue;
        border-radius: 50%;

        @include viewport--md {
          left: 15px;
        }

        @include viewport--sm {
          left: 5px;
        }
      }
    }

    ul {
      @include viewport--sm {
        padding-right: 0;
      }
    }
  }

  &--vacancy {
    .text-page__list {
      margin-bottom: 90px;
      padding: 0;
      font-size: 18px;
      line-height: 26px;
      column-gap: 30px;
      column-count: 2;

      @include viewport--md {
        margin-bottom: 80px;
        max-width: 600px;
        display: block;
        column-count: 1;
      }

      @include viewport--sm {
        margin-bottom: 55px;
        font-size: 16px;
        line-height: 1.5;
      }

      li {
        margin-bottom: 26px;

        @include viewport--md {
          padding-left: 40px;

          &::before {
            left: 0;
            top: 8px;
          }
        }

        @include viewport--sm {
          margin-bottom: 24px;
          padding-left: 35px;
        }
      }
    }
  }
}

.text-page__lists {
  margin-bottom: 90px;
  display: flex;

  @include viewport--md {
    margin-bottom: 80px;
    max-width: 600px;
    display: block;
  }

  @include viewport--sm {
    margin-bottom: 55px;
  }

  ul {
    padding: 0;
    margin-right: 30px;
    font-size: 18px;
    line-height: 26px;
    width: 100%;

    @include viewport--sm {
      font-size: 16px;
      line-height: 1.5;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  li {
    margin-bottom: 26px;

    @include viewport--md {
      padding-left: 50px;
    }

    @include viewport--sm {
      margin-bottom: 24px;
      padding-left: 35px;
    }
  }
}

.text-page__bottom-wrapper {
  margin-top: 36px;
  padding: 40px 0;

  @include viewport--md {
    margin-top: 7px;
  }

  @include viewport--sm {
    padding: 29px 0 44px;
  }
}

.text-page__social {
  display: flex;
  align-items: center;

  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  color: $color-ship-cove;

  @include viewport--sm {
    width: max-content;
  }

  ul {
    list-style: none;

    li {
      margin: 0;
      padding: 0;

      &::before {
        display: none;
      }
    }

    li + li {
      margin: 0;
      padding: 0;
    }
  }

  span {
    white-space: nowrap;
  }

  .social {
    padding-right: 0;
    margin-left: 26px;
  }

  .social__item {
    margin-right: 18px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.text-page + .container > .blue-banner {
  margin-top: 80px;

  @include viewport--sm {
    margin-top: 60px;
  }
}
