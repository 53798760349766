.modal--vacancy {
  .modal__content {
    width: 900px;
    padding: 0;

    border-radius: 10px;

    @include viewport--md {
      width: 740px;
    }

    @include viewport--sm {
      width: 320px;
    }
  }

  .modal__close-btn {
    position: absolute;
    top: 28px;
    right: 25px;

    width: 30px;
    height: 30px;
    padding: 0;

    background-color: $color-transparent;
    background-image: url("../img/svg/icon-close-popup.svg");
    background-repeat: no-repeat;
    background-size: contain;

    @include viewport--md {
      top: 17px;
      right: 19px;
    }

    svg {
      display: none;
    }
  }
}

.vacancy-popup {
  min-height: 600px;

  @include viewport--sm {
    min-height: 560px;
  }

  &.is-success {
    .vacancy-popup__container {
      height: 0;

      opacity: 0;

      pointer-events: none;
    }

    .vacancy-popup__success {
      opacity: 1;
    }
  }
}

.vacancy-popup__container {
  position: relative;

  display: flex;
  min-height: 600px;
  overflow: hidden;

  background-color: white;
  border-radius: 10px;

  transition: opacity $default-transition-settings;

  @include viewport--sm {
    display: block;
    min-height: 560px;
  }
}

.vacancy-popup__left {
  width: 400px;
  padding: 55px 70px;

  @include viewport--md {
    width: 360px;
    padding: 41px 44px;
  }

  @include viewport--sm {
    width: 100%;
    padding: 25px 20px 47px;
  }

  svg {
    display: block;
    width: 59px;
    height: 77px;
    margin-bottom: 52px;

    @include viewport--md {
      margin-bottom: 60px;
    }

    @include viewport--sm {
      margin-bottom: 20px;
    }
  }
}

.vacancy-popup__question {
  margin-bottom: 22px;

  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  color: $color-black;

  @include viewport--md {
    margin-bottom: 30px;
  }

  @include viewport--sm {
    margin-bottom: 28px;
  }
}

.vacancy-popup__promise {
  font-size: 16px;
  line-height: 19px;
  color: $color-ship-cove;
}

.vacancy-popup__right {
  width: 500px;
  padding: 65px 40px;

  background-color: $color-lavender;

  @include viewport--md {
    width: 380px;
    padding: 60px 20px;
  }

  @include viewport--sm {
    width: 100%;
    padding: 42px 20px;
  }
}

.vacancy-popup__title {
  max-width: 250px;
  margin: 0 auto 67px;

  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: $color-ship-cove;

  @include viewport--md {
    margin-bottom: 48px;
  }

  @include viewport--sm {
    max-width: none;
    margin-bottom: 25px;

    font-size: 22px;
  }
}

.vacancy-popup__form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 65px;

  @include viewport--md {
    margin-bottom: 60px;
  }

  @include viewport--sm {
    flex-direction: column;
    margin-bottom: 16px;
  }
}

.vacancy-popup__item {
  position: relative;

  width: calc(50% - 6px);
  margin-bottom: 12px;

  @include viewport--sm {
    width: 100%;
  }

  @include viewport--sm {
    margin-bottom: 13px;
  }

  label {
    position: absolute;
    top: 9px;
    left: 11px;

    font-size: 12px;
    line-height: 1.2;
    color: $color-ship-cove;
  }

  input {
    width: 100%;
    padding: 30px 11px 8px;

    font-weight: 600;
    font-size: 16px;
    line-height: 1.2;
    color: $color-black;

    background-color: $color-default-white;
    border: 1px solid $color-hawkes-blue;
    border-radius: 5px;
  }
}

.vacancy-popup__btn-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include viewport--md {
    flex-direction: column-reverse;
  }

  p {
    max-width: 165px;
    margin: 0 15px 0 0;

    font-size: 12px;
    line-height: 15px;

    @include viewport--md {
      max-width: none;
      margin: 0;
    }
  }

  a {
    color: $color-cerulean-blue;
    text-decoration: underline;
  }

  button {
    min-width: 236px;
    padding: 20px 26px;

    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    @include viewport--md {
      width: 100%;
      margin-bottom: 28px;
    }

    @include viewport--sm {
      margin-bottom: 23px;
    }
  }
}

.vacancy-popup__close {
  position: absolute;
  top: 28px;
  right: 25px;

  padding: 0;

  background-color: $color-transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;

  @include viewport--md {
    top: 17px;
    right: 19px;
  }

  svg {
    display: block;
  }
}

.vacancy-popup__success {
  position: relative;
  position: absolute;
  top: 50%;
  left: 50%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 900px;
  height: 600px;
  overflow: hidden;

  color: $color-black;

  background-color: $color-default-white;
  border-radius: 10px;
  transform: translate(-50%, -50%);
  opacity: 0;

  @include viewport--md {
    width: 740px;
  }

  @include viewport--sm {
    width: 320px;
    height: 560px;
  }

  & > svg {
    display: block;
    width: 59px;
    height: 77px;
    margin-bottom: 42px;

    @include viewport--md {
      margin-bottom: 34px;
    }

    @include viewport--sm {
      margin-bottom: 20px;
    }
  }
}

.vacancy-popup__success-title {
  margin-bottom: 10px;

  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
}

.vacancy-popup__success-text {
  font-size: 18px;
  line-height: 22px;
}
