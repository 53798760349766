.benefits {
  $root: &;

  // &__inner {
  //   margin: 0 auto;
  //   padding: 0 53px;

  //   @include viewport--md {
  //     padding: 0 40px;
  //   }

  //   @include viewport--md {
  //     padding: 0 38px;
  //   }

  //   @include viewport--sm {
  //     padding: 0 35px;
  //   }
  // }

  &__title {
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;

    clip: rect(0 0 0 0);
  }

  &__list {
    list-style: none;
    padding: 50px 0 50px 37px;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px 10px;

    @include viewport--lg {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 17px;
    }

    @include viewport--md {
      padding: 33px 0 33px 0;
    }

    @include viewport--sm {
      display: flex;
      flex-direction: column;
      gap: 0;
    }
  }

  &__item {
    display: flex;

    @include viewport--lg {
      justify-content: center;
    }

    @include viewport--sm {
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__text-wrap {
    padding: 23px 27px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 4px;
      border-radius: 2px;
      display: block;
      background-color: $color-cornflower-blue;
    }

    @include viewport--sm {
      padding: 10px 0 10px 27px;
    }
  }

  &__text {
    color: $color-default-white;
    font-family: $font-family--inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;

    @include viewport--lg {
      width: 100%;
      max-width: 330px;
    }
  }

  &--marketing-page {
    #{$root}__inner {
      padding: 0;
    }

    #{$root}__list {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(calc(25% - 5px), 1fr));
      padding: 0;
      gap: 10px 0;

      @include viewport--md {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 16px;
      }

      @include viewport--sm {
        grid-template-columns: 1fr;
        row-gap: 18px;
      }
    }

    #{$root}__item {
      margin-bottom: 0;
    }

    #{$root}__text-wrap {
      @include viewport--1280 {
        padding-right: 15px;
      }

      @include viewport--md {
        padding: 10px 16px 10px 27px;
      }

      @include viewport--sm {
        padding-right: 0;
        margin-left: 20px;
        margin-right: 20px;
      }
    }

    #{$root}__text {
      font-weight: 600;
      font-size: 18px;
    }
  }

  &--marketing-mix-optimization {
    #{$root}__list {
      grid-template-columns: repeat(3, 1fr);
      padding: 0;
      gap: 10px 50px;

      @include viewport--1280 {
        gap: 10px 16px;
      }

      @include viewport--sm {
        gap: 19px 16px;
      }
    }

    #{$root}__item {
      justify-content: flex-start;

      @include viewport--sm {
        margin: 0;
      }
    }

    #{$root}__text-wrap {
      @include viewport--1280 {
        padding-right: 10px;
      }

      @include viewport--md {
        padding: 0 10px 0 27px;
      }

      @include viewport--sm {
        padding: 10px 10px 10px 27px;
      }
    }

    #{$root}__text {
      max-width: initial;

      @include viewport--md {
        font-size: 18px;
      }
    }
  }

  &--ai-driven-attribution {
    #{$root}__inner {
      padding: 0;
    }

    #{$root}__list {
      padding-left: 0;
      gap: 20px 50px;

      @include viewport--md {
        padding: 0;
      }
    }

    #{$root}__text {
      font-size: 18px;
      font-weight: 500;

      @include viewport--sm {
        max-width: unset;
      }
    }

    #{$root}__item {
      @include viewport--sm {
        justify-content: flex-start;
      }
    }

    #{$root}__text-wrap {
      padding: 24px 5px 24px 32px;

      @include viewport--sm {
        padding: 14px 5px 11px 27px;
      }
    }
  }

  &--incrementality-testing-page {
    #{$root}__list {
      grid-template-columns: repeat(3, 1fr);
      padding: 37px 0 37px 0;
      column-gap: 65px;

      @include viewport--lg {
        column-gap: 0;
      }

      @include viewport--md {
        display: flex;
        flex-direction: column;
        gap: 0;
      }
    }

    #{$root}__item {
      justify-content: flex-start;
      align-self: center;

      @include viewport--md {
        margin-bottom: 12px;
        align-self: flex-start;

        &:last-child {
          margin-bottom: 0;
        }
      }

      @include viewport--sm {
        align-self: start;
      }
    }

    #{$root}__text-wrap {
      padding-right: 18px;
      @media (max-width: 1280px) {
        padding-left: 15px;
        padding-right: 5px;
      }
    }

    #{$root}__text {
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0;
    }

    #{$root}__inner {
      padding: 0;
    }
  }

  &--synthetic-conversions {
    #{$root}__inner {
      padding: 0 40px;

      @include viewport--lg {
        padding: 0;
      }
    }

    #{$root}__list {
      padding-left: 0;
      gap: 20px 50px;

      grid-template-columns: 370px 1fr 320px;

      @include viewport--lg {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 17px;
      }

      @include viewport--md {
        padding: 0;
      }
    }

    #{$root}__item {
      @include viewport--sm {
        justify-content: flex-start;
      }
    }

    #{$root}__text-wrap {
      padding: 24px 5px 24px 32px;
      max-width: 357px;

      @include viewport--sm {
        padding: 14px 5px 11px 27px;
      }
    }

    #{$root}__text {
      @include viewport--md {
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
}
