.ai-cards {
  $root: &;

  overflow: hidden;
  padding-bottom: 165px;
  padding-top: 48px;
  margin-bottom: -50px;

  @include viewport--md {
    margin-bottom: -140px;
    padding-top: 0;
  }

  @include viewport--sm {
    padding-bottom: 100px;
    margin-bottom: -17px;
  }

  &__container {
    position: relative;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;

    @include viewport--xl {
      max-width: 1280px;
      padding-left: 40px;
      padding-right: 40px;
    }

    @include viewport--lg {
      max-width: 1280px;
      padding-left: 40px;
      padding-right: 40px;
    }

    @include viewport--md {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  &__bg {
    position: absolute;
    bottom: -211px;
    right: -161px;
    z-index: -1;

    @include viewport--xl {
      width: 441px;
      bottom: -160px;
      right: -81px;
    }

    @include viewport--md {
      width: 327px;
      bottom: -121px;
      right: -89px;
    }

    @include viewport--sm {
      width: 233px;
      bottom: -84px;
      right: -65px;
    }
  }

  &__title {
    font-family: $font-family--inter;
    font-weight: 600;
    font-size: 48px;
    padding: 64px 16px;
    margin: 0 auto 48px;
    line-height: 64px;
    letter-spacing: -0.02em;
    text-align: center;
    color: #33376b;
    width: 100%;
    max-width: 926px;

    @include viewport--xl {
      max-width: 695px;
      padding-top: 35px;
      margin-bottom: 20px;
      font-size: 36px;
      line-height: 48px;
    }

    @include viewport--lg {
      max-width: 695px;
      padding-top: 35px;
      margin-bottom: 20px;
      font-size: 36px;
      line-height: 48px;
    }

    @include viewport--md {
      padding-top: 65px;
      font-size: 32px;
      line-height: 40px;
      max-width: 720px;
      margin: 0 auto 8px;
    }

    @include viewport--sm {
      margin: 0 auto 16px;
      padding: 40px 16px;
    }

    span {
      background: linear-gradient(90deg, #4d6dd3 0%, #5f98ff 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include viewport--xl {
      gap: 17px;
    }

    @include viewport--md {
      gap: 12px;
    }
  }

  &__card {
    border-radius: 60px;
    padding: 60px 64px 60px 100px;
    background: #f2f6fe;
    display: grid;
    grid-template-columns: 37% 1fr;
    gap: 6.5%;

    @include viewport--xl {
      border-radius: 45px;
      grid-template-columns: 39% 56.34%;
      padding: 44px 44px 44px 75px;
      justify-content: space-between;
      gap: 0;
    }

    @include viewport--lg {
      grid-template-columns: 39% 56.34%;
      padding: 44px 44px 44px 75px;
      justify-content: space-between;
      gap: 0;
    }

    @include viewport--md {
      grid-template-columns: 40% 1fr;
      border-radius: 30px;
      padding: 32px 7px 32px 24px;
      gap: 0 20px;
    }

    @include viewport--sm {
      border-radius: 20px;
      padding: 24px 16px 16px;
      display: flex;
      flex-direction: column;
      gap: 0;
    }
  }

  &__card-title {
    margin: 0 0 20px;
    font-family: $font-family--inter;
    font-weight: 600;
    font-size: 48px;
    line-height: 64px;
    letter-spacing: -0.02em;
    color: #33376b;

    @include viewport--xl {
      margin: 0 0 15px;
      font-size: 36px;
      line-height: 48px;
      padding-right: 18px;
    }

    @include viewport--md {
      padding-right: 0;
      font-size: 32px;
      line-height: 40px;
      margin: 0;
      grid-column: span 2;
      max-width: 440px;
    }

    @include viewport--sm {
      margin: 0 0 12px;
      font-size: 24px;
      line-height: 32px;
    }

    span {
      background: linear-gradient(90deg, #4d6dd3 0%, #5f98ff 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &__card-text-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include viewport--md {
      justify-content: flex-start;
      margin-top: 20px;
    }

    @include viewport--sm {
      margin-top: 0;
      margin-bottom: 24px;
    }
  }

  &__card-text {
    margin: 0;
    font-family: $font-family--inter;
    font-weight: 400;
    font-size: 22px;
    line-height: 36px;
    color: #414a68;

    @include viewport--xl {
      font-size: 18px;
      line-height: 30px;
    }

    @include viewport--md {
      font-size: 16px;
      line-height: 26px;
    }

    @include viewport--sm {
      font-size: 18px;
      line-height: 28px;
    }

    & + .ai-cards__card-text {
      margin-top: 16px;

      @include viewport--xl {
        margin-top: 12px;
      }

      @include viewport--sm {
        margin-top: 16px;
      }
    }
  }

  &__card-image-wrap{
    @include viewport--xl {
      display: flex;
      align-items: center;
      height: fit-content;
    }

    @include viewport--md {
      margin-top: auto;
    }

    video {
      clip-path: inset(5px 5px);
    }
  }

  &__card-image-wrap > svg {
    transform: unset !important;
  }

  &__card-image {
    @include viewport--sm {
      margin-right: -16px;
      margin-left: -16px;
    }
  }

  &__description {
    font-family: $font-family--inter;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;

    text-align: center;
    color: $color-port-gore;

    max-width: 805px;
    margin: 0 auto 30px;
  }

  &__btn {
    display: block;
    width: 220px;
    margin: 0 auto 100px;

    @include viewport--md {
      margin-bottom: 75px;
    }
  }

  &--ltv-forecasting-solution {
    background: linear-gradient(180deg, #f2f5ff 0%, #fff 100%);
    padding-top: 100px;
    padding-bottom: 100px;
    margin-bottom: 0;

    @include viewport--md {
      padding-top: 55px;
      padding-bottom: 55px;
    }

    #{$root}__title {
      padding: 0;
      margin-bottom: 30px;
    }


  }
}
