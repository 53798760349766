.pricing-card {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 364px;
  padding: 48px 24px 54px;

  background-color: $color-default-white;
  border-radius: 10px;
  box-shadow: $card-shadow;

  @include viewport--lg {
    padding: 48px 20px 54px;
  }

  &__img {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    max-width: 123px;
    height: 106px;
    max-height: 106px;
  }

  &__title {
    margin: 0 0 12px;

    font-weight: 700;
    font-size: 26px;
    line-height: 34px;
    text-align: center;
    color: $color-black;
    max-width: 97%;

    @include viewport--md {
      max-width: none;
    }

    @include viewport--sm {
      max-width: 89%;
    }

    @media (max-width: 413px) {
      max-width: none;
    }
  }

  &__description {
    margin: 0 0 36px;

    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: $color-ship-cove;

    @include viewport--md {
      margin-bottom: 24px;
    }

    @include viewport--sm {
      margin-bottom: 32px;
    }
  }

  &__offer {
    margin: auto 0 38px;
    display: flex;
    flex-direction: column;

    span {
      font-weight: 700;
      text-align: center;
      color: $color-blue;

      &:first-child {
        font-size: 18px;
        line-height: 26px;
      }

      &:last-child {
        font-size: 26px;
        line-height: 30px;
      }
    }
  }

  &__lead {
    margin: auto 0 42px;
    width: 120px;
    text-align: center;

    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: $color-blue;
  }

  &__special {
    position: absolute;
    top: 0;
    left: 50%;

    width: 85%;
    padding: 12px 18px;

    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: $color-default-white;

    background-color: $color-blue;
    border-radius: 100px;
    transform: translate(-50%, -50%);

    @include viewport--sm {
      padding: 12px 24px;
    }

    @media (max-width: 413px) {
      padding: 12px 7px;
      width: 91%;
      font-size: 15px;
    }
  }

  .btn {
    min-width: 206px;
  }

}
