.quote-cards {
  $root: &;

  padding-top: 79px;
  padding-bottom: 77px;

  background-color: $color-blue;
  background-image: url("../img/bg/blue-stars-background.svg");
  background-repeat: no-repeat;
  background-size: 900px 192px;
  background-position: bottom 21px left 0;

  @include viewport--sm {
    padding-top: 45px;
    padding-bottom: 45px;

    background-image: none;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: -35px;
    margin-top: -35px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 361px;
    padding: 30px 33px 26px;
    margin-top: 35px;
    margin-right: 35px;

    background-color: $color-default-white;
    box-shadow: 0px 10px 50px rgba(3, 5, 31, 0.1);
    border-radius: 10px;

    transition: transform $default-transition-settings;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        transform: translate(0, -5px);
      }
    }

    @include viewport--sm {
      max-width: 341px;
    }

    &--no-author-and-source {
      padding-top: 50px;
      padding-bottom: 50px;

      @include viewport--sm {
        padding: 25px;
      }

      .quote-cards__quote-wrapper {
        justify-content: flex-start;
        margin-bottom: 0;
      }

      .quote-cards__quote {
        color: $color-torea-bay-4;
        font-size: 22px;
        line-height: 29px;
        font-family: $font-family--inter;
        font-weight: 400;
        margin: 0;
      }
    }
  }

  &__card-logo {
    margin-bottom: 29px;
  }

  &__quote-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 29px;
  }

  &__quote {
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    color: $color-torea-bay-4;

    span {
      font-weight: 700;
      color: $color-blue;
    }

    @include viewport--sm {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__author {
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: $color-comet;

    @include viewport--sm {
      font-size: 14px;
      line-height: 17px;
    }
  }

  &__author-name {
    font-weight: 700;
    color: $color-blue;
  }

  &__source-logo-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 26px;
    margin-top: 25px;
  }

  &__source-logo {
    max-height: 26px;
    object-fit: contain;
  }

  &__buttons {
    display: flex;
    justify-content: center;
  }

  &__btn {
    margin-top: 45px;
  }

  &--grey {
    padding: 75px 0;
    background-color: #f5f7ff;
    background-image: none;

    @include viewport--sm {
      padding: 45px 0;
    }

    .container {
      padding: 0 90px;

      @include viewport--lg {
        padding: 0 60px;
      }

      @include viewport--md {
        padding: 0 40px;
      }

      @include viewport--sm {
        padding: 0 26px;
      }
    }

    .quote-cards__items {
      margin-top: -32px;
      margin-right: -32px;

      @include viewport--sm {
        margin-top: -17px;
        margin-right: 0;
      }
    }

    .quote-cards__item {
      align-items: flex-start;
      margin-top: 32px;
      margin-right: 32px;
      padding: 27px 21px;
      max-width: 405px;

      @include viewport--sm {
        max-width: 364px;
        margin-top: 17px;
        margin-right: 0;
        padding: 27px 21px;
      }
    }

    .quote-cards__item--narrow {
      max-width: 367px;

      @include viewport--md {
        max-width: 405px;
      }

      @include viewport--sm {
        max-width: 364px;
      }
    }

    .quote-cards__card-logo {
      margin-bottom: 40px;

      @include viewport--sm {
        width: 100px;
        height: 17px;
        margin-bottom: 33px;
      }
    }

    .quote-cards__quote-wrapper {
      min-height: 72px;
      margin-bottom: 40px;

      @include viewport--sm {
        min-height: 66px;
        margin-bottom: 33px;
      }
    }

    .quote-cards__quote {
      font-size: 20px;
      line-height: normal;
      text-align: left;
      color: #41475b;
      letter-spacing: -0.1px;

      @include viewport--sm {
        font-size: 18px;
        line-height: normal;
        letter-spacing: 0.2px;
      }
    }

    .quote-cards__author {
      text-align: left;
    }

    .quote-cards__author-name {
      font-size: 16px;
      font-weight: 700;
      line-height: normal;
      color: $color-default-black;
      letter-spacing: -0.1px;

      @include viewport--sm {
        font-size: 16px;
        line-height: normal;
      }
    }

    .quote-cards__author-position {
      font-size: 16px;
      font-weight: 700;
      line-height: normal;
      color: $color-default-black;
      letter-spacing: -0.1px;

      @include viewport--sm {
        font-size: 16px;
        line-height: normal;
      }
    }
  }

  &--book-demo {
    background: linear-gradient(90deg, #F1F4FF 0.31%, $color-default-white 98%);

    @include viewport--md {
      background: #F5F7FF;
    }
  }

  &--ai-driven-attribution {
    padding-top: 62px;
    background-image: none;
    background-color: $color-royal-blue-5;

    .container {
      padding: 0 30px;
    }

    #{$root}__card-logo {
      margin-bottom: 22px;
    }

    #{$root}__quote-wrapper {
      justify-content: flex-start;
    }

    #{$root}__items {
      margin-right: -21px;
      margin-top: -21px;
    }

    #{$root}__item {
      max-width: 395px;
      margin-right: 21px;
      padding: 24px 20px 20px;
      pointer-events: none;

      &:nth-child(2) {
        padding: 24px 50px 20px;

        @include viewport--sm {
          padding: 24px 20px 20px;
        }
      }

      @include viewport--sm {
        margin-top: 23px;
      }
    }

    #{$root}__quote {
      color: $color-torea-bay-4;
      font-family: $font-family--inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 131.915%;

      span {
        font-weight: 700;
        color: $color-torea-bay-4;
      }
    }
  }
}
