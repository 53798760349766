.steps {
  padding: 93px 0 104px;

  background-color: $color-ghost-white;

  @include viewport--md {
    padding-bottom: 78px;
  }

  @include viewport--sm {
    padding-top: 85px;
    padding-bottom: 70px;
  }
}

.steps__container {
  max-width: 1200px;
  margin: 0 auto;

  text-align: center;
}

.steps__title {
  margin: 0 0 10px;

  font-size: 36px;
  line-height: 48px;

  @include viewport--sm {
    font-size: 24px;
    line-height: 34px;
  }
}

.steps__subtitle {
  margin-bottom: 73px;

  font-weight: bold;
  font-size: 26px;
  line-height: 34px;
  color: $color-ship-cove;

  @include viewport--md {
    margin-bottom: 52px;
  }

  @include viewport--sm {
    margin-bottom: 65px;

    font-size: 20px;
    line-height: 28px;
  }
}

.steps__list {
  display: flex;
  justify-content: space-between;
  margin: 0 0 95px;
  margin-right: -30px;
  padding: 0;

  list-style: none;

  @include viewport--md {
    display: block;
    margin-bottom: 78px;
    margin-right: 0;
  }

  @include viewport--sm {
    margin-bottom: 12px;
  }
}

.steps__item {
  margin-right: 30px;

  @include viewport--md {
    width: max-content;
    margin: 0 auto 50px;
  }

  @include viewport--sm {
    margin-bottom: 40px;
    width: 100%;
  }

  &:last-child {
    margin-right: 0;

    @include viewport--md {
      margin-right: auto;
    }

    @include viewport--sm {
      margin-bottom: 0;
    }
  }

  img {
    width: auto;
    max-height: 225px;

    border-radius: 10px;
    box-shadow: 0 0 20px rgba(77, 109, 211, 0.1);

    object-fit: contain;

    @include viewport--sm {
      margin: 0 auto;
    }
  }

  &--no-shadow {
    img {
      box-shadow: none;
    }
  }
}

.steps__number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin: 0 auto 20px;

  font-size: 20px;
  line-height: 36px;
  color: $color-default-white;

  background-color: $color-blue;
  border-radius: 60%;
}

.steps__name {
  max-width: 270px;
  margin: 0 auto 50px;
  margin-top: 40px;

  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: $color-torea-bay;

  @include viewport--md {
    margin-top: 43px;
  }
}

.steps__btns {
  @include viewport--sm {
    padding-top: 44px;
  }

  .btn {
    margin-right: 15px;

    @include viewport--md {
      margin-right: 0;
    }

    @include viewport--sm {
      width: max-content;
      min-width: 280px;
    }
  }
}

.steps--ga {
  .steps__btns {
    .btn {
      margin-right: 0;
      margin-left: 85px;

      @include viewport--md {
        margin-left: 0;
      }
    }
  }
}
