.influence-card {
  display: flex;
  flex-direction: column;
  max-width: 395px;
  padding: 42px 52px;

  background: $color-default-white;
  box-shadow: 0px 7px 50px rgba(53, 59, 122, 0.1);
  border-radius: 10px;

  transition: opacity $default-transition-settings;

  &[href]:hover {
    opacity: 0.7;
  }

  @include viewport--sm {
    max-width: 327px;
    padding: 26px 42px;
  }

  &__value {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    font-weight: 700;
    font-size: 50px;
    line-height: 61px;
    color: $color-torea-bay-4;

    @include viewport--sm {
      margin-bottom: 27px;

      font-size: 42px;
      line-height: 51px;
    }

    img {
      width: 18px;
      height: 30px;
      margin-left: 20px;
      margin-top: -3px;

      @include viewport--sm {
        width: 15px;
        height: 25px;
        margin-left: 15px;
        margin-top: -2px;
      }
    }

    &--decrease {
      img {
        transform: rotate(180deg);
      }
    }
  }

  &__text {
    margin-bottom: 32px;

    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: $color-comet;

    @include viewport--sm {
      margin-bottom: 27px;

      font-size: 16px;
      line-height: 19px;
    }
  }

  &__logo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: auto;
  }
}