.bigquery-intro {
  background: $almost-white no-repeat;
  padding: 114px 0 260px;

  @include viewport--md {
    background: none;
    background-color: $color-ghost-white;
    padding: 49px 0 60px;
  }

  @include viewport--sm {
    padding: 39px 0 58px;
  }

  .container {
    position: relative;

    @include viewport--md {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__title {
    font-size: 48px;
    line-height: 56px;
    font-weight: 700;
    color: $color-black;
    margin: 0 0 36px;
    max-width: 560px;

    @include viewport--lg {
      font-size: 40px;
      line-height: 48px;
      max-width: 470px;
    }

    @include viewport--md {
      font-size: 36px;
      line-height: 44px;
      text-align: center;
      margin-bottom: 15px;
    }

    @include viewport--sm {
      font-size: 24px;
      line-height: 34px;
      max-width: 330px;
      margin-bottom: 18px;
    }
  }

  &__description {
    font-size: 18px;
    line-height: 26px;
    color: $color-comet;
    margin: 0 0 26px;
    max-width: 500px;

    @include viewport--lg {
      max-width: 470px;
    }

    @include viewport--md {
      text-align: center;
      margin-bottom: 20px;
    }

    @include viewport--sm {
      font-size: 16px;
      line-height: 24px;
      max-width: 370px;
      margin-bottom: 24px;
    }
  }

  &__lead {
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    line-height: 24px;
    color: $color-blue;
    margin: 0 0 34px;
    max-width: 500px;
    font-weight: 600;
    transition: color $default-transition-settings;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-comet;
      }
    }

    @include viewport--lg {
      max-width: 470px;
    }

    @include viewport--md {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 22px;
    }

    @include viewport--sm {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }
  }

  &__action {
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;

    @include viewport--lg {
      max-width: 470px;
    }

    @include viewport--md {
      margin-bottom: 22px;
    }

    @include viewport--sm {
      justify-content: center;
    }

    .btn {
      min-width: 214px;

      @include viewport--md {
        margin-bottom: 0;
      }

      @include viewport--sm {
        width: 100%;
        max-width: 290px;
      }
    }
  }


  .google-partner {
    position: absolute;
    left: 120px;
    bottom: -174px;

    @include viewport--lg {
      left: 60px;
    }

    @include viewport--md {
      position: static;
      order: 1;
    }

    & + .img-fix img {
      position: absolute;
      right: 90px;
      top: 73%;
      max-width: 585px;
      transform: translateY(-50%);

      @include viewport--lg {
        right: 2.5vw;
        width: 45%;
      }

      @include viewport--md {
        width: 100%;
        top: 0;
        right: auto;
        transform: none;
        max-width: none;
      }
    }

    & + .img-fix {
      @include viewport--md {
        order: -1;
        max-width: 585px;
      }
    }
  }
}
