.simple-card {
  box-shadow: 0 4px 19px 0 rgba(66, 92, 176, 0.25);
  background-color: $color-default-white;
  border-radius: 10px;
  padding: 35px;
  max-width: 370px;
  min-height: 210px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
  align-items: flex-start;
  flex-shrink: 0;

  @include viewport--md {
    width: calc(50% - 18px);
    padding-right: 18px;
  }

  @include viewport--sm {
    width: 100%;
    max-width: 370px;
    flex-shrink: 1;
  }


  &__icon {
    width: 54px;
    height: 54px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    margin: 0;
    font-family: $font-family--lato;
    font-weight: 700;
    font-size: 22px;
    text-align: left;
    line-height: 118%;
    color: $color-deep-koamaru;
    max-width: 280px;
  }

  &__description {
    margin: 0;
    font-weight: 400;
    font-size: 20px;
    line-height: 151%;
    color: $color-port-gore;
  }

  &__image {
    max-width: 300px;
    width: 100%;

    img {
      width: 100%;
      height: auto;
    }
  }
}