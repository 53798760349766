.pricing-intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 75px;

    @include viewport--sm {
        width: 363px;
        margin: 0 auto;
    }

    &__title {
        max-width: 85%;
        margin: 0 0 30px 0;
        color: $color-black;
        text-align: center;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px;

        @include viewport--sm {
            margin-top: -5px;
            padding: 0 11%;
            max-width: initial;
            font-size: 28px;
            line-height: 36px;
            margin-bottom: 24px;
        }

        span {
            color: $color-blue;
        }
    }

    &__text {
        max-width: 63%;
        margin: 0 0 47px 0;
        color: $color-comet;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;

        @include viewport--sm {
            margin-bottom: 42px;
            max-width: initial;
            font-size: 20px;
            font-weight: 400;
            line-height: 31px;
            padding: 0 13px;
        }

        p {
            margin-bottom: 26px;
            font-weight: 500;

            @include viewport--sm {
                margin-bottom: 31px;
            }
        }

        p:last-child {
            @include viewport--sm {
                font-weight: 700;
            }
        }
    }

    & .demo-sm-form {
        @include viewport--sm {
            display: none;
        }

        & input {
            width: 318px;
        }
    }

    &__demo {
        display: none;

        @include viewport--sm {
            display: inline-block;
            min-width: 215px;
            padding: 16px 30px 19px 30px;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }
}

.pricing-plans .trusted-brands--blue {
    margin: 0 -84px;
    background-color: transparent;

    @include viewport--md {
        margin: 0;
    }

    @include viewport--sm {
        display: none;
    }

    .container {
        padding: 0;
    }

    .trusted-brands__list {
        justify-content: center;
        flex-wrap: wrap;
        margin-left: 0;
    }

    .trusted-brands__item {
        width: 20%;
        margin-left: 0;

        @include viewport--md {
            width: 33.33%
        }

    }
}
