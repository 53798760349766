.advantages {
  padding: 55px 0 93px;
  background: $color-ghost-white;

  @include viewport--md {
    padding: 40px 0 44px;
  }

  @include viewport--sm {
    padding: 50px 0 54px;
  }

  &__block-title {
    margin-bottom: 90px;

    font-weight: 700;
    font-size: 42px;
    line-height: 48px;
    color: $color-blue;
    text-align: center;

    span {
      color: $color-torea-bay;
    }

    @include viewport--md {

      font-size: 36px;
      line-height: 44px;
    }

    @include viewport--sm {

      font-size: 28px;
      line-height: 36px;
      margin: 0 0 50px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    color: $color-black;
    margin: 0 0 81px;
    text-align: center;

    @include viewport--md {
      margin: 0 0 68px;
    }

    @include viewport--sm {
      font-size: 24px;
      line-height: 34px;
      margin: 0 0 52px;
    }
  }

  &__list {
    margin: 0 0 -30px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 1279px) {
      justify-content: center;
    }

    @include viewport--sm {
      margin-bottom: -20px;
    }
  }

  &__item {
    padding: 46px 26px 34px 29px;
    background-color: $color-default-white;

    border: 1px solid;
    border-image-source: linear-gradient(180deg, #E8EBF8 0%, rgba(232, 235, 248, 0) 100%);
    border-radius: 10px;
    width: calc(33% - 22.5px);
    margin-right: 30px;
    margin-bottom: 30px;
    min-height: 300px;

    &:last-child {
      margin-right: 0;
    }

    @media (min-width: 1280px) {
      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    @media (min-width: 1024px) and (max-width: 1279px) {
      width: calc(33.3333% - 20px);

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    @include viewport--md {
      min-height: 0;
      padding-bottom: 24px;
      width: calc(50% - 15px);

      &:nth-child(even) {
        margin-right: 0;
      }
    }

    @include viewport--sm {
      padding: 20px 30px 20px;
      margin-right: 0;
      width: 100%;
      margin-bottom: 20px;
    }
  }

  &__img {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }

  &__name {
    color: $color-black;
    font-weight: 700;
    font-size: 32px;
    line-height: 34px;
    max-width: 270px;
    margin-bottom: 28px;

    @include viewport--md {
      margin-bottom: 25px;
    }

    @include viewport--sm {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 18px;
      max-width: 260px;
    }
  }

  &__description {
    color: $color-comet;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    margin-bottom: 30px;

    span {
      font-weight: 700;
    }

    @include viewport--sm {
      font-size: 16px;
      line-height: 24px;
      width: 95%;
      margin-bottom: 15px;
    }

    &:last-child {
      margin: 0;
    }
  }

  &--per-3 {
    @include viewport--sm {
      padding-top: 43px;
    }

    .advantages__title {
      margin-bottom: 71px;

      @include viewport--md {
        max-width: 550px;
        margin: 0 auto 30px;
      }

      @include viewport--sm {
        margin-bottom: 42px;
      }
    }

    .advantages__name {
      color: $color-black;
      font-weight: 700;
      font-size: 32px;
      line-height: 34px;
      max-width: 270px;
      margin-bottom: 28px;

      @include viewport--lg {
        font-size: 20px;
        line-height: 28px;
      }

      @include viewport--md {
        font-size: 24px;
        line-height: 32px;
      }

      @include viewport--sm {
        margin-bottom: 18px;
      }
    }

    .advantages__list {
      margin: 0 0 -40px;

      @include viewport--md {
        margin-bottom: -30px;
      }

      @include viewport--sm {
        margin-bottom: -20px;
      }
    }

    .advantages__item {
      width: calc(33.3333% - 30px);
      margin-right: 45px;
      margin-bottom: 40px;
      padding-bottom: 24px;
      padding-right: 24px;

      @media (min-width: 1024px) {
        &:nth-child(3n) {
          margin-right: 0;
        }
      }

      @include viewport--md {
        margin-right: 30px;
        margin-bottom: 30px;
        width: calc(50% - 15px);

        &:nth-child(even) {
          margin-right: 0;
        }
      }

      @include viewport--sm {
        margin-right: 0;
        width: 100%;
        margin-bottom: 20px;
        min-height: 286px;
      }
    }
  }
}
