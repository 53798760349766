.subscribe {
    margin: 0 auto 20px;
    display: flex;
    width: calc(100% - 44px);
    max-width: 1209px;
    min-height: 303px;
    padding: 60px;

    color: $color-periwinkle;
    background-color: $color-torea-bay;

    border-radius: 10px;

    background-image: url("../img/bg/newsletter-subscribe-bg@1x.png");
    background-repeat: no-repeat;
    background-position: bottom -53px right -49px;
    background-size: 456px 323px;

    @include retina {
        background-image: url("../img/bg/newsletter-subscribe-bg@2x.png");
    }

    @media (max-width: 1100px) {
        max-width: 950px;
        background-position: bottom -115px right -90px;
    }

    @include viewport--md {
        margin: 0 40px;
        padding: 47px 49px 59px;
        width: auto;
        min-height: 332px;
    }

    @include viewport--sm {
        margin: 0 22px 35px;
        padding: 47px 30px 51px;
        background-image: none;
    }

    &--blog {
        width: initial;

        @media (max-width: 1100px) {
            max-width: initial;
        }

        @include viewport--md {
            margin: initial;
        }

        @include viewport--sm {
            margin: initial;
        }
    }

    &__wrapper {
        max-width: 650px;

        @include viewport--sm {
            max-width: none;
            width: 100%;
        }
    }

    h2 {
        margin: 0;
        margin-bottom: 25px;

        font-weight: 700;
        // font-size: 48px;
        // line-height: 56px;
        font-size: 43px;
        line-height: 43px;
        color: $color-default-white;

        @include viewport--md {
            margin-bottom: 17px;

            font-size: 36px;
            line-height: 48px;
        }

        @include viewport--sm {
            margin-bottom: 19px;

            font-size: 24px;
            line-height: 34px;
        }
    }

    &--done {
        form {
            display: none;
        }

        .subscribe__success-msg {
            display: block;
        }
    }
}

.subscribe__success-msg {
    margin: 0;
    font-size: 20px;
    line-height: 24px;
    color: $color-default-white;
    font-weight: 700;
    display: none;

    @include viewport--sm {
        margin-top: 40px;
        font-size: 16px;
        line-height: 20px;
    }
}

.subscribe__text {
    margin-bottom: 29px;
    // padding-right: 190px;
    max-width: 688px;

    font-weight: 400;
    // font-size: 18px;
    // line-height: 24px;
    font-size: 24px;
    line-height: 32px;

    @include viewport--md {
        // margin-bottom: 37px;
        margin-bottom: 55px;
        font-size: 20px;
        line-height: 26px;
    }

    @include viewport--sm {
        margin-bottom: 20px;
        padding-right: 0;

        font-size: 16px;
        line-height: 24px;
    }

    p {
        margin: 0;
    }

    p + p {
        margin-top: 24px;
    }
}

.subscribe__fields {
    display: flex;

    @include viewport--sm {
        display: block;
    }

    input {
        flex-basis: 318px;
        height: 54px;
        max-height: 54px;
        padding: 16px 22px;

        border: none;
        border-radius: 5px;

        &::placeholder {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: $color-rock-blue;
        }

        @include viewport--sm {
            display: block;
            width: 100%;
        }

        @media (max-width: 350px) {
            padding: 10px;
        }
    }

    .btn {
        margin-left: 14px;
        flex-basis: 188px;

        @include viewport--sm {
            margin: 0;
            margin-top: 19px;
            width: 100%;
        }
    }
}
