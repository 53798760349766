.influence-block {
  padding: 75px 0;

  background-color: $color-ghost-white;

  &__container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 90px;

    @include viewport--lg {
      padding: 0 60px;
    }

    @include viewport--md {
      padding: 0 40px;
    }

    @include viewport--sm {
      padding: 0 16px;
    }
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: -37px;
    margin-top: -37px;
  }

  &__card {
    // width: calc(33% - 25px);
    width: 395px;
    margin-top: 37px;
    margin-right: 37px;
  }
}