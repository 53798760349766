.custom-select {
  position: relative;

  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  color: $color-black;

  width: 300px;
  //padding: 14px 0 28px;
  //margin: 0 0 44px 0;

  @media screen and (max-width: 767px) {
    //padding: 4px 0 14px;
    width: 100%;
  }

  &__label {
    position: absolute;
    top: 50%;
    left: 10px;

    transform: translateY(-50%);

    transition: transform 0.3s ease, opacity  0.3s ease;

    pointer-events: none;

    @media screen and (max-width: 767px) {
      transform: translateY(calc(-50% - 5px));
    }
  }

  &__placeholder {
    position: absolute;
    top: 50%;
    left: 10px;

    transform: translateY(-50%);

    transition: transform 0.3s ease, opacity 0.3s ease;

    pointer-events: none;

    @media screen and (max-width: 767px) {
      transform: translateY(calc(-50% - 5px));
    }
  }

  select {
    position: absolute;
    top: 18px;

    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;

    white-space: nowrap;

    border: 0;

    clip: rect(0 0 0 0);
    clip-path: inset(100%);
  }

  &__button {
    display: flex;
    align-items: center;
    width: 100%;
    height: 57px;
    padding: 5px 10px;

    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    outline: none;
    cursor: pointer;

    transition: border 0.3s ease;

    &:hover {
      border: 2px solid rgba(0, 0, 0, 0.5);
    }

    &:focus {
      border: 2px solid rgba(0, 0, 0, 0.8);
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.3;
    }

    &--book-demo {
      height: 70px;

      @include viewport--sm {
        height: 59px;
      }
    }
  }

  &__list {
    position: absolute;
    left: 5px;
    bottom: 23px;
    z-index: 1;

    width: 285px;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    //max-height: 175px;

    list-style: none;

    background-color: #ffffff;
    filter: drop-shadow(0px 4px 20px rgba(51, 74, 147, 0.20));
    border-radius: 10px;
    transform: translateY(100%) translateZ(0);
    visibility: hidden;
    opacity: 0;

    transition: visibility 0.3s ease, opacity 0.3s ease;

    @media (max-width: 400px) {
      width: 85%;
    }

    //&::before {
    //  content: '';
    //  position: absolute;
    //  top: -11.5px;
    //  left: 28px;
    //  width: 0;
    //  height: 0;
    //  border-left: 9px solid transparent;
    //  border-right: 9px solid transparent;
    //
    //  border-bottom: 12px solid $color-default-white;
    //}
  }

  &__arrow-up {
    display: none;
    position: absolute;
    top: 22.5px;
    left: 33px;
    z-index: 20;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;

    border-bottom: 12px solid $color-default-white;
    pointer-events: none;
    transition: border-bottom-color 0.3s ease;

    &--hover {
      border-bottom-color: $color-zumthor;
    }
  }

  &__item {
    color: $color-fiord;
    padding: 17px 35px;
    border-bottom: 1px solid #DAE4F8;
    font-family: $font-family--inter;
    font-weight: 600;
    outline: none;
    cursor: pointer;

    transition: background-color 0.3s ease;

    //&[aria-selected="true"] {
    //  background-color: $color-zumthor;
    //
    //  @media (hover: hover) {
    //    &:hover:not(.focus-visible) {
    //      background-color: $color-zumthor;
    //    }
    //  }
    //
    //  &.focus-visible:focus {
    //    background-color: $color-zumthor;
    //  }
    //}

    @media (hover: hover) {
      &:hover:not(.focus-visible) {
        background-color: $color-zumthor;
      }
    }

    &.focus-visible:focus {
      background-color: $color-zumthor;
    }
  }

  &__list > li:first-child {
    border-radius: 10px 10px 0 0;
  }

  &__list > li:last-child {
    border-radius: 0 0 10px 10px;
  }

  &__error {
    position: absolute;
    bottom: -15px;
    left: 0;

    color: #ff1553;
    pointer-events: none;
    opacity: 0;

    transition: opacity 0.1s ease;
  }

  &__text {
    //font-size: 20px;
    //line-height: 24px;
    flex-shrink: 0;
    width: 85%;
    overflow: hidden;

    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    letter-spacing: 0.01em;

    //opacity: 0;
    //
    //transition: opacity 0.3s ease 0.1s;

    br {
      display: none;
    }
  }

  &__icon {
    position: relative;
    flex-shrink: 0;
    width: 11px;
    height: 9px;
    left: -9px;
    margin-left: auto;
    margin-top: -20px;
    transition: transform 0.3s ease;
    background-repeat: no-repeat;
    background-position: center;
    transform: translateZ(0);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='9' viewBox='0 0 12 9' fill='none'%3E%3Cpath d='M1.35463 1.55562L5.75297 7.0961L10.1513 1.55562H1.35463Z' fill='%23E8EBF8' stroke='%23C0C7E8' stroke-width='1.17987'/%3E%3C/svg%3E");
  }

  &.is-disabled {
    pointer-events: none;

    .custom-select__list {
      display: none;
    }
  }

  &.open-up {
    .custom-select__list {
      bottom: auto;
      top: 0;
      transform: translateY(calc(-100% + 10px));
    }
  }

  &.is-open {
    .custom-select__list {
      visibility: visible;
      opacity: 1;
    }

    .custom-select__icon {
      transform: rotate(180deg);
    }

    .custom-select__label {
      opacity: 0;
    }

    .custom-select__arrow-up {
      display: initial;
    }
  }

  &.not-empty {
    .custom-select__text {
      opacity: 1;
    }

    .custom-select__label {
      opacity: 0;
    }

    .custom-select__placeholder {
      opacity: 0;
    }
  }

  &.is-invalid {
    .custom-select__error {
      opacity: 1;
    }

    .custom-select__button {
      border: 2px solid #ff1553;

      &:focus,
      &:hover {
        border: 2px solid rgba(#ff1553, 0.5);
      }
    }
  }

  /* &.is-valid {
    .custom-select__button {
      border: 2px solid $color-eucalyptus;

      &:focus,
      &:hover {
        border: 2px solid rgba($color-eucalyptus, 0.5);
      }
    }
  } */

  &--placeholder {
    .custom-select__label {
      transform: translateY(calc(-50% - 40px));
      opacity: 1;
    }

    &.is-open .custom-select__label {
      opacity: 1;
    }

    &.not-empty .custom-select__label {
      opacity: 1;
    }
  }

  &.slide-up.not-empty,
  &.slide-up.is-open {
    .custom-select__label {
      transform: translateY(calc(-50% - 40px));
      opacity: 1;
    }

    .custom-select__text {
      opacity: 1;
    }
  }

  .input-message {
    position: absolute;
    right: 0;
    bottom: -5px;

    &.is-invalid {
      color: #ff0000;
    }

    &.is-valid {
      color: #15ab07;
    }
  }

  &--in-page-lead {
    width: 100%;

    .custom-select__button {
      width: 100%;
      padding: 28px 0 0 12px;

      font-weight: 600;
      font-size: 16px;
      line-height: 1.2;
      color: $color-black;

      background-color: $color-default-white;
      border: 1px solid $color-hawkes-blue;
      text-overflow: ellipsis;
      border-radius: 5px;

      @include viewport--sm {
        padding: 23px 0 0 7px;
      }
    }

    .custom-select__lab {
      pointer-events: none;
      max-width: 95%;
    }

    .custom-select__text {
      color: $color-black;
      font-family: $font-family--inter;
      font-size: 17.483px;
      font-style: normal;
      font-weight: 600;
      line-height: 19.6px;
      letter-spacing: 0.01em;

      @include viewport--sm {
        font-family: $font-family--proxima;
        font-size: 16px;
        letter-spacing: 0;
      }
    }

    .custom-select__arrow-up {
      top: 35.5px;

      @include viewport--sm {
        top: 25px;
      }
    }

    .custom-select__icon {
      left: -15px;
      margin-top: -27px;
    }
  }

  &--pricing {
    width: 100%;

    .custom-select__button {
      width: 100%;
      border: 1px solid #c0c7e8;
      border-radius: 8px;

      text-overflow: ellipsis;

      padding: 35px 15px 9px;

      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #33376b;
      font-family: $font-family--inter;

      background: $color-default-white;
      height: 70px;
    }

    .custom-select__lab {
      pointer-events: none;
      max-width: 95%;

      position: absolute;
      z-index: 1;
      left: 15px;
      top: 8px;

      font-family: $font-family--inter;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #afb9d7;
    }

    .custom-select__arrow-up {
      top: 35.5px;
    }

    .custom-select__icon {
      left: -15px;
      margin-top: -27px;
    }
  }
}
