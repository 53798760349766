.new-page-steps {
  // background-color: $color-zircon;
  padding: 90px 0;

  background: linear-gradient(180deg, #F6FAFF 0%, #FEFFFF 21.52%, #FFFFFF 100%);

  @include viewport--sm {
    padding: 60px 0;
  }

  &--cookieless {
    padding-top: 95px;

    @include viewport--sm {
      background: none;
    }

    & .new-page-steps__block-title {
      max-width: 830px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__block-title {
    margin: 0;
    margin-bottom: 90px;

    font-weight: 700;
    font-size: 42px;
    line-height: 42px;
    color: $color-tuna;
    text-align: center;

    span {
      color: $color-blue;
    }

    @include viewport--md {
      margin-bottom: 37px;

      font-size: 36px;
      line-height: 44px;
    }

    @include viewport--sm {
      // margin: 0;
      display: none;

      font-size: 28px;
      line-height: 36px;
    }
  }

  .container {
    position: relative;
  }

  .progress-pagination {
    align-items: flex-start;
    margin-bottom: 30px;

    @include viewport--sm {
      display: none;
    }
  }

  &__textbox {
    max-width: 515px;
    min-width: 300px;
    margin-right: 30px;

    @include viewport--md {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 0;
    }

    &--descr-mob {
      display: none;

      @include viewport--md {
        display: flex;
        margin-top: 4px;
      }
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;

    @include viewport--sm {
      flex-direction: column;
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 43px;
    margin-bottom: 107px;

    &:nth-child(even) {
      flex-direction: row-reverse;

      .new-page-steps__textbox {
        margin-right: 0;
        margin-left: 30px;

        @include viewport--md {
          margin-left: 0;
        }
      }

      @include viewport--md {
        flex-direction: column;
      }
    }

    &:last-child {
      margin-bottom: 0;

      @include viewport--md {
        margin: 0;
      }
    }

    &:first-child .new-page-steps__textbox--cookieless {
      @include viewport--sm {
        display: none;
      }
    }

    @include viewport--lg {
      padding: 0px 75px;
    }

    @include viewport--md {
      align-items: center;
      padding: 46px 24px;
      flex-direction: column;
      text-align: center;
      margin: 0;
    }

    @include viewport--sm {
      padding: 0;
      margin-bottom: 38px;
    }

  }

  &__count {
    font-size: 40px;
    line-height: 36px;
    color: $color-hawkes-blue;
    font-family: $font-family--roboto;
    font-weight: 700;
    margin: 0 0 50px;

    @include viewport--md {
      margin-bottom: 30px;
    }

    @include viewport--sm {
      margin-bottom: 20px;
      order: -2;
    }
  }

  &__title {
    margin: 0 0 44px;
    font-size: 24px;
    line-height: 26px;
    color: $color-blue;
    max-width: 500px;
    font-weight: bold;

    @include viewport--md {
      text-align: center;
      max-width: 560px;
      margin-bottom: 40px;
    }

    @include viewport--md {
      text-align: center;
      max-width: 560px;
      margin-bottom: 26px;
    }

    @include viewport--sm {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__subtitle {
    margin: 0 0 44px;
    font-size: 30px;
    line-height: 36px;
    font-weight: bold;
    color: $color-torea-bay-4;
    max-width: 500px;

    span {
      color: $color-blue;
    }

    h4 {
      display: inline;
      margin: 0;

      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
      color: inherit;
    }

    @include viewport--md {
      text-align: center;
      max-width: 560px;
      // margin-bottom: 40px;
      margin-bottom: 0;
    }

    @include viewport--sm {
      font-size: 24px;
      line-height: 30px;
      // margin-bottom: 30px;
    }
  }

  //&__title {
  //  text-align: left;
  //  font-size: 26px;
  //  line-height: 34px;
  //  font-weight: 700;
  //  margin: 0 auto 25px;
  //  color: $color-black;
  //  max-width: 1000px;
  //
  //  span {
  //    color: $color-blue;
  //  }
  //
  //  @include viewport--md {
  //    width: 550px;
  //    margin-bottom: 55px;
  //    text-align: center;
  //  }
  //
  //  @include viewport--sm {
  //    font-size: 24px;
  //    line-height: 34px;
  //    width: 100%;
  //    margin-bottom: 0;
  //  }
  //}

  &__slide-title {
    font-size: 26px;
    line-height: 34px;
    font-weight: 700;
    margin: 0 0 26px;
    color: $color-black;
    max-width: 515px;

    span {
      color: $color-blue;
    }

    @include viewport--md {
      font-size: 26px;
      line-height: 34px;
      margin-bottom: 20px;
      max-width: 500px;
      text-align: center;
    }

    @include viewport--sm {
      font-size: 24px;
      margin-bottom: 24px;
      max-width: 280px;
      width: 100%;
    }
  }

  &__description,
  &__lead {
    margin: 0 0 44px;
    font-size: 20px;
    line-height: 32px;
    color: $color-comet;
    max-width: 495px;
    font-weight: 300;

    @include viewport--md {
      max-width: 500px;
      text-align: center;
    }

    @include viewport--sm {
      font-size: 16px;
      line-height: 24px;
      max-width: 360px;
      width: 100%;
    }
  }

  &__description {
    margin: 0 0 25px;

    span {
      font-weight: 600;
    }

    &:last-child {
      margin: 0;
    }

    &--desktop {
      @include viewport--md {
        display: none;
      }
    }
  }

  img {
    max-width: 595px;
    min-width: 300px;
    max-height: 448px;
    object-fit: contain;
    object-position: right;
    box-shadow: none;

    @include viewport--md {
      position: static;
      transform: none;
      margin: 20px 0;
      object-position: center;
    }

    @include viewport--sm {
      // order: -1;
      max-width: 364px;
      max-height: 364px;
      width: 100%;
      //margin-top: 0;
    }
  }

  .iconfixleft {
    margin-left: -40px;

    @include viewport--md {
      margin: 20px 0;
    }
  }

  .iconfixtop {
    margin-top: -55px;

    @include viewport--md {
      margin: 0 0 30px;
    }
  }

  picture {
    @include viewport--sm {
      order: -1;
    }
  }

  &__action-links {
    margin-top: 26px;
    margin-bottom: -20px;
    display: flex;
    flex-wrap: wrap;
    max-width: 400px;

    @include viewport--md {
      margin-top: 37px;
    }

    @include viewport--sm {
      align-self: flex-start;
    }

    @media (max-width: 413px) {
      flex-direction: column;
      align-items: center;
      align-self: center;
    }
  }

  &--blue {
    background-color: $color-torea-bay;

    .slider-steps {
      &__title {
        color: $color-wild-blue-yonder;
      }

      &__count {
        color: $color-orange;
      }

      &__slide-title,
      &__description {
        color: $color-default-white;
      }
    }

    .progress-pagination {
      &__item:not(.hack-selector) {
        &::before {
          background-color: $color-orange;
        }

        @media (hover: hover), screen and (min-width: 0\0) {
          &:not(.swiper-pagination-bullet-active) {
            &:hover,
            &:focus {
              .progress-pagination__label {
                color: $color-orange;
              }

              .progress-pagination__bullet {
                background-color: rgba($color-orange, 0.6);
                box-shadow: 0 0 0 1px rgba($color-orange, 0.6);
              }
            }
          }
        }
      }

      &__bullet {
        border-color: $color-torea-bay;
        background-color: $color-hawkes-blue;
        box-shadow: 0 0 0 1px rgba($color-hawkes-blue, 0.5);
      }

      &__label {
        color: $color-default-white;
      }

      .swiper-pagination-bullet-active {
        .progress-pagination__bullet {
          background-color: $color-orange;
          box-shadow: 0 0 0 1px rgba($color-orange, 0.5);
        }

        .progress-pagination__label {
          color: $color-orange;
        }
      }
    }
  }

  &--ai {
    padding-bottom: 48px;

    @include viewport--md {
      padding-top: 55px;
      padding-bottom: 20px;
    }

    @include viewport--sm {
      padding-top: 53px;
    }

    .swiper-pagination-bullet-active:not(.hack-selector) {
      .progress-pagination__label {
        @include viewport--md {
          font-size: 18px;
          line-height: 18px;
        }
      }
    }

    .slider-steps {
      &__slider {
        max-width: 1200px;
      }

      &__title {
        @include viewport--md {
          margin-bottom: 11px;
        }

        @include viewport--sm {
          margin-bottom: 7px;
        }
      }

      &__item {
        padding: 90px 45px 45px;
        min-height: 820px;
        align-items: center;

        @include viewport--md {
          padding: 22px 5px 45px;
          min-height: 0;
        }

        @include viewport--sm {
          padding-bottom: 14px;
        }

        img {
          position: static;
          transform: none;
          max-width: 900px;
          max-height: none;

          @include viewport--lg {
            max-width: 820px;
            width: 100%;
          }

          @include viewport--md {
            margin-top: 29px;
            margin-bottom: 0;
          }

          @include viewport--sm {
            margin-top: 10px;
            order: initial;
            margin-bottom: 0;
          }
        }

        picture {
          @include viewport--sm {
            order: initial;
            margin-bottom: 0;
          }
        }
      }

      &__count {
        font-size: 36px;
        line-height: 48px;
        margin-bottom: 32px;

        @include viewport--md {
          margin-bottom: 25px;
        }

        @include viewport--sm {
          margin-bottom: 21px;
        }
      }

      &__slide-title {
        max-width: none;
        text-align: center;
        margin-bottom: 22px;
        font-size: 36px;
        line-height: 48px;

        @include viewport--md {
          max-width: 500px;
          margin-bottom: 27px;
        }

        @include viewport--sm {
          margin-bottom: 25px;
          font-size: 24px;
          line-height: 34px;
          max-width: 300px;
        }
      }

      &__description {
        margin-bottom: 36px;
        max-width: 820px;
        text-align: center;

        @include viewport--md {
          max-width: 500px;
        }
      }
    }

    .slider-arrows__btn {
      top: 73.5%;

      @include viewport--md {
        top: 25px;
      }
    }
  }

  &--main {
    padding: 50px 0;

    @include viewport--md {
      padding: 60px 0 45px;
    }

    .container {
      padding: 0 130px;

      @include viewport--md {
        padding: 0 32px;
      }
    }

    img {
      max-width: 483px;
      margin-right: 30px;

      @include viewport--md {
        margin-right: 0;
        margin: 42px 0;
      }

      @include viewport--sm {
        max-width: 364px;
      }
    }

    .image-shadow {
      max-width: 469px;
      margin-right: 0;
      box-shadow: 0px 12px 44px rgba(66, 92, 176, 0.1);
      border-radius: 20px;
    }

    .new-page-steps {
      &__block-title {
        font-size: 36px;
        line-height: 44px;
        color: $color-torea-bay-4;

        @include viewport--sm {
          display: block;
          margin-bottom: 45px;
          font-style: italic;
          font-size: 24px;
          line-height: 30px;
          letter-spacing: 0.01em;
        }
      }

      &__subtitle {
        margin-bottom: 35px;

        @include viewport--md {
          margin-bottom: 0;
          letter-spacing: 0.01em;
        }
      }


      &__item {
        padding: 0;
        margin-bottom: 110px;

        &:last-child {
          margin: 0;
        }

        @include viewport--md {
          margin-bottom: 90px;

          &:last-child {
            margin: 0;
          }
        }
      }

      &__textbox {
        align-self: flex-start;

        @include viewport--md {
          align-self: center;
        }
      }

      &__textbox--cookieless {
        align-self: center;
      }
    }

    .new-page-steps__item--levelled {
      padding-top: 15px;

      @include viewport--md {
        padding-top: 0;
      }

      img {
        max-width: 486px;
        margin-right: 35px;

        @include viewport--md {
          margin-right: 0;
          margin-bottom: 42px;
        }

        @include viewport--sm {
          max-width: 364px;
          margin-bottom: 30px;
        }
      }
    }
  }
}
