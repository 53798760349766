.why-bigquery {
  background-color: $color-blue;
  padding: 95px 0 160px;

  @include viewport--md {
    padding: 94px 0 120px;
  }

  @include viewport--sm {
    padding: 63px 0 100px;
  }

  &__title {
    margin: 0 0 85px;
    font-size: 38px;
    line-height: 52px;
    font-weight: 700;
    color: $color-default-white;
    text-align: center;

    @include viewport--md {
      margin-bottom: 50px;
    }

    @include viewport--sm {
      font-size: 24px;
      line-height: 34px;
      max-width: 280px;
      margin: 0 auto 60px;
    }
  }

  &__list {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    list-style: none;

    @include viewport--md {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: -100px;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 60%;
      background: url("../img/svg/icon-white-arrow.svg") no-repeat;
      width: 52px;
      height: 24px;

      @media (max-width: 1279px) {
        top: 64%;
      }

      @include viewport--md {
        display: none;
      }
    }

    &::before {
      left: 22%;

      @media (max-width: 1279px) {
        left: calc(15% + 100px);
      }
    }

    &::after {
      left: 64%;

      @media (max-width: 1279px) {
        left: calc(65% + 10px);
      }
    }
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 330px;

    @include viewport--md {
      margin-bottom: 100px;

      &:not(:last-child) {
        &::before {
          content: "";
          position: absolute;
          background: url("../img/svg/icon-white-arrow.svg") no-repeat;
          width: 52px;
          height: 24px;
          transform: translateX(-50%) rotate(90deg);
          left: 50%;
          bottom: -18%;
        }
      }
    }

    &:nth-child(1) {
      max-width: 220px;
    }

    &:nth-child(2) {
      max-width: 340px;

      .why-bigquery__subtitle {
        max-width: 320px;
      }

      @media (min-width: 1024px) and (max-width: 1279px) {
        max-width: 25%;
      }
    }

    &:nth-child(3) {
      max-width: 330px;

      .why-bigquery__subtitle {
        max-width: 220px;
      }

      @media (min-width: 1024px) and (max-width: 1279px) {
        max-width: 25%;
      }
    }
  }

  &__subtitle {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    color: $color-default-white;
    text-align: center;
    margin: 0 0 20px;

    @include viewport--md {
      font-size: 18px;
      line-height: 22px;
    }
  }
}
