.accordion {
  

  &__element {
    padding-top: 32px;
    padding-bottom: 30px;
    border-bottom: 2px solid rgba($color-default-white, 0.15);
  }

  &__element.is-active > &__button {
    &::after {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &__button {
    font-family: $font-family--inter;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: $color-default-white;
    width: 100%;
    text-align: left;

    position: relative;
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      width: 24px;
      height: 24px;
      background: url("../img/svg/icon-arrow-bottom.svg") no-repeat;
      background-size: 100% 100%;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: transform $default-transition-settings;
    }
  }

  &__wrapper {
    font-family: $font-family--inter;
    color: #afb9d7;

    font-size: 16px;
    line-height: 24px;

    display: grid;
    grid-template-columns: 100%;
    gap: 8px;
    padding-top: 24px;

    p {
      margin: 0;
      font-family: $font-family--inter;
    }

    ul {
      margin: 0;
      padding: 0;
      padding-left: 20px;
    }
  }
}
