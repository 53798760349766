.case-study {
  $root: &;

  &__inner {
    display: grid;
    grid-template-columns: 36% 1fr;
    grid-template-rows: min-content min-content 1fr;
    column-gap: 25px;
    padding-top: 25px;
    padding-bottom: 45px;

    @include viewport--md {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__label {
    grid-area: 1/2/2/3;
    width: 100%;
    max-width: 687px;
    color: $color-blue;
    font-family: $font-family--inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0 0 45px auto;

    @include viewport--md {
      max-width: 515px;
      text-align: center;
      margin: 0 auto 25px;
    }

    @include viewport--sm {
      max-width: unset;
    }
  }

  &__title {
    grid-area: 2/2/3/3;
    width: 100%;
    max-width: 687px;
    color: $color-midnight-express-2;
    font-family: $font-family--inter;
    font-size: 33px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0 0 28px auto;

    span {
      color: $color-blue;
    }

    @include viewport--md {
      max-width: 515px;
      text-align: center;
      margin: 0 auto 45px;
    }

    @include viewport--sm {
      text-align: center;
      font-size: 22px;
      max-width: unset;
    }
  }

  &__text-wrap {
    grid-area: 3/2/4/3;
    width: 100%;
    max-width: 687px;
    margin: 0 0 0 auto;

    @include viewport--md {
      max-width: 515px;
      margin: 0 auto;
    }

    @include viewport--sm {
      max-width: unset;
    }
  }

  &__text {
    color: $color-port-gore;
    font-family: $font-family--lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30.2px;
    margin: 0 0 30px;

    &:last-child {
      margin-bottom: 0;
    }

    @include viewport--md {
      text-align: center;
    }

    @include viewport--sm {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 30.2px; /* 167.778% */

      & + #{$root}__text--author {
        margin-top: -12px;
      }
    }

    &--author {
      color: $color-default-black;
      font-family: $font-family--inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-top: 47px;

      @include viewport--sm {
        color: $color-black-russian;
        text-align: center;
        font-size: 18px;
      }
    }
  }

  &__img-wrap {
    position: relative;
    overflow: hidden;
    clip-path: inset(1px 1px);
    grid-area: 2/1/4/2;
    height: fit-content;
    width: 100%;
    border-radius: 6px;

    @include viewport--md {
      max-width: 515px;
      margin-bottom: 45px;
    }

    @include viewport--sm {
      max-width: unset;
    }

    &::before {
      content: "";
      display: block;
      padding-bottom: calc(585 / 892 * 100%); /* H/W */
    }
  }

  &__img-wrap img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }




  &--main {
    #{$root}__inner {
      padding: 0;
      grid-template-columns: 41% 1fr;
      column-gap: 25px;

      // @include viewport--1280 {
      //   column-gap: 30px;
      // }

      @include viewport--md {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    #{$root}__img-wrap {
      margin-top: 5px;
      border-radius: 10px;

      @include viewport--md {
        margin-top: 0;
        margin-bottom: 24px;  
        order: 1;
      }

      @include viewport--sm {
        margin-bottom: 22px;
      }

    }

    #{$root}__title {
      text-align: left;
      margin: 0 0 28px 30px;

      @include viewport--1280 {
        font-size: 26px;
        margin-left: 10px;
      }

      @include viewport--md {
        order: 2;
        font-size: 20px;
        text-align: center;
        margin: 0 0 16px;
      }

      @include viewport--sm {
        text-align: left;
      }
    }

    #{$root}__text-wrap {
      margin-left: 30px;

      @include viewport--1280 {
        margin-left: 10px;
      }

      @include viewport--md {
        order: 3;
        margin: 0;
      }
    }

    #{$root}__text {
      text-align: left;
      font-style: italic;
      

      @include viewport--md {
        font-weight: 400;
        font-size: 18px;
        line-height: 168%;
        text-align: center;
        margin-bottom: 16px;
      }

      @include viewport--sm {
        text-align: left;
      }

      &--author {
        color: $color-torea-bay-4;
        font-weight: 600;
        font-size: 20px;
        font-style: normal;
        margin-top: 30px;

        @include viewport--md {
          font-weight: 600;
          font-size: 18px;
          line-height: normal;
          margin: 0;
        }

        span {
          color: $color-blue;
        }
      }
    }

    #{$root}__block-button {
      @include viewport--md {
        text-align: center;
      }

      .btn {
        margin: 0;

        @include viewport--md {
          margin-top: 24px;
        }

        @include viewport--sm {
          width: 100%;
        }
      }
    }
  }

  &--marketing-mix-optimization {
    #{$root}__inner {
      padding: 0;
      grid-template-columns: 38% 1fr;
      column-gap: 84px;

      @include viewport--1280 {
        column-gap: 30px;
      }

      @include viewport--md {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    #{$root}__img-wrap {
      border-radius: 7px;
      box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.1);
      clip-path: initial;
      align-self: center;


      @include viewport--md {
        width: 50%;
        min-width: 374px;

        margin-top: 0;
        margin-bottom: 45px;  
      }

      @include viewport--sm {
        width: 100%;
        max-width: 374px;
        min-width: initial;
      }

    }

    #{$root}__title {
      text-align: left;
      color: $color-default-white;
      padding-top: 22px;


      @include viewport--md {
        padding: 0;
        font-size: 22px;
        text-align: center;
        margin: 0 0 45px;
      }

      span {
        color: #7d9cff;
      }
    }

    #{$root}__text-wrap {
      @include viewport--md {
        max-width: 560px;
      }
    }

    #{$root}__text {
      text-align: left;
      font-style: italic;
      font-weight: 400;
      font-size: 20px;
      line-height: 151%;
      color: $color-lavender-4;

      @include viewport--md {
        font-weight: 400;
        font-size: 18px;
        line-height: 137%;
        text-align: center;
        margin-bottom: 20px;
      }

      &--author {
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: $color-default-white;
        margin-top: 28px;

        @include viewport--md {
          font-weight: 600;
          line-height: normal;
          margin: 0 auto;
          max-width: 270px;
        }

        span {
          color: #7d9cff;
        }
      }
    }
  }
}