.blog-page {
  padding-top: 81px;

  background-image: $almost-white;

  @include viewport--md {
    padding-top: 48px;
    padding-bottom: 7px;
  }

  @include viewport--sm {
    padding-top: 43px;
    padding-bottom: 35px;
  }

  h1 {
    margin: 0 0 43px;
    font-size: 46px;
    line-height: 56px;
    font-weight: 700;
    color: $color-black;

    text-align: center;

    @include viewport--md {
      margin-bottom: 23px;
      font-size: 36px;
      line-height: 44px;
    }

    @include viewport--sm {
      margin-bottom: 35px;
      font-size: 28px;
      line-height: 36px;
    }
  }

  .page-pagination {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 94px;
    margin-bottom: 116px;
    justify-content: center;

    @include viewport--md {
      margin: 53px auto 76px;
    }

    @include viewport--sm {
      margin-bottom: 80px;
    }
  }
}

.blog-page__list {
  max-width: 1066px;
  box-sizing: content-box;
  margin: 0 auto;

  padding: 0 120px;

  @include viewport--lg {
    padding: 0 60px;
  }

  @include viewport--md {
    max-width: 532px;
    padding: 0 40px;
  }

  @include viewport--sm {
    padding: 0 22px;
  }
}

// .blog-page__item {
//   margin-bottom: 65px;
// }

// .blog-page__item + .blog-page__item {
//   margin-top: 68px;

//   @include viewport--md {
//     margin-top: 31px;
//   }

//   @include viewport--sm {
//     margin-top: 32px;
//   }
// }
