.main-nav {
  $root: &;

  flex-grow: 1;


  &__toggle {
    display: none;

    @include viewport--md {
      position: absolute;
      top: 42px;
      right: 38px;

      display: flex;
    }

    @include viewport--sm {
      top: 31px;
      right: 22px;
    }

    svg {
      transition: fill $default-transition-settings;

      &:first-child {
        display: block;

        fill: $color-blue;
      }

      &:last-child {
        display: none;
        margin: 2px;
        fill: $color-blue;
      }
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        svg {
          fill: rgba($color-blue, 0.7);
        }
      }
    }

    &--active {
      svg {
        &:first-child {
          display: none;
        }

        &:last-child {
          display: block;
        }
      }

      & ~ .main-nav__wrapper {

        @include viewport--md {
          display: flex;
          visibility: visible;
          width: 100%;
          overflow-y: auto;

          opacity: 1;
          transform: translateX(0);
        }

        .main-nav__item {
          @include viewport--md {
            transform: translateX(0);
            opacity: 1;
            transition: opacity 0.3s cubic-bezier(.3,.34,.85,.96), transform 0.3s cubic-bezier(.3,.34,.85,.96);
          }
        }
      }
    }
  }

  &__wrapper {
    display: flex;

    @include viewport--md {
      position: fixed;
      top: 108px;
      bottom: 0;
      left: 0;
      z-index: 1000;

      visibility: hidden;
      flex-direction: column;
      width: 0;
      overflow: hidden;
      padding: 0;

      opacity: 0;
      background-color: $color-default-white;
      transition: opacity 0.25s cubic-bezier(.3,.34,.85,.96), transform 0.55s cubic-bezier(.165,.84,.44,1);
      transform: translateX(100%);

      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    @include viewport--sm {
      top: 89px;
      padding: 0;
    }
  }

  &__list {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    //margin-right: 64px;
    padding: 0;
    padding-bottom: 4px;
    margin-left: auto;
    margin-right: auto;

    list-style: none;

    @include viewport--lg {
      //margin-right: 2vw;
      padding-bottom: 0;
    }

    @include viewport--md {
      flex-shrink: 0;
      flex-direction: column;
      margin-right: 0;
      margin-left: 0;
      align-items: stretch;
      margin-bottom: 30px;
    }
  }

  &__item {
    position: relative;
    margin-top: 5px;
    margin-bottom: 5px;

    @include viewport--md {
      position: static;
      margin-top: 0;
      margin-bottom: 0;

      border-bottom: 1px solid $color-periwinkle-gray;

      transform: translateX(-15px);
      opacity: 0;
    }

    &:first-child {
      @include viewport--md {
        border-top: 1px solid $color-periwinkle-gray;
      }
    }

    &.main-nav__item:nth-child(1) {
      @include viewport--md {
        transition-delay: 0.12s;
      }
    }
    &.main-nav__item:nth-child(2) {
      @include viewport--md {
        transition-delay: 0.24s;
      }
    }
    &.main-nav__item:nth-child(3) {
      @include viewport--md {
        transition-delay: 0.36s;
      }
    }
    &.main-nav__item:nth-child(4) {
      @include viewport--md {
        transition-delay: 0.48s;
      }
    }
    &.main-nav__item:nth-child(5) {
      @include viewport--md {
        transition-delay: 0.6s;
      }
    }
    &.main-nav__item:nth-child(6) {
      @include viewport--md {
        transition-delay: 0.72s;
      }
    }
    &.main-nav__item:nth-child(7) {
      @include viewport--md {
        transition-delay: 0.84s;
      }
    }

    &--active {
      @include viewport--md {
        border-bottom: none;
      }
    }
  }

  &__item + &__item {
    margin-left: 4vw;

    @include viewport--lg {
      margin-left: 2.5vw;
    }

    @include viewport--md {
      margin-left: 0;
      // margin-top: 30px;
    }
  }

  &__link {
    display: flex;
    align-items: center;

    transition: color $default-transition-settings;

    color: $color-fiord;
    font-family: $font-family--inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:not(.main-nav__link--active):hover,
      &:not(.main-nav__link--active):focus {
        color: $color-blue;

        svg {
          stroke: $color-blue;

          // @include viewport--md {
          //   transform: translateX(15%) rotate(-90deg);
          // }
        }
      }
    }

    @include viewport--lg {
      font-size: 14px;
      line-height: 16px;
    }

    @include viewport--md {
      padding: 15px 25px;

      font-size: 24px;
      line-height: 29px;
      justify-content: space-between;
      color: $color-torea-bay-4;
    }

    svg {
      margin-left: 12px;

      fill: $color-transparent;
      stroke: $color-torea-bay-4;
      transition: stroke $default-transition-settings;

      @include viewport--lg {
        margin-left: 8px;
      }

      @include viewport--md {
        // transform: rotate(-90deg);
        width: 21px;
        height: auto;
      }
    }

    &--active {
      color: $color-blue;

      svg {
        stroke: $color-blue;

        @include viewport--md {
          transform: scaleY(-1);
        }
      }
    }

    &--current {
      color: $color-blue;
    }
  }

  &__action-wrapper {
    display: flex;
    align-self: center;

    @include viewport--md {
      align-self: initial;
      padding: 0 25px;
      // margin-top: auto;
      margin-bottom: 40px;
    }

    @include viewport--sm {
      margin-bottom: 15px;
    }

    .btn:not(.hack-selector) {
      @include viewport--md {
        padding-top: 14px;
        min-height: 49px;
        line-height: 21px;
        width: 100%;
        margin-bottom: 20px;
      }
    }

    & > :first-child {
      min-width: 166px;
      //margin-right: 24px;

      @include viewport--lg {
        min-width: 142px;
        //margin-right: 1vw;
      }

      //@include viewport--md {
      //  margin-right: 28px;
      //}
    }
  }

  &__submenu {
    display: flex;
    position: absolute;
    // background-image: $mild-white;
    // box-shadow: 0 20px 50px 10px rgba(30, 49, 108, 0.2);
    background: $color-default-white;
    box-shadow: 0px 0px 40px rgba(30, 49, 108, 0.3);
    border-radius: 10px;
    bottom: -25px;
    transform: translateY(100%);
    z-index: 1000;
    visibility: hidden;
    opacity: 0;
    transition: opacity $default-transition-settings,
      visibility $default-transition-settings;

    @include viewport--md {
      height: 0;
      overflow: hidden;

      background-image: none;
      background-color: $color-default-white;
      // padding: 30px 31px 0;
      flex-direction: column;
      border-radius: 0;
      transform: none;
      box-shadow: none;
      // height: 100%;
      // overflow-y: auto;

      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    // @include viewport--sm {
    //   padding: 30px 16px 0;
    // }

    &::before {
      content: "";
      position: absolute;
      top: -8px;
      left: 15%;
      width: 17px;
      height: 10px;
      // background: url("../img/svg/icon-submenu-polygon.svg") no-repeat;
      background: url("../img/svg/icon-submenu-polygon-solutions.svg") no-repeat;

      @include viewport--md {
        display: none;
      }
    }

    &--open {
      height: auto;

      visibility: visible;
      opacity: 1;

      @include viewport--md {
        position: static;
      }
    }

    .main-nav__action-wrapper {
      display: none;

      @include viewport--md {
        display: flex;
      }
    }

    .main-nav__submenu-wrap {
      width: 100%;
      display: flex;

      @include viewport--md {
        display: block;
      }
    }

    .main-nav__submenu-list {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;

      border-radius: 10px;
      overflow: hidden;
    }

    .main-nav__submenu-mob-group {
      margin: 0;
      padding: 0;
      list-style: none;

      &::after {
        content: "";

        display: block;
        height: 1px;
        margin: 9px 44px 10px 44px;
        // width: 100%;
        // height: 20px;
        // border-color: transparent;
        // border-top-width: 9px;
        // border-right-width: 44px;
        // border-bottom-width: 10px;
        // border-left-width: 44px;

        background-color: $color-periwinkle-gray-2;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      &--no-separator {
        &::after {
          display: none;
        }
      }
    }

    .main-nav__submenu-item {
      &:hover {
        background-color: $color-zumthor;

        @include viewport--md {
          background-color: transparent;
        }
      }

      &--optimisation {
        span {
          @include viewport--sm {
            margin-right: -13px;
          }
        }
      }
    }

    .main-nav__submenu-backwards {
      display: none;

      @include viewport--md {
        // display: flex;
        align-items: center;

        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
        color: $color-east-bay;
        transition: color $default-transition-settings;
        margin-bottom: 20px;
        transform: translate3d(0, 0, 0);
      }

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-blue;

          svg {
            stroke: $color-blue;
            transform: translateX(-15%) rotate(90deg);
          }
        }
      }

      svg {
        margin-right: 18px;
        transform: rotate(90deg);
        width: 18px;
        height: auto;

        fill: $color-transparent;
        stroke: $color-east-bay;
        transition: stroke $default-transition-settings, transform $default-transition-settings;
      }
    }

    &--company {
      left: -20%;
      width: 247px;

      @include viewport--lg {
        width: 200px;
      }

      @include viewport--md {
        width: 100%;
        left: 0;
        bottom: auto;
        top: 0;
      }

      .main-nav__submenu-list {
        width: 100%;
        flex-direction: column;

        // @include viewport--md {
        //   margin-bottom: 40px;
        // }
      }

      .main-nav__submenu-item {
        display: flex;
        padding: 0 35px;

        @include viewport--md {
          padding: 0;
        }

        & + .main-nav__submenu-item {
          border-top: 1px solid $color-lavender;

          @include viewport--md {
            border: none;
          }
        }
      }

      .main-nav__submenu-mobile-image {
        padding: 0 40px;
      }

      .main-nav__submenu-link {
        padding: 20px 0;
        font-size: 16px;
        line-height: 19px;
        color: $color-fiord;
        font-weight: 500;
        font-family: $font-family--inter;

        transition: color $default-transition-settings;

        // @media (hover: hover), screen and (min-width: 0\0) {
        //   &:not(.main-nav__submenu-link--active):hover,
        //   &:not(.main-nav__submenu-link--active):focus {
        //     color: $color-blue;
        //   }
        // }

        @include viewport--md {
          display: flex;
          align-items: flex-end;
          width: 100%;
          padding: 25px 42px;

          font-size: 18px;
          line-height: 22px;
          // color: $color-east-bay;
          // margin-bottom: 25px;

          img {
            flex-shrink: 0;
            align-self: flex-start;
            margin-right: 17px;
          }
        }

        &--active {
          color: $color-blue;
        }

        &--current {
          color: $color-blue;
        }
      }
    }

    &--products {
      left: -155%;
      width: 976px;
      min-height: 339px;

      @include viewport--lg {
        width: 750px;
      }

      @include viewport--md {
        width: 100%;
        left: 0;
        bottom: auto;
        top: 0;
      }

      &::before {
        left: 17%;
      }

      .main-nav {
        &__submenu-wrapper {
          width: 50%;

          &:nth-of-type(1) {
            border-right: 1px solid $color-lavender;

            @include viewport--md {
              border: none;
            }
          }

          @include viewport--md {
            width: 100%;
          }
        }

        &__submenu-item {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 38px 40px 27px 90px;

          & + .main-nav__submenu-item {
            border-top: 1px solid $color-lavender;

            @include viewport--md {
              border: none;
              margin-bottom: 40px;

              &::before {
                display: none;
              }
            }
          }

          @include viewport--md {
            padding: 0;
            margin-bottom: 40px;

            &::before {
              content: "";
              position: absolute;
              width: 100vw;
              height: 1px;
              left: -31px;
              bottom: -25px;
              background-color: $color-lavender;
            }
          }

          @include viewport--sm {
            &::before {
              left: -16px;
            }
          }

          &--xl {
            @media (min-width: 1024px) {
              padding: 44px 70px 41px 42px;

              .main-nav {
                &__submenu-img {
                  position: static;
                  width: 79px;
                  height: 52px;
                  object-fit: contain;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }

                &__submenu-title {
                  font-size: 24px;
                  line-height: 29px;
                  margin: 25px 0 13px;
                }

                &__submenu-description {
                  font-size: 16px;
                  line-height: 22px;
                }

                &__submenu-more {
                  color: $color-default-white;
                  box-shadow: none;
                  border-radius: 5px;
                  background-color: $color-blue;
                  padding: 12px 20px;
                  min-width: 147px;
                  text-align: center;
                  margin-top: 5px;
                  text-decoration: none;

                  @media (hover: hover), screen and (min-width: 0\0) {
                    &:not(.hack-selector):hover,
                    &:not(.hack-selector):focus {
                      background-color: $color-cerulean-blue;
                      color: $color-default-white;
                    }
                  }
                }
              }
            }
          }
        }

        &__submenu-img {
          position: absolute;
          top: 36px;
          left: 32px;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;

          @include viewport--md {
            display: none;
          }
        }

        &__submenu-title {
          font-size: 18px;
          line-height: 22px;
          font-weight: 700;
          color: $color-black;
          margin: 0 0 13px;

          a {
            color: inherit;
          }
        }

        &__submenu-description {
          font-size: 14px;
          line-height: 18px;
          color: $color-comet;
          margin: 0 0 16px;
        }

        &__submenu-more {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: $color-blue;
          text-decoration: underline;
          transition: color $default-transition-settings, background $default-transition-settings;

          @media (hover: hover), screen and (min-width: 0\0) {
            &:not(.main-nav__submenu-more--active):hover,
            &:not(.main-nav__submenu-more--active):focus {
              color: $color-black;
            }
          }

          &--active {
            color: rgba($color-comet, 0.6);
          }
        }
      }
    }

    &--detailed {
      left: -155px;

      width: 390px;
      // padding: 29px 23px 28px 28px;
      padding: 14px 0;

      background: linear-gradient(2.79deg, #FFFFFF -4.96%, #EBF2FF 162.91%);
      border: 2px solid rgba(227, 236, 249, 0.14);
      box-shadow: 0px 30px 40px rgba(30, 49, 108, 0.3);
      border-radius: 10px;

      &::before {
        left: calc(50% - 8px);
        background: url("../img/svg/icon-submenu-polygon-solutions.svg") no-repeat;
      }

      // &::after {
      //   content: "";
      //   position: absolute;
      //   width: 100%;
      //   height: 15px;
      //   top: -15px;
      //   left: 0;

      //   display: block;
      // }

      @include viewport--md {
        width: 100%;
        left: 0;
        bottom: auto;
        top: 0;

        // padding: 30px 31px 0;
        padding: 0;

        background-image: none;
        background-color: $color-default-white;
        border-radius: 0;
        box-shadow: none;
      }

      .main-nav__submenu-list-detailed {
        list-style: none;
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;

        @include viewport--md {
          display: none;
        }
      }

      // .main-nav__submenu-item-detailed:not(:last-child) {
      //   margin-bottom: 28px;
      // }

      .main-nav__submenu-link-detailed {
        display: flex;
        align-items: flex-start;
        padding: 14px 23px 14px 28px;
        margin-left: -2px;
        margin-right: -2px;

        img {
          flex-shrink: 0;
          margin-right: 15px;

          &:nth-child(2) {
            display: none;
          }
        }

        &:hover {
          background-color: $color-zumthor;

          img:first-child {
            display: none;
          }

          img:nth-child(2) {
            display: block;
          }

          // .main-nav__submenu-title-detailed {
          //   color: $color-royal-blue-4;
          // }

          // .main-nav__submenu-descr-detailed {
          //   color: $color-dull-lavender;
          // }
        }
      }

      .main-nav__submenu-title-detailed {
        display: block;
        margin-bottom: 10px;

        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: $color-fiord;

        transition: color $default-transition-settings;
      }

      .main-nav__submenu-descr-detailed {
        display: block;

        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        // color: rgba(95, 98, 128, 0.71);
        color: $color-manatee-2;

        transition: color $default-transition-settings;
      }

      .main-nav__submenu-list--mob {
        display: none;

        @include viewport--md {
          display: flex;
        }
      }
    }

    &--detailed-columns {
      right: 50%;
      left: auto;

      width: 752px;
      padding: 28px 0 17px;

      background: $color-default-white;
      border: 2px solid rgba(227, 236, 249, 0.14);
      box-shadow: 0px 0px 40px rgba(30, 49, 108, 0.3);
      border-radius: 10px;

      transform: translateX(50%) translateY(100%);

      &::before {
        left: calc(50% - 8px);
        background: url("../img/svg/icon-submenu-polygon-solutions.svg") no-repeat;

        @include viewport--lg {
          left: calc(50% + 52px);
        }
      }

      // &::after {
      //   content: "";
      //   position: absolute;
      //   width: 100%;
      //   height: 15px;
      //   top: -15px;
      //   left: 0;

      //   display: block;
      // }

      @include viewport--lg {
        right: calc(50% + 60px);
      }

      @include viewport--md {
        width: 100%;
        left: 0;
        bottom: auto;
        top: 0;

        // padding: 30px 31px 0;
        padding: 0;

        background-image: none;
        background-color: $color-default-white;
        border: none;
        border-radius: 0;
        box-shadow: none;

        transform: none;
      }

      .main-nav__submenu-columns {
        display: flex;
        justify-content: space-between;
        width: calc(100% + 4px);
        margin-left: -2px;
        margin-right: -2px;

        @include viewport--md {
          display: none;
        }
      }

      .main-nav__submenu-column {
        // width: 334px;
        &:first-child {
          width: 373px;
        }

        &:last-child {
          width: 390px;

          .main-nav__submenu-link-detailed {
            padding-left: 28px;
            padding-right: 28px;
            //margin-left: 14px;
          }
        }

        &--sm {
          &:first-child,
          &:last-child {
            width: 227px;
          }
        }
      }

      .main-nav__submenu-column-title {
        display: block;
        padding-left: 28px;
        margin-bottom: 15px;

        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: $color-blue;
        font-family: $font-family--inter;
        min-height: 24px;

        &[href] {
          transition: opacity $default-transition-settings;

          &:hover {
            opacity: 0.7;
          }
        }

        @include viewport--md {
          padding: 15px 42px;
          margin-bottom: 0;
          min-height: 22px;
          font-size: 18px;
          line-height: 22px;
          color: $color-fiord;
        }
      }

      .main-nav__submenu-list-detailed {
        list-style: none;
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;

        @include viewport--md {
          display: none;
        }
      }

      // .main-nav__submenu-item-detailed:not(:last-child) {
      //   margin-bottom: 28px;
      // }

      .main-nav__submenu-image-detailed {
        display: flex;
        width: 348px;
        padding-left: 26px;
        padding-top: 13px;
      }

      .main-nav__submenu-link-detailed {
        display: flex;
        align-items: flex-start;
        padding: 14px 5px 14px 28px;

        img {
          flex-shrink: 0;
          margin-right: 15px;

          &:nth-child(2) {
            display: none;
          }
        }

        &:hover {
          background-color: $color-zumthor;

          img:first-child {
            display: none;
          }

          img:nth-child(2) {
            display: block;
          }

          // .main-nav__submenu-title-detailed {
          //   color: $color-royal-blue-4;
          // }

          // .main-nav__submenu-descr-detailed {
          //   color: $color-dull-lavender;
          // }
        }
      }

      .main-nav__submenu-title-detailed {
        display: block;
        margin-bottom: 10px;

        font-weight: 500;
        font-size: 15px;
        line-height: 19px;
        color: $color-fiord;
        font-family: $font-family--inter;

        transition: color $default-transition-settings;
      }

      .main-nav__submenu-descr-detailed {
        display: block;

        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: rgba(95, 98, 128, 0.71);
        //color: $color-manatee-2;
        font-family: $font-family--lato;

        transition: color $default-transition-settings;
      }

      .main-nav__submenu-link-sm-col {
        position: relative;

        display: flex;
        align-items: flex-end;
        padding: 14px 25px 14px 28px;

        &:hover {
          &::before {
            opacity: 1
          }
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: -4px;
          left: 0;
          z-index: -1;

          display: block;

          opacity: 0;
          background-color: $color-zumthor;

          pointer-events: none;
          transition: opacity $default-transition-settings;
        }

        img {
          flex-shrink: 0;
          margin-right: 10px;
        }
      }

      .main-nav__submenu-text-wrapper-sm-col {
        margin-bottom: -3px;

        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: $color-fiord;
      }

      .main-nav__submenu-list--mob {
        display: none;

        @include viewport--md {
          display: flex;
        }
      }
    }

    &--one-sm-column {
      right: calc(50% + 50px);

      width: 412px;

      @include viewport--md {
        width: 100%;
      }

      .main-nav__submenu-column:last-child .main-nav__submenu-link-detailed {
        padding-right: 45px;
      }

      .main-nav__submenu-column {
        &:first-child {
          width: 100%;
        }
      }

      &::before {
        left: calc(50% + 40px);
      }
    }
  }

  &__founder {
    display: none;

    @include viewport--md {
      padding: 0 25px;
      display: block;
    }
  }
}
