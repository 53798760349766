.main-video-mobile {
  display: none;
  overflow: hidden;

  @include viewport--md {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    //padding-top: 0;
    padding-bottom: 68px;
  }

  @include viewport--sm {
    padding-top: 60px;
    padding-bottom: 38px;
  }

  &__title {
    margin-bottom: 38px;

    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: $color-comet;

    @media (max-width: 330px) {
      font-size: 15px;
    }
  }

  &__wrapper {
    width: 100%;
    max-width: 497px;
  }

  &__video {
    position: relative;

    display: block;
    width: 100%;
    padding-top: 56.3%;
    background: linear-gradient(108.91deg, #5483EC 4.74%, #5789FF 20.36%, #5381FF 66.97%, #3D60CC 80.35%, #3B51AD 99.59%);

    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;

      width: 100%;
      height: 100%;

      border: 2px solid $color-blue;

      pointer-events: none;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
